export default {
  state: {
    search: null,
    previousRoute: "",
  },
  getters: {
    getLastRoute: (d) => {
      return d.routerHistory[d.routerHistory.length - 1];
    },
  },
  mutations: {
    SET_PREV_ROUTE(state, payload) {
      state.previousRoute = payload;
    },
    SET_SEARCH(state, search) {
      state.search = search;
    },
  },
  actions: {
    setPreviousRoute({commit}, payload) {
      commit("SET_PREV_ROUTE", payload);
    },
    setSearch({commit}, search) {
      commit("SET_SEARCH", search);
    },
  },
};
