<template>
  <div class="embed-responsive embed-responsive-16by9 documentPreview">
    <iframe
      frameborder="0"
      scrolling="no"
      style="height:650px;width:100%;border:none;"
      :src="iframesrc"
    ></iframe>
  </div>
</template>


<script>
import Vue from "vue";
import { mapState } from "vuex";
import UtilityString from '@/utilities/strings';
import EVENTS from "@/constants/events";

let utility = new UtilityString();

export default {
  props: {
    dataObj: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      EVENT_ACTION: {},
      iframesrc: "",
      modal: {
        hideShowModal: false,
        message: "",
        modalSaveButtonTitle: "",
        data: {}
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    //Initialize Data
    loadData() {

      let uniqueId = utility.generateGUID();    

      this.$nextTick(() => {
        this.iframesrc = this.dataObj.url + "&v=" + uniqueId;
      });
    }
  },

  mounted() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    this.loadData();
  },

  components: {}
};
</script>
