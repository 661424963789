<template>
  <div>
    <!--start modal new project body -->
    <div class="col-md-12 u-pt-small">
      <div v-if="isError" class="alert alert-danger" role="alert">
        Sorry, there was an error while creating this job. Please try again.
      </div>
      <div v-if="!modalObj.isInEditMode" class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Select Equipment
          </div>
          <v-select v-model="selectedEquipment" id="slidepanel-edit-work-order-tabs-equipment-modal-select-equipment"
            :options="uxEquipmentList" @input="onUpdateSelection" code="id" label="displayName" :clearable="false">
          </v-select>
        </div>
      </div>
      <!-- row -->
      <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

      <div class="row">
        <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Equipment Name
          </div>
          <ValidationProvider name="name" rules="required" v-slot="{errors}">
            <input class="form-control" id="slidepanel-edit-work-order-tabs-equipment-modal-equipment-name" type="text"
              v-model="modalDataDetails.Name" @keyup="
                updateFieldSimplified(
                  'Name', //PropertyName
                  modalDataDetails.Name, //Property Value
                  'Equipment Name', //Display Name
                  true
                )
              " />
            <span class="text-danger">{{ errors.length > 0 ? "Equipment Name is required." : "" }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Location On Site
          </div>
          <!-- <ValidationProvider name="location" rules="required" v-slot="{errors}"> -->
          <input type="text" class="form-control" id="slidepanel-edit-work-order-tabs-equipment-modal-location-on-site"
            v-model="modalDataDetails.LocationOnSite" @keyup="
              updateFieldSimplified(
                'LocationOnSite', //PropertyName
                modalDataDetails.LocationOnSite, //Property Value
                'Location on site', //Display Name
                true
              )
            " />
          <!-- <span class="text-danger">{{ errors.length > 0 ? "Location is required." : "" }}</span>
          </ValidationProvider> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Description
          </div>
          <!-- <ValidationProvider name="description" rules="required" v-slot="{errors}"> -->
          <textarea class="form-control" id="slidepanel-edit-work-order-tabs-equipment-modal-description"
            v-model="modalDataDetails.Description" @keyup="
              updateFieldSimplified(
                'Description', //PropertyName
                modalDataDetails.Description, //Property Value
                'Description', //Display Name
                true
              )
            "></textarea>
          <!-- <span class="text-danger">{{ errors.length > 0 ? "Description is required." : "" }}</span>
          </ValidationProvider> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Make
          </div>
          <!-- <ValidationProvider name="make" rules="required" v-slot="{errors}"> -->
          <input type="text" class="form-control" id="slidepanel-edit-work-order-tabs-equipment-modal-make"
            v-model="modalDataDetails.Make" @keyup="
              updateFieldSimplified(
                'Make', //PropertyName
                modalDataDetails.Make, //Property Value
                'Make', //Display Name
                true
              )
            " />
          <!-- <span class="text-danger">{{ errors.length > 0 ? "Make is required." : "" }}</span>
          </ValidationProvider> -->
        </div>
        <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Model Number
          </div>
          <!-- <ValidationProvider name="modelNumber" rules="required" v-slot="{errors}"> -->
          <input type="text" class="form-control" id="slidepanel-edit-work-order-tabs-equipment-modal-model-number"
            v-model="modalDataDetails.ModelNumber" @keyup="
              updateFieldSimplified(
                'ModelNumber', //PropertyName
                modalDataDetails.ModelNumber, //Property Value
                'Model Number', //Display Name
                true
              )
            " />
          <!-- <span class="text-danger">{{ errors.length > 0 ? "Model Number is required." : "" }}</span>
          </ValidationProvider> -->
        </div>
        <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Serial Number
          </div>
          <!-- <ValidationProvider name="serialNumber" rules="required" v-slot="{errors}"> -->
          <input type="text" class="form-control" id="slidepanel-edit-work-order-tabs-equipment-modal-serial-number"
            v-model="modalDataDetails.SerialNumber" @keyup="
              updateFieldSimplified(
                'SerialNumber', //PropertyName
                modalDataDetails.SerialNumber, //Property Value
                'Serial Number', //Display Name
                true
              )
            " />
          <!-- <span class="text-danger">{{ errors.length > 0 ? "Serial Number is required." : "" }}</span>
          </ValidationProvider> -->
        </div>
        <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Manufacturer / Supplier
          </div>
          <!-- <ValidationProvider name="manufacturer" rules="required" v-slot="{errors}"> -->
          <input type="text" class="form-control"
            id="slidepanel-edit-work-order-tabs-equipment-modal-manufacturer-supplier"
            v-model="modalDataDetails.Manufacturer" @keyup="
              updateFieldSimplified(
                'Manufacturer', //PropertyName
                modalDataDetails.Manufacturer, //Property Value
                'Manufacturer/Supplier', //Display Name
                true
              )
            " />
          <!-- <span class="text-danger">{{ errors.length > 0 ? "Manufacturer / Supplier is required." : "" }}</span>
          </ValidationProvider> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Notes
          </div>
          <textarea class="form-control" type="text" id="slidepanel-edit-work-order-tabs-equipment-modal-notes"
            v-model="modalDataDetails.Notes" rows="5" @keyup="
              updateFieldSimplified(
                'Notes', //PropertyName
                modalDataDetails.Notes, //Property Value
                'Notes', //Display Name
                true
              )
            " />
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- col-md-12-->
    <!--end modal new project body -->
  </div>
</template>

<script>
import EVENTS from "@/constants/events";
import editMixin from "@/mixin/edit-mixin";

import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";

const global = new GlobalServices();

export default {
  mixins: [editMixin],

  data() {
    return {
      isInfo: true,
      isSuccesful: false,
      isError: false,
      selectedEquipment: null,
      selectedFilter: "all",
      formData: {
        actionId: MODULE.EQUIPMENT.ActionId,
        moduleId: MODULE.PROJECTS.ModuleId,
        Name: null,
        LocationOnSite: null,
        Description: null,
        Make: null,
        ModelNumber: null,
        SerialNumber: null,
        Manufacturer: null,
        Notes: null,
      },

      uxEquipmentList: [],

      modalStatus: {
        modalName: "",
        modalTitle: "",
        data: null,
      },
    };
  },
  watch: {
    modalDataDetails: {
      handler(val) {
        this.updateModalData(val);
      },
      deep: true,
    },
  },

  methods: {
    updateModalData(data) {
      this.$store.dispatch("setModalDataDetailsObject", data);
    },
    async onUpdateSelection() {
      this.modalDataDetails.Name = this.selectedEquipment.displayName;
      let item = await global.getEditDetails(MODULE.INVENTORY.ActionId, this.selectedEquipment.id);
      if (!item) item = await global.getEditDetails(MODULE.PRICING.ActionId, this.selectedEquipment.id);
      this.modalDataDetails.LocationOnSite = item.LocationOnSite;
      this.modalDataDetails.Description = `${this.selectedEquipment.displayName}${item.Description ? "\n" + item.Description : ""}`;
      this.modalDataDetails.Make = item.Make;
      this.modalDataDetails.ModelNumber = item.ModelNumber;
      this.modalDataDetails.SerialNumber = item.SerialNumber;
      this.modalDataDetails.Manufacturer = item.Manufacturer;
      this.modalDataDetails.Notes = item.Notes;
    },
    async initializeData() {
      //Get Data From Modal
      this.modalStatus = this.$store.getters.getModalStatus;
      if (this.modalObj.isInEditMode) {
        //Load Details Details from API
        this.formData = await global.getEditDetails(MODULE.PROJECTS.Equipment.ActionId, this.modalObj.data.id);
        this.formData.actionId = MODULE.PROJECTS.Equipment.ActionId;
        this.formData.moduleId = MODULE.PROJECTS.ModuleId;
      }

      this.formData.parentId = this.modalStatus.data.intProjectID;
      //Get Inventory List
      let equipmentList = await global.getUXDropDown(MODULE.INVENTORY.ActionId);
      this.uxEquipmentList = equipmentList || [];
      this.updateModalData(this.formData);
    },
  },

  components: {},
  async mounted() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    this.initializeData();
  },
};
</script>
