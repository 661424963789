<template>
  <div class="align-items-center col-xl-3 col-lg-4 col-md-6 pr-1 pb-3">
    <div class="py-4 pl-3 pr-2 d-flex dash-grey-card c-card">
      <div>
        <i :class="`fa ${stat.icon ? stat.icon : 'fa-bar-chart'} card-icon ss-primary-font-color`"></i>
      </div>
      <div class="ml-3">
        <h4 class="d-block text-muted">{{ stat.title }}</h4>
        <h4 class="font-weight-normal">{{ stat.numberValue ? stat.numberValue : "-" }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ModalSmallCard",
    props: {
      stat: {
        type: Object,
        required: true,
      },
    },
  };
</script>
