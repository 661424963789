import axios from "axios";
import EditDataObject from "@/interfaces/EditDataObject";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class ProjectServices {
  config = new API_CONFIG();
  auth = AuthService.getInstance();

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async lookupReference(id, refNumber) {
    let queryString = this.auth.getQueryString();
    let dataResults = await axios.get("projects/referencenumber" + queryString + "&p1=" + refNumber + "&id=" + id, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Global Generic update method method
  async updateData(editObj: EditDataObject) {
    editObj.actionId = 23;
    editObj = Object.assign(editObj, this.auth.getAuthObject());

    let statusResult = await axios
      .put("global/update", editObj, apiHeaderObj)
      .then((results) => {
        return results.data;
      })
      .catch(function(error) {});

    return statusResult;
  }

  //List Project Materials
  async getProjectMaterials(id: number) {
    let queryString = this.auth.getQueryString();

    queryString += "&pid=" + id + "&typeId=1&type=material&moduleId=2&projectID=" + id;

    let dataResults = await axios.get("materials" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  //On Project Hover, return job description summary and notes
  async getProjectHoverSummary(id: number) {
    let queryString = this.auth.getQueryString();
    let dataResults = await axios.get("projects/hover" + queryString + "&id=" + id, apiHeaderObj);

    return dataResults.data.resources;
  }

  //On Project Hover, return job description summary and notes
  async getDispatchMessage(id: number) {
    let queryString = this.auth.getQueryString();
    let dataResults = await axios.get("projects/dispatch/message" + queryString + "&id=" + id, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Get Project Details by ProjectID
  async getProjectDetails(id: number) {
    let queryString = this.auth.getQueryString();
    let dataResults = await axios.get("projects/details" + queryString + "&test=123&id=" + id, apiHeaderObj);

    return dataResults.data.resources;
  }
}

export default ProjectServices;
