<template>
  <b-card class="success-holder dash-grey-card border-0 py-3">
    <div class="text-center pt-5">
      <img :src="successUrl" alt="" width="200" height="auto" class="mx-auto mb-3" />
      <template v-if="!loading">
        <h1 class=" font-weight-bold" v-if="amount > 0">{{ amount | currency({fractionCount: getDecimalPoint(amount)}) }}</h1>
        <h2 class="col-md-6 mx-auto" v-html="mainText"></h2>
        <h5 class="text-muted" v-if="false">{{ subText }}</h5>
        <div v-if="enabledNewTransaction">
          <b-button size="md" @click="newTransaction" class="ss-warning-bg mr-2 border-0" id="add_another"><i class="fa fa-plus u-mr-xsmall"></i> Add Another Item</b-button>
        </div>
      </template>
      <template v-else><h1 class="font-weight-bold">Loading...</h1></template>
    </div>
  </b-card>
</template>

<script>
  import DefaultSuccessImg from "@/assets/images/success-check.gif";
  export default {
    data() {
      return {
        successUrl: DefaultSuccessImg + "?time=" + new Date().getTime(),
      };
    },
    props: {
      amount: {
        type: Number,
        required: false,
        default: 0,
      },
      mainText: {
        type: String,
        required: false,
        default: "Your payment was recorded successfully.",
      },
      subText: {
        type: String,
        required: false,
        default: "Your payment was successfully recorded.",
      },
      loading: {
        type: Boolean,
        default: false,
      },
      enabledNewTransaction: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      newTransaction() {
        this.$emit("transaction");
      },
    },
  };
</script>
<style scoped>
  .success-holder {
    height: 100%;
    box-shadow: none !important;
    position: absolute;
    left: 0;
    right: 0;
    /* top: 0; */
    bottom: 0;
  }
  /* @media (max-height: 600px) {
    .success-holder img {
      width: 50%;
    }
  } */

  @media (max-height: 400px) {
    .success-holder img {
      width: 20%;
      margin-bottom: 0px !important;
    }
    h2 {
      margin-bottom: 0px;
    }
  }
</style>
