<template>
  <div>
    <b-alert v-if="response.type === 'danger'" :show="response.show" class="mb-2" variant="danger">
      <i class="fa fa-times mr-2"></i>
      <span class="sub-title mr-2"> {{ response.message }} </span>
    </b-alert>
    <b-alert show class="mb-2" variant="info">
      <i class="fa fa-info mr-2"></i>
      <span class="sub-title mr-2"> Please send feedback or report bugs in the description; we've already attached a screenshot and know who you are :) so click send. </span>
    </b-alert>
    <div v-if="screen === 'success'">
      <SuccessScreen :amount="0" main-text="Successfully sent your bug report. Thank you!" />
    </div>
    <div v-else>
      <ValidationObserver ref="formDocumentEditor">
        <div class="mb-3">
          <h4>Issue Description</h4>
          <ValidationProvider v-slot="v" name="Feedback" rules="required">
            <textarea v-model="feedback" class="form-control" type="text" rows="4" />
            <span v-if="v.errors && v.errors.length > 0" class="text-danger">{{ v.errors[0] ? "Please fill up the description of the issue" : "" }}</span>
          </ValidationProvider>
        </div>
        <h4>Automatic Screenshot</h4>
        <b-card class="p-1" no-body>
          <img v-if="modalStatus.data && modalStatus.data.screenshotUrl" :src="modalStatus.data.screenshotUrl" />
          <b-skeleton-img v-else />
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
  import Vue from "vue";
  import {mapGetters} from "vuex";
  import EVENTS from "@/constants/events";
  import BugReportService from "@/services/bug-report";
  import SuccessScreen from "@/components/_universal/global/success-screen.vue";
  const bugReportService = new BugReportService();
  export default {
    data() {
      return {
        feedback: null,
        response: {
          type: "error",
          show: false,
          message: "",
        },
        screen: "",
      };
    },
    components: {
      SuccessScreen,
    },
    computed: {
      ...mapGetters({modalStatus: "getModalStatus"}),
    },
    methods: {
      async onSave() {
        try {
          this.screen = "";
          this.$root.$emit("toggle-save-button", false);
          const user = Vue.prototype.$auth.user;
          await bugReportService.submitBugReport({feedback: this.feedback, screenshot: this.modalStatus.data.screenshotUrl, email: user.email});
          this.feedback = null;
          this.screen = "success";
          this.$root.$emit("toggle-save-button", true);
        } catch (e) {
          this.response = {
            type: "danger",
            show: true,
            message: e.message,
          };
        }
      },
    },
    created() {
      this.EVENT_ACTION = EVENTS;
      this.screen = "";
      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.SETTINGS.BugReportModal, this.onSave);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.SETTINGS.BugReportModal, this.onSave);
    },
  };
</script>
