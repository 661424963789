<template>
  <b-tabs content-class="mt-3" class="position-relative" style="top:-15px">
    <b-tab active id="stats-tab-1" lazy>
      <template v-slot:title>
        <!-- <span class="badge badge-warning">New</span> &nbsp; -->
        <strong>Stats</strong>
      </template>
      <div class="row">
        <modal-small-card v-for="stat in stats" :key="stat.id" :stat="stat" />
      </div>
    </b-tab>
    <b-tab active id="stats-tab-2" lazy>
      <template v-slot:title>
        <!-- <span class="badge badge-warning">New</span> &nbsp; -->
        <strong>Charts</strong>
      </template>
      <div class="row">
        <div class="col-lg-6 mb-3 mb-3">
          <BarChart v-if="Object.keys(barDataSets).length > 0" :datasets="barDataSets" :height="200" />
        </div>
        <div class="col-lg-6 mb-3">
          <PieChart v-if="Object.keys(pieDataSets).length > 0" :datasets="pieDataSets" :height="200" />
        </div>
      </div>
    </b-tab>
    <b-tab active id="stats-tab-3" lazy>
      <template v-slot:title>
        <!-- <span class="badge badge-warning">New</span> &nbsp; -->
        <strong>Table</strong>
      </template>
      <div class="row">
        <!-- Table -->
        <div class="col-lg-12 actual-estimate-table">
          <!-- Header -->
          <b-card no-body class="mb-3">
            <div class="custom-table">
              <div class="c-table__head c-table__head--slim">
                <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <h5 class="font-weight-bold mb-0 mr-3">Description</h5>
                  </div>
                  <div class="col-3 custom-padding ss-primary-bg text-white">
                    <h5 class="font-weight-bold mb-0 text-white ml-5">Actual</h5>
                  </div>
                  <div class="col-4 custom-padding">
                    <div class="d-flex align-items-end">
                      <h5 class="font-weight-bold mb-0">Estimate</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>

          <!-- Upper table -->
          <b-card v-if="table.upper && table.upper.length > 0" no-body class="mb-3">
            <div class="custom-table heighlight-middle-column">
              <div class="field-rows border-bottom-grey1" v-for="(d, key) in table.upper" :key="`upper_${key}`">
                <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <span class="mr-3">{{ d.description }}</span>
                  </div>
                  <div class="col-3 text-nowrap custom-padding">
                    <span class="ml-5">{{ d.actualValue }}</span>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <span>{{ d.estimateValue }}</span>
                  </div>
                </div>
              </div>

              <!-- <div class="field-rows border-bottom-grey1">
                <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <span class="mr-3">Gross Profit Margin</span>
                  </div>
                  <div class="col-3 text-nowrap custom-padding">
                    <span class="ml-5">38%</span>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <span>15%</span>
                  </div>
                </div>
              </div> -->
            </div>
          </b-card>

          <!-- Middle table  -->
          <b-card v-if="table.middle && table.middle.length > 0" no-body class="mb-3">
            <div class="custom-table">
              <div class="field-rows heighlight-middle-column" v-for="(d, key) in table.middle" :key="`middle_${key}`">
                <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <span class="mr-3">{{ d.description }}</span>
                  </div>
                  <div class="col-3 text-nowrap custom-padding">
                    <span class="ml-5">{{ d.actualValue }}</span>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <span>{{ d.estimateValue }}</span>
                  </div>
                </div>
              </div>
              <!-- Material Cost -->
              <!-- <div class="field-rows heighlight-middle-column">
                <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <span class="mr-3">Material Cost</span>
                  </div>
                  <div class="col-3 text-nowrap custom-padding">
                    <span class="ml-5">$4,904.00</span>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <span>$17,494.00</span>
                  </div>
                </div>
              </div> -->

              <!-- Labour Cost -->
              <!-- <div class="field-rows heighlight-middle-column">
                <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <span class="mr-3">Labour Cost</span>
                  </div>
                  <div class="col-3 text-nowra custom-padding">
                    <span class="ml-5">$23,904.00</span>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <span>$30,494.00</span>
                  </div>
                </div>
              </div> -->

              <!-- Markup -->
              <!-- <div class="field-rows heighlight-middle-column">
                <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <span class="mr-3">Markup</span>
                  </div>
                  <div class="col-3 text-nowra custom-padding">
                    <span class="ml-5">$10,203.00</span>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <span>$6,383.00</span>
                  </div>
                </div>
              </div> -->
            </div>
          </b-card>

          <!-- Lower table -->
          <b-card no-body class="mb-3 highlight-section">
            <div class="custom-table heighlight-middle-column">
              <div class="ss-primary-font-color">
                <template v-if="table.lower && table.lower.length > 0">
                  <div class="row px-3" v-for="(d, key) in table.lower" :key="`lower_${key}`">
                    <div class="col-4 custom-padding text-right">
                      <h5 class="mr-3">{{ d.description }}</h5>
                    </div>
                    <div class="col-3 text-nowra custom-padding">
                      <h5 class="ml-5">{{ d.actualValue }}</h5>
                    </div>
                    <div class="col-4 text-nowrap custom-padding">
                      <h5>{{ d.estimateValue }}</h5>
                    </div>
                  </div>
                </template>
                <!-- <div class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <h5 class="mr-3">Tax</h5>
                  </div>
                  <div class="col-3 text-nowra custom-padding">
                    <h5 class="ml-5">$4,203.00</h5>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <h5>$9,203.00</h5>
                  </div>
                </div> -->
                <div v-if="table.total && table.total.description" class="row px-3">
                  <div class="col-4 custom-padding text-right">
                    <h4 class="font-weight-bold mr-3">{{ table.total.description }}</h4>
                  </div>
                  <div class="col-3 text-nowra custom-padding">
                    <h4 class="font-weight-bold ml-5">{{ table.total.actualValue }}</h4>
                  </div>
                  <div class="col-4 text-nowrap custom-padding">
                    <h4 class="font-weight-bold">{{ table.total.estimateValue }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
  import GlobalServices from "@/services/global";
  import ModalSmallCard from "@/components/_cards/modal-small-card";
  let global = new GlobalServices();
  import editMixin from "@/mixin/edit-mixin";
  import chart from "@/mixin/charts-mixin";
  import BarChart from "@/components/charts/bar-chart.vue";
  import PieChart from "@/components/charts/pie-chart.vue";
  import MODULE from "@/constants/modules";
  export default {
    name: "ModalDashboard",
    mixins: [editMixin, chart],
    components: {
      ModalSmallCard,
      BarChart,
      PieChart,
    },
    data() {
      return {
        stats: [],
        table: {
          upper: [],
          middle: [],
          lower: [],
          total: {},
        },
        barDataSets: {},
        pieDataSets: {},
      };
    },
    async mounted() {
      // this.loadStats();
      this.loadTable();
      const params = {
        actionId: MODULE.TASKS.ActionId,
        moduleId: MODULE.PROJECTS.ModuleId,
        ProjectId: this.modalObj.data.id,
      };
      this.barDataSets = await this.getChartsData(params, "bar", "", true, ["Hours"]);
      this.pieDataSets = await this.getChartsData(params, "pie", "TimeTrackingType", true);
    },
    methods: {
      async loadStats() {
        if (this.modalObj.actionId && this.modalObj.data && this.modalObj.data.id) {
          this.stats = await global.getEditStats(this.modalObj.actionId, this.modalObj.data.id);
        }
      },

      async loadTable() {
        const params = {
          actionId: MODULE.COMBINATION.UTILIZATION,
          moduleId: MODULE.PROJECTS.ModuleId,
          viewby: "BreakDownStats",
          id: this.modalObj.data.id,
        };
        const results = await global.getStatsReportDataByActionId(params);
        this.stats = results.map((d) => {
          return {
            title: d.description,
            numberValue: d.actualValue,
          };
        });

        const grossProfit = results.find((d) => d.description === "Gross Profit");
        this.table.upper.push(grossProfit);
        const grossProfitMargin = results.find((d) => d.description === "Gross Profit Margin");
        this.table.upper.push(grossProfitMargin);

        const materialCost = results.find((d) => d.description === "Material Cost");
        this.table.middle.push(materialCost);
        const labourCost = results.find((d) => d.description === "Labour Cost");
        this.table.middle.push(labourCost);
        const markUp = results.find((d) => d.description === "Mark Up");
        if (markUp) this.table.middle.push(markUp);

        const subTotal = results.find((d) => d.description === "SubTotal");
        this.table.lower.push(subTotal);
        const tax = results.find((d) => d.description === "Tax");
        this.table.lower.push(tax);

        const total = results.find((d) => d.description === "Total");
        this.table.total = total;
      },
    },
  };
</script>
