<template>
    <div>
        <b-modal v-model="isModalVisible" size="sm" hide-footer hide-header modal-class="whats-new-modal">
            <div class="d-flex justify-content-between">
                <h5>Title goes here</h5>
                <i class="fa fa-times clickable" @click="closeModal"></i>
            </div>
            <div class="">
                <p>Discover what is new for you today! </p>
                <div class="mb-2">
                    <iframe class="b-skeleton b-skeleton-img" width="100%" height="150" src="https://www.youtube.com/embed/4e32H46wxOE"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>
                </div>
                <button class="btn ss-primary-bg ss-white-font-color u-border-zero u-width-100"
                    href="#!">
                        Do something
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {    
        return {
        }
    },

    computed: {
        isModalVisible() {
            return this.$store.getters.getShowWhatsNewModal;
        }
    },
    methods: {
        closeModal () {
            this.$store.dispatch("setWhatsNewModal", false)
        }
    }
}
</script>
<style>
.video-iframe {
    /* background: #e4e4e4; */
}
</style>