<template>
  <div>
      <div v-if="isNotificationVisible" class="badge-warning" style="padding:05px 10px; border-radius:03px;">We have updated our application. Please save your work and refresh the page to get the latest version.</div>
      <div v-if="isIdle" class="badge-warning" style="padding:05px 10px; border-radius:03px;">Looks like you're sleeping... we're going to log you out in 20 seconds.</div>
  </div>
</template>

<script>
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import UtilityString from "@/utilities/strings";

const utility = new UtilityString();
const app = new ApplicationService();


export default {
  data() {
    return {
      timer:{},
      EVENT_ACTION: {},
      isNotificationVisible:false,
      currentVersionId:"",      
    };
  },
  computed: {
		isIdle() {
			return this.$store.state.idleVue.isIdle;
		}
	},
  methods: {
    onIdle() {
  
  },
  onActive() {
    
  },
    async loadData() {
        let currentVersion = await app.getLatestVersion();

        if(currentVersion){
          if(currentVersion.id != this.currentVersionId){
            this.isNotificationVisible = true;
          }
        }

    },    
  },
  
  components: {

  },
  mounted() {
  
      this.timer = setInterval(async () =>{
        await this.loadData();
      },1000 * 60 * 60);

  },
  async created() {
    this.EVENT_ACTION = EVENTS;
    
    //Get the current version and set the value
    let currentVersion = await app.getLatestVersion();   

    if(currentVersion){
     this.currentVersionId = currentVersion.id;     
    }
   


  } ,
  beforeDestroy() {

        if (this.timer)
            clearInterval(this.timer);
  }
};
</script>
