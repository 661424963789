<template>
  <b-card no-body>
    <div class="table-responsive table-responsive-md w-auto1 dashboard-table1">
      <table class="table" id="datatable">
        <thead class="c-table__head c-table__head--slim">
          <tr class="c-table__row">
            <!-- Table Header -->
            <th
              v-for="header in headers"
              :key="header.id"
              class="c-table__cell c-table__cell--head"
              :class="{
                'sort-class': header.isSortable,
                'no-sort': !header.isSortable,
                'text-center': header.rightAlign,
                'min-col': header.smallest,
              }"
            >
              <template v-if="header.isSortable">
                <div class="d-flex align-items-end">
                  <a :id="'table-header-' + header.title" v-if="header.isSortable" href="#">{{ header.title }}</a>
                  <div class="d-flex flex-column ml-2 small clickable">
                    <i class="fa fa-chevron-up"></i>
                    <i class="fa fa-chevron-down"></i>
                  </div>
                </div>
              </template>

              <template v-else>{{ header.title }}</template>
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Table Row -->
          <template v-if="items.length > 0">
            <tr class="c-table__row" v-for="item in items" :key="item.id">
              <td class="align-top" v-for="header in headers" :key="`headers-td-${header.id}`">
                <template v-if="header.isHtmlColumn">
                  <span v-if="!header.hasOwnProperty('clickable') || (header.hasOwnProperty('clickable') && !header.clickable)" v-html="item[header.columnName]"></span>
                  <a v-else @click.prevent="rowClick(item)" href="#" :id="`dashboard-table-edit-button-projectId-${item.intProjectID}`" v-html="item[header.columnName]"></a>
                </template>
                <template v-else-if="header.columnName === 'tooltip'">
                  <i class="fa fa-sticky-note-o" v-bind:id="'tooltip_' + item.intTaskID">
                    <b-tooltip v-bind:target="'tooltip_' + item.intTaskID" triggers="hover" placement="righttop">
                      <div>Technician Notes</div>
                      <p v-html="item.longDescription"></p>
                    </b-tooltip>
                  </i>
                </template>
                <template v-else>
                  <span v-if="!header.hasOwnProperty('clickable') || (header.hasOwnProperty('clickable') && !header.clickable)">{{ item[header.columnName] }}</span>
                  <a v-else @click.prevent="rowClick(item)" href="#" :id="`dashboard-table-edit-button-projectId-${item.intProjectID}`">{{ item[header.columnName] }}</a>
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="c-table__row">
              <td class="align-top text-center" :colspan="headers.length">No Data found</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </b-card>
</template>

<script>
  import SearchService from "@/services/search";
  const global = new SearchService();
  export default {
    name: "GenericTable",
    props: {
      headers: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      actionId: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        notesHoverInfo: null,
      };
    },
    methods: {
      rowClick(item) {
        this.$emit("work-order-click", item);
      },
    },
  };
</script>
<style scoped>
  .c-table__cell {
    min-width: 120px;
  }

  td {
    word-break: break-all;
  }
</style>
