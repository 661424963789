<template>
<div class="split-screen">
  <div class="row ss-light-grey-bg" style="margin-top:-16px; margin-bottom:-16px;">
    <div class="col-md-7 h-100 position-sticky text-center" style="top:0px;">
      <img src="@/assets/logo.png" alt="" class="w-80">
    </div>
    <div class="col-md-5 ss-light-bg">
        <div>
          <h1 v-for="n in 20" :key="n">abc</h1>
        </div>
      </div>
  </div>
</div>
</template>


<script>
// import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {
      date: null
    };
  },
  components: {}
};
</script>
