import moment from "moment";
class UtilityString {
  propertyHasValue = function(obj, key) {
    return key.split(".").reduce(function(o, x) {
      return typeof o == "undefined" || o === null ? o : o[x];
    }, obj);
  };

  generateGUID = function() {
    let guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";

    guid = guid.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    return guid;
  };

  //Remove white space from a specific value
  trimSpace(value) {
    if (value) {
      //Ignore Numbers
      if (typeof value == "number") {
        return value;
      } else {
        return value.toString().replace(/^\s+|\s+$/gm, ""); //Remove starting and ending spaces.
      }
    }
    return value;
  }

  isObjectLike(value) {
    return value != null && typeof value == "object";
  }

  removeCharactersForMobile(value) {
    return value
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace("+", "")
      .replace(" ", "");
  }

  removeCodeForMobile(code, value) {
    return value.replace(code, "");
  }

  generatePassword = function(length = 12) {
    let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  //Remove whitespace from all properties in an object
  trimObjectPropertySpace(obj) {
    if (obj) {
      for (var key in obj) {
        let value = obj[key];

        //Don't check objects
        if (this.isObjectLike(value) == false) {
          //Ensure value is not null
          if (value) {
            //Ignore Numbers
            if (typeof value == "number") {
              obj[key] = value;
            } else {
              obj[key] = value.toString().replace(/^\s+|\s+$/gm, ""); //Remove starting and ending spaces.
            }
          }
        }
      }
    }

    return obj;
  }

  //Convert Object to QueryString
  serializeObject = function(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  parseQueryString = function(params: any): string {
    return Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
  };

  //Format phone number
  formatPhoneNumber = function(propertyValue) {
    var x = propertyValue.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  };

  formatTime = function(datetime, format = "HH:mm a") {
    const time = moment(datetime).format(format);
    return time;
  };

  formatDate = function(datetime, format = "DD-MMM-YYYY") {
    const date = moment(datetime).format(format);
    return date;
  };

  formatDateTime = function(datetime, format = "DD-MMM-YYYY - HH:mm a") {
    const date = moment(datetime).format(format);
    return date;
  };

  stripDollarSign(amount) {
    return amount.replace("$", "").replace(",", "");
  }

  formatAmount = function(amount) {
    if (amount % 1 !== 0) {
      return amount.toFixed(4); // Show 4 decimal places if the amount has 4 decimal places
    } else {
      return amount.toFixed(2); // Show 2 decimal places by default
    }
  };
}

export default UtilityString;
