const vm = this;
export default {
  computed: {
    isUsingMobile() {
      return this.isMobile().Android() || this.isMobile().BlackBerry() || this.isMobile().iOS() || this.isMobile().Windows();
    },
  },
  methods: {
    isMobile: () => ({
      Android: () => navigator.userAgent.match(/Android/i) !== null,
      BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
      iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null,
      Windows: () => navigator.userAgent.match(/IEMobile/i) !== null,
      tablet: () => navigator.userAgent.match(/iPad/i) !== null,
      iPhone: () => navigator.userAgent.match(/iPhone/i) !== null,
    }),
  },
};
