
























































































import Vue from "vue";
import {DashboardStats} from "@/interfaces/modules/dashboard/DashboardStats";
import {DashboardFilter} from "@/interfaces/filters/DashboardFilters";

export default Vue.extend({
  name: "DashboardSummary",
  data() {
    return {
      interval: {},
      filter: {} as DashboardFilter,
      timer: null,
    };
  },
  methods: {
    onViewAllClicked(statusid) {
      this.filter.projectStatusId = statusid;
      this.$store.dispatch("getFilterResults", this.filter);
      // stop the timer from filters
      this.$root.$emit("filters-timer", true);
      if (!this.timer) this.setTimer();
    },
    statsIcon(iconName, fullSize) {
      if (fullSize) return "fa ss-white-font-color u-mr-auto " + iconName;
      else return "fa ss-white-font-color u-mr-auto u-mt-zero " + iconName;
    },
    helfSizeBoxCss(colourScheme) {
      return "ss--dashboard-information__box_module u-height-100 " + colourScheme;
    },
    fullSizeBoxCss(colourScheme) {
      return "ss--dashboard-information__box_module u-height-100 " + colourScheme;
    },
    async reloadStats() {
      await this.$store.dispatch("checkforNewDashboardStats");
    },
    async setTimer() {
      this.timer = setInterval(async () => {
        this.$store.dispatch("getFilterResults", this.filter);
      }, 5000);
    },
    clearTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    handlerReloadTimer(isClear) {
      if (isClear) {
        this.clearTimer();
      } else {
        this.setTimer();
      }
    },
  },
  computed: {
    resultsfullSizeBoxs() {
      let results = this.$store.getters.getDashboardStats;

      if (results) {
        return results.summary.fullSizeBoxs;
      } else {
        return [];
      }
    },
    resultshalfSizeBoxs() {
      let results = this.$store.getters.getDashboardStats;

      if (results) {
        return results.summary.halfSizeBoxs;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.interval = setInterval(this.reloadStats, 15000);
    this.$root.$on("summary-timer", this.handlerReloadTimer);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.$root.$off("summary-timer", this.handlerReloadTimer);
  },
  async created() {
    this.$store.dispatch("initializeDashboard");
    this.$store.dispatch("checkforNewDashboardStats", true);
  },
});
