<template>
  <div>
    <ValidationObserver ref="formNewJob">
      <!--start modal new project body -->
      <div class="col-md-12 u-pt-small">
        <div v-if="isSavingSuccessful" class="alert alert-success" role="alert"
          id="modal-new-work-order-success-message">
          Successfully created a new job.
        </div>
        <div v-if="isError" class="alert alert-danger" role="alert">
          Sorry, there was an error while creating this job.
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6  ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span
                class="u-mr-small">1</span>Choose a Client</div>
            <ValidationProvider name="client" rules="required" v-slot="{ errors }">
              <v-select v-model="selectedClient" :options="uxClientList" @input="onSelectClient" code="id"
                label="displayName" id="dashboard-top-nav-new-work-order-modal-choose-client"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6  ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span
                class="u-mr-small">2</span>Location</div>
            <ValidationProvider name="location" rules="required" v-slot="{ errors }">
              <v-select v-model="selectedLocation" :options="uxLocationList" @input="onSelectLocation" code="id"
                label="displayName" id="dashboard-top-nav-new-work-order-modal-location"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6 mt-2 mt-lg-0 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small "><span
                class="u-mr-small">3</span>Job/Project Type</div>
            <ValidationProvider name="location" rules="required" v-slot="{ errors }">
              <v-select v-model="selectedProjectType" :options="uxProjectTypeList" @input="onSelectProjectType"
                code="id" label="displayName" id="dashboard-top-nav-new-work-order-modal-job-type"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <!-- row -->
        <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

        <div class="row">
          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Primary Contact Name</span>
            <input class="form-control" type="text" v-model="project.primaryContactName"
              id="dashboard-top-nav-new-work-order-modal-primary-contact" />
          </div>

          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Primary Contact Phone</span>
            <input class="form-control" type="text" v-model="project.primaryContactPhone"
              @input="formatPhoneNumber('primaryContactPhone')"
              id="dashboard-top-nav-new-work-order-modal-primary-phone" />
          </div>
          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Status/Priority</span>

            <v-select v-model="selectedProjectStatus" :options="uxProjectStatusList" @input="onProjectStatusSelected"
              code="id" label="displayName" id="dashboard-top-nav-new-work-order-modal-status-priority"></v-select>
          </div>

          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Job Description</span>
            <textarea class="form-control" type="text" rows="8" v-model="project.jobDescription"
              id="dashboard-top-nav-new-work-order-modal-job-description" />
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- col-md-12-->
      <!--end modal new project body -->
    </ValidationObserver>
  </div>
</template>

<script>
import ClientService from "@/services/clients";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import UtilityString from "@/utilities/strings";

const utility = new UtilityString();

const global = new GlobalServices();
const client = new ClientService();

export default {
  data() {
    return {
      isSavingSuccessful: false,
      isError: false,
      client: {},
      project: {
        id: 0,
        primaryContactName: "",
        primaryContactPhone: "",
      },
      moduleFilter: { clientId: 0 },
      selectedClient: null,
      selectedLocation: null,
      selectedProjectType: null,
      selectedProjectStatus: null,
      uxClientList: [],
      uxLocationList: [],
      uxProjectTypeList: [],
      uxProjectStatusList: [],
      modalStatus: {
        modalName: "",
        modalTitle: "",
        data: null,
      },
    };
  },
  methods: {
    formatPhoneNumber(propertyName) {
      this.project[propertyName] = utility.formatPhoneNumber(this.project[propertyName]);
    },

    async onSave(data) {
      this.$refs.formNewJob.validate().then(async (success) => {
        if (!success) {
          return;
        }

        this.project.moduleId = MODULE.PROJECTS.ModuleId;
        this.project.clientId = this.selectedClient.id;
        this.project.locationId = this.selectedLocation.id;
        this.project.projectTypeId = this.selectedProjectType.id;
        this.project.projectStatusId = this.selectedProjectStatus.id;

        //Remove all spaces from properties
        this.project = utility.trimObjectPropertySpace(this.project);

        //ONLY TRACK EVENTS IN PRODUCTION
        // if (process.env.VUE_APP_ENVIRONMENT === "production") {
        //   window["analytics"].track(this.EVENT_ACTION.PROJECTS.Save, this.project);
        // }

        let result = await global.createNewActivity(MODULE.PROJECTS.ActionId, this.project);

        if (result.success) {
          this.isSavingSuccessful = true;
          this.isError = false;

          //Reset board
          this.selectedClient = null;
          this.selectedClient = null;
          this.selectedLocation = null;

          this.project.primaryContactName = "";
          this.project.primaryContactPhone = "";
          this.project.jobDescription = "";

          // Wait until the models are updated in the UI

          this.$refs.formNewJob.reset();
        } else {
          this.isError = true;
          this.isSavingSuccessful = false;
        }

        setTimeout(() => {
          this.isSavingSuccessful = false;
          this.isError = false;

          this.$root.$emit(this.EVENT_ACTION.DASHBOARD.Update);
        }, 5000);
      });
    },
    async onSelectClient(e) {
      //reset client info
      this.client = null;

      //Set Clignt Module Filter
      this.moduleFilter.clientId = e.id;

      //Get Client Locations
      this.uxLocationList = await global.getUXDropDown(MODULE.LOCATION.ActionId, null, null, this.moduleFilter);
      //Set Location Default
      this.selectedLocation = global.setDropDownDefault(this.uxLocationList);

      //Get Client Details
      this.client = await client.getClientById(e.id);

      this.project.primaryContactName = this.client.primaryContact;
      this.project.primaryContactPhone = this.client.phone;
    },
    async onProjectStatusSelected(e) { },
    async onSelectLocation(e) {
      this.client = await client.getClientByIdAndLocationId(this.selectedClient.id, e.id);

      this.project.primaryContactName = this.client.primaryContact;
      this.project.primaryContactPhone = this.client.phone;
    },
    async onSelectProjectType(e) { },

    async initializeData() {
      //Get Client List
      this.uxClientList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);

      //Populate Project Type Dropdown List
      this.uxProjectTypeList = await global.getUXDropDown(MODULE.PROJECTS.Type);

      //Set Prject Type Defaut Value
      this.selectedProjectType = global.setDropDownDefault(this.uxProjectTypeList);

      //Populate Project Type Dropdown List
      this.uxProjectStatusList = await global.getUXDropDown(MODULE.PROJECTS.Status);

      //Set Prject Status Defaut Value
      this.selectedProjectStatus = global.setDropDownDefault(this.uxProjectStatusList);
    },
  },

  components: {},
  mounted() {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window.analytics.page(this.EVENT_ACTION.PROJECTS.View);
    // }
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    this.initializeData();

    //Subscribe to save button "Dispatch" click event on modal box
    this.$root.$on("Save" + this.EVENT_ACTION.PROJECTS.New, this.onSave);
  },
  beforeDestroy() {
    //Unscribe from save button "Dispatch" click event on modal box
    this.$root.$off("Save" + this.EVENT_ACTION.PROJECTS.New, this.onSave);
  },
};
</script>
