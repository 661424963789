import axios from "axios";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";

let auth = AuthService.getInstance();

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class InvoiceService {
  private static _instance: InvoiceService;

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async getInvoicePresetByProjectId(id) {
    let queryString = auth.getQueryString() + "&id=" + id;

    let dataResults = await axios.get("invoice/preset/V2" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getInvoiceEditPreset(id) {
    let queryString = auth.getQueryString() + "&id=" + id;

    let dataResults = await axios.get("invoice/edit/preset" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getInvoiceByProjectId(id) {
    if (!id) return;
    let queryString = auth.getQueryString() + "&id=" + id;

    let dataResults = await axios.get("invoice/projects" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getOutstandingInvoiceByClientId(id) {
    if (!id) return;
    let queryString = auth.getQueryString() + "&id=" + id;

    let dataResults = await axios.get("invoice/outstanding" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  static createInstance() {
    InvoiceService.getInstance();
  }

  static getInstance() {
    return this._instance || (this._instance = new this());
  }
}

export default InvoiceService;
