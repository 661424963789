






























import Vue from "vue";
import QuoteServices from "@/services/quotes";
import TemplateServices from "@/services/templates";
import EditDataObject from "@/interfaces/EditDataObject";
import UXInventoryService from "@/services/ux/uxinventory";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
import editMixin from "@/mixin/edit-mixin";

const templateApi = new TemplateServices();
const quoteApi = new QuoteServices();
const global = new GlobalServices();

export default {
  name: "QuoteDetails",
  mixins: [editMixin],
  props: {
    dataObj: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      id: 0,
      template: {
        description: "",
      },
      selectedItem: {},
      quoteDetails: {},
      showErrorWarning: false,
      subTotal: 0,
      uxTemplateList: [],
      config: {
        toolbarButtons: [
          ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript"],
          ["fontFamily", "fontSize", "textColor", "backgroundColor"],
          ["inlineClass", "inlineStyle", "clearFormatting"],
        ],

        height: 600,
        events: {
          "froalaEditor.initialized": function() {},
        },
        key: "YNB3fJ3B8C10D6B5D2A-9rlvqgkD6zdjI-8I-7B-22fdtB1zH-9iB3B9B6D5C2C4D3H3G3H3==",
        attribution: false,
      },
    };
  },

  methods: {
    async updateData(dataObj) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        global.postData(dataObj);
      }, 500);
    },
    updateField(propertyName, propertyValue, displayName) {
      let editObj = {} as EditDataObject;
      editObj.propertyName = propertyName;
      editObj.propertyValue = propertyValue;
      editObj.displayName = displayName;
      editObj.id = this.dataObj.data.id;
      editObj.actionId = MODULE.QUOTATION.ActionId;
      editObj.referenceNumber = this.quoteDetails.qutoteNumber;

      this.updateData(editObj);
    },
    async onSelectTerms(event) {
      this.loadSelectedTemplate(event.id);
    },
    async loadSelectedTemplate(id) {
      //On Dropdown change call API to get template description
      const templateDetail = await global.getEditDetails(this.dataObj.param.actionId, id);
      this.template = await templateApi.getTemplateById(id);
      this.template.description = templateDetail && templateDetail.isHTML && !templateDetail.isPlainText ? templateDetail.Description : templateDetail.PlainText;
      //Update Databse
      this.updateField("termsConditions", this.template.description, "Terms & Conditions");
    },
    async initializeData() {
      this.template.description = await quoteApi.getQuoteTermsById(this.dataObj.data.id);

      this.uxTemplateList = await global.getUXDropDown(MODULE.TEMPLATES.ActionId, MODULE.QUOTATION.ModuleId, MODULE.QUOTATION.TemplateTypeId);

      //If no terms and conditions exist load the default
      if (!this.template.description) {
        //Get the first default template in the list
        this.selectedItem = this.uxTemplateList.find((list) => list.isDefault == true);

        if (this.selectedItem) {
          this.loadSelectedTemplate(this.selectedItem.id);
        }
      }
    },
  },
  async mounted() {},
  async created() {
    this.initializeData();
  },
};
