<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <span class="c-toolbar__state-title u-text-capitalize">Select Material</span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>
      <div class="col-md-6">
        <span class="c-toolbar__state-title u-text-capitalize">Filter by</span>
        <div class="row pt-2 pl-3">
          <div class="u-mb-xsmall mr-4">
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" checked id="radio1" name="radios" type="radio" />
              <label class="c-choice__label" for="radio1">Stocking Item</label>
            </div>
          </div>
          <div class="u-mb-xsmall mr-4">
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" id="radio2" name="radios" type="radio" />
              <label class="c-choice__label" for="radio2">Non-stocking Item</label>
            </div>
          </div>
          <div class="u-mb-xsmall">
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" id="radio3" name="radios" type="radio" />
              <label class="c-choice__label" for="radio3">All Items</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Material Name</span>
        <textarea class="form-control" type="text" rows="5" />
      </div>
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Quantity</span>
        <input class="form-control" type="number" />
      </div>
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Selling / Unit Price</span>
        <input class="form-control" type="number" />
      </div>
      <div class="col-md-12 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Notes</span>
        <textarea class="form-control" type="text" rows="5" />
      </div>
    </div>
  </div>
</template>


<script>
// import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {};
  },
  components: {}
};
</script>
