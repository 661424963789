<template>
  <div class="table-responsive table-responsive-md w-auto dashboard-table">
    <table class="table" id="datatable">
      <thead class="c-table__head c-table__head--slim">
        <tr class="c-table__row">
          <th class="c-table__cell c-table__cell--head no-sort"></th>
          <th class="c-table__cell c-table__cell--head no-sort">Status</th>
          <th class="c-table__cell c-table__cell--head no-sort">P.O Number</th>
          <th class="c-table__cell c-table__cell--head no-sort">Supplier</th>
          <th class="c-table__cell c-table__cell--head no-sort">Amount</th>
          <th class="c-table__cell c-table__cell--head no-sort">
            Requested Date
          </th>
          <th class="c-table__cell c-table__cell--head no-sort">
            Created Date
          </th>
          <th class="c-table__cell c-table__cell--head no-sort">Created By</th>
        </tr>
      </thead>
      <tbody>
        <tr class="c-table__row" v-for="purchaseorder in purchaseorders" :key="purchaseorder.purchaseId">
          <td class="text-left menu-icons pl-2">
            <a @click.prevent="onViewPurchaseOrder(purchaseorder)" :href="dynamicURL(purchaseorder)">View/Edit</a>
          </td>

          <td class="align-middle" v-html="purchaseorder.htmlColour"></td>
          <td class="align-middle">{{ purchaseorder.purchaseRef }}</td>
          <td class="align-middle">{{ purchaseorder.supplier }}</td>
          <td class="align-middle">{{ purchaseorder.amount | currency({fractionCount: getDecimalPoint(purchaseorder.amount)}) }}</td>
          <td class="align-middle">{{ purchaseorder.displayRequestedDate }}</td>
          <td class="align-middle">{{ purchaseorder.displayCreatedDate }}</td>
          <td class="align-middle">{{ purchaseorder.createdBy }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import EarthApplicationService from "@/services/earth-application";
  import PurchaseOrderService from "@/services/purchaseorder";
  import MODULE from "@/constants/modules";
  import EVENTS from "@/constants/events";

  const api = new PurchaseOrderService();
  const earth = new EarthApplicationService();

  export default {
    props: {
      dataObj: Object,
    },
    data() {
      return {
        projectId: 0,
        EVENT_ACTION: {},
        intervalApi: null,
        purchaseorders: null,
      };
    },
    computed: {},
    methods: {
      //Conditionally generate URL for open in new tab
      dynamicURL(purchaseOrder) {
        return `/purchase-orders/details/${purchaseOrder.purchaseId}`;
      },
      onViewPurchaseOrder(purchaseOrder) {
        window.open(`https://portal.snapsuite.io/purchase-orders/details/${purchaseOrder.purchaseId}`);
      },
      async loadData() {
        this.projectId = this.dataObj.data.intProjectID;

        this.purchaseorders = await api.getPurchaseOrderByProjectId(this.projectId);
      },
    },
    async created() {
      await this.loadData();
    },
    async mounted() {
      this.EVENT_ACTION = EVENTS;

      this.intervalApi = setInterval(async () => {
        await this.loadData();
      }, 10000);
    },
    beforeDestroy() {
      clearInterval(this.intervalApi);
    },
  };
</script>
