<template>
  <!-- Action Bar -->
  <div class="c-card u-p-small u-mb-small mx-2 py-2">
    <div class="row">
      <div class="col-lg-12">
        <!-- Action Buttons -->
        <b-button
          v-for="action in actionBarDataObj"
          v-bind:key="action.id"
          @click="onActionItemClicked(action)"
          variant="light"
          class="text-muted light-btn ss--border-0"
          :id="`${pageTitle}-sidepanel-action-bar-button-${action.menuTitle}`"
        >
          <i :class="action.icon"></i>
          {{action.menuTitle}}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import UtilityString from "@/utilities/strings";

const utility = new UtilityString();
const app = new ApplicationService();
const earth = new EarthApplicationService();

export default {
  props: {
    actionBarDataObj: {
      type: Array,
      required: true,
      pageTitle: ''
    }
  },

  data() {
    return {
      project: {},
      hasQuote: false,
      EVENT_ACTION: {},
      pageTitle: ''
    };
  },
  methods: {
    onActionItemClicked(action) {

      //Pass action to global navigation action handler...
      app.actionNavigation(action);

    },
    onViewQuote() {
      let url = earth.getViewQuoteDetailsUrl(this.project.intSalesQuotationID);
      app.openNewTab(url);
    },




    async loadData() {
      this.project = this.dataObj;
    }

  },
  computed: {},
  components: {},
  mounted() { },
  async created() {
    this.EVENT_ACTION = EVENTS;
    await this.loadData();

    if(this.$route.meta.gtm){
      this.pageTitle = this.$route.meta.gtm;
    }else{
      this.pageTitle = "";
    }
  }
};
</script>
