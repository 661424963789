import axios from "axios";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";

let auth = AuthService.getInstance();

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class EmployeeService {
  private static _instance: EmployeeService;

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async createNewEmployee(data) {
    this.setAPIDefaults(data);

    let dataResults = await axios.post("employees/post", data, apiHeaderObj);

    return dataResults.data;
  }

  private setAPIDefaults(item: any) {
    axios.defaults.baseURL = apiUrl;

    item = Object.assign(item, auth.getAuthObject());
  }

  static createInstance() {
    EmployeeService.getInstance();
  }

  static getInstance() {
    return this._instance || (this._instance = new this());
  }
}

export default EmployeeService;
