














































// import Vue from "vue";
import NotesService from "@/services/notes";
import MODULE from "@/constants/modules";
import UtilityString from "@/utilities/strings";
import hash from "object-hash";

const utility = new UtilityString();
const notes = new NotesService();

export default {
  props: {
    dataObj: {
      type: Object,
      required: true,
    },
    intNoteTypeId: {
      type: Number,
      required: false,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inEditMode: false,
      selectedItem: {},
      payload: {},
    };
  },
  methods: {
    showHideLabel() {
      if (!this.inEditMode) return true;
      else return false;
    },
    showHideTextBox(index) {
      if (this.inEditMode) return true;
      else return false;
    },

    async deleteItem(noteObj) {
      await notes.deleteItem(noteObj);

      this.dataObj = null;
    },
    cancelRow() {
      // this.showErrorWarning = false;
      // this.selectedItem = null;
      this.inEditMode = false;
    },
    editRow() {
      this.inEditMode = true;
      this.$emit("editing", true);
    },
    async saveRow() {
      if (this.intNoteTypeId) {
        this.dataObj.typeId = this.intNoteTypeId;
      }
      this.payload = {
        intCreatedEmployeeID: 0,
        intLastModifiedEmployeeID: 0,
        id: this.dataObj.id,
        isEditingEnabled: true,
        summary: this.dataObj.name,
        notes: this.dataObj.notes,
        source: "Notes",
        typeId: this.dataObj.typeId,
        countDays: null,
        t: this.dataObj.t,
        u: this.dataObj.u,
        uid: this.dataObj.uid,
        s: "Jupiter",
      } as any;

      switch (parseInt(this.intNoteTypeId)) {
        case MODULE.NOTES.SALES:
          this.payload.quoteId = parseInt(this.$route.params.id);
          break;
        case MODULE.NOTES.PROJECT:
          this.payload.projectId = parseInt(this.$route.params.id);
          break;
        case MODULE.NOTES.CLIENT:
          this.payload.clientId = parseInt(this.$route.params.id);
          break;
        case MODULE.NOTES.INVOICE:
          this.payload.invoiceId = parseInt(this.$route.params.id);
          break;
        case MODULE.NOTES.PURCHASE_ORDER:
          this.payload.purchaseId = parseInt(this.$route.params.id);
          break;
        case MODULE.NOTES.TEMPLATES:
          this.payload.templateId = parseInt(this.$route.params.id);
          this.payload.IntTemplateId = parseInt(this.$route.params.id);
          break;
        case MODULE.NOTES.INVENTORY:
          this.payload.inventoryId = parseInt(this.$route.params.id);
          this.payload.IntInventoryId = parseInt(this.$route.params.id);
        case MODULE.NOTES.SUPPLIER:
          this.payload.clientId = parseInt(this.$route.params.id);
          break;
        default:
          break;
      }
      //Remove all spaces from properties
      this.payload = utility.trimObjectPropertySpace(this.payload);
      //Generate a Hash from the data to be submitted
      this.payload.hash = hash(this.payload);

      await notes.updateData(this.payload);
      this.inEditMode = false;
      this.$emit("editing", false);
    },
  },
  mounted() {},
};
