<template>
  <div v-if="invoice && invoiceDetails" class="px-3 h-100">
    <ValidationObserver v-if="view === 'form'" ref="formInvoiceRecordSinglePayment">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="row">
            <div class="info-box col-md-6 pr-0">
              <div class="u-border-rounded text-right pr-3 py-2 ss-info-bg">
                <h5 id="quick-info-invoice-details-invoice-total" class="mb-0 ss-white-font-color">Invoice Total</h5>
                <h3 id="quick-info-invoice-details-invoice-total-value" class="ss-white-font-color mb-0">
                  {{ formatCurrency(getInvoiceRoundinValue(invoiceDetails.applicationCurrencyRoundingOption, invoice.total)) }}
                </h3>
              </div>
            </div>

            <div class="info-box col-md-6 pr-0">
              <div class="u-border-rounded text-right pr-3 py-2 ss-danger-bg">
                <h5 id="quick-info-invoice-details-invoice-Outstanding" class="mb-0 ss-white-font-color">Outstanding</h5>
                <h3 id="quick-info-invoice-details-invoice-Outstanding-value" class="ss-white-font-color mb-0">
                  {{ formatCurrency(getInvoiceRoundinValue(invoiceDetails.applicationCurrencyRoundingOption, invoice.outstanding)) }}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <span class="c-toolbar__state-title u-text-capitalize">Client Name</span>
          <h5 id="single-record-payment-client-name">{{ invoice.clientName }}</h5>
        </div>

        <div class="col-md-8 u-mb-xsmall">
          <span class="c-toolbar__state-title u-text-capitalize">Invoice</span>
          <h5 id="single-record-payment-invoice">{{ invoice.referenceNumber }} - {{ invoice.workOrder }} {{ invoice.clientName }}</h5>
        </div>

        <div class="col-md-4 u-mb-xsmall">
          <ValidationProvider name="paymentType" v-slot="{errors}" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Payment Type</span>
            <v-select v-model="selectedPaymentMethod" code="id" label="displayName" id="modal-billing-invoice-record-payment-invoice-to-pay" :options="UXPaymentList" :clearable="false"></v-select>
            <span class="text-danger">{{ errors[0] ? "Payment type is required." : "" }}</span>
          </ValidationProvider>
        </div>

        <div class="col-md-4 u-mb-xsmall">
          <ValidationProvider name="paymentDate" v-slot="{errors}" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Received Payment Date</span>
            <date-picker
              class="w-100"
              v-model="dateReceived"
              value-type="format"
              format="DD-MMM-YYYY"
              type="date"
              lang="en"
              id="modal-billing-invoice-record-payment-received-payment-date"
            ></date-picker>
            <span class="text-danger">{{ errors[0] ? "Payment date is required." : "" }}</span>
          </ValidationProvider>
        </div>

        <div class="col-md-4  u-mb-xsmall">
          <ValidationProvider
            name="amount"
            v-slot="{errors, failedRules}"
            :rules="`min_value:0.01|required|max_value:${getInvoiceRoundinValue(invoiceDetails.applicationCurrencyRoundingOption, invoice.outstanding)}`"
          >
            <span class="c-toolbar__state-title u-text-capitalize">Amount</span>
            <div class="input-group input-with-dollar-sign">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" type="number" :disabled="isAmountDisabled" v-model="amount" placeholder id="single-record-payment-amount" />
            </div>
            <span v-if="errors && errors[0]" class="text-danger">{{
              failedRules && failedRules.min_value
                ? "Amount field should be greater than zero."
                : failedRules && failedRules.max_value
                ? `Amount field should be lesser than ${formatCurrency(getInvoiceRoundinValue(invoiceDetails.applicationCurrencyRoundingOption, invoice.outstanding))}`
                : errors[0]
            }}</span>
          </ValidationProvider>
        </div>

        <div class="col-md-12 u-mb-xsmall">
          <span class="c-toolbar__state-title u-text-capitalize">Notes</span>
          <textarea class="form-control" type="text" v-model="notes" rows="5" id="single-record-payment-notes" />
        </div>
        <div v-if="view !== 'success'" class="spacer"></div>
      </div>
    </ValidationObserver>

    <div v-if="view === 'summary'">
      <div class="dash-grey-card c-card col-lg-8 col-md-7 col-sm-9 px-3 py-2 mx-auto">
        <div class="mb-3">
          <h3 class="mb-0">Confirm Payment Details</h3>
          <p class="mb-0">Please confirm the details of this payment.</p>
        </div>

        <div class="mb-3">
          <strong class="mb-0 ss-primary-font-color d-block">{{ invoice.clientName }}</strong>
          <span class="mb-0"> {{ invoice.referenceNumber }} - </span>
          <span v-html="invoice.workOrder"></span>
        </div>

        <div class="d-flex align-items-center justify-content-between pb-3 mb-3 border-bottom">
          <p class="mb-0">Work Order #:</p>
          <h5 class="mb-0">{{ invoice.workOrder }}</h5>
        </div>
        <div class="d-flex align-items-center justify-content-between pb-3 mb-3 border-bottom">
          <p class="mb-0">Payment Date:</p>
          <h5 class="mb-0">{{ dateReceived }}</h5>
        </div>
        <div class="d-flex align-items-center justify-content-between pb-3 mb-3 border-bottom">
          <p class="mb-0">Payment Type:</p>
          <h5 class="mb-0">{{ selectedPaymentMethod.displayName }}</h5>
        </div>
        <div class="d-flex align-items-center justify-content-between pb-3 mb-3 border-bottom">
          <p class="mb-0">Amount:</p>
          <!-- <h5 class="mb-0">{{ amount | currency({fractionCount: getDecimalPoint(amount)}) }}</h5> -->
          <h5 class="mb-0">{{ !isRoundingUP ? formatCurrency(amount) : amount | currency }}</h5>
        </div>
        <div v-if="notes" class="d-flex align-items-center justify-content-between pb-3 mb-3 border-bottom">
          <p class="mb-0">Note:</p>
          <h5 class="mb-0">{{ notes }}</h5>
        </div>
        <div v-if="remainingBalance > 0" class="d-flex align-items-center justify-content-between pb-3 mb-3 border-bottom">
          <p class="mb-0">Remaining Balance:</p>
          <h5 class="mb-0">{{ formatCurrency(remainingBalance) }}</h5>
          <!-- <h5 class="mb-0">{{ remainingBalance | currency({fractionCount: getDecimalPoint(remainingBalance)}) }}</h5> -->
        </div>
      </div>
    </div>

    <SuccessScreen v-if="view === 'success'" :amount="parseFloat(amount)"></SuccessScreen>
    <SuccessScreen v-if="view === 'paid'" :amount="parseFloat(invoice.total)" main-text="This invoice has already been paid" :loading="isRecalculating"></SuccessScreen>

    <div v-if="view !== 'success' && view !== 'paid'" class="modal-invoice-footer">
      <div>
        <b-button size="md" @click="onSave" :disabled="isSaving" class="ss-primary-bg mr-2 border-0" :id="`modal-save-button`">
          <!-- Normal State -->
          <i v-if="!isSaving" class="fa fa-save u-mr-xsmall"></i>
          <span v-if="!isSaving">
            {{ saveButtonTitle }}
          </span>

          <!-- Processing State -->
          <img v-if="isSaving" src="@/assets/images/loading-spinner.gif" class="loading" alt="Loading..." />
          <span v-if="isSaving">&nbsp;Processing...</span>
        </b-button>

        <!-- Cancel Button -->
        <b-button size="md" class="ss-info-bg border-0" @click="onClose" :disabled="isSaving" :id="`modal-cancel-button`"> <i class="fa fa-times u-mr-xsmall"></i>{{ closeButtonTitle }} </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment";
  import DatePicker from "vue2-datepicker";
  import SuccessScreen from "../_universal/global/success-screen.vue";
  import GlobalServices from "@/services/global";
  import SearchService from "@/services/search";
  import InvoiceService from "@/services/invoice";
  import MODULE from "@/constants/modules";
  import EVENTS from "@/constants/events";
  import UtilityString from "@/utilities/strings";
  const utility = new UtilityString();
  const global = new GlobalServices();
  const searchService = new SearchService();
  const invoiceService = new InvoiceService();
  export default {
    data() {
      return {
        isRecalculating: false,
        isSaving: false,
        dateReceived: null,
        view: "form",
        UXClientList: [],
        selectedClient: null,
        UXPaymentList: [],
        selectedPaymentMethod: null,
        invoice: null,
        invoiceDetails: {},
        amount: 0,
        notes: null,
        payments: null,
      };
    },
    components: {
      DatePicker,
      SuccessScreen,
    },
    mounted() {
      this.init();
    },
    computed: {
      saveButtonTitle() {
        if (this.view === "form") {
          return "Record Payment";
        } else if (this.view === "summary") {
          return "Confirm";
        } else {
          return "";
        }
      },
      closeButtonTitle() {
        if (this.view === "form") {
          return "Close";
        } else if (this.view === "summary") {
          return "No";
        } else {
          return "Close";
        }
      },
      remainingBalance() {
        if (!this.invoice.total) return 0;

        const res =
          parseFloat(this.getInvoiceRoundinValue(this.invoiceDetails.applicationCurrencyRoundingOption, this.invoice.total)) -
          (parseFloat(this.getInvoiceRoundinValue(this.invoiceDetails.applicationCurrencyRoundingOption, this.amount)) +
            parseFloat(this.getInvoiceRoundinValue(this.invoiceDetails.applicationCurrencyRoundingOption, this.payments)));
        return utility.formatAmount(res);
      },
      isAmountDisabled() {
        return (this.invoiceDetails && this.invoiceDetails.isPaid) || (this.amount <= 0 && this.invoice && this.invoice.outstanding === 0);
      },
      isRoundingUP() {
        return this.invoiceDetails.applicationCurrencyRoundingOption === MODULE.INVOICE.ROUNDING.ROUNDUP;
      },
    },
    methods: {
      async init() {
        this.modalStatus = this.$store.getters.getModalStatus;
        this.invoice = this.modalStatus.data;
        this.invoiceDetails = await invoiceService.getInvoiceEditPreset(this.invoice.id);
        if (this.invoiceDetails && this.invoiceDetails.isPaid) {
          this.isRecalculating = true;
          this.view = "paid";
        }
        this.UXClientList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
        // client id = idRefNumber2
        if (this.invoice && this.invoice.idRefNumber2) {
          this.selectedClient = this.UXClientList.find((client) => parseInt(client.id) === parseInt(this.invoice.idRefNumber2));
        }
        this.UXPaymentList = await global.getUXDropDown(MODULE.PAYMENT.UX.PaymentType);
        this.selectedPaymentMethod = global.setDropDownDefault(this.UXPaymentList);
        const invoiceCalculation = await global.recalculateInvoice(this.invoice.id);
        let results = await searchService.getResults("global/details/info", {
          actionId: MODULE.INVOICE.ActionId,
          id: this.invoice.id,
        });

        // const outstanding = results.resources.find((r) => r.title === "Outstanding");
        let payments = null;
        if (results && results.resources) payments = results.resources.find((r) => r.title === "Payments");
        this.invoice = {
          ...this.invoice,
          ...invoiceCalculation,
          // subTotal: parseFloat(utility.stripDollarSign(outstanding.numberValue)),
          // payments: parseFloat(utility.stripDollarSign(payments.numberValue)),
        };
        this.payments = payments ? parseFloat(utility.stripDollarSign(payments.numberValue)) : 0;
        this.dateReceived = moment().format("DD-MMM-YYYY");
        this.amount = this.getInvoiceRoundinValue(this.invoiceDetails.applicationCurrencyRoundingOption, this.invoice.outstanding);
        this.isRecalculating = false;
      },

      async onSave() {
        try {
          if (this.view === "form") {
            this.amountErrorMessage = null;
            const isValid = await this.$refs.formInvoiceRecordSinglePayment.validate();
            if (isValid && !this.isAmountDisabled) {
              this.view = "summary";
            }
          } else if (this.view === "summary") {
            this.isSaving = true;
            let payload = {
              ClientID: this.selectedClient.id,
              moduleId: MODULE.INVOICE.ActionId, // id 27
              InvoiceID: this.invoice.id, // intInvoiceID
              ProjectID: this.invoice.idRefNumber1, // intProjectID
              DatePaid: this.dateReceived,
              PaymentTypeID: this.selectedPaymentMethod.id,
              Notes: this.notes,
              Amount: parseFloat(this.amount),
              Balance: parseFloat(this.remainingBalance),
            };
            //Remove all spaces from properties
            payload = utility.trimObjectPropertySpace(payload);
            await global.createNewActivity(MODULE.PAYMENT.ActionId, payload);

            this.view = "success";
            this.isSaving = false;
          }
        } catch (e) {
          this.isSaving = false;
        }
      },
      onClose() {
        if (this.view === "form") {
          // close modal
          this.$root.$emit("close-parent-modal");
        } else if (this.view === "summary") {
          this.view = "form";
        } else {
          // close modal
          this.$root.$emit("close-parent-modal");
        }
      },
    },
    created() {
      this.EVENT_ACTION = EVENTS;
      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.INVOICE.RecordSinglePayment, this.onSave);
    },
    beforeDestroy() {
      this.view = "form";
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.INVOICE.RecordSinglePayment, this.onSave);
    },
  };
</script>
<style>
  .spacer {
    height: 100px;
  }
  .modal-invoice-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dedede;
    padding: 15px 30px;
  }
  @media (max-width: 1566px) {
    .modal-invoice-footer {
      position: sticky;
      bottom: 0;
      margin-left: -18px;
      margin-right: -15px;
      margin-bottom: -15px;
    }
  }
</style>
