<template>
  <div>
    <ValidationObserver ref="formUpdateProjectLocation">
      <!--start modal new project body -->
      <div class="col-md-12 ">
        <div class="row">
          <div class="col-md-12">
            <div v-if="isDisabledDueToInvoices" class="alert alert-danger" role="alert">
              There's an invoice already associated with this job; as a result, you are not allowed to change the client or location for this job.
            </div>
          </div>
          <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">Client</div>
            <ValidationProvider name="client" rules="required" v-slot="{errors}">
              <v-select
                v-model="selectedClient"
                :options="uxClientList"
                @input="onSelectClient"
                :clearable="false"
                code="id"
                label="displayName"
                id="dashboard-top-nav-new-work-order-modal-choose-client"
                :disabled="isDisabledDueToInvoices || loading"
              ></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">Location</div>
            <ValidationProvider name="location" rules="required" v-slot="{errors}">
              <v-select
                v-model="selectedLocation"
                :options="uxLocationList"
                @input="onSelectLocation"
                :clearable="false"
                code="id"
                label="displayName"
                id="dashboard-top-nav-new-work-order-modal-location"
                :disabled="isDisabledDueToInvoices || loading"
              ></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import editMixin from "@/mixin/edit-mixin";
  import GlobalServices from "@/services/global";
  import InvoiceService from "@/services/invoice";
  const global = new GlobalServices();
  const api = new InvoiceService();
  export default {
    name: "UpdateProjectLocation",
    mixins: [editMixin],
    data() {
      return {
        loading: true,
        uxClientList: [],
        selectedClient: null,
        uxLocationList: [],
        selectedLocation: null,
        moduleFilter: {clientId: 0},
        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },
        invoices: [],
      };
    },
    computed: {
      isDisabledDueToInvoices() {
        return this.invoices && this.invoices.length > 0;
      },
    },
    mounted() {
      this.loadData();
    },
    methods: {
      async loadData() {
        //Get Data From Modal
        this.modalStatus = this.$store.getters.getModalStatus;
        // get invoices first
        this.invoices = await api.getInvoiceByProjectId(this.modalStatus.data.intProjectID);
        //Get Client List
        this.uxClientList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
        if (this.modalStatus.data && this.modalStatus.data.intClientID) {
          const client = this.uxClientList.find((client) => client.id === this.modalStatus.data.intClientID);
          this.selectedClient = client;
          //Set Clignt Module Filter
          this.moduleFilter.clientId = client.id;
          //Get Client Locations
          this.uxLocationList = await global.getUXDropDown(MODULE.LOCATION.ActionId, null, null, this.moduleFilter);
          this.selectedLocation = this.uxLocationList.find((location) => location.id === this.modalStatus.data.intLocationID);
        }
        let obj = {};
        obj.actionId = MODULE.PROJECTS.ActionId;
        obj.moduleId = MODULE.PROJECTS.ModuleId;
        obj.id = this.modalStatus.data.intProjectID;
        obj.parentActionId = MODULE.PROJECTS.ActionId;
        obj.parentId = this.modalStatus.data.intProjectID;
        this.updateModalData(obj);
        this.loading = false;
      },
      onSelectLocation(e) {
        if (e && e.id) {
          this.immediatelyUpdateField(
            "IntLocationID", //PropertyName
            e.id, //Property Value
            "Location", //Display Name,
            e.displayName, //Display Value
            "intLocationID", //Data object Property Name
            null,
            null,
            true
          );
        }
      },
      updateModalData(data) {
        this.$store.dispatch("setModalDataDetailsObject", data);
      },
      async onSelectClient(e) {
        this.immediatelyUpdateField(
          "IntClientID", //PropertyName
          e.id, //Property Value
          "Client", //Display Name,
          e.displayName, //Display Value
          "intClientID", //Data object Property Name
          null,
          null,
          true
        );
        //Set Clignt Module Filter
        this.moduleFilter.clientId = e.id;

        //Get Client Locations
        this.uxLocationList = await global.getUXDropDown(MODULE.LOCATION.ActionId, null, null, this.moduleFilter);

        //Set Location Default
        this.selectedLocation = await global.setDropDownDefault(this.uxLocationList);
        setTimeout(() => {
          this.onSelectLocation(this.selectedLocation);
        }, 1000);
      },
    },
  };
</script>
