<template>
  <div v-if="currentDataObj && currentDataObj.data && currentDataObj.details" class="row pt-2">
    <div class="col-md-12 mb-2">
      <span class="c-toolbar__state-title u-text-capitalize">Summary</span>
      <input id="slidepanel-edit-invoice-tabs-summary" type="text" class="form-control"
        v-model="currentDataObj.details.summary"
        @keyup="updateField('Summary', currentDataObj.details.summary, 'Summary', currentDataObj.details.summary, 'Summary', null, null, null, null, MODULE.INVOICE.ActionId)"
        :disabled="isAllowedEdit">
    </div>
    <div class="col-md-12 mb-2">
      <span class="c-toolbar__state-title u-text-capitalize">Description</span>
      <textarea class="form-control" id="slidepanel-edit-invoice-tabs-description"
        v-model="currentDataObj.details.description" cols="30" rows="8"
        @keyup="updateField('Description', currentDataObj.details.description, 'Description', currentDataObj.details.description, 'Description', null, null, null, null, MODULE.INVOICE.ActionId)"
        :disabled="isAllowedEdit" />
    </div>
  </div>
</template>

<script>
import editMixin from "@/mixin/edit-mixin";
import MODULE from "@/constants/modules";
// import editor from "@/components/_universal/global/editor.vue";
export default {
  mixins: [editMixin],
  computed: {
    isAllowedEdit() {
      return this.dataObj.details.isLockedFromEditing
    }
  },
  created() {
    this.MODULE = MODULE
  }
};
</script>
