<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Start Date</span>
        <date-picker
          format="DD-MMM-YYYY - HH:mm a"
          :minute-step="15"
          style="width: 100%;"
          type="datetime"
          v-model="date"
          lang="en"
        ></date-picker>
      </div>
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">End Date</span>
        <date-picker
          format="DD-MMM-YYYY - HH:mm a"
          :minute-step="15"
          style="width: 100%;"
          type="datetime"
          v-model="date"
          lang="en"
        ></date-picker>
      </div>
      <div class="col-md-12 mt-2">
        <div class="row pt-2 pl-3">
          <div class="u-mb-xsmall mr-5">
            <div class="c-choice c-choice--radio mb-0">
              <input
                class="c-choice__input"
                id="radio1"
                name="radios"
                type="radio"
                value="daily"
                v-model="mode"
              />
              <label class="c-choice__label" for="radio1">Daily</label>
            </div>
          </div>
          <div class="u-mb-xsmall mr-5">
            <div class="c-choice c-choice--radio mb-0">
              <input
                class="c-choice__input"
                id="radio2"
                name="radio1"
                type="radio"
                value="weekly"
                v-model="mode"
              />
              <label class="c-choice__label" for="radio2">Weekly</label>
            </div>
          </div>
          <div class="u-mb-xsmall mr-5">
            <div class="c-choice c-choice--radio mb-0">
              <input
                class="c-choice__input"
                id="radio3"
                name="radio1"
                type="radio"
                value="monthly"
                v-model="mode"
              />
              <label class="c-choice__label" for="radio3">Montly</label>
            </div>
          </div>
          <div class="u-mb-xsmall mr-4">
            <div class="c-choice c-choice--radio mb-0">
              <input
                class="c-choice__input"
                id="radio4"
                name="radio1"
                type="radio"
                value="yearly"
                v-model="mode"
              />
              <label class="c-choice__label" for="radio4">Yearly</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="border px-3 py-4">
          <!-- daily -->
          <div v-if="mode === 'daily'">
            <div class="d-flex align-items-center mb-2">
              <div class="c-choice c-choice--radio mb-0">
                <input class="c-choice__input" id="daily" name="daily" type="radio" />
                <label class="c-choice__label mb-0" for="daily">Every</label>
              </div>
              <input class="form-control mx-1" type="text" style="width:50px;" />
              <span>Day(s)</span>
            </div>
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" id="weekdays" name="daily" type="radio" />
              <label class="c-choice__label" for="weekdays">Every Weekday</label>
            </div>
          </div>

          <!-- weekly -->
          <div v-if="mode === 'weekly'">
            <div class="d-flex align-items-center mb-2">
              <div class="c-choice c-choice--radio mb-0">
                <input class="c-choice__input" id="daily" name="daily" type="radio" />
                <label class="c-choice__label mb-0" for="daily">Every</label>
              </div>
              <input class="form-control mx-1" type="text" style="width:50px;" />
              <span>weeks(s)</span>
            </div>
            <div class="d-flex pt-2">
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="1" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="1">Sunday</label>
              </div>
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="2" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="2">Monday</label>
              </div>
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="3" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="3">Tuesday</label>
              </div>
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="4" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="4">Wednesday</label>
              </div>
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="5" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="5">Thursday</label>
              </div>
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="6" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="6">Friday</label>
              </div>
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="7" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="7">Saturday</label>
              </div>
            </div>
          </div>

          <!-- monthly -->
          <div v-if="mode === 'monthly'">
            <div class="d-flex">
              <div class="u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Schedule Date (Day)</span>
                <input class="form-control" type="text" style="width:150px;" />
              </div>
              <div class="col-6 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">of every</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
              </div>
            </div>
          </div>

          <!-- yearly -->
          <div v-if="mode === 'yearly'">
            <div class="d-flex">
              <div class="u-mb-xsmall pr-3">
                <span class="c-toolbar__state-title u-text-capitalize">Schedule Date (Day)</span>
                <input class="form-control" type="text" style="width:150px;" />
              </div>
              <div class="col-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">of Month</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
              </div>
              <div class="col-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">of every</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {
      date: null,
      mode: "daily"
    };
  },
  components: {
    DatePicker
  }
};
</script>
