<template>
  <div class="">
    <div class="d-flex flex-wrap flex-md-nowrap">
      <div class="mr-3 px-0 col-md-2 col-12">
        <b-card>
          <h4 class="mb-0">Technicians</h4>
          <small class="mb-3 d-block text-muted">Select the technicians that you like to assign to this project.</small>
          <div class="c-choice c-choice--checkbox u-mr-small mb-2" v-for="person in technicians" :key="person.id">
            <input class="c-choice__input" :id="person.id" :value="person.id" name="checkboxes" type="checkbox" v-model="employeeIds" />
            <label class="c-choice__label" :for="person.id">{{ person.displayName }}</label>
          </div>
        </b-card>
      </div>
      <div class="w-100">
        <b-card v-if="$isDev && false" class="mb-3">
          <h4 class="mb-0">Actual</h4>
          <small class="mb-3 d-block text-muted">These are your actual returns, based on your current quote.</small>
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall  border-top-green">
                <h4 class="highlights-title text-muted">Avg. Hours</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">$55/hr</h2>
                <small class="text-muted">Average of Technician Hours</small>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 mb-3 pl-0">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall  border-top-orange">
                <h4 class="highlights-title text-muted">Est. Hours</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">100</h2>
                <small class="text-muted">Time to complete job</small>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 mb-3 pl-0">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall border-top-red">
                <h4 class="highlights-title text-muted">Labor Cost</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">$5,550</h2>
                <small class="text-muted">Total cost of technicians</small>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 mb-3 pl-0">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall border-top-purple">
                <h4 class="highlights-title text-muted">Quote Total</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">$7,150</h2>
                <small class="text-muted">This is the client total</small>
              </div>
            </div>
          </div>
        </b-card>
        <b-card class="mb-3">
          <h4 class="mb-0">Analysis</h4>
          <small class="mb-3 d-block text-muted">Choose your markup to see the potential profit.</small>
          <div class="row">
            <!-- <div class="col-lg-3 col-md-5 mb-3">
              <label for="labourRate">Choose your Markup</label>
              <v-select class="mb-3" :options="dropDownList" v-model="dropDownSelected" taggable value="id" label="displayName"></v-select>
            </div> -->

            <div class="col-lg-3 col-md-5 mb-3">
              <label for="markup">Markup (%)</label>
              <input type="text" class="form-control" id="markup" aria-describedby="markup" @blur="updateJobCosting" v-model="markup" placeholder="Enter your markup" />
            </div>
            <div class="col-lg-3 col-md-5 mb-3">
              <label for="labourRate">Estimated Labor Hours (h)</label>
              <input type="text" class="form-control" id="labourRate" aria-describedby="labourRate" @blur="updateJobCosting" v-model="estLaborHours" placeholder="Labour Hours" />
            </div>
            <div class="col-lg-3 col-md-5 mb-3">
              <label for="customerRate">Customer Rate ($)</label>
              <div class="input-group input-with-dollar-sign">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control" id="customerRate" aria-describedby="customerRate" @blur="updateJobCosting" v-model="customerRate" placeholder="Customer Rate" />
              </div>
            </div>
            <div class="col mb-3 text-right pt-2">
              <b-button :disabled="isSaving" @click="updateQuoteTotal" size="md" class="c-btn-outlined-primary mt-4 h-41">
                <template v-if="!isSaving">
                  <i class="fa fa-save u-mr-xsmall"></i>
                  Update Quote
                </template>
                <template v-else>
                  <img src="@/assets/images/loading-spinner.gif" class="loading" alt="Loading..." />
                  <span>&nbsp;Processing...</span>
                </template>
              </b-button>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall border-top-blue">
                <h4 class="highlights-title text-muted">Est. Labor Cost</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">{{ totalLabourCost | currency }}</h2>
                <small class="text-muted">Based on {{ labourRate | currency }} avg. hourly rate</small>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall border-top-red">
                <h4 class="highlights-title text-muted">Est. Material Cost</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">{{ totalMaterial | currency }}</h2>
                <small class="text-muted">Total material cost</small>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall border-top-green">
                <h4 class="highlights-title text-muted">Break Even Cost</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">{{ breakEvenCost | currency }}</h2>
                <small class="text-muted">Est. cost to do the job</small>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
              <div class="dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall border-top-purple">
                <h4 class="highlights-title text-muted">Final Price</h4>
                <h2 class="u-mb-zero hightlight-number font-weight-normal">{{ finalPrice | currency }}</h2>
                <small class="text-muted">Total including markup</small>
              </div>
            </div>
          </div>
        </b-card>
        <b-card>
          <JobCostingListItems :data-obj="dataObj" @labor-placeholder="setLaborPlaceholder" :items="costingListItems" />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import JobCostingListItems from "@/components/sales/tab-quote-job-costing-list-items.vue";
  import editMixin from "@/mixin/edit-mixin";
  import UXTechnicianService from "@/services/ux/uxtechnicians";
  import QuoteServices from "@/services/quotes";
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  const global = new GlobalServices();
  const api = new QuoteServices();
  const tech = new UXTechnicianService();
  export default {
    mixins: [editMixin],
    data() {
      return {
        markup: "No Markup",
        labourRate: 0,
        customerRate: 0,
        estLaborHours: 0,
        totalMaterial: 0,
        totalLabor: 0,
        totalLabourCost: 0,
        totalLaborDisplay: null,
        breakEvenCost: 0,
        finalPrice: 0,
        employeeIds: [],
        quoteDetailsItems: [],
        costingListItems: [],
        isSaving: false,
        dropDownSelected: {
          displayName: "Mark Up",
          id: 0,
        },
        dropDownList: [
          {displayName: "No Markup", id: 0},
          {displayName: "10%", id: 1},
          {displayName: "20%", id: 2},
          {displayName: "30%", id: 3},

          {displayName: "40%", id: 5},
          {displayName: "50%", id: 6},
          {displayName: "60%", id: 7},
          {displayName: "70%", id: 8},
          {displayName: "80%", id: 9},
          {displayName: "90%", id: 10},
        ],
        headers: [
          {
            id: 1,
            title: "Technician",
            columnName: "total",
            isSortable: true,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 3,
            title: "Time In",
            columnName: "total",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 3,
            title: "Travel",
            columnName: "total",
            isSortable: true,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 4,
            title: "Time Out",
            columnName: "total",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 5,
            title: "Total",
            columnName: "total",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 6,
            title: "Cost",
            columnName: "total",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 7,
            title: "Billable",
            columnName: "total",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
        ],
        technicians: [],
      };
    },
    watch: {
      employeeIds(value) {
        this.updateJobCosting();
      },
    },
    methods: {
      setLaborPlaceholder(data) {
        (this.totalMaterial = data.materialCost), (this.totalLabor = data.totalLabor), (this.finalPrice = data.finalPrice), (this.estLaborHours = data.estLaborHours);
        this.reloadItemList(data);
      },
      async reloadItemList(data) {
        this.quoteDetailsItems = await api.getQuoteDetails(this.dataObj.data.id);
        if (this.quoteDetailsItems.length > 0) {
          this.costingListItems = data.items;
        }
        await this.updateJobCosting();
      },
      async updateQuoteTotal() {
        if (this.costingListItems.length === 0) return;
        this.quoteDetailsItems = await api.getQuoteDetails(this.dataObj.data.id);
        this.isSaving = true;
        let item;
        // let qty = 0;
        let description = "";
        this.costingListItems.forEach((item) => {
          description += `${item.title} \n`;
          // qty += parseFloat(item.qty);
        });

        if (this.quoteDetailsItems.length > 0) {
          // update first
          item = this.quoteDetailsItems[0];
          item.unitPrice = this.finalPrice;
          item.qty = 1;
          item.description = description;
        } else {
          // create first
          item = {
            title: "",
            qid: this.dataObj.data.id,
            id: this.dataObj.data.id,
            quoteDetailId: 0,
            description: description,
            intQuoteTypeID: 1,
            qty: 1,
            unitPrice: this.finalPrice,
            labourUnitPerHour: 0,
            labourUnitPerMinute: 0,
            amount: this.finalPrice,
            labor: 0,
            grandTotal: this.finalPrice,
          };
        }
        // update the first item in details tab
        await api.updateData(item);
        this.updateField("Amount", this.finalPrice, "Amount");
        this.isSaving = false;
      },
      async updateJobCosting() {
        this.isSaving = true;
        const payload = {
          id: this.dataObj.details.GuidID,
          referenceId: this.dataObj.data.id,
          // markup: this.dropDownSelected.id !== 0 ? parseFloat(this.dropDownSelected.displayName) : 0,
          markup: this.markup === "No Markup" ? 0 : parseFloat(this.markup),
          totalLabourHours: parseFloat(this.estLaborHours),
          clientHourlyRate: parseFloat(this.customerRate),
          totalMaterialCost: this.totalMaterial,
          totalFinalPrice: this.finalPrice,
          jobCostingDetails: this.costingListItems,
          actionId: MODULE.QUOTATION.ActionId,
          employeeIds: this.employeeIds,
          guid: this.dataObj.details.GuidID,
        };
        const results = await global.globalRecalculate(payload);

        this.setResults(results);
        this.isSaving = false;
      },
      setResults(results) {
        if (results) {
          this.estLaborHours = results.totalLabourHours;
          this.totalLabourCost = results.totalLabourCost;
          this.totalMaterial = results.totalMaterialCost;
          this.finalPrice = results.totalFinalPrice;
          this.costingListItems = results.jobCostingDetails;
          this.totalLaborDisplay = results.totalLabourHoursDisplay;
          this.breakEvenCost = results.breakEvenCost;
          this.labourRate = results.labourRate;
          this.customerRate = results.clientHourlyRate;

          this.costingListItems = results.jobCostingDetails;
          this.markup = parseFloat(results.markup) === 0 ? "No Markup" : results.markup;
          // if (parseFloat(results.markup) > 0) {
          //   const getIndex = this.dropDownList.findIndex((item) => item.displayName === `${results.markup}%`);
          //   if (getIndex >= 0) {
          //     this.dropDownSelected = this.dropDownList[getIndex];
          //   } else {
          //     this.dropDownSelected.displayName = `${results.markup}%`;
          //   }
          // }
        }
      },
      async getCalculatedResults() {
        this.isSaving = true;
        const items = await global.globalCalculations(MODULE.QUOTATION.ActionId, this.dataObj.data.id, this.dataObj.details.GuidID);

        this.setResults(items);
        this.isSaving = false;
      },
    },
    async mounted() {
      this.getCalculatedResults();
      this.technicians = await tech.getTechnicians();
    },
    components: {
      JobCostingListItems,
    },
  };
</script>
