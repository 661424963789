<template>
    <div class="col-md-12">
        <div class="row"> 
        <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Drop Status</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
            </div>
            <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Address Type</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
            </div>
            <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Drop Type</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
            </div>
            <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">NAP</span>
                <input class="form-control"  type="text"/>
            </div>
            <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Port</span>
                <input class="form-control"  type="text"/>
            </div>
            <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Fiber Count</span>
                <input class="form-control"  type="text"/>
            </div>
            <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Cancellation Reason</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
            </div>
            <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Cancel Description</span>
                <input class="form-control"  type="text"/>
            </div>
            <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Second Visit Code</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data () {
        return {

        };
    },
}
</script>
