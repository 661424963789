<template>
  <div class="col-lg-3 col-md-6 mb-3">
    <!-- <div v-if="item" :class="[`dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall  border-top-${borderTop}` ,{highlighted: alwaysHighlighted}]"> -->
    <div v-if="item" :class="[`dash-grey-card c-card u-text-center u-pt-xsmall u-pb-xsmall h-100 border-top-${borderTop.toLowerCase()} ${smallCardBg}`]">
      <h4 class="highlights-title text-muted">{{ item.displayTitle }}</h4>
      <h2 class="u-mb-zero hightlight-number" :id="getPropertyNameAsId(item.displayTitle)">
        <span v-if="item.primarySymbol === '$'">{{ item.primarySymbol }}</span>
        <AnimatedNumber v-if="item.primaryNumber" :my-number="parseFloat(item.primaryNumber)" :is-currency="item.primarySymbol === '$'" />
        <span v-else>{{ item.primaryNumber }}</span>
        <span v-if="item.primarySymbol !== '$'">{{ item.primarySymbol }}</span>

        <span v-if="item.secondarySymbol && item.secondaryNumber !== null && item.secondarySymbol === '$'">{{ item.secondarySymbol }}</span>
        <AnimatedNumber v-if="item.secondaryNumber && item.secondaryNumber !== null" :my-number="parseInt(item.secondaryNumber)" :is-currency="item.secondarySymbol === '$'" />
        <span v-if="item.secondarySymbol !== '$'">{{ item.secondarySymbol }}</span>

        <small v-if="item.subText" class="d-block u-text-small text-muted">{{ item.subText }}</small>
        <small v-else class="py-2 d-block u-text-small"></small>
      </h2>
      <small class="text-muted" v-if="item.displayDescription">{{ item.displayDescription }}</small>
    </div>
  </div>
</template>

<script>
  import AnimatedNumber from "@/components/_universal/animated-numbers.vue";
  export default {
    name: "SmallCard",
    props: {
      borderTop: {
        type: String,
        default: "purple",
      },
      item: {
        type: Object,
        required: true,
      },
      alwaysHighlighted: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        hover: false,
      };
    },
    components: {
      AnimatedNumber,
    },

    computed: {
      smallCardBg() {
        return this.alwaysHighlighted ? "highlighted bg-" + this.borderTop.toLowerCase() : "";
      },
    },

    methods: {
      getPropertyNameAsId(name) {
        return name
          .split(" ")
          .join("-")
          .replace("/", "-")
          .toLowerCase();
      },
    },
  };
</script>
