export default {
  state: {
    email: {},
    ccCheckbox: [],
    bccCheckbox: [],
    template: null,
    content: null,
    templateDetails: {
      id: 0,
      description: "",
    },
    attachment: [],
  },
  getters: {
    getEmailData: (state) => {
      return state.email;
    },
    getCcData: (state) => {
      return state.ccCheckbox;
    },
    getBccData: (state) => {
      return state.bccCheckbox;
    },
    getTemplate: (state) => {
      return state.template;
    },
    getContent: (state) => {
      return state.content;
    },
    getTemplateDetails: (state) => {
      return state.templateDetails;
    },
    getAttachment: (state) => {
      return state.attachment;
    },
    getSelectedAttachment: (state) => {
      return state.attachment.filter((d) => d.isSelected);
    },
  },
  mutations: {
    setEmailData(state, email) {
      state.email = email;
    },
    setEmailCcData(state, emails) {
      state.ccCheckbox = emails;
    },
    setEmailBccData(state, emails) {
      state.bccCheckbox = emails;
    },
    setTemplate(state, data) {
      state.template = data;
    },
    setContent(state, data) {
      state.content = data;
    },
    setTemplateDetails(state, data) {
      state.templateDetails = data;
    },
    setAttachment(state, data) {
      state.attachment = data;
    },
  },
  actions: {
    setEmailData({commit}, email) {
      commit("setEmailData", email);
    },
    setTemplateDetails({state, commit}, template) {
      const email = {subject: template.subject, ...state.email};
      commit("setEmailData", email);
      commit("setContent", template.content);
    },
  },
};
