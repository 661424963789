import axios from "axios";
import API_CONFIG from "@/config/API_CONFIG";
import AuthService from "@/auth/authService";

let auth = AuthService.getInstance();
let config = new API_CONFIG();
let snapsuiteKey = config.SNAPSUITE_KEY;
let apiHeaderObj;

class BugReportService {
  constructor() {
    apiHeaderObj = {
      headers: {
        "x-snapsuite-key": snapsuiteKey,
      },
      baseURL: "https://snapsuite-support.azure-api.net",
    };
  }

  async submitBugReport(params) {
    let dataResults = await axios.post("jupiter/bugs", {...params, ...auth.getAuthObject()}, apiHeaderObj);
    return dataResults.data;
  }
}

export default BugReportService;
