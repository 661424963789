<template>
  <div class="sidebar-notifs pt-4 mt-5 u-height-100vh" :class="{'hideSidebarNotifs': !showSidebarNotifs}">
    <div class="u-mb-xsmall notif-search">
      <div class="search-input-wrapper p-2 d-flex">
        <input class="form-control" type="text" placeholder="Search" v-model="query" />
        <i class="fa fa-filter clickable h-41" v-b-toggle.search-filters></i>
        <a @click="onClosePanel" class="d-flex radius-4 ml-2 ss-danger-bg text-white px-3 h-41">
          <i class="fa fa-close u-text-larger align-self-center pb-1"></i>
        </a>
      </div>
      <b-card no-body class="mb-1 sidebar-item">
        <b-collapse id="search-filters" accordion="my-accordion" role="tabpanel">
          <b-card-body class="px-2">
            <div class="row">
              <div v-for="(f, idx) in filtersList" :key="idx" class="col-6 c-choice c-choice--checkbox u-mb-zero">
                <input class="c-choice__input" :id="f.id" :value="f.value" :name="f.id" type="checkbox"
                  v-model="filters" />
                <label class="c-choice__label" :for="f.id">{{ f.title }}</label>
              </div>
            </div>
          </b-card-body>
          <div class="ml-2 mr-4 u-bg-white radius-4" v-if="$isDev">
            <v-select :options="timeList" v-model="selectedTime" label="label"></v-select>
          </div>
        </b-collapse>
      </b-card>
    </div>
    <div class="scrollbar scrollable-div notif-sidebar-scroller">
      <NotificationActivity :data="activities" />
    </div>
  </div>
</template>


<script>
import ActivityServices from "@/services/activity";
import MODULE from "@/constants/modules";
import NotificationActivity from "@/components/notification-side-bar/notification-activity.vue";
import { flatten } from 'lodash/array'
const api = new ActivityServices();

export default {
  data() {
    return {
      filtersList: [
        { title: 'Clients', value: MODULE.CLIENTS.ActionId, id: 'clients-checkbox' },
        { title: 'Sales', value: MODULE.QUOTATION.ActionId, id: 'sales-checkbox' },
        { title: 'Work Order', value: MODULE.PROJECTS.ActionId, id: 'wo-checkbox' },
        { title: 'Technician', value: MODULE.EMPLOYEE.ActionId, id: 'technician-checkbox' },
        { title: 'Billing', value: MODULE.INVOICE.ActionId, id: 'billing-checkbox' },
        { title: 'Purchase Order', value: MODULE.PURCHASEORDER.ActionId, id: 'po-checkbox' },
      ],
      query: null,
      filters: [],
      intervalApi: null,
      activities: [],
      selectedTime: { label: "Last Month", value: "30d" },
      timeList: [
        { label: "Last 30 minutes", value: "30m" },
        { label: "Last 1 Hour", value: "1h" },
        { label: "Last 4 Hours", value: "4h" },
        { label: "Last 8 Hours", value: "8h" },
        { label: "Last 12 Hours", value: "12h" },
        { label: "Yesterday", value: "1d" },
        { label: "Last 3 Days", value: "3d" },
        { label: "Last 5 Days", value: "5d" },
        { label: "Last Week", value: "7d" },
        { label: "Last 2 Weeks", value: "14d" },
        { label: "Last Month", value: "30d" },
      ],
    };
  },
  components: { NotificationActivity },
  watch: {
    showSidebarNotifs(value) {
      if (value) {
        this.initializeData();

        this.intervalApi = setInterval(async () => {
          await this.initializeData();
        }, 5000);
      } else {
        clearInterval(this.intervalApi);
      }
    },
    filters(value) {
      this.reInitialize()
    },
    query(value) {
      this.reInitialize()
    },
    selectedTime(value) {
      this.reInitialize()
    }
  },
  computed: {
    showSidebarNotifs() {
      return this.$store.getters.getSidebarNotifsStatus;
    },
  },
  methods: {
    reInitialize() {
      clearInterval(this.intervalApi);
      this.initializeData()
      this.intervalApi = setInterval(async () => {
        await this.initializeData();
      }, 5000);
    },
    async initializeData() {
      const activities = await api.getAllActivity(this.query, this.filters, this.selectedTime.value);
      const notFlatten = activities.map((data) => {
        return data.results.map((res) => {
          return {
            id: res.id,
            icon: this.getIcon(res.activityTypeName),
            title: res.displayName,
            description: res.description,
            date: res.displayDateTime,
            iconBackground: res.htmlColourSchemeClass
          }
        })
      })
      this.activities = flatten(notFlatten)
    },
    getIcon(activityTypeName) {
      switch (activityTypeName) {
        case 'Emailed':
          return 'fa-envelope'
          break
        case 'Notes':
          return 'fa-sticky-note'
          break
        case 'Call':
          return 'fa-phone'
          break
        case 'Updated':
          return 'fa-pencil'
          break
        case 'System':
          return 'fa-cog'
          break
        case 'Schedule':
          return 'fa-calendar'
          break
        case 'Dispatch':
          return 'fa-truck'
          break
        case 'Status':
          return 'fa-sliders'
          break
        default:
          return 'fa-cog'
          break
      }
    },
    onClosePanel() {
      if (this.showSidebarNotifs) {
        this.$store.dispatch("toggleSidebarNotifs", false);
      }
    },
  }
};
</script>