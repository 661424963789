import axios from "axios";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";

let auth = AuthService.getInstance();

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class ClientService {
  private static _instance: ClientService;

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async getClientList() {
    let queryString = auth.getQueryString();

    let dataResults = await axios.get("clients" + queryString, apiHeaderObj);

    return dataResults.data;
  }

  async getClientById(id) {
    let queryString = auth.getQueryString() + "&clientID=" + id;

    let dataResults = await axios.get("client/details" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getClientByIdAndLocationId(id, locationId) {
    let queryString = auth.getQueryString() + "&clientID=" + id + "&locationID=" + locationId;

    let dataResults = await axios.get("client/details" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getTemplate(id, typeId) {
    let queryString = auth.getQueryString() + "&typeId=" + typeId + "&id=" + id;

    let dataResults = await axios.get("ux/template" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  static createInstance() {
    ClientService.getInstance();
  }

  static getInstance() {
    return this._instance || (this._instance = new this());
  }
}

export default ClientService;
