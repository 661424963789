<template>
    <div class="col-md-12">
        <div class="row"> 
        <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Project Status</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
            </div>
            <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Technician Status</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
            </div>
            <div class="col-md-4 mb-3">
                <span class="c-toolbar__state-title u-text-capitalize">Apointment Date</span>
                <date-picker format="YYYY-MM-DD" style="width: 100%;" lang="en"></date-picker>
            </div>
            <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Contact Name</span>
                <input class="form-control"  type="text"/>
            </div>
            <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Contact Phone Number</span>
                <input class="form-control"  type="text"/>
            </div>
            <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Client P.O Ref#</span>
                <input class="form-control"  type="text"/>
            </div>
            <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Internal Notes</span>
                <textarea class="form-control"  type="text"  rows="8"/>
            </div>
            <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Job Description</span>
                <textarea class="form-control"  type="text"  rows="8"/>
            </div>
        </div>
    </div>
</template>


<script>
import DatePicker from "vue2-datepicker";
export default {
    data () {
        return {

        };
    },
    components: {
        DatePicker
  }
}
</script>
