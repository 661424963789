<template>
  <div v-if="document">
    <ValidationObserver ref="formDocumentEditor">
      <!--start modal new project body -->
      <div class="row">
        <div class="col-12">
          <b-alert show class="mb-2" variant="success" v-if="isSuccesful">
            <i class="fa fa-check-circle mr-2"></i>
            <span class="sub-title mr-2" id="modal-new-client-success-message">Succesfully updated document. You may close this window.</span>
          </b-alert>
        </div>
        <div class="col-md-6 u-mb-xsmall">
          <span class="c-toolbar__state-title u-text-capitalize">
            Name
          </span>
          <ValidationProvider v-slot="v" name="Name" rules="required">
            <input
              class="form-control"
              type="text"
              v-model="document.documentName"
              id="modal-document-name"
              @keyup="
                updateField(
                  'DocumentName', //PropertyName
                  document.documentName, //Property Value
                  'Document Name', //Display Name
                  document.documentName, //Display Value
                  null, //dataObjPropertyName?,
                  null, //previousDisplayValue?,
                  null, //previousValueId?,
                  null, //isModal?,
                  document.id, //Current Item Row Id
                  MODULE.DOCUMENTS.ActionId
                )
              "
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-md-6 col-md-6 u-mb-xsmall">
          <span class="c-toolbar__state-title u-text-capitalize">
            Document Type
          </span>
          <v-select v-model="selectedDocumentType" :options="uxDocumentTypeList" :clearable="false" value="id" label="displayName" id="modal-document-type" @input="onUpdateSelection"></v-select>
        </div>

        <div class="col-md-12 u-mb-xsmall">
          <span class="c-toolbar__state-title u-text-capitalize">
            Description
          </span>
          <textarea
            class="form-control"
            type="text"
            v-model="document.notes"
            @keyup="
              updateField(
                'Description', //PropertyName
                document.notes, //Property Value
                'Document Description', //Display Name
                document.notes, //Display Value
                null, //dataObjPropertyName?,
                null, //previousDisplayValue?,
                null, //previousValueId?,
                null, //isModal?,
                document.id, //Current Item Row Id
                MODULE.DOCUMENTS.ActionId
              )
            "
            id="modal-document-description"
            rows="4"
          />
        </div>
      </div>
      <!-- col-md-12-->
      <!--end modal new project body -->
    </ValidationObserver>
  </div>
</template>

<script>
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import editMixin from "@/mixin/edit-mixin";
  const global = new GlobalServices();

  export default {
    mixins: [editMixin],
    data() {
      return {
        modalStatus: {},
        EVENT_ACTION: null,
        MODULE: null,
        document: null,
        uxDocumentTypeList: [],
        selectedDocumentType: null,
        isSuccesful: false,
      };
    },

    methods: {
      async onUpdateSelection(evt) {
        await this.updateField(
          "IntDocumentTypeID", //PropertyName
          evt.id, //Property Value
          "Document Type", //Display Name
          evt.displayName, //Display Value
          null, //dataObjPropertyName?,
          null, //previousDisplayValue?,
          null, //previousValueId?,
          null, //isModal?,
          this.document.id, //Current Item Row Id
          MODULE.DOCUMENTS.ActionId
        );
      },
      async initialize() {
        this.uxDocumentTypeList = await global.getUXDropDown(MODULE.DOCUMENTS.UX.DOCTYPES);
        this.modalStatus = this.$store.getters.getModalStatus;
        this.document = this.modalStatus.data;

        this.selectedDocumentType = this.uxDocumentTypeList.find((doc) => this.document.type === doc.displayName);
      },
      async onSave(data) {
        this.isSuccesful = true;
        setTimeout(() => {
          this.isSuccesful = false;
        }, 5000);
      },
    },

    async mounted() {
      this.initialize();
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
      this.MODULE = MODULE;
      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + EVENTS.DOCUMENTS_PHOTOS.Edit, this.onSave);
    },
    beforeDestroy() {
      this.$root.$off("Save" + EVENTS.DOCUMENTS_PHOTOS.Edit);
    },
  };
</script>
