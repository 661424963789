<template>
  <div>
    <!-- Filters -->
    <!-- <div v-if="$isDev" :class="['d-sm-flex', dataObj.actionButton ? 'section-pull-top' : 'section-fix-top']"> -->
    <div v-if="$isDev" :class="['d-sm-flex', true ? 'section-pull-top' : 'section-fix-top']">
      <b-form-group class="ml-auto mb-0 custom-group-radio-buttons technician-dashboard-group-buttons" v-slot="{ariaDescribedby}">
        <b-form-radio-group id="btn-radios-1" button-variant="outline-primary" v-model="selectedRadio" :options="options" :aria-describedby="ariaDescribedby" name="radios-btn-default" buttons>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <div class="table-responsive table-responsive-md w-auto dashboard-table">
      <table class="table" id="datatable">
        <thead class="c-table__head c-table__head--slim">
          <tr class="c-table__row">
            <th class="c-table__cell--head text-center" width="5%"></th>
            <th class="c-table__cell--head text-left" width="10%">Document Name</th>
            <th class="c-table__cell--head text-left" width="10%">Description</th>
            <th class="c-table__cell--head text-center" width="10%">Document Type</th>
            <!-- <th class="c-table__cell--head text-right" width="5%">File Size</th> -->
            <th class="c-table__cell--head text-center" width="10%">Date/Time</th>
            <th class="c-table__cell--head text-right" width="1%"></th>
            <th class="c-table__cell--head text-right" width="5%">Delete</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="documents.length > 0">
            <tr class="c-table__row" v-for="document in documents" :key="document.documentId">
              <td class="align-middle text-center">
                <a :id="`view-document-${document.documentId}`" @click.prevent="showPreview(document)" href="#"> <i class="fa fa-picture-o" aria-hidden="true"></i> View </a>
              </td>
              <td class="text-left menu-icons pl-2">{{ document.documentName }}</td>
              <td class="align-middle">{{ document.notes }}</td>
              <td class="text-nowrap align-middle text-center">{{ document.type }}</td>
              <!-- <td class="align-middle text-right">{{ document.size }}</td> -->
              <td class="align-middle text-center">{{ document.displayCreatedDate }}</td>
              <td class="align-middle text-right"><i @click="editDocument(document)" class="fa fa-pencil u-text-primary u-text-larger d-block clickable" aria-hidden="true"></i></td>
              <td class="align-middle text-right">
                <i v-if="document.isDeletable" @click="deleteItem(document)" class="fa fa-trash u-text-danger u-text-larger d-block clickable" aria-hidden="true"></i>
              </td>
            </tr>
          </template>
          <template v-if="documents.length === 0">
            <tr class="c-table__row">
              <td class="text-center" colspan="7">No documents found</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import EVENTS from "@/constants/events";
  import GlobalServices from "@/services/global";
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  const global = new GlobalServices();

  export default {
    mixins: [editMixin],
    props: {
      dataObj: Object,
    },
    data() {
      return {
        id: 0,
        EVENT_ACTION: {},
        intervalApi: null,
        documents: [],
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          modalTitle: "",
          data: {},
        },
        document: {
          url: "",
          wordURL: "",
          documentName: "",
        },
        selectedRadio: "workOrder",
        options: [
          {text: "Work Order", value: "workOrder"},
          {text: "Purchase Order", value: "purchaseOrder"},
          {text: "Invoice", value: "invoice"},
          {text: "All Documents", value: "all"},
        ],
      };
    },
    methods: {
      deleteItem(data) {
        this.updateField(
          "IsDeleted", //PropertyName
          true, //Property Value
          "Deleted Document", //Display Name
          "deleted", //Display Value
          null, //dataObjPropertyName?,
          null, //previousDisplayValue?,
          null, //previousValueId?,
          null, //isModal?,
          data.id, //Current Item Row Id
          MODULE.DOCUMENTS.ActionId
        );
      },
      showPreview(document) {
        if (document) {
          if (document.documentType !== "Generated") {
            this.showModal(this.EVENT_ACTION.Image.View, "Image Gallery", "", document);
          } else {
            // this.$store.dispatch("setApplicationModalIframe", {
            //   url: document.url,
            //   title: document.documentName,
            //   showToolbar: false,
            //   data: document,
            // });

            // this.showModal(this.EVENT_ACTION.IFRAME.PreviewImage, document.documentName, "", document, false);
            // temporarily disable since pdf plugin is not working
            this.showModal(this.EVENT_ACTION.PDF.View, "PDF", "", document);
          }
        }
      },
      editDocument(document) {
        this.showModal(this.EVENT_ACTION.DOCUMENTS_PHOTOS.Edit, "Edit Document", "", document);
      },
      showModal(paramModalName, modalTitle, sendButtonText, data) {
        this.modalStatus.hideShowModal = true;
        this.modalStatus.modalName = paramModalName;
        this.modalStatus.modalTitle = modalTitle;
        this.modalStatus.modalSaveButtonTitle = sendButtonText;
        this.modalStatus.data = data;
        this.modalStatus.dataListing = this.documents;

        this.$store.dispatch("toggleModalStatus", this.modalStatus);
      },
      async loadData() {
        const results = await global.getDataByActionId(this.dataObj.param, true);
        this.documents = results.table.data.map((document) => {
          return {
            documentId: parseInt(document.id),
            id: parseInt(document.id),
            documentName: document.name,
            url: document.url,
            displayCreatedDate: document.createdDate,
            description: document.description,
            size: document.size || 0,
            documentType: document.url.split(".").pop() === "pdf" ? "Generated" : "Attachments",
            type: document.type,
            isDeletable: document.isDeletable,
            icon: document.icon,
            notes: document.notes,
          };
        });
      },
    },
    async created() {
      await this.loadData();
    },
    async mounted() {
      // this.id = this.dataObj.data.id;

      this.EVENT_ACTION = EVENTS;

      this.intervalApi = setInterval(async () => {
        await this.loadData();
      }, 5000);
    },
    beforeDestroy() {
      clearInterval(this.intervalApi);
    },
  };
</script>
