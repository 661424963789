import axios from "axios";
import API_CONFIG from "@/config/API_CONFIG";
import AuthService from "@/auth/authService";
import UtilityString from "@/utilities/strings";
let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class SchedulerServices {
  config = new API_CONFIG();
  auth = AuthService.getInstance();
  utility = new UtilityString();

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async getSchedulerTechnicians() {
    let results;

    let paramString = this.auth.getQueryString();

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get("scheduler/technicians" + paramString, apiHeaderObj);

    return results.data.resources;
  }

  async getAssignedSchedulerTechnicians(id: Number) {
    let results;

    let paramString = this.auth.getQueryString() + `&id=${id}`;

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get("scheduler/assigned/technicians" + paramString, apiHeaderObj);

    return results.data.resources;
  }

  async assignTechnician(payload: Object) {
    let results;

    payload = Object.assign(payload, this.auth.getAuthSchedulerObject());

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.put("scheduler/projects/put", payload, apiHeaderObj);

    return results.data.resources;
  }
}

export default SchedulerServices;
