<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="row pt-2 pl-3">
          <div class="u-mb-xsmall mr-4">
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" checked id="radio1" name="radios" type="radio" />
              <label class="c-choice__label" for="radio1">Technician</label>
            </div>
          </div>
          <div class="u-mb-xsmall mr-4">
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" id="radio2" name="radios" type="radio" />
              <label class="c-choice__label" for="radio2">Equipment</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Choose a Technician</span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>
      <div class="col-md-6 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Rate</span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>

      <div class="col-md-3 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Due Date</span>
        <date-picker
          format="DD-MMM-YYYY - HH:mm a"
          :minute-step="15"
          style="width: 100%;"
          type="datetime"
          v-model="date"
          lang="en"
        ></date-picker>
      </div>
      <div class="col-md-3 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Time In</span>
        <date-picker
          format="DD-MMM-YYYY - HH:mm a"
          :minute-step="15"
          style="width: 100%;"
          type="datetime"
          v-model="date"
          lang="en"
        ></date-picker>
      </div>
      <div class="col-md-3 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Time Out</span>
        <date-picker
          format="DD-MMM-YYYY - HH:mm a"
          :minute-step="15"
          style="width: 100%;"
          type="datetime"
          v-model="date"
          lang="en"
        ></date-picker>
      </div>
      <div class="col-md-3 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Break Time</span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>

      <div class="col-md-6 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Travel</span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>
      <div class="col-md-6 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Premium/Overtime</span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>
      <div class="col-md-12 u-mb-xsmall">
        <div class="c-choice c-choice--checkbox mb-0">
          <input class="c-choice__input" id="emergencyCAll" name="checkboxes" type="checkbox" />
          <label class="c-choice__label" for="emergencyCAll">Emergency Call</label>
        </div>
      </div>
      <div class="col-md-12 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Description</span>
        <textarea class="form-control" type="text" rows="5" />
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {
      date: null
    };
  },
  components: {
    DatePicker
  }
};
</script>
