<template>
  <div>
    <tablelisttab v-if="listDataObj.table" :list-data-obj="listDataObj" :data-obj="dataObj" @update="updatelistDataObj" :table-id="componentTableName"></tablelisttab>
  </div>
</template>

<script>
  //Standard Components
  import EVENTS from "@/constants/events";

  //Template Layout Components
  import tablelisttab from "@/components/_universal/list-table-tab.vue";

  import editMixin from "@/mixin/edit-mixin";
  import GlobalServices from "@/services/global";

  let global = new GlobalServices();

  export default {
    mixins: [editMixin],
    data() {
      return {
        listDataObj: {},
        sidePanelDataObj: {},
        EVENT_ACTION: {},
        componentTableName: null
      };
    },
    methods: {
      async loadData() {
        //Set Header Properties to be passed in as prop values, second paramater set to true to indicate we're calling action from a list:isTabList
        this.listDataObj = await global.getDataByActionId(this.dataObj.param, true);
      },
      updatelistDataObj(results) {
        this.listDataObj = results;
      },
    },
    components: {
      tablelisttab,
    },
    async mounted() {
      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window["analytics"].page(this.EVENT_ACTION.CLIENTS.View);
      // }
      const name = this.dataObj.title
      this.componentTableName = name.replace(" ", "-").replace("/", "-").toLowerCase();

      this.$root.$on(this.closeEvent, this.loadData);

      let sidePanel = this.$store.getters.getSidePanel;

      //Ensure the side bar is open
      if (sidePanel.isOpen === true) {
        this.timer = setInterval(async () => {
          await this.loadData();
        }, 2000);
      }
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
      await this.loadData();
    },
  };
</script>
