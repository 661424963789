





























































































































































































































































































































































import UXClientService from "@/services/ux/uxclients";

import {VueAccordion, VueAccordionItem} from "vue-accordion-styless";
import UXProjectsService from "@/services/ux/uxprojects";
import UXTechnicianService from "@/services/ux/uxtechnicians";
import {DashboardFilter} from "@/interfaces/filters/DashboardFilters";
import UXDueDateService from "@/services/ux/uxdates";
import UXEmployeeService from "@/services/ux/uxemployee";
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
const global = new GlobalServices();
export default {
  props: {},
  data() {
    return {
      filter: {} as DashboardFilter,
      keywordAddress: "",
      projectRefNumber: "",
      selectedClient: null,
      selectedTechnicianStatus: null,
      selectedProjectType: null,
      selectedProjectStatus: null,
      selectedTechnicians: null,
      technicianStatusList: [],
      projectStatusList: [],
      projectTypeList: [],
      selectedDueDate: null,
      selectedCreatedBy: null,
      clientList: [],
      dueDateList: [],
      techniciansList: [],
      createdByList: [],
      isClose: true,
      isFilterClose: true,
      isAdvanceFiltersShowing: false,
      isSortClose: true,
      fromDate: null,
      toDate: null,
      isDateInvalid: false,
      selectedDateFilterBy: null,
      selectedCompanyLocation: {displayName: "Choose a Location", value: ""},
      exclude: {
        projectType: [],
        status: [],
        techStatus: [],
      },
      companyLocation: [
        {displayName: "Choose a Location", value: ""},
        {displayName: "Toronto - North", value: "Toronto - North"},
        {displayName: "Toronto - South", value: "Toronto - South"},
        {displayName: "Toronto - East", value: "Toronto - East"},
        {displayName: "Toronto - West", value: "Toronto - West"},
        {displayName: "New York - Upstate", value: "New York - Upstate"},
        {displayName: "Texus", value: "Texus"},
        {displayName: "Florida", value: "Florida"},
      ], //Fake company loocation for now
      dateFilterByList: [
        {displayName: "Appointment Date", value: "AppointmentDate"},
        {displayName: "Completed Date", value: "CompletedDate"},
        {displayName: "Created Date", value: "CreatedDate"},
        {displayName: "Due Date", value: "DueDate"},
        {displayName: "Est. Completed Date", value: "EstCompletedDate"},
        {displayName: "Start Date", value: "StartDate"},
      ],
      timer: null,
      counter: 0,
    };
  },
  methods: {
    resetParamaters() {
      this.filter = {} as DashboardFilter;
      this.selectedClient = null;
      this.selectedProjectType = null;
      this.selectedProjectStatus = null;
      this.selectedTechnicianStatus = null;
      this.keywordAddress = null;
      this.projectRefNumber = null;
      this.selectedDueDate = null;
      this.selectedTechnicians = null;
      this.selectedCreatedBy = null;
      this.selectedDateFilterBy = null;
      this.fromDate = null;
      this.toDate = null;
      this.exclude = {
        projectType: [],
        status: [],
        techStatus: [],
      };
      this.$store.commit("setPage", 1);
    },
    toggle() {
      this.isClose = !this.isClose;
    },
    filterToggle() {
      this.isFilterClose = !this.isFilterClose;
    },
    toggleAdvanceFilters() {
      this.isAdvanceFiltersShowing = !this.isAdvanceFiltersShowing;
    },
    sortToggle() {
      this.isSortClose = !this.isSortClose;
    },
    //UX Clients
    async loadClientDropdown() {
      // const uxclients = new UXClientService();

      // const results = await uxclients.getClients();
      this.clientList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
    },
    async loadProjectDropdown() {
      const projects = new UXProjectsService();

      const apiProjectType = await projects.getProjectType();
      const apiProjectStatus = await projects.getProjectStatus();

      this.projectTypeList = apiProjectType;
      this.projectStatusList = apiProjectStatus;
    },
    //UX Technicians
    async loadTechnicianDropdown() {
      const techs = new UXTechnicianService();

      const apiTechStatus = await techs.getTechnicianStatus();
      const apiTechs = await techs.getTechnicians();

      this.techniciansList = apiTechs;
      this.technicianStatusList = apiTechStatus;

      this.$store.dispatch("setTechniciansList", apiTechs);
    },
    async loadDueDateDropdown() {
      const dateService = new UXDueDateService();

      const apidates = await dateService.getDueDateList();

      this.dueDateList = apidates;
    },
    async loadEmployeeDropdown() {
      const employeeService = new UXEmployeeService();

      const apiemployee = await employeeService.getEmployees();

      this.createdByList = apiemployee;
    },
    async reloadResults(filterParam) {
      this.counter = Object.keys(filterParam).length;
      this.$store.dispatch("getFilterResults", filterParam);

      if (!this.timer) {
        this.$root.$emit("summary-timer", true);
        this.setTimer();
      }
    },
    async onDateFilterByChanged(event) {
      if (this.isDateFilterValid()) {
        await this.reloadResults(this.filter);
      } else {
        delete this.filter.dateFilter;
        delete this.filter.toDate;
        delete this.filter.fromDate;
      }
    },

    //Validate paramaters if the user is trying to filter by Date Filter By
    isDateFilterValid() {
      let hasToDate = false;
      let hasFromDate = true;

      //Ensure User selecte a Date Filter By
      if (this.selectedDateFilterBy != null) {
        this.filter.dateFilter = this.selectedDateFilterBy.value;

        //Validate To Date
        if (this.toDate) {
          hasToDate = true;
          this.filter.toDate = this.toDate;
        }

        //Validate From Date
        if (this.fromDate) {
          hasFromDate = true;
          this.filter.fromDate = this.fromDate;
        }
      }

      if (hasFromDate && hasToDate) return true;
      else return false;
    },
    handlerReloadTimer(isClear) {
      if (isClear) {
        this.clearTimer();
      } else {
        this.setTimer();
      }
    },
    async setTimer() {
      if (this.page <= 1) {
        this.timer = setInterval(async () => {
          await this.reloadResults(this.filter);
        }, 5000);
      }
    },
    clearTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    async onTechnicianStatusSelected(event) {
      if (event) {
        this.filter.technicianStatusId = event.id;
        this.filter.maximumRows = 300;
      } else {
        delete this.filter.technicianStatusId;
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async onTechnicianSelected(event) {
      if (event) {
        this.filter.technicianId = event.id;
        this.filter.maximumRows = 300;
      } else {
        delete this.filter.technicianId;
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async onClientSelected(event) {
      if (event) {
        this.filter.clientId = event.id;
        this.filter.maximumRows = 300;
      } else {
        delete this.filter.clientId;
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async onProjectTypeSelected(event) {
      if (event) {
        this.filter.projectTypeId = event.id;
        this.filter.maximumRows = 300;
      } else {
        delete this.filter.projectTypeId;
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async onSelectExcludeProjectType() {
      if (this.exclude.projectType.length > 0) {
        this.$set(this.filter, "exclude.projectTypeId", this.exclude.projectType.map((i) => i.id).toString());
        this.filter.maximumRows = 300;
      } else {
        this.$delete(this.filter, "exclude.projectTypeId");
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async clearExcludeProjectType() {
      if (this.exclude.projectType.length > 0) {
        this.exclude.projectType = [];
        this.$delete(this.filter, "exclude.projectTypeId");
        delete this.filter.maximumRows;
        await this.reloadResults(this.filter);
      }
    },
    async onSelectExcludeStatus() {
      if (this.exclude.status.length > 0) {
        this.$set(this.filter, "exclude.projectStatusId", this.exclude.status.map((i) => i.id).toString());
        this.filter.maximumRows = 300;
      } else {
        this.$delete(this.filter, "exclude.projectStatusId");
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async clearExcludeStatus() {
      if (this.exclude.status.length > 0) {
        this.exclude.status = [];

        this.$delete(this.filter, "exclude.projectStatusId");
        delete this.filter.maximumRows;
        await this.reloadResults(this.filter);
      }
    },
    async onSelectExcludeTechtatus() {
      if (this.exclude.techStatus.length > 0) {
        this.$set(this.filter, "exclude.technicianStatusId", this.exclude.techStatus.map((i) => i.id).toString());
        this.filter.maximumRows = 300;
      } else {
        this.$delete(this.filter, "exclude.technicianStatusId");
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async clearExcludeTechtatus() {
      if (this.exclude.techStatus.length > 0) {
        this.exclude.techStatus = [];
        this.$delete(this.filter, "exclude.technicianStatusId");
        delete this.filter.maximumRows;
        await this.reloadResults(this.filter);
      }
    },
    async onProjectStatusSelected(event) {
      if (event) {
        this.filter.projectStatusId = event.id;
        this.filter.maximumRows = 300;
      } else {
        delete this.filter.projectStatusId;
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async onCreatedBySelected(event) {
      if (event) {
        this.filter.createdById = event.employeeID;
        this.filter.maximumRows = 300;
      } else {
        delete this.filter.createdById;
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async onDueDateSelected(event) {
      if (event) {
        this.filter.dueDateId = event.id;
        this.filter.maximumRows = 300;
      } else {
        delete this.filter.dueDateId;
        delete this.filter.maximumRows;
      }
      await this.reloadResults(this.filter);
    },
    async onAddressInputChange(event) {
      if (this.keywordAddress.length > 2) {
        this.filter.keywordAddress = this.keywordAddress;
        this.filter.maximumRows = 300;
        await this.reloadResults(this.filter);
      }

      if (this.keywordAddress.length == 0) {
        delete this.filter.keywordAddress;
        delete this.filter.maximumRows;
        await this.reloadResults(this.filter);
      }
    },
    async onProjectRefNumberInputChange(event) {
      if (this.projectRefNumber.length > 2) {
        this.filter.projectRefNumber = this.projectRefNumber;
        this.filter.maximumRows = 300;
        await this.reloadResults(this.filter);
      }

      if (this.projectRefNumber.length == 0) {
        delete this.filter.projectRefNumber;
        delete this.filter.maximumRows;
        await this.reloadResults(this.filter);
      }
    },
  },
  computed: {
    selectedClientId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.clientId;
      }
      return null;
    },
    selectedProjectTypeId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.clientId;
      }
      return null;
    },
    selectedProjectStatusId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.projectStatusId;
      }
      return null;
    },
    selectedTechnicianStatusId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.technicianStatusId;
      }
      return null;
    },
    selectedTechnicianId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.technicianId;
      }
      return null;
    },
    selectedDueDateId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.dueDateId;
      }
      return null;
    },
    selectedAssignedToId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.assignedToId;
      }
      return null;
    },
    selectedCreatedId() {
      if (this.$store.getters.getFilter) {
        return this.$store.getters.getFilter.createdById;
      }
      return null;
    },
    page() {
      return parseInt(this.$store.getters.getPage);
    },
  },
  watch: {
    page(value) {
      if (value >= 3) {
        this.clearTimer();
      }
    },
  },
  async mounted() {
    await this.setTimer();

    this.$store.subscribe((mutation, state) => {
      //Monitor if the technician list change to load the dropdown.
      if (mutation.type === "reset") {
        this.resetParamaters();

        // stop the summary incase there is running
        this.$root.$emit("summary-timer", true);
        if (!this.timer) this.setTimer();
      }
    });
  },
  async created() {
    await this.loadProjectDropdown();
    await this.loadClientDropdown();
    await this.loadTechnicianDropdown();
    await this.loadDueDateDropdown();
    await this.loadEmployeeDropdown();

    this.$root.$on("filters-timer", this.handlerReloadTimer);
  },
  beforeDestroy() {
    this.$root.$off("filters-timer", this.handlerReloadTimer);
    this.clearTimer();
  },
  components: {
    //DatePicker,
    VueAccordion,
    VueAccordionItem,
  },
};
