<template>
  <div class="col-md-12" style="background:#FFF;">
    <div class="row">
      <div class="col-md-12">
        <b-tabs content-class="mt-3">
          <b-tab active>
            <template v-slot:title>
              <strong>Support Now</strong>
            </template>
            <div class="row">
              <div class="row">
                <div class="col-md-12">
                  <div class="alert alert-info" role="alert">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    &nbsp;If you are currently on a support call or you were
                    given permission to start a remote screen share,
                    <br />please follow the instructions below to join the
                    meeting.
                  </div>
                </div>
                <div class="col-md-6 text-center">
                  <a
                    type="button"
                    class="btn btn-outline-primary btn-lg"
                    href="  https://v.ringcentral.com/join/636639927?pw=9488ccedbacec3470d0884950aef88f5"
                    target="_blank"
                  >
                    <i class="fa fa-video-camera" aria-hidden="true"></i>
                    &nbsp;Join Meeting Now
                  </a>
                  <img
                    src="@/assets/images/support-staff.png"
                    class="w-80 mt-4"
                    alt="Support staff image"
                  />
                </div>
                <div class="col-md-6">
                  <h3>Dial-in Numbers</h3>

                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">United States</h5>

                      <div class="card-text row">
                        <div class="col-md-12">
                          Dial:
                          <strong>+1 (650) 419 1505</strong>
                          <br />
                          <br />Meeting:
                          <strong>636 639 927</strong>

                          <br />Password:
                          <strong>278893</strong>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">Canada</h5>

                      <div class="card-text row">
                        <div class="col-md-12">
                          Dial:
                          <strong>+1 (515) 819 1648</strong>
                          <br />
                          <br />Meeting:
                          <strong>636 639 927</strong>

                          <br />Password:
                          <strong>278893</strong>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <strong>Schedule a Meeting</strong>
            </template>

            <iframe
              frameborder="0"
              scrolling="no"
              style="height:650px;width:100%;border:none;"
              src="/support.html"
            ></iframe>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";

import EVENTS from "@/constants/events";

export default {
  data() {
    return {
      EVENT_ACTION: {},

      modal: {
        hideShowModal: false,
        message: "",
        modalSaveButtonTitle: "",
        data: {}
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    //Initialize Data
    async loadData() { }
  },
  mounted() {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window.analytics.page(this.EVENT_ACTION.SUPPORT.View);
    // }
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.loadData();
  },

  components: {}
};
</script>
