<template>
  <div>
    <div :class="['d-sm-flex', {'mb-3':dataObj.actionButton}]">
      <b-button v-if="dataObj.actionButton" :id="$route.name+'-tabs-add-new-location'" size="md"
        @click.prevent="onActionItemClicked(dataObj.actionButton)" class="ss-warning-bg border-0">
        <i class="fa fa-plus u-mr-xsmall"></i>
        {{ dataObj.actionButton.menuTitle }}
      </b-button>
      <!-- <pre>{{dataObj}} </pre> -->
      <!-- 
      <div class="ml-auto col-lg-4 col-md-6">
        <input class="form-control h-42" type="text" placeholder="Search Equipment" />
      </div>

      <div class="btn-group h-100">
        <a
          class="btn c-btn-outlined-gray"
          href="#"
          @click.prevent="onActionItemClicked(dataObj.expandListButton)"
        >
          <i class="fa fa-expand"></i>
        </a>
        <a class="btn c-btn-outlined-gray" href="#" @click="openNewTab()">
          <i class="fa fa-window-restore"></i>
        </a>
      </div> -->
    </div>
    <component :data-obj="dataObj" :is="dataObj.componentName" />
  </div>
</template>

<script>
  import ApplicationService from "@/services/application";
  import * as components from "@/components";

  const app = new ApplicationService();

  export default {
    name: "tab-component",
    props: {
      dataObj: {
        type: Object,
        required: true,
      },
    },
    methods: {
      onActionItemClicked(action) {
        //Pass action to global navigation action handler...
        app.actionNavigation(action);
      },
      openNewTab() {
        let routeData = this.$router.resolve({name: "clients-equipment-list"});
        window.open(routeData.href, "_blank");
      },
    },
    components: {
      ...components,
    },
  };
</script>
