






















import editMixin from "@/mixin/edit-mixin";
import MODULE from "@/constants/modules";
export default {
  name: "UniversalDetails",
  mixins: [editMixin],
  props: {
    dataObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      description: null,
    };
  },
  mounted() {
    this.modalObj.actionId = MODULE.PRICING.ActionId;
  },
};
