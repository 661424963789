<template>
  <div>
    <ValidationObserver ref="modalForm">
      <!--start modal new project body -->
      <div class="col-md-12 u-pt-small">
        <div v-if="isSavingSuccessful" class="alert alert-success" id="modal-new-purchase-order-success-message" role="alert">
          Successfully created a new Purchase Order. You may close this window.
        </div>
        <div v-if="isError" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">1</span>Choose a Supplier</div>
            <ValidationProvider name="supplier" rules="required" v-slot="{errors}">
              <v-select v-model="selectedSupplier" id="new-purchase-order-modal-choose-supplier" :options="uxSuppliersList" @input="onSelectSupplier" code="id" label="displayName" :clearable="false">
              </v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">2</span>Delivery Preference</div>
            <ValidationProvider name="delivery preference" rules="required" v-slot="{errors}">
              <v-select
                v-model="selectedDeliveryPreference"
                id="new-purchase-order-modal-delivery-preference"
                :options="uxDeliveryPreferenceList"
                @input="onSelectDeliveryPreference"
                code="id"
                label="displayName"
                :clearable="false"
              ></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div v-if="projectId" class="col-lg-4 col-md-6 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small"><span class="u-mr-small">3</span>Delivery/Pickup Date</div>
            <ValidationProvider name="delivery pickup date" rules="required" v-slot="{errors}">
              <span class="c-toolbar__state-title u-text-capitalize"></span>
              <date-picker format="DD-MMM-YYYY" style="width: 100%;" id="new-purchase-order-modal-delivery-pickup-date" v-model="deliveryDate" @change="onChangeDeliveryDate" lang="en"></date-picker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div v-if="!projectId" class="col-lg-4 col-md-6 ss--new-quote__modal_module u-mb-small mt-lg-0 mt-2">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small"><span class="u-mr-small">3</span>Choose a Job</div>
            <ValidationProvider name="Work Order" rules="required" v-slot="{errors}">
              <span class="c-toolbar__state-title u-text-capitalize"></span>
              <v-select
                v-model="purchaseOrder.IntProjectID"
                id="new-purchase-order-modal-choose-job"
                :options="uxJobOrders"
                :reduce="(project) => project.id"
                code="id"
                label="displayName"
                :clearable="false"
              ></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <!-- row -->
        <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

        <div class="row">
          <div class="col-md-6 u-mb-xsmall mt-2">
            <span class="c-toolbar__state-title u-text-capitalize">Delivery Instructions</span>

            <input rows="2" class="form-control" id="new-purchase-order-modal-delivery-instructions" type="text" v-model="purchaseOrder.DeliveryNotes" />
          </div>

          <div class="col-md-2 mt-5 u-mb-xsmall">
            <b-form-checkbox v-model="purchaseOrder.IsCritical" id="new-purchase-order-modal-urgent">
              <span class="ml-2">Urgent</span>
            </b-form-checkbox>
          </div>

          <div class="col-md-4 u-mb-xsmall u-border-left">
            <div class="info-box" id="modal-new-purchase-order-info-box">
              <div class="ss-danger-light-bg u-border-rounded text-right pr-3 py-1">
                <p class="mb-0 ss-white-font-color mb-0">Sub Total</p>
                <h4 class="ss-white-font-color mb-0" id="modal-new-purchase-order-info-box-sub-total">
                  {{ subTotal | currency }}
                </h4>
                <!-- <p class="mb-0 ss-white-font-color" id="modal-new-purchase-order-info-box-with-tax">With Tax: {{
                    purchaseOrderTotal | currency
                }}</p> -->
              </div>
            </div>
          </div>

          <div class="col-md-12 u-mb-xsmall pt-4">
            <UniversalList
              :used-in="'PURCHASE_ORDER'"
              v-bind:propItemList="propItemList"
              v-bind:lookupList="lookupList"
              v-on:EVENT_ReCalculate="onUpdateSubTotal"
              v-on:EVENT_ListUpdated="onUpdateList"
              @get-list="onLoadInventory"
              table-id="datatable-modal-list-purchase-order"
            ></UniversalList>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- col-md-12-->
      <!--end modal new project body -->
    </ValidationObserver>
  </div>
</template>

<script>
  import UniversalList from "@/components/_universal/list";
  import GlobalServices from "@/services/global";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import UtilityString from "@/utilities/strings";
  const utility = new UtilityString();
  const global = new GlobalServices();
  import editMixin from "@/mixin/edit-mixin";

  export default {
    mixins: [editMixin],
    data() {
      return {
        isSavingSuccessful: false,
        isError: false,
        errorMessage: "Sorry, there was an error while creating this Purchase Order. Please try again.",
        purchaseOrder: {},
        propItemList: [],
        lookupList: [],
        isDisabled: true,
        subTotal: 0,
        taxRate: 0,
        grandTotal: 0,
        purchaseOrderTotal: 0,
        selectedTax: null,
        deliveryDate: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10), //Add 5 days to current date by default
        selectedSupplier: {displayName: "", id: 0},
        selectedDeliveryPreference: {displayName: "", id: 0},
        uxDeliveryPreferenceList: [],
        uxSuppliersList: [],
        uxJobOrders: [],
        projectId: null,
      };
    },

    methods: {
      async onChangeDeliveryDate(e) {
        this.purchaseOrder.DeliveryPickupDate = e;
        this.isError = false; //reset error
      },
      async getProjectMaterials() {
        if (this.purchaseOrder.IntProjectID) {
          const itemList = await global.getTableDataOnly({
            id: this.purchaseOrder.IntProjectID,
            parentActionId: this.MODULE.DASHBOARD.ActionId,
            actionId: this.MODULE.MATERIALS.ActionId,
          });
          this.propItemList = this.purchaseOrder.purchaseOrderItems = this.mapItemList(itemList);
        }
      },
      mapItemList(itemList) {
        return itemList.map((item) => {
          const qty = item.qty !== null ? parseFloat(item.qty) : 0;
          const unitPrice = item.unitPrice ? parseFloat(utility.stripDollarSign(item.unitPrice)) : 0;
          const total = qty * unitPrice;
          return {
            name: item.name,
            guid: item.guid,
            description: item.description ? item.description : item.name,
            qty,
            unitPrice,
            total,
            amount: total,
            id: item.id,
            utcCreatedDate: item.createdDate,
          };
        });
      },
      async setBasicInfo() {
        this.projectId = this.modalStatus.data.intProjectID || null;
        if (this.projectId) {
          this.purchaseOrder.IntProjectID = this.projectId;
          await this.getProjectMaterials();
        }
        this.purchaseOrder.moduleId = this.MODULE.PURCHASEORDER.ModuleId;

        this.purchaseOrder.DeliveryPickupDate = this.deliveryDate;

        // this.lookupList = await global.getUXDropDown(this.MODULE.COMBINATION.INVENTORYPRICING);
        this.onLoadInventory();
        //Set List Items
        // this.propItemList = this.purchaseOrder.purchaseOrderItems;
      },

      async onLoadInventory(mode) {
        // let inventoryService = new UXInventoryService();

        // //Hack to pass additional paramaters to inventory list
        // let queryString = "&actionId=" + MODULE.QUOTATION.ActionId;
        let defaultActionId = MODULE.PRICING.ActionId;
        if (mode) defaultActionId = mode;
        this.lookupList = await global.getUXDropDown(defaultActionId);
      },

      async initializeData() {
        //Get Data From Modal
        this.modalStatus = this.$store.getters.getModalStatus;

        //Guarantee the the current modal details object it set correctly
        this.$store.dispatch("setModalDataDetailsObject", this.modalObj.details);

        //Get Suppliers List
        this.uxSuppliersList = await global.getUXDropDown(this.MODULE.SUPPLIER.ActionId);
        this.modalUXData.uxSuppliersList = this.uxSuppliersList;
        if (!this.projectId) {
          //Get Suppliers List
          this.uxJobOrders = await global.getUXDropDown(this.MODULE.PROJECTS.ActionId);
          this.modalUXData.uxJobOrders = this.uxJobOrders;
        }

        //Populate Delivery Preferences
        this.uxDeliveryPreferenceList = await global.getUXDropDown(this.MODULE.PURCHASEORDER.UX.DeliveryPreference);

        //Set Basic Values
        await this.setBasicInfo();
      },
      onSelectDeliveryPreference(e) {
        this.purchaseOrder.IntDeliveryPreferenceID = e.id;
        this.purchaseOrder.DeliveryOptions = e.displayName; //set for backward compatability
      },
      async onSelectSupplier(e) {
        this.purchaseOrder.IntSupplierID = e.id;
        const supplier = await global.getEditDetails(this.MODULE.SUPPLIER.ActionId, e.id);

        if (supplier && supplier.IntDeliveryID) {
          this.selectedDeliveryPreference = this.uxDeliveryPreferenceList.find((d) => parseInt(d.id) === parseInt(supplier.IntDeliveryID));
        } else {
          this.selectedDeliveryPreference = global.setDropDownDefault(this.uxDeliveryPreferenceList);
        }

        this.onSelectDeliveryPreference(this.selectedDeliveryPreference);
        this.isError = false; //reset error
      },
      onUpdateList(list) {},
      onUpdateSubTotal(value) {
        this.isError = false; //Reset error message.
        this.subTotal = parseFloat(value);

        this.purchaseOrder.purchaseOrderItems = this.propItemList;

        this.onChangeTax();
      },
      onChangeTax() {
        this.purchaseOrderTotal = _.add(this._.multiply(this.subTotal, this.taxRate), this.subTotal);
      },
      async onSave(data) {
        this.$refs.modalForm.validate().then(async (success) => {
          if (!success) {
            return;
          }

          //Ensure we have at lease one line item on P.O.
          if (this.purchaseOrder.purchaseOrderItems.length == 0) {
            this.isError = true;
            this.isSavingSuccessful = false;

            this.errorMessage = "Please enter at lease one line item.";
            return;
          }

          //Ensure we have selected a delivery preference
          if (this.selectedDeliveryPreference.id == 0) {
            this.isError = true;
            this.isSavingSuccessful = false;

            this.errorMessage = "Please choose a delivery preference";
            return;
          }

          //Ensure we have selected a supplier
          if (this.selectedSupplier.id == 0) {
            this.isError = true;
            this.isSavingSuccessful = false;

            this.errorMessage = "Please choose a Supplier";
            return;
          }

          if (this.selectedSupplier.id && this.selectedDeliveryPreference.id) {
            //Temp. Hack to support old project earth..
            this.purchaseOrder.status = "New";
            this.purchaseOrder.PurchaseRefDate = new Date(Date.now());
            this.purchaseOrder.ModifiedDate = new Date(Date.now());
            this.purchaseOrder.CreatedDate = new Date(Date.now());

            this.purchaseOrder.IntLastModifiedUserID = 1;
            this.purchaseOrder.IntCreatedUserID = 1;
            this.purchaseOrder.IntPurchaseOrderStatusID = 1001; // set to new to avoid bug on filtering

            let result = await global.createNewActivity(MODULE.PURCHASEORDER.ActionId, this.purchaseOrder);

            if (result.success) {
              this.isSavingSuccessful = true;
              this.isError = false;
              this.isInfo = false;

              this.purchaseOrder.DeliveryNote = "";
              this.purchaseOrder.purchaseOrderItems = [];
              this.propItemList = [];
              this.purchaseOrder.IsCritical = 0;

              // Wait until the models are updated in the UI
              this.$refs.modalForm.reset();
            } else {
              this.isError = true;
              this.isSavingSuccessful = false;
            }

            setTimeout(() => {
              this.isSavingSuccessful = false;
              this.isError = false;
            }, 30000);
          } else {
            this.isSavingSuccessful = false;
            this.isError = true;
            return;
          }
        });
      },
    },
    components: {
      UniversalList,
    },
    async created() {
      this.initializeData();

      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page(this.EVENT_ACTION.PURCHASEORDER.View);
      // }

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.PURCHASEORDER.New, this.onSave);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.PURCHASEORDER.New, this.onSave);
    },
  };
</script>
