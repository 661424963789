






























































































































































































































































































































































































































































import Vue from "vue";
import editMixin from "@/mixin/edit-mixin";
import GlobalServices from "@/services/global";

let global = new GlobalServices();

export default {
  mixins: [editMixin],
  data() {
    return {

    };
  },

  async created() {   


  },
};
