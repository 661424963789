




import Vue from "vue";

export default Vue.extend({
  async created() {
    //Set profile details in global vuex
    await this.$store.dispatch("applicationConfiguration");

    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      
    //    if(typeof Vue.prototype.$auth != "undefined") {
    //      if(typeof Vue.prototype.$auth.user != "undefined") {

    //           window["analytics"].identify(Vue.prototype.$auth.user["https://snapsuite_u"], {
    //             groupId: Vue.prototype.$auth.user["https://snapsuite_tenantId"],
    //             email: this.$auth.user.name,
    //             displayName: this.$auth.user.nickname,
    //           });
    //      }
    //    }
    // }

    this.$router.replace("/");
  }
});
