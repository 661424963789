<template>
  <div>
    <TabInvoiceDetails :data-obj="dataObj" />
    <!-- <UniversalList v-bind:propItemList="propItemList" v-bind:lookupList="lookupList"
      v-on:EVENT_ReCalculate="onUpdateSubTotal" v-on:EVENT_ListUpdated="onUpdateList"
      table-id="datatable-list-details-invoice"></UniversalList> -->
  </div>
</template>
<script>
  import editMixin from "@/mixin/edit-mixin";
  import TabInvoiceDetails from "@/components/invoice/table/invoice-tab";
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  const global = new GlobalServices();
  export default {
    name: "TabInvoiceItems",
    mixins: [editMixin],
    data() {
      return {
        input: {
          unli: "",
        },
        taxRate: 0,
        subTotal: 0,
        invoiceTotal: 0,
        invoice: {},
        propItemList: [],
        lookupList: [],
      };
    },
    components: {
      TabInvoiceDetails,
    },
    methods: {
      async initializeData() {
        this.invoice = this.dataObj.details;
        this.lookupList = await global.getUXDropDown(MODULE.CLIENTS.UX.ClientRates);
        this.taxRate = this.invoice.taxRate;
        // const results = await global.getTableDataByActionId({
        //   // id: this.dataObj.param.id,
        //   actionId: MODULE.INVOICE.UX.InvoiceDetails,
        //   // moduleId: MODULE.TECHNICIANS.ModuleId,
        //   parentId: this.dataObj.param.id
        //   // parentActionId: 0,
        // });

        // this.propItemList = results.map((item) => {
        //   return {
        //     ...item,
        //     amount: item.total
        //   }
        // })
      },
    },
    mounted() {
      this.initializeData();
    },
  };
</script>
