<template>
  <div>
    <div class="row">
      <!-- Top -->
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Group Rates</span>

            <input class="form-control" type="text" v-model="modalDataDetails.StoreName" @keyup="
              updateFieldSimplified(
                'StoreName', //PropertyName
                modalDataDetails.StoreName, //Property Value
                'StoreName', //Display Name
                true
              )
            " />
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Display Name</span>
            <input class="form-control" type="text" v-model="modalDataDetails.OverideBillingName" @keyup="
              updateFieldSimplified(
                'OverideBillingName', //PropertyName
                modalDataDetails.OverideBillingName, //Property Value
                'Overide BillingName', //Display Name
                true
              )
            " />
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Position</span>
            <v-select :options="modalUXData.uxContacts"
              :value="this.getSelectedDropDownValue('uxContacts', 'IntContactID', true)" @input="
                onDropdownSelectionChange(
                  $event,
                  'Contact', //Display Name
                  'uxContacts', //Dropdown List Object
                  'IntContactID', //Edit Details Property Name
                  true
                )
              " label="displayName"></v-select>
          </div>
          <div class="col-md-12 border-bottom u-mt-small u-mb-small u-height-2"></div>
        </div>
      </div>

      <!-- Middle -->
      <div class="col-md-6">
        <div class="row">
          <!-- Address & Notes -->
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Address</span>
            <ValidationProvider v-slot="v" name="address" rules="required">
              <input class="form-control" type="text" v-model="modalDataDetails.Address" @keyup="
                updateFieldSimplified(
                  'Address', //PropertyName
                  modalDataDetails.Address, //Property Value
                  'Address', //Display Name
                  true
                )
              " />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">City</span>
            <ValidationProvider v-slot="v" name="city" rules="required">
              <input class="form-control" type="text" v-model="modalDataDetails.City" @keyup="
                updateFieldSimplified(
                  'City', //PropertyName
                  modalDataDetails.City, //Property Value
                  'City', //Display Name
                  true
                )
              " />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">State/Province</span>
            <ValidationProvider v-slot="v" name="state/province" rules="required">
              <input class="form-control" type="text" v-model="modalDataDetails.Province" @keyup="
                updateFieldSimplified(
                  'Province', //PropertyName
                  modalDataDetails.Province, //Property Value
                  'Province', //Display Name
                  true
                )
              " />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Zip/Postal Code</span>
            <input class="form-control" type="text" v-model="modalDataDetails.PostalCode" @keyup="
              updateFieldSimplified(
                'PostalCode', //PropertyName
                modalDataDetails.PostalCode, //Property Value
                'Zip/Postal Code', //Display Name
                true
              )
            " />
          </div>
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Country</span>
            <input class="form-control" type="text" v-model="modalDataDetails.Country" @keyup="
              updateFieldSimplified(
                'Country', //PropertyName
                modalDataDetails.Country, //Property Value
                'Country', //Display Name
                true
              )
            " />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Overide Client Billing Email</span>
            <input class="form-control" type="text" v-model="modalDataDetails.OverideBillingEmailAddress" @keyup="
              updateFieldSimplified(
                'OverideBillingEmailAddress', //PropertyName
                modalDataDetails.OverideBillingEmailAddress, //Property Value
                'Overide Billing Email Address', //Display Name
                true
              )
            " />
          </div>
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Email</span>
            <input class="form-control" type="text" v-model="modalDataDetails.Email" @keyup="
              updateFieldSimplified(
                'Email', //PropertyName
                modalDataDetails.Email, //Property Value
                'Location Email', //Display Name
                true
              )
            " />
          </div>

          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Phone</span>
            <input class="form-control" type="text" v-model="modalDataDetails.Phone" @keyup="
              updateFieldSimplified(
                'Phone', //PropertyName
                modalDataDetails.Phone, //Property Value
                'Location Phone', //Display Name
                true
              )
            " />
          </div>
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Fax</span>
            <input class="form-control" type="text" v-model="modalDataDetails.Fax" @keyup="
              updateFieldSimplified(
                'Fax', //PropertyName
                modalDataDetails.Fax, //Property Value
                'Location Fax', //Display Name
                true
              )
            " />
          </div>
        </div>
      </div>

      <!-- col-md-12-->
      <div class="col-md-12">
        <span class="c-toolbar__state-title u-text-capitalize">Notes/Comments</span>

        <textarea class="form-control" type="text" rows="6" v-model="modalDataDetails.Notes" @keyup="
          updateFieldSimplified(
            'Notes', //PropertyName
            modalDataDetails.Notes, //Property Value
            'Location npNotes', //Display Name
            true
          )
        " />
      </div>
    </div>
  </div>
</template>

<script>
import GlobalServices from "@/services/global";
let global = new GlobalServices();

import editMixin from "@/mixin/edit-mixin";

export default {
  mixins: [editMixin],
  data() {
    return {};
  },
  async mounted() { },
  async created() {
    let modalDetails = {};
    let modalUXData = {};
    let module = this.MODULE.CONTACTS;
    let page = this.APP.PAGE.CONTACTS;

    //Get edit data
    if (this.modalObj.isInEditMode) {
      //Load Details Details from API
      modalDetails = await global.getEditDetails(module.ActionId, this.modalObj.data.id);

      //TODO: Load primary contact on new modal
      //DropDown List Values - Call back function from controller, getTabSection to load related dropdown menu values...
      modalUXData = await this.modalObj.dataObj.uxdata(page);
    } else {
      //New Item

      //Set Reference ID
      modalDetails.moduleId = module.ModuleId;
      modalDetails.clientId = this.modalObj.data.id;
    }

    this.$store.dispatch("setModalDataDetailsObject", modalDetails);
    this.$store.dispatch("setModalUXData", modalUXData);
  },
};
</script>
