<template>
  <div>
    <!-- <b-alert :show="successCountDown" class="mb-2 position-sticky top-10 z-index-5" variant="success">
      <i class="fa fa-check-circle mr-2"></i>
      <span class="sub-title mr-2"> Something here </span>
    </b-alert> -->
    <alert-countdown ref="assign-notification" />
    <table class="w-100">
      <thead class="text-sm bg-gray-100 py-2 text-left">
        <th class="pl-3 py-2">
          <div class="c-choice c-choice--checkbox mb-0">
            <input class="c-choice__input" id="comments" name="checkboxes" type="checkbox" v-model="allTech"
              @change="checkAll" :disabled="saving" />
            <label class="c-choice__label mb-0" for="comments"></label>
          </div>
        </th>
        <!-- <th class="px-3"></th> -->
        <th class="px-3">Technicians</th>
        <th class="px-3">Role</th>
        <th class="px-3">Rate</th>
        <th class="px-3">Overtime</th>
        <th class="px-3">Phone</th>
        <th class="px-3">Email</th>
      </thead>
      <tbody>
        <tr v-for="tech in uxTechniciansList" :key="tech.id" class="bg-white border-b text-sm text-left">
          <td class="pl-3 py-3 ">
            <div class="c-choice c-choice--checkbox  mb-0">
              <input class="c-choice__input" :id="`tech-${tech.employeeId}`" name="checkboxes" type="checkbox"
                v-model="tech.isChecked" @change="checkboxUpdate(tech)" :disabled="saving" />
              <label class="c-choice__label mb-0" :for="`tech-${tech.employeeId}`"></label>
            </div>
          </td>
          <!-- <td class="px-3 w-5"><a href="#">Edit</a></td> -->
          <td class="px-3 w-20">{{ tech.name }}</td>
          <td class="px-3 w-20">{{ tech.role }}</td>
          <td class="px-3 w-5">{{ tech.rate }}</td>
          <td class="px-3 w-5">{{ tech.overTime }}</td>
          <td class="px-3 w-20">{{ tech.phone }}</td>
          <td class="px-3">{{ tech.email }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SchedulerServices from "@/services/scheduler";
import editMixin from "@/mixin/edit-mixin";
import AlertCountdown from "@/components/_universal/utility/alert-countdown.vue";
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
const global = new GlobalServices();
const scheduler = new SchedulerServices();
export default {
  name: "AssignTech",
  mixins: [editMixin], // this is used to access the data from project
  data() {
    return {
      uxTechniciansList: [],
      allTech: false,
      saving: false,
      modalStatus: {
        modalName: "",
        modalTitle: "",
        data: null,
      },
    };
  },
  components: {
    AlertCountdown,
  },
  computed: {
    isAllTechniciansChecked() {
      const checkedTech = this.uxTechniciansList.filter((tech) => tech.isChecked);
      return checkedTech.length === this.uxTechniciansList.length;
    },
  },
  watch: {
    uxTechniciansList: {
      deep: true,
      handler() {
        this.allTech = this.isAllTechniciansChecked;
      },
    },
  },
  methods: {
    async checkAll() {
      if (this.allTech) {
        // call api to update everyone to true
      } else {
        // call api to update everyone to false
      }
    },
    async checkboxUpdate(tech) {
      await scheduler.assignTechnician({
        EmployeeID: tech.employeeId,
        ProjectID: this.modalStatus.data.intProjectID,
        //Intentionally left blank... api will handle this logic
        StartDate: "",
        EndDate: "",
        EmployeeUniqueId: tech.guid,
        isChecked: tech.isChecked,
      });

      // show notification
      this.$refs["assign-notification"].setNotification({
        msg: `Successfully ${tech.isChecked ? "assign" : "removed"} technician ${tech.name}`,
        variant: "success",
      });
    },
    async initializeData() {
      //Get Data From Modal
      this.modalStatus = this.$store.getters.getModalStatus;
      // load technicians
      const results = await global.getTableDataByActionId({
        // id: this.modalStatus.data.intProjectID,
        actionId: MODULE.TECHNICIANS.ActionId,
        moduleId: MODULE.TECHNICIANS.ModuleId,
        projectId: this.modalStatus.data.intProjectID,
        parentActionId: MODULE.PROJECTS.ActionId,
      });
      this.uxTechniciansList = results.map((data) => {
        return {
          employeeId: data.employeeId,
          id: data.id,
          guid: data.guid,
          image: data.image,
          name: data.technician,
          rate: data.rate,
          overTime: data.overTime,
          role: data.role,
          phone: data.phone,
          email: data.email,
          isChecked: data.isAssigned, // if currentTech is found then put the value of isChecked else set it to false for default
        };
      });
    },
  },
  mounted() {
    this.initializeData();
  },
};
</script>
