import APPPLICATION from "@/constants/application";
import EVENTS from "@/constants/events";
import CONSTANT_MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import store from "@/store/index";
import hash from "object-hash";
import _ from "lodash";

let global = new GlobalServices();

export default {
  props: {
    dataObj: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      APP: {},
      MODULE: {},
      EVENT_ACTION: {},
      UXData: {}, //Stores multiple ux dropdown list object results from api
      currentDataObj: {
        details: {},
        uxdata: () => {},
      },
      modalObj: {
        //When on modal screen stores a modal object with data
        data: null,
        details: {},
        uxdata: () => {},
      },
      timer: {}, //Keeps track of any setInterval function call, so we can clear it in the unmount

      //Keep track of current save status to hide/show message to user...
      isStatusSaving: false,
      isStatusSaved: false,
      isStatusError: false,

      isDisabled: false, //Keep track of if we should disable editing

      savingEvent: "SavingEvent", //Event name to keep track of when user is making a change and the changes are being saved.
      savedEvent: "SavedEvent", //Event name to keep track of when data has been successfully saved to server.
      errorEvent: "ErrorEvent", //Event name to keep track of any errors

      saveEvent: "Save", //Event name to keep track of when modal SAVE button is pressed. This property is dynamically set depending on modal component name
      closeEvent: "Close", //Event name to keep track of when modal CLOSE button is pressed. This property is dynamically set depending on modal component name
      alertEvent: "AlertNotification", //Event name ot keep track of message notifications to the user.
      successEvent: "show-success", //Event name ot keep track of message notifications to the user.

      onClientDropDownEvent: "ClientProspectDropDownChanged",

      uxTimeList: [
        {displayName: "15 minutes", value: 15},
        {displayName: "30 minutes", value: 30},
        {displayName: "45 minutes", value: 45},
        {displayName: "1 hour", value: 60},
        {displayName: "1 hour 15 minutes", value: 75},
        {displayName: "1 hour 30 minutes", value: 90},
        {displayName: "1 hour 45 minutes", value: 105},
        {displayName: "2 hours", value: 120},
        {displayName: "2 hours 15 minutes", value: 135},
        {displayName: "2 hours 30 minutes", value: 150},
        {displayName: "2 hours 45 minutes", value: 165},
        {displayName: "3 hours", value: 180},
        {displayName: "3 hours 15 minutes", value: 195},
        {displayName: "3 hours 30 minutes", value: 210},
        {displayName: "3 hours 45 minutes", value: 225},
        {displayName: "4 hours", value: 240},
        {displayName: "4 hours 15 minutes", value: 255},
        {displayName: "4 hours 30 minutes", value: 270},
        {displayName: "4 hours 45 minutes", value: 285},
        {displayName: "5 hours", value: 300},
      ],
    };
  },
  computed: {
    modalDataDetails: () => {
      return store.getters.getModalDataDetailsObject;
    },
    modalUXData: () => {
      return store.getters.getModalUXDataObject;
    },
    successMessage() {
      let modalTitle = this.$store.getters.getModalStatus.modalTitle.toLowerCase();

      modalTitle = modalTitle.replace("add", "added").replace("edit", "edited");

      return `Successfully ${modalTitle}. You may close this window.`;
    },
  },
  methods: {
    //Fires everytime a chackbox is changed...
    checkboxChanged(
      propertyName, //Database PropertyName
      propertyValue, //Database Property Value
      displayName,
      isModal //Display Name)
    ) {
      //Call real update field
      if (this.modalObj.isInEditMode && isModal) {
        this.updateField(
          propertyName,
          !propertyValue, //Ensure flag is change to the opposit of current flag
          displayName,
          null,
          null,
          null,
          null,
          isModal
        );
      } else if (!isModal) {
        this.updateField(
          propertyName,
          !propertyValue, //Ensure flag is change to the opposit of current flag
          displayName,
          null,
          null,
          null,
          null,
          isModal
        );
      }
    },

    //Change Dropdown and get filter record
    async onDropdownSelectionChangeFilter(event, displayName, uxDataObjName, dataObjPropertyName, isModal, uxDataObjFilterName) {
      if (event) {
        await this.onDropdownSelectionChange(event, displayName, uxDataObjName, dataObjPropertyName, isModal);
      }
    },

    //Returns the default value to be set, in an item list, with dropdown
    getRowSelectedDropDownValue(uxDataObjName, dataObjPropertyName, rowItem, defaultText) {
      //Ensure details object is not empty
      if (rowItem) {
        //Get the Id of the initial dropdown value
        let currentValueId = rowItem[dataObjPropertyName];

        if (currentValueId) {
          //UX Data Obj
          if (this.UXData[uxDataObjName]) {
            let selectedObj = this.UXData[uxDataObjName].filter((d) => {
              return d.id === currentValueId;
            });

            return selectedObj;
          }
        }
      }

      return {
        displayName: defaultText,
        id: "0",
      };
    },

    //Get the default selected value for the dropdown list
    //isModal - set to true, when calling from modal box
    getSelectedDropDownValue(uxDataObjName, dataObjPropertyName, isModal) {
      let detailsObj; //The Curent object being edited
      let dropDownListObj; //Temp. Dropdown list variable
      let selectedObj; //Selected dropdown list object to return

      if (isModal) {
        detailsObj = this.modalDataDetails;
        dropDownListObj = this.modalUXData;
      } else {
        detailsObj = this.currentDataObj.details;
        dropDownListObj = this.UXData;
      }

      //Notify user changes are being saved... messages displayed in sidepanel-header component
      this.$root.$emit(this.savingEvent, true);

      //Ensure details object is not empty
      if (detailsObj) {
        //Get the Id of the initial dropdown value
        let currentValueId = detailsObj[dataObjPropertyName];

        if (currentValueId) {
          //UX Data Obj
          if (dropDownListObj[uxDataObjName]) {
            selectedObj = dropDownListObj[uxDataObjName].filter((t) => {
              return t.id === currentValueId;
            });

            return selectedObj;
          }
        }
      }
      //If nothing was selected above i.e. the value is null, set a default.
      //Set Default Template Value
      // if (dropDownListObj[uxDataObjName] && !this.modalObj.isInEditMode) {
      //   selectedObj = global.setDropDownDefault(dropDownListObj[uxDataObjName]);
      // }
      return selectedObj;
    },

    //This function only exist to shorten the number of required paramaters required for updates
    async updateFieldSimplified(
      propertyName, //Database PropertyName
      propertyValue, //Database Property Value
      displayName, //Display Name
      isModal?,
      ignore?
    ) {
      // dont allow to update if isModal = true and isInEditMode = false
      //If we're in a modal
      if ((isModal && this.modalObj.isInEditMode) || !isModal) {
        //Call real update field
        this.updateField(propertyName, propertyValue, displayName, null, null, null, null, isModal, null, null, ignore);
      }
    },

    //This function only exist to shorten the number of required paramaters required for updates
    async updateFieldSimplifiedByPassParentID(
      propertyName, //Database PropertyName
      propertyValue, //Database Property Value
      displayName, //Display Name
      isModal?
    ) {
      // dont allow to update if isModal = true and isInEditMode = false
      //If we're in a modal
      if ((isModal && this.modalObj.isInEditMode) || !isModal) {
        //Call real update field
        const parentID = this.modalObj && this.modalObj.details && this.modalObj.details.id ? this.modalObj.details.id : this.modalObj.dataObj.param.id;
        this.updateField(propertyName, propertyValue, displayName, null, null, null, null, isModal, null, null, false, null, parentID);
      }
    },

    //This function only exist to shorten the number of required paramaters required for updates
    async updateDateTimeFieldSimplified(
      propertyName, //Database PropertyName
      propertyValue, //Database Property Value
      displayName, //Display Name
      isModal?,
      date?
    ) {
      // dont allow to update if isModal = true and isInEditMode = false
      //If we're in a modal
      if (isModal && this.modalObj.isInEditMode) {
        //Call real update field
        this.updateDateTimeSimplified(propertyName, propertyValue, displayName, isModal, date);
      }

      if (!isModal) {
        //Call real update field
        this.updateDateTimeSimplified(propertyName, propertyValue, displayName, isModal, date);
      }
    },

    async updateDateTimeSimplified(propertyName, propertyValue, displayName, isModal?, date?) {
      const editObj = {
        actionId: 0,
        id: 0,
        referenceNumber: "",
        propertyName: "",
        propertyValue: "",
        displayName: "",
        displayValue: "",
        previousDisplayValue: "",
        previousValueId: 0,
        moduleId: 0,
        date: null,
      };

      //Notify user changes are being saved... messages displayed in sidepanel-header component
      this.$root.$emit(this.savingEvent, true);

      if (isModal) {
        let detailsObj = this.modalDataDetails;

        editObj.id = detailsObj.id;
        editObj.actionId = detailsObj.actionId;
        if (detailsObj.moduleId) {
          editObj.moduleId = detailsObj.moduleId;
        }
      } else {
        //Load from prop is using a component
        if (this.dataObj) {
          if (this.dataObj.data) {
            editObj.id = this.dataObj.data.id;
          }

          if (this.dataObj.actionId) {
            editObj.actionId = this.dataObj.actionId;
          } else if (this.dataObj.details) {
            editObj.actionId = this.dataObj.details.actionId;
          }
        }
      }

      // editObj.referenceNumber = this.project.projectRefNumber;
      editObj.propertyName = propertyName;
      editObj.propertyValue = propertyValue;
      editObj.displayName = displayName;
      if (date) {
        editObj.date = date;
      }

      //Ensure that the original value and the new change is not the same
      //This will prevent posting to server on initial load
      // if (previousDisplayValue !== this.dataObj.data[dataObjPropertyName]) {
      await this.sendDataChange(editObj);
    },

    //Generic update api property field
    async updateField(
      propertyName,
      propertyValue,
      displayName,
      displayValue?,
      dataObjPropertyName?,
      previousDisplayValue?,
      previousValueId?,
      isModal?,
      overideId?,
      overActionId?,
      ignore?,
      parentActionId?,
      parentId?
    ) {
      const editObj = {
        actionId: 0,
        id: 0,
        referenceNumber: "",
        propertyName: "",
        propertyValue: "",
        displayName: "",
        displayValue: "",
        previousDisplayValue: "",
        previousValueId: 0,
        moduleId: 0,
        ignore: false,
      } as any;

      //Notify user changes are being saved... messages displayed in sidepanel-header component
      this.$root.$emit(this.savingEvent, true);

      if (isModal) {
        let detailsObj = this.modalDataDetails;

        editObj.id = detailsObj.id;
        editObj.actionId = detailsObj.actionId;
        if (detailsObj.moduleId) {
          editObj.moduleId = detailsObj.moduleId;
        }
      } else {
        //Load from prop is using a component
        if (this.dataObj) {
          if (this.dataObj.data) {
            editObj.id = this.dataObj.data.id;
          }

          if (this.dataObj.actionId) {
            editObj.actionId = this.dataObj.actionId;
          } else if (this.dataObj.details) {
            editObj.actionId = this.dataObj.details.actionId;
          } else if (this.dataObj.param.actionId) {
            editObj.actionId = this.dataObj.param.actionId;
          }

          if (this.dataObj.moduleId) {
            editObj.moduleId = this.dataObj.moduleId;
          }
        }
      }

      //Overide Id if specified
      if (overideId) editObj.id = overideId;

      // editObj.referenceNumber = this.project.projectRefNumber;
      editObj.propertyName = propertyName;
      editObj.propertyValue = propertyValue;
      editObj.displayName = displayName;
      editObj.displayValue = displayValue;
      editObj.previousDisplayValue = previousDisplayValue;
      editObj.previousValueId = previousValueId;
      if (ignore) editObj.ignore = ignore;
      //Overide Action Id if specified
      if (overActionId) editObj.actionId = overActionId;
      if (parentActionId) editObj.parentActionId = parentActionId;
      if (parentId) editObj.parentId = parentId;
      //Ensure that the original value and the new change is not the same
      //This will prevent posting to server on initial load
      // if (previousDisplayValue !== this.dataObj.data[dataObjPropertyName]) {
      await this.sendDataChange(editObj);
      // }
    },

    async sendDataChange(editObj) {
      let response = {
        success: false,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      //Wait 500 miliseconds before pushing data.
      this.timer = setTimeout(async () => {
        response = await global.postData(editObj);

        //Ensure the response is succesfull
        //Update the original state to the current state only if the date was saved
        if (response) {
          if (response.success) {
            //Notify user changes have been saved... messages displayed in sidepanel-header component
            this.$root.$emit(this.savedEvent, true);
          } else {
            this.$root.$emit(this.errorEvent, true);
          }
        } else {
          this.$root.$emit(this.errorEvent, true);
        }
      }, 500);
    },

    async immediatelyUpdateField(
      propertyName,
      propertyValue,
      displayName,
      displayValue?,
      dataObjPropertyName?,
      previousDisplayValue?,
      previousValueId?,
      isModal?,
      overideId?,
      overActionId?,
      ignore?,
      parentActionId?,
      parentId?
    ) {
      const editObj = {
        actionId: 0,
        id: 0,
        referenceNumber: "",
        propertyName: "",
        propertyValue: "",
        displayName: "",
        displayValue: "",
        previousDisplayValue: "",
        previousValueId: 0,
        moduleId: 0,
        ignore: false,
      } as any;

      //Notify user changes are being saved... messages displayed in sidepanel-header component
      this.$root.$emit(this.savingEvent, true);

      if (isModal) {
        let detailsObj = this.modalDataDetails;

        editObj.id = detailsObj.id;
        editObj.actionId = detailsObj.actionId;
        if (detailsObj.moduleId) {
          editObj.moduleId = detailsObj.moduleId;
        }
      } else {
        //Load from prop is using a component
        if (this.dataObj) {
          if (this.dataObj.data) {
            editObj.id = this.dataObj.data.id;
          }

          if (this.dataObj.actionId) {
            editObj.actionId = this.dataObj.actionId;
          } else if (this.dataObj.details) {
            editObj.actionId = this.dataObj.details.actionId;
          } else if (this.dataObj.param.actionId) {
            editObj.actionId = this.dataObj.param.actionId;
          }

          if (this.dataObj.moduleId) {
            editObj.moduleId = this.dataObj.moduleId;
          }
        }
      }

      //Overide Id if specified
      if (overideId) editObj.id = overideId;

      // editObj.referenceNumber = this.project.projectRefNumber;
      editObj.propertyName = propertyName;
      editObj.propertyValue = propertyValue;
      editObj.displayName = displayName;
      editObj.displayValue = displayValue;
      editObj.previousDisplayValue = previousDisplayValue;
      editObj.previousValueId = previousValueId;
      if (ignore) editObj.ignore = ignore;
      //Overide Action Id if specified
      if (overActionId) editObj.actionId = overActionId;
      if (parentActionId) editObj.parentActionId = parentActionId;
      if (parentId) editObj.parentId = parentId;
      //Ensure that the original value and the new change is not the same
      //This will prevent posting to server on initial load
      // if (previousDisplayValue !== this.dataObj.data[dataObjPropertyName]) {
      await this.immediateSendDataChange(editObj);
      // }
    },

    async immediateSendDataChange(editObj) {
      let response = {
        success: false,
      };

      response = await global.postData(editObj);

      //Ensure the response is succesfull
      //Update the original state to the current state only if the date was saved
      if (response) {
        if (response.success) {
          //Notify user changes have been saved... messages displayed in sidepanel-header component
          this.$root.$emit(this.savedEvent, true);
        } else {
          this.$root.$emit(this.errorEvent, true);
        }
      } else {
        this.$root.$emit(this.errorEvent, true);
      }
    },
    //Lookup the previous value that was set in a dropdown
    //optionalDataObj - this is used to overide or set the currentDataObj.details object value (used on modal screens, due to complications with mixin life cycle hook when loading data)
    getPreviousDropdownValue(uxDataObjName, dataObjPropertyName, isModal) {
      let detailsObj;
      let currentUXObj; //The Current dropdown being evaluated...

      if (isModal) {
        detailsObj = this.modalDataDetails;
        currentUXObj = this.modalUXData;
      } else {
        detailsObj = this.currentDataObj.details;
        currentUXObj = this.currentDataObj.details;
      }

      let previousDropdownSelection = [];

      //Get the Id of the initial dropdown value
      let currentValueId = detailsObj[dataObjPropertyName];

      if (currentValueId) {
        //UX Data Obj
        if (currentUXObj[uxDataObjName]) {
          previousDropdownSelection = currentUXObj[uxDataObjName].filter((t) => {
            return t.id === currentValueId;
          });
        }
      }

      //Return the display Name
      if (previousDropdownSelection.length > 0) {
        return {
          previousDisplayName: previousDropdownSelection[0].displayName,
          previousValueId: previousDropdownSelection[0].id,
        };
      } else return "";
    },

    async onDropdownSelectionChange(event, displayName, uxDataObjName, dataObjPropertyName, isModal, overideId?, overActionId?) {
      let detailsObj;
      let dropDownListObj;

      if (isModal) {
        detailsObj = this.modalDataDetails;
        dropDownListObj = this.modalUXData;
      } else {
        detailsObj = this.currentDataObj.details;
        dropDownListObj = this.UXData;
      }

      //Lookup the previous value, so we can keep track of what was changed.
      let previousSelectedValue = this.getPreviousDropdownValue(uxDataObjName, dataObjPropertyName, isModal);
      if ((this.modalObj.isInEditMode && isModal) || !isModal) {
        //Update API
        await this.updateField(
          dataObjPropertyName,
          event ? event.id : null,
          displayName,
          event ? event.displayName : "",
          dataObjPropertyName,
          previousSelectedValue.previousDisplayName,
          previousSelectedValue.previousValueId,
          isModal,
          overideId,
          overActionId
        );
      }

      //Update the current property value
      detailsObj[dataObjPropertyName] = event ? event.id : null;

      if (isModal) {
        this.$store.dispatch("setModalDataDetailsObject", detailsObj);
      } else {
        this.currentDataObj.details = detailsObj;
      }

      //Artificially add a dely before notifying location dropdown
      //without this, the API seems to fire at will, sometimes client api works sometimes not,
      //i'm sure it has to do with the fact that the last promise is still pending, but this hack solves it for now.
      this.timer = setTimeout(() => {
        //Broadcast to any component that care, that a dropdown was changed for client
        if (event && dataObjPropertyName == "IntClientID") {
          this.$root.$emit(this.onClientDropDownEvent, event.id);
        }
      }, 500);
    },

    async dynamicallyLoadUXDropdown() {
      if (this.dataObj.uxdata) {
        //Based on the current page, call the API and pass in the page type, to load the appropriate pages..
        //Get all dropdown list values
        this.UXData = await this.dataObj.uxdata(this.dataObj.pageName);
      }
    },

    //Display alert message to the user depending on status (Succesfull or Error)
    //The actual alert message is located in the /layouts/modal vue.
    async alertNotification(isSuccesful) {
      let alertType = "";
      let alertMessage = "";

      if (isSuccesful) {
        alertType = "success";
        alertMessage = "Succesfully ";
      } else {
        alertType = "danger";
        alertMessage = "Error ";
      }

      let alertObj = {
        type: alertType,
        message: alertMessage,
        isVisible: true,
      };

      this.$root.$emit(this.alertEvent, alertObj);

      alertObj = null;
    },
    //Subscribe to Modal Close Button, to perform save on close
    async onModalSave(actionId, data) {
      let result = {
        success: false,
      };
      // refetch modal status
      this.modalObj = this.$store.getters.getModalStatus;

      //Edit
      if (this.modalObj.isInEditMode) {
        //Send fake notification to user
        await this.alertNotification(true);
      }
      //New
      else {
        if (this.modalObj.actionId) {
          //Generate a Hash from the data to be submitted
          this.modalDataDetails.hash = hash(this.modalDataDetails);
          this.modalDataDetails.actionId = this.modalObj.actionId;

          //Submit activity
          result = await global.createNewActivity(this.modalObj.actionId, this.modalDataDetails);
          if (result.success) {
            const componentForReset = ["NewMaterial", "NewProjectEquipment", "NewProjectTask", "NewProjectTimeTracking", "NewContact"];
            if (_.intersection([this.modalObj.componentName], componentForReset).length > 0) {
              this.$root.$emit(this.successEvent, {
                show: true,
                text: this.successMessage,
              });
              this.resetModalDataDetails();
              this.$root.$emit("on-save"); // reset data
            } else {
              this.clearModalDetails();
            }
            //Notify user update was succesful only if component is not NewPurchaseOrder
            // if (this.modalObj.componentName !== "NewPurchaseOrder") await this.alertNotification(this.isSuccesful);
            this.isSuccesful = true;
            this.isError = false;
          } else {
            this.isSuccesful = false;
            this.isError = true;

            //Notify user of error
            await this.alertNotification(!this.isError);
          }
        }
      }
    },

    resetModalDataDetails() {
      if (this.modalObj && this.modalObj.componentName === "NewMaterial") {
        this.modalDataDetails.Qty = 1;
        this.modalDataDetails.UnitPrice = 0;
        this.modalDataDetails.Name = null;
        this.modalDataDetails.Notes = null;
        this.modalDataDetails.Description = null;
        this.modalDataDetails.hash = null;
      } else if (this.modalObj && this.modalObj.componentName === "NewProjectEquipment") {
        this.modalDataDetails.Name = null;
        this.modalDataDetails.LocationOnSite = null;
        this.modalDataDetails.Make = null;
        this.modalDataDetails.ModelNumber = null;
        this.modalDataDetails.SerialNumber = null;
        this.modalDataDetails.Manufacturer = null;
        this.modalDataDetails.Notes = null;
        this.modalDataDetails.Description = null;
        this.modalDataDetails.hash = null;
      } else if (this.modalObj && this.modalObj.componentName === "NewProjectTask") {
        this.modalDataDetails.TaskName = null;
        this.modalDataDetails.DueDate = null;
        this.modalDataDetails.StartDate = null;
        this.modalDataDetails.EndDate = null;
        this.modalDataDetails.Description = null;
        this.modalDataDetails.hash = null;
        this.modalDataDetails.IntTaskStatusID = null;
        this.modalDataDetails.IntTaskCategoryID = null;
        this.modalDataDetails.IntPriorityID = null;
      } else if (this.modalObj && this.modalObj.componentName === "NewProjectTimeTracking") {
        this.modalDataDetails.hash = null;
        this.modalDataDetails.IntEmployeeID = null;
        this.modalDataDetails.IntEquipmentID = null;
        this.modalDataDetails.TaskName = null;
        this.modalDataDetails.IntRateID = null;
        this.modalDataDetails.IntTasksLabelID = null;
        // this.modalDataDetails.PremiumTime = null;
        // this.modalDataDetails.Travel = null;
        // this.modalDataDetails.BreakTime = null;
        this.modalDataDetails.Type = null;
        this.modalDataDetails.Date = null;
        this.modalDataDetails.TimeIn = null;
        this.modalDataDetails.TimeOut = null;
        this.modalDataDetails.Description = null;
        // this.modalDataDetails.Emergency = false;
      } else if (this.modalObj && this.modalObj.componentName === "NewContact") {
        this.modalDataDetails.FirstName = null;
        this.modalDataDetails.LastName = null;
        this.modalDataDetails.IntRoleID = null;
        this.modalDataDetails.IntLocationId = null;
        this.modalDataDetails.Email = null;
        this.modalDataDetails.Mobile = null;
        this.modalDataDetails.Phone = null;
        this.modalDataDetails.AutoCCEmailOnQuote = false;
        this.modalDataDetails.AutoCCEmailOnInvoice = false;
        this.modalDataDetails.AutoCCEmailOnProjects = false;
        this.modalDataDetails.AddressLn1 = null;
        this.modalDataDetails.WebAddress = null;
        this.modalDataDetails.Notes = null;
        this.modalDataDetails.hash = null;
      }
      this.$store.dispatch("setModalDataDetailsObject", this.modalDataDetails);
    },

    clearModalDetails() {
      //Clear modal data
      store.dispatch("setModalDataDetailsObject", {});
      store.dispatch("setModalUXData", {});
    },
  },
  async mounted() {
    this.currentDataObj = _.clone(this.dataObj);
    //Allow Modal popup to have full access to the current data object details
    if (this.modalObj && this.currentDataObj && this.currentDataObj.details) {
      this.modalObj.details = this.currentDataObj.details;
    }
  },

  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;
    this.MODULE = CONSTANT_MODULE;
    this.APP = APPPLICATION;

    this.modalObj = this.$store.getters.getModalStatus;

    //Handle refresh of list by subscribing to the modal events
    if (this.dataObj) {
      if (this.dataObj.actionButton && this.dataObj.actionButton.componentName) {
        //Subscribe to save button click event on modal box
        this.saveEvent = this.saveEvent + this.dataObj.actionButton.componentName;
        this.closeEvent = this.closeEvent + this.dataObj.actionButton.componentName;
        this.alertEvent = this.alertEvent + this.dataObj.actionButton.componentName;
        this.successEvent = "show-success" + this.dataObj.actionButton.componentName;
      }

      await this.dynamicallyLoadUXDropdown();
    }

    // initialize
    while (this.$root._events[this.saveEvent] && this.$root._events[this.saveEvent].length > 1) {
      this.$root.$off(this.saveEvent);
    }

    if (!this.$root._events[this.saveEvent]) {
      this.$root.$on(this.saveEvent, this.onModalSave);
    }
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);

    //Unscribe from save button event...
    this.$root.$off(this.saveEvent);
    this.$root.$off(this.alertEvent);
    this.$root.$off(this.closeEvent);
    this.clearModalDetails();
    this.$store.dispatch("setFilter", null);
  },
  components: {},
};
