<template>
  <div class="col-md-12" style="background:#FFF;">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <global-settings-modal id="slidepanel-edit-work-order-global-settings-status-priority" label="Status/Priority" :actionId="MODULE.PROJECTS.Status" />

        <v-select
          v-model="selectedProjectStatus"
          id="slidepanel-edit-work-order-status-priority"
          :options="projectStatusList"
          :value="selectedProjectStatusId"
          @input="yar"
          code="id"
          label="displayName"
        >
        </v-select>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <!-- <span class="c-toolbar__state-title u-text-capitalize">
          Tech/Crew Status
        </span> -->
        <global-settings-modal id="slidepanel-edit-work-order-global-settings-status-priority" label="Tech/Crew Status" :actionId="MODULE.PROJECTS.Tech" />
        <!-- <span v-if="$isDev" class="clickable" @click="showModal(EVENT_ACTION.SETTINGS.SpecificListing, 'Edit Tech/Crew Status', MODULE.PROJECTS.Type)">edit</span> -->
        <v-select
          v-model="selectedTechnicianStatus"
          id="slidepanel-edit-work-order-tech-crew-status"
          :options="technicianStatusList"
          :value="selectedTechnicianStatusId"
          @input="onTechnicianStatusSelected"
          code="id"
          label="displayName"
        ></v-select>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <global-settings-modal label="Job/Project Type" id="slidepanel-edit-work-order-global-settings-job-type" :actionId="MODULE.PROJECTS.Type" />
        <v-select
          v-model="selectedProjectType"
          id="slidepanel-edit-work-order-job-type"
          :options="projectTypeList"
          :value="selectedProjectTypeId"
          @input="onProjectTypeSelected"
          code="id"
          label="displayName"
        ></v-select>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Total Invoiced</span>
        <div class="input-group input-with-dollar-sign">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            class="form-control"
            type="text"
            id="slidepanel-edit-work-order-total-invoiced"
            v-model="project.totalAmonunt"
            @keyup="updateField('totalAmonunt', project.totalAmonunt, 'Total Invoiced')"
          />
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Job Value ($)</span>
        <div class="input-group input-with-dollar-sign">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input class="form-control" type="text" id="slidepanel-edit-work-order-job-value" v-model="project.totalSales" @keyup="updateField('totalSales', project.totalSales, 'Job Value ($)')" />
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Start Date/Time</span>
        <DatePickerConvert
          v-if="project"
          id="slidepanel-edit-work-order-start-date-time"
          :current-date="project.projectStartDate"
          :time-picker-options="{
            start: '06:30',
            step: '00:30',
            end: '19:30',
          }"
          format="DD-MMM-YYYY - hh:mm a"
          type="datetime"
          :shortcuts="shortcuts"
          style="width: 100%;"
          @onUpdate="(value) => updateField('projectStartDate', value, 'Start Date', null, null, false)"
        />
        <!-- <date-picker
          format="DD-MMM-YYYY - hh:mm a"
          :time-picker-options="{
            start: '06:30',
            step: '00:30',
            end: '19:30',
          }"
          type="datetime"
          :shortcuts="shortcuts"
          style="width: 100%;"
          v-model="project.projectStartDate"
          @keyup=""
          lang="en"
        ></date-picker> -->
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Est. Completion Date/Time</span>
        <DatePickerConvert
          v-if="project"
          id="slidepanel-edit-work-order-estimated-completion-date-time"
          :current-date="project.projectEndDate"
          :time-picker-options="{
            start: '06:30',
            step: '00:30',
            end: '19:30',
          }"
          type="datetime"
          format="DD-MMM-YYYY - hh:mm a"
          :shortcuts="shortcuts"
          :default-value="project.projectStartDate"
          :disabled-date="notBeforeToday"
          :disabled-time="notBeforeStartDateTime"
          style="width: 100%;"
          @onUpdate="(value) => updateField('projectEndDate', value, 'Est. Completion Date', null, null, false)"
        />
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Due Date</span>
        <DatePickerConvert
          id="slidepanel-edit-work-order-due-date"
          v-if="project"
          :current-date="project.dueDate"
          :time-picker-options="{
            start: '06:30',
            step: '00:30',
            end: '19:30',
          }"
          type="datetime"
          format="DD-MMM-YYYY - hh:mm a"
          :shortcuts="shortcuts"
          style="width: 100%;"
          @onUpdate="(value) => updateField('dueDate', value, 'Due Date', null, null, false)"
        />
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Primary Contact Name</span>
        <input
          class="form-control"
          type="text"
          id="slidepanel-edit-work-order--primary-contact-name"
          v-model="project.primaryContactName"
          @keyup="updateField('primaryContactName', project.primaryContactName, 'Primary Contact Name')"
        />
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Primary Contact Phone</span>
        <input
          class="form-control"
          type="text"
          id="slidepanel-edit-work-order-primary-contact-phone"
          v-model="project.primaryContactPhone"
          @input="formatPhoneNumber('primaryContactPhone')"
          @keyup="updateField('primaryContactPhone', project.primaryContactPhone, 'Primary Contact Phone')"
        />
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Client P.O. Reference#</span>
        <input
          class="form-control"
          v-model="project.clientPORef"
          id="slidepanel-edit-work-order-client-po-reference"
          @change="updateField('clientPORef', project.clientPORef, 'Client P.O. Reference#')"
          type="text"
        />
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Progress of Work Completed</span>
        <input
          class="form-control"
          type="text"
          id="slidepanel-edit-work-order-progess-of-work-completed"
          v-model="project.workProgressInfo"
          @keyup="updateField('workProgressInfo', project.workProgressInfo, 'Progress of Work Completed')"
        />
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Appointment Date &amp; Time</span>
        <DatePickerConvert
          v-if="project"
          id="slidepanel-edit-work-order-appointment-date-time"
          :current-date="project.confirmationDate"
          :time-picker-options="{
            start: '06:30',
            step: '00:30',
            end: '19:30',
          }"
          type="datetime"
          format="DD-MMM-YYYY - hh:mm a"
          :shortcuts="shortcuts"
          style="width: 100%;"
          @onUpdate="(value) => updateField('confirmationDate', value, 'Appointment Date', null, null, false)"
        />
        <!-- <date-picker
          format="DD-MMM-YYYY - hh:mm a"
          :time-picker-options="{
            start: '06:30',
            step: '00:30',
            end: '19:30',
          }"
          style="width: 100%;"
          type="datetime"
          v-model="project.confirmationDate"
          @keyup="updateField('confirmationDate', project.confirmationDate, 'Appointment Date', null, null, false)"
          lang="en"
        ></date-picker> -->
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Territory/Map#</span>
        <input
          class="form-control"
          type="text"
          id="slidepanel-edit-work-order-territory-map"
          v-model="project.customField5"
          @keyup="updateField('customField5', project.customField5, 'Territory/Map#')"
        />
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize text-danger">Actual Completed Date &amp; Time</span>
        <DatePickerConvert
          v-if="project"
          id="slidepanel-edit-work-order-actual-completed-date-time"
          :current-date="project.projectActualEndDate"
          :minute-step="15"
          type="datetime"
          format="DD-MMM-YYYY - hh:mm a"
          style="width: 100%;"
          @onUpdate="(value) => updateField('projectActualEndDate', value, 'Actual Completed Date & Time', null, null, false)"
        />
        <!-- <date-picker
          format="DD-MMM-YYYY - HH:mm a"
          :minute-step="15"
          style="width: 100%;"
          type="datetime"
          v-model="project.projectActualEndDate"
          @keyup="updateField('projectActualEndDate', project.projectActualEndDate, 'Actual Completed Date & Time', null, null, false)"
          lang="en"
        ></date-picker> -->
      </div>

      <div class="col-lg-6 col-md-12 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Job Description</span>
        <textarea
          class="form-control"
          type="text"
          rows="8"
          id="slidepanel-edit-work-order-job-description"
          v-model="project.jobDescription"
          @keyup="updateField('jobDescription', project.jobDescription, 'Job Description')"
        />
        <AiDropDown v-if="$isDev" />
      </div>
      <div class="col-lg-6 col-md-12 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Scheduling Notes / Timing / Equipment Requirements</span>
        <textarea
          class="form-control"
          type="text"
          rows="8"
          id="slidepanel-edit-work-order-scheduling-notes"
          v-model="project.schedulingInfo"
          @keyup="updateField('schedulingInfo', project.schedulingInfo, 'Scheduling Notes / Timing / Equipment Requirements')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DatePickerConvert from "@/components/_universal/date-picker-convert.vue";
  import ProjectServices from "@/services/projects";
  // import DatePicker from "vue2-datepicker";
  import EditDataObject from "@/interfaces/EditDataObject";
  import UXProjectsService from "@/services/ux/uxprojects";
  import UXTechnicianService from "@/services/ux/uxtechnicians";
  import _ from "lodash";
  import moment from "moment";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import UtilityString from "@/utilities/strings";
  import AiDropDown from "@/components/_universal/ai-dropdown.vue";

  const utility = new UtilityString();

  let api = new ProjectServices();
  import utilityMixin from "@/mixin/utility-mixin";

  export default {
    mixins: [utilityMixin],
    props: {
      dataObj: Object,
    },
    data() {
      return {
        EVENT_ACTION: {},
        projectOriginalState: {},
        project: {
          primaryContactName: "",
          primaryContactPhone: "",
        },
        savingEvent: "SavingEvent", //Event name to keep track of when user is making a change and the changes are being saved.
        savedEvent: "SavedEvent", //Event name to keep track of when data has been successfully saved to server.
        errorEvent: "ErrorEvent", //Event name to keep track of any errors
        selectedTechnicianStatus: null,
        selectedProjectStatus: null,
        selectedProjectType: null,
        technicianStatusList: [],
        projectStatusList: [],
        projectTypeList: [],

        modalStatus: {
          hideShowModal: false,
          modalName: "",
          isSaveButtonHidden: false,
          modalTitle: "",
          data: {},
        },

        //Date picker shortcuts
        shortcuts: [
          {
            text: "Today",
            onClick() {
              const date = new Date();
              // return a Date
              return date;
            },
          },
          {
            text: "Tomorrow",
            onClick() {
              const date = moment()
                .add(1, "days")
                .calendar();
              return date;
            },
          },
        ],
      };
    },

    computed: {
      startDate() {
        return this.project.projectStartDate;
      },
      dueDate() {
        return this.project.dueDate;
      },
      completionDate() {
        return this.project.projectEndDate;
      },
      appointmentDate() {
        return this.project.confirmationDate;
      },
      actualCompletedDate() {
        return this.project.projectActualEndDate;
      },
      selectedProjectTypeId() {
        return this.selectedProjectType;
      },
      selectedProjectStatusId() {
        return this.selectedProjectStatus;
      },
      selectedTechnicianStatusId() {
        return this.selectedTechnicianStatus;
      },
      isStartDateAheadOfDueDate() {
        return moment(this.project.projectStartDate).isSameOrBefore(this.project.projectEndDate);
      },
    },
    watch: {
      async startDate() {
        await this.updateField("projectStartDate", this.project.projectStartDate, "Start Date");
      },
      async dueDate() {
        await this.updateField("dueDate", this.project.dueDate, "Due Date");
      },
      async completionDate() {
        await this.updateField("projectEndDate", this.project.projectEndDate, "Est. Completion Date");
      },
      async appointmentDate() {
        await this.updateField("confirmationDate", this.project.confirmationDate, "Appointment Date");
      },
      async actualCompletedDate() {
        await this.updateField("projectActualEndDate", this.project.projectActualEndDate, "Actual Completed Date & Time");
      },
    },
    methods: {
      formatPhoneNumber(propertyName) {
        this.project[propertyName] = utility.formatPhoneNumber(this.project[propertyName]);
      },

      //Prevent Est. Completed Date to Start before Start deactivated()
      notBeforeStartDateTime(date) {
        let startDate = this.project.projectStartDate;

        return date < new Date(startDate);
      },

      notBeforeToday(date) {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
      },

      //UX Technicians
      async loadTechnicianDropdown() {
        const techs = new UXTechnicianService();

        const apiTechStatus = await techs.getTechnicianStatus();

        this.technicianStatusList = apiTechStatus;
      },
      async loadProjectDropdown() {
        const projects = new UXProjectsService();

        this.projectTypeList = await projects.getProjectType();
        this.projectStatusList = await projects.getProjectStatus();
      },
      async onTechnicianStatusSelected(event) {
        if (event) {
          //Lookup the previous value, so we can keep track of what was changed.
          let previousDisplayValue = "";

          previousDisplayValue = this.getPreviousDropdownValue(this.technicianStatusList, this.project.intSmsmessageStatusId);

          await this.updateField("IntSMSMessageStatusId", event.id, "Tech/Crew Status", event.displayName, previousDisplayValue);

          //Update the current project technician status ID
          this.project.intSmsmessageStatusId = event.id;
        }
      },
      async onProjectTypeSelected(event) {
        if (event) {
          //Lookup the previous value, so we can keep track of what was changed.
          let previousDisplayValue = this.getPreviousDropdownValue(this.projectTypeList, this.project.intProjectTypeID);

          await this.updateField("IntProjectTypeID", event.id, "Job/Project Type", event.displayName, previousDisplayValue);

          //Update the current project type ID
          this.project.intProjectTypeID = event.id;
        }
      },
      async yar(event) {
        if (event) {
          //Lookup the previous value, so we can keep track of what was changed.
          let previousDisplayValue = this.getPreviousDropdownValue(this.projectStatusList, this.project.intProjectStatusID);

          await this.updateField("IntProjectStatusID", event.id, "Status/Priority", event.displayName, previousDisplayValue);

          //Update the current project status ID
          this.project.intProjectStatusID = event.id;
        }
      },
      async updateField(propertyName, propertyValue, displayName, displayValue, previousDisplayValue, encodeProperty) {
        let editObj = {};

        //Get the original state of the field to capture the from and to values for database logging.
        if (!Boolean(previousDisplayValue)) {
          previousDisplayValue = this.projectOriginalState[propertyName];
          displayValue = propertyValue;
        }

        //Notify user changes are being saved... messages displayed in sidepanel-header component
        this.$root.$emit(this.savingEvent, true);

        //Load from prop is using a component

        if (this.dataObj) {
          editObj.id = this.dataObj.data.intProjectID;
        } else {
          editObj.id = this.$route.query.pid;
        }

        editObj.referenceNumber = this.project.projectRefNumber;
        editObj.propertyName = propertyName;
        editObj.propertyValue = Boolean(encodeProperty) ? this.htmlEncodeCharacters(propertyValue) : propertyValue;
        editObj.displayName = displayName;
        editObj.displayValue = displayValue;
        editObj.previousDisplayValue = previousDisplayValue;
        editObj.moduleId = MODULE.PROJECTS.ModuleId;

        //Ensure that the original value and the new change is not the same
        //This will prevent posting to server on initial load
        if (propertyValue !== this.projectOriginalState[propertyName]) {
          await this.postAPIData(editObj);
        }
      },
      async setSelectedDropdownValues() {
        //Set Technician Status
        this.selectedTechnicianStatus = this.technicianStatusList.filter((t) => {
          return t.id === this.project.intSmsmessageStatusId;
        });

        //Project Status
        this.selectedProjectStatus = this.projectStatusList.filter((t) => {
          return t.id === this.project.intProjectStatusID;
        });

        //Set Job/Project Type
        this.selectedProjectType = this.projectTypeList.filter((t) => {
          return t.id === this.project.intProjectTypeID;
        });
      },
      getPreviousDropdownValue(dropDownList, id) {
        let previousDropdownSelection = {};

        if (id) {
          //Set Job/Project Type

          previousDropdownSelection = dropDownList.filter((t) => {
            return t.id === id;
          });
        }

        if (previousDropdownSelection.length > 0) {
          return previousDropdownSelection[0].displayName;
        } else return "";
      },

      async postAPIData(dataObj) {
        let response = {};

        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        //Wait 500 miliseconds before pushing data.
        this.timer = setTimeout(async () => {
          response = await api.updateData(dataObj);

          //Ensure the response is succesfull
          //Update the original state to the current state only if the date was saved
          if (response) {
            if (response.success) {
              this.projectOriginalState[dataObj.propertyName] = dataObj.propertyValue;
              //Notify user changes have been saved... messages displayed in sidepanel-header component
              this.$root.$emit(this.savedEvent, true);
            }
          } else {
            this.$root.$emit(this.errorEvent, true);
          }
        }, 500);
      },
      async loadData() {
        let id = 0;

        //Load from prop is using a component
        if (this.dataObj) {
          id = this.dataObj.data.intProjectID;
        } else {
          id = this.$route.query.pid;
        }

        if (id > 0) {
          this.project = await api.getProjectDetails(id);

          //Convert HTML encoded characters to normal text...
          this.project.jobDescription = this.htmlDecodeCharacters(this.project.jobDescription);

          this.project.schedulingInfo = this.htmlDecodeCharacters(this.project.schedulingInfo);

          this.projectOriginalState = JSON.parse(JSON.stringify(this.project));

          await this.setSelectedDropdownValues();
        }
      },
      showModal(paramModalName, modalTitle, actionId) {
        this.modalStatus.hideShowModal = true;
        this.modalStatus.modalName = paramModalName;
        this.modalStatus.data = {
          actionId: actionId,
        };
        this.modalStatus.modalTitle = modalTitle;
        this.modalStatus.isSaveButtonHidden = true;
        this.modalStatus.modalSaveButtonTitle = "";
        this.$store.dispatch("toggleModalStatus", this.modalStatus);
      },
    },

    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
      this.MODULE = MODULE;

      await this.loadProjectDropdown();
      await this.loadTechnicianDropdown();

      await this.loadData();
    },
    components: {
      // DatePicker,
      DatePickerConvert,
      AiDropDown,
    },
    beforeDestroy() {
      //Unscribe from save button event...
      this.$root.$off(this.saveEvent);
    },
  };
</script>
