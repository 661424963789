import AuthService from "@/services/application";
import ApplicationService from "@/services/application";
import IApplication from "@/interfaces/ApplicationInterface";

export default {
  state: {
    //List - stores the current list filter and data
    list: {
      filter: null,
      data: null,
    },

    //The side panel is initially set in the app.vue file on load.
    sidePanel: {
      isSidePanelFrame: false, //Decides if the side panel should show an iframe instead
      isOpen: false,
      name: "MainSidePanel", //Name of the side panel, incase multiple panels are open
      data: {}, //The data to be passed into the side panel as prop value
      panelObj: {}, //Reference to the side panel object, so we can close it anywhere.
    },
    modalDataDetailsObj: {
      //When on modal screen sctores a modal object with data
      data: {},
      uxdata: {},
    },
    modalIframe: {
      url: "", //Url for the iframe to load
      title: "", //Title of the modal box
      showToolbar: false, //Decides if the show the title bar with Print, Download and Open in New Tab
      data: {}, //(Optional) data object padding any additional properties needed for the iframe
    },
    iframe: {
      showIframe: false,
      iframeUrl: "",
      friendlyUrl: "",
    },
    appconfig: {} as IApplication,
    navigation: [],
    searchOrigin: null, //path
    showWhatsNewModal: false,
  },

  getters: {
    getList: (state) => {
      return state.list;
    },
    getSidePanel: (state) => {
      return state.sidePanel;
    },
    getModalIframe: (state) => {
      return state.modalIframe;
    },
    getModalDataDetailsObject: (state) => {
      return state.modalDataDetailsObj.data;
    },
    getModalUXDataObject: (state) => {
      return state.modalDataDetailsObj.uxdata;
    },
    getIframeProperties: (state) => {
      return state.iframe;
    },
    getApplicationConfiguration: (state: IApplication) => {
      return state;
    },
    getNavigation: (state) => {
      return state.navigation;
    },
    getSearchOrigin: (state) => {
      return state.searchOrigin;
    },
    getShowWhatsNewModal: (state) => {
      return state.showWhatsNewModal;
    },
  },

  mutations: {
    setFilter(state, filter) {
      state.list.filter = filter;
    },
    setList(state, data) {
      state.list.data = data;
    },
    setSidePanel(state, sidePanelObj) {
      state.sidePanel = sidePanelObj;
    },
    setIframeStatus(state, iframeStatusObj) {
      state.iframe = iframeStatusObj;
    },
    setModalIframe(state, iframe) {
      state.modalIframe = iframe;
    },
    setModalDataDetailsObject(state, data) {
      state.modalDataDetailsObj.data = data;
    },
    setModalUXData(state, data) {
      state.modalDataDetailsObj.uxdata = data;
    },
    setApplicationConfiguration(state, companyProfileParam) {
      state.appconfig = companyProfileParam;
    },
    setNavigation(state, navigationParam) {
      state.navigation = navigationParam;
    },
    setSearchOrigin(state, origin) {
      state.searchOrigin = origin;
    },

    setWhatsNewModal(state, data) {
      state.showWhatsNewModal = data;
    },
  },

  actions: {
    setFilter({commit}, filter) {
      commit("setFilter", filter);
    },
    setList({commit}, data) {
      commit("setList", data);
    },
    setModalDataDetailsObject({commit}, data) {
      commit("setModalDataDetailsObject", data);
    },
    setModalUXData({commit}, data) {
      commit("setModalUXData", data);
    },
    setSidePanel({commit}, sidePanelObj) {
      commit("setSidePanel", sidePanelObj);
    },

    setApplicationIframe({commit}, iframeStatusObj) {
      commit("setIframeStatus", iframeStatusObj);
    },
    setApplicationModalIframe({commit}, iframe) {
      commit("setModalIframe", iframe);
    },
    setSearchOrigin({commit}, origin) {
      commit("setSearchOrigin", origin);
    },
    setWhatsNewModal({commit}, data) {
      commit("setWhatsNewModal", data);
    },
    async applicationConfiguration({commit}) {
      let app = new ApplicationService();
      let companyProfile: IApplication;
      let navigation;

      //Call API Get Company Profile
      companyProfile = await app.getApplicationConfiguration();

      //Load Navigation
      navigation = await app.getNavigation();

      commit("setApplicationConfiguration", companyProfile);

      commit("setNavigation", navigation);
    },
  },
};
