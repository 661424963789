<template>
  <span class="ml-2" v-if="results !== null">
    <span v-if="!results">
      <i class="fa fa-check text-success mr-1"></i>
      <small class="text-success">Valid</small>
    </span>
    <span v-else>
      <i class="fa fa-times text-danger mr-1"></i>
      <small class="text-danger">Invalid (duplicate)</small>
    </span>
  </span>
</template>

<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import {debounce} from "lodash/function";
  const global = new GlobalServices();
  export default {
    name: "SearchDuplicate",
    props: {
      model: {
        type: String,
        default: "",
      },
      search: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        results: null,
      };
    },
    watch: {
      search(value) {
        if (value) {
          this.results = null;
          this.debounceSearchClients(value, this.model);
        } else {
          this.results = null;
        }
      },
    },
    methods: {
      debounceSearchClients: debounce(function(value, type) {
        this.searchClient(value, type);
      }, 1000),
      async searchClient(value, type) {
        let query = {};
        if (type === "businessName") {
          query.q = value;
        }
        if (type === "email") {
          query.email = value;
        }
        if (type === "address") {
          query.address = value;
        }
        query.actionId = MODULE.CLIENTS.ActionId;
        const results = await global.getClientLookup(query);
        this.results = results === null ? Boolean(results) : true;

        this.$emit("response", {
          propertyName: this.model,
          error: this.results,
        });
      },
    },
  };
</script>
