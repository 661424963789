import {getInstance} from "./index";
import store from "@/store";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      // add condition here to check if user is allowed to view the page
      // console.log(`store`, store.state.Application.navigation)
      // const navigation = store.state.Application.navigation.leftNav as any
      // if (navigation) {
      //   return next();
      // } else {
      //   return next('/');
      // }

      return next();
    }
    // Otherwise, log in
    authService.loginWithRedirect({
      appState: {targetUrl: to.fullPath},
    });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
