const MODULE = {
  DASHBOARD: {
    ModuleId: 1,
    ActionId: 23,
  },
  CLIENTS: {
    ModuleId: 8,
    ActionId: 6,
    Type: {
      Commercial: 1,
      Prospect: 2,
      Residential: 3,
    },
    UX: {
      PayTerms: 9,
      ClientType: 10,
      ClientStatus: 8,
      ClientRates: 11,
      ClientRating: 82,
      ClientRateGroup: 12,
      Industry: 7,
      SalesRep: 2,
      CreatedBy: 2,
      Location: 16,
      PreferredLanguage: 95,
      Account: 1,
      Group: 112,
    },
  },
  CONTACTS: {
    ModuleId: 18,
    ActionId: 13,
  },
  LEADS: {
    ModuleId: 58,
    ActionId: 6,
    UX: {
      LeadStatus: 106,
      LeadStages: 105,
    },
  },
  NOTES: {
    SALES: 4,
    PROJECT: 3,
    CLIENT: 2,
    INVOICE: 6,
    PURCHASE_ORDER: 8,
    TEMPLATES: 13,
    INVENTORY: 18,
    ActionId: 60,
    SUPPLIER: 10,
  },
  PROJECTS: {
    ModuleId: 2,
    ActionId: 23,
    Status: 43,
    Type: 44,
    Equipment: {
      ActionId: 92,
    },
    Tech: 54,
  },
  MATERIALS: {
    ModuleId: 59,
    ActionId: 25,
  },
  TASKS: {
    ModuleId: 20,
    ActionId: 30,
    UX: {
      Status: 31,
      Priority: 37,
      Category: 33,
    },
  },
  TIMETRACKING: {
    ActionId: 86,
    UX: {
      TaskLabels: 109,
    },
  },
  INVOICE: {
    ModuleId: 26,
    ActionId: 27,
    UX: {
      InvoiceStatus: 84,
      InvoiceDetails: 81,
      InvoiceTaxRates: 104,
      InvoiceLabels: 102,
      DuplicateActionId: 107,
      RoundingMethodActionId: 108,
    },
    ROUNDING: {
      PRECISE: 1,
      ROUNDUP: 2,
    },
  },
  SALES: {
    ModuleId: 6,
  },
  PURCHASEORDER: {
    ModuleId: 7,
    ActionId: 24,
    UX: {
      DeliveryPreference: 87,
      Status: 96,
    },
  },
  QUOTATION: {
    ModuleId: 31,
    TemplateTypeId: 15,
    ActionId: 19,
    UX: {
      QuoteStatus: 20,
      QuoteType: 21,
    },
  },
  QUOTATIONDETAILS: {
    ModuleId: 31,
    ActionId: 22,
  },
  TEMPLATES: {
    ModuleId: 17,
    ActionId: 80,
    UX: {
      TemplateType: 83,
      Templates: 80,
      Modules: 993,
    },
  },
  LOCATION: {
    ModuleId: 54,
    ActionId: 16,
  },
  ACTIVITY: {
    ModuleId: null,
    ActionId: 52,
  },
  SUPPLIER: {
    ModuleId: 19,
    ActionId: 56,
  },
  PAGES: {
    ActionId: 90,
    ModuleId: 34,
  },
  INVENTORY: {
    ModuleId: 21,
    ActionId: 39,
    UX: {
      InventoryCategory: 42,
      InventoryUnitType: 89, // dropdown for unit type
      InventoryType: 41,
      InventoryLocation: 40,
      InventoryWarehouse: 93,
    },
  },
  PRICING: {
    ActionId: 88,
  },
  ADMIN: {
    ModuleId: 991,
    ActionId: 991,
    UX: {
      ActiveUsers: 992,
      ModuleTable: 993,
      ActivityType: 994,
      ActionIDList: 998,
    },
  },
  PAYMENT: {
    ModuleId: 3,
    ActionId: 51,
    UX: {
      PaymentType: 52,
      PaymentTerms: 9,
    },
  },
  INDUSTRY: {
    ActionId: 7,
  },
  ROLES: {
    ActionId: 4,
  },
  EQUIPMENT: {
    ModuleId: 0,
    ActionId: 85,
  },
  BILLINGRATES: {
    ModuleId: 0,
    ActionId: 0,
  },
  DOCUMENTS: {
    ModuleId: 55,
    ActionId: 48,
    UX: {
      DOCTYPES: 50,
    },
  },
  ACCOUNTS_RECEIVABLE: {
    ModuleId: 0,
    ActionId: 0,
  },
  TECHNICIANS: {
    ModuleId: 2,
    ActionId: 2,
  },
  EMPLOYEE: {
    ModuleId: 24,
    ActionId: 2,
  },
  COMBINATION: {
    INVENTORYPRICING: 103,
    CALLS: 105,
    EMAILS: 106,
    MEETINGS: 107,
    UTILIZATION: 110,
    MANAGER_REPORT: 111,
  },
};

export default MODULE;
