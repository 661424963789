



















import editMixin from "@/mixin/edit-mixin";
// import editor from "@/components/_universal/global/editor.vue";
export default {
  mixins: [editMixin],
};
