<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Select Employee</span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Name</span>
        <input class="form-control" type="text" />
      </div>
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Due Date</span>
        <date-picker
          format="DD-MMM-YYYY - HH:mm a"
          :minute-step="15"
          style="width: 100%;"
          type="datetime"
          v-model="date"
          lang="en"
        ></date-picker>
      </div>
      <div class="col-md-12 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Description</span>
        <textarea class="form-control" type="text" rows="5" />
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {
      date: null
    };
  },
  components: {
    DatePicker
  }
};
</script>
