<template>
  <div>
    <!--start modal new client -->
    <div class="col-md-12">
      <b-alert show class="mb-2" variant="success" v-if="isSuccesful">
        <i class="fa fa-check-circle mr-2"></i>
        <span class="sub-title mr-2">Succesfully created new client.</span>
      </b-alert>
      <div class="row">
        <div class="col-md-12 u-mb-xsmall">
          <vue-json-to-table :data="activityDataObj"></vue-json-to-table>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- col-md-12-->
    <!--end modal new client -->
  </div>
</template>


<script>
import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import UtilityString from "@/utilities/strings";
import ActivityServices from "@/services/activity";
import EVENTS from "@/constants/events";

const api = new ActivityServices();
const global = new GlobalServices();
const utility = new UtilityString();

export default {
  data() {
    return {
      EVENT_ACTION: {},
      isSuccesful: false,
      isError: false,
      activityData: {},
      selectedClientType: { displayName: "", id: 0 },
      selectedIndustry: { displayName: "", id: 0 },
      uxClientTypeList: [],
      uxIndustryList: [],
      client: {},

      modalStatus: {
        hideShowModal: false,
        modalName: "",
        modalTitle: "",
        data: {}
      }
    };
  },
  computed: {
    activityDataObj() {
      return this.activityData;
    }
  },
  methods: {
    async onSave(data) { },

    async loadData() {
      let data = this.$store.getters.getModalStatus.data;

      this.activityData = await api.getDetailActivity(
        data.id,
        MODULE.PROJECTS.ModuleId
      );

      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page(this.EVENT_ACTION.ACTIVITY.View);
      // }
    }
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    //Subscribe to save button "Dispatch" click event on modal box
    this.$root.$on("Save" + this.EVENT_ACTION.CLIENTS.New, this.onSave);
  },
  async mounted() {
    await this.loadData();
  },
  beforeDestroy() {
    //Unscribe from save button "Dispatch" click event on modal box
    this.$root.$off("Save" + this.EVENT_ACTION.CLIENTS.New, this.onSave);
  }
};
</script>
<style scopped>
.activity {
  border: 1px solid #ced4da !important;
  padding: 0px;
}
.table-main .row-data {
  margin: 0px !important;
  border: 1px solid #e5e5e5 !important;
  border-bottom: 0px !important;
}
.key {
  width: 170px !important;
  min-width: 170px !important;
  background: #f0f7f7 !important;
}
.row-data .table-main {
  color: #6e0677;
  width: 100%;
}

.row-data div {
  width: 100%;
}
</style>