import axios from "axios";
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";

let auth = AuthService.getInstance();

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class DocumentService {

    private static _instance: DocumentService;


  
    constructor() {
       axios.defaults.baseURL = apiUrl;

        apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }

    }



    async getDocuments(id, moduleId) {

        let queryString = auth.getQueryString() + "&moduleId=" + moduleId + "&id=" + id;

        let dataResults = await axios.get('documents/list' + queryString, apiHeaderObj);

        return dataResults.data.resources;

    }




    static createInstance() {
        DocumentService.getInstance();
    }

    static getInstance() {
        return this._instance || (this._instance = new this());
    }

}

export default DocumentService;