import axios from "axios";
import Vue from "vue";
import store from "@/store/index";
import router from "@/router";
import AuthService from "@/auth/authService";
import API_CONFIG from "@/config/API_CONFIG";
import IApplication from "@/interfaces/ApplicationInterface";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import APP from "@/constants/application";

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

let modalStatus = {
  hideShowModal: false,
  modalName: "",
  modalTitle: "",
  modalSaveButtonTitle: "",
  UI_TYPE: 0, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
  moduleId: null,
  data: {},
  dataObj: {},
  isInEditMode: false, //Whether the modal box is currently editing an item or crating a new item
  actionId: 0,
};

let sidePanel = {
  isSidePanelFrame: false,
  isOpen: true,
  name: "MainSidePanel", //Name of the side panel, incase multiple panels are open
  data: null, //The data to be passed into the side panel as prop value
  panelObj: null, //Reference to the side panel object, so we can close it anywhere.
};

class ApplicationService {
  constructor() {
    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  actionNavigation(action) {
    //If action is a modal or link
    if (action.UI_TYPE === APP.UI_TYPE.Modal) {
      this.showModal(action);
    } else if (action.UI_TYPE === APP.UI_TYPE.ModalIFrame) {
      //Used to support iframe image preview passing (backward compability)
      if (action.data.url) {
        //Pass data to global data store
        store.dispatch("setApplicationModalIframe", {
          url: action.data.url,
          title: "",
          showToolbar: true,
          data: document,
        });
      }

      this.showModal(action);
    }
    //Open Link to a new tab
    else if (action.UI_TYPE == APP.UI_TYPE.NewTab) {
      this.openNewTab(action.url);
    }
  }

  //Open/Show details pane
  showSidePanel(listDataObj, data, route) {
    let isIframe = false;

    if (listDataObj) {
      if (listDataObj.isSidePanelFrame) isIframe = true;
    }

    this.setPanelProperties(data, route, isIframe);

    //this.trackAnalytics(listDataObj.tracking);
  }

  setPanelProperties(data, route, isIframe) {
    sidePanel = store.getters.getSidePanel;

    sidePanel.isOpen = true;

    if (isIframe) sidePanel.isSidePanelFrame = true;
    else sidePanel.isSidePanelFrame = false;

    sidePanel.name = "MainSidePanel"; //Name of the side panel, incase multiple panels are open

    sidePanel.data = data;

    sidePanel.panelObj.show();

    sidePanel.panelObj.promise.then((result) => {});
    //Send Data to Side Panel
    store.dispatch("setSidePanel", sidePanel);
  }

  closeSidePanel() {
    sidePanel.panelObj.hide();
    sidePanel.isOpen = false;
    sidePanel.data = null;

    //this.$emit("closePanel", {});

    //Clear Side Panel Data
    store.dispatch("setSidePanel", sidePanel);
  }

  //Open Modal Dialog Box and pass data paramaters.
  showModal(action) {
    modalStatus.hideShowModal = true;
    modalStatus.modalName = action.componentName;
    modalStatus.data = action.data;

    //Automatically assign values from action to modal status
    // Object.assign(modalStatus, action);
    modalStatus = {...modalStatus, ...action};

    store.dispatch("toggleModalStatus", modalStatus);
  }

  //Get Application Navigation
  async print(moduleId, id) {
    let auth = AuthService.getInstance();
    let config = new API_CONFIG();
    let queryString = auth.getQueryString();
    let dataResults;

    axios.defaults.baseURL = config.API_URL;

    let param = "&id=" + id + "&moduleid=" + moduleId;

    dataResults = await axios.get("/print" + queryString + param, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Get all application settings
  async getApplicationConfiguration(): Promise<IApplication> {
    let auth = AuthService.getInstance();
    let config = new API_CONFIG();
    let queryString = auth.getQueryString();
    let dataResults;

    axios.defaults.baseURL = config.API_URL;

    dataResults = await axios.get("/application/settings" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Get Application Navigation
  async getNavigation() {
    let auth = AuthService.getInstance();
    let config = new API_CONFIG();
    let queryString = auth.getQueryString();
    let dataResults;

    axios.defaults.baseURL = config.API_URL;

    dataResults = await axios.get("/application/navigation" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getLatestVersion() {
    let auth = AuthService.getInstance();
    let config = new API_CONFIG();
    let queryString = auth.getQueryString();
    let dataResults;

    axios.defaults.baseURL = config.API_URL;

    dataResults = await axios.get("/application/version/latest" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async openNewTab(url) {
    if (url) window.open(url, "_blank");
  }

  //Send's Data to google tag Manager and to other external api tracking source
  trackAnalytics(tracking) {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   Vue.gtm.trackEvent({
    //     event: null, // Event type [default = 'interaction'] (Optional)
    //     category: tracking.ModuleName, //"PROJECTS",
    //     action: tracking.ModuleAction, //EVENTS.PROJECTS.View,
    //     label: tracking.Description, //"Viewed Project",
    //     value: tracking.Id, // data.intProjectID
    //     noninteraction: false, // Optional
    //   });
    // }
  }
}

export default ApplicationService;
