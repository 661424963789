import axios from "axios";
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";


let auth = AuthService.getInstance();

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;



class TemplateService {

    private static _instance: TemplateService;

    
    constructor() {
       axios.defaults.baseURL = apiUrl;

        apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }

    }




    async getTemplateById(id) {

        axios.defaults.baseURL = apiUrl;

        let queryString = auth.getQueryString() + "&id=" + id;

        let dataResults = await axios.get('templates' + queryString, apiHeaderObj);

        return dataResults.data.resources;

    }



    async getTemplate(id, typeId) {

        axios.defaults.baseURL = apiUrl;

        let queryString = auth.getQueryString() + "&typeId=" + typeId + "&id=" + id;

        let dataResults = await axios.get('ux/template' + queryString, apiHeaderObj);

        return dataResults.data.resources;

    }






    //Update Item Record
    async updateTemplate(item) {

        this.setAPIDefaults(item);

        let result = null;

        if (item.id === 0)
            result = await axios.post("template/add", item, apiHeaderObj).catch(function (error) { });
        else
            result = await axios.put("template/update", item, apiHeaderObj).catch(function (error) { });


        return result.data.resources;
    }





    //Delete notes item
    async deleteTemplate(item) {


        this.setAPIDefaults(item);


        let result = null;

        result = await axios.post("template/delete", item, apiHeaderObj).catch(function (error) { });


        return result;
    }




    private setAPIDefaults(item: any) {
        axios.defaults.baseURL = apiUrl;

        item = Object.assign(item, auth.getAuthObject());


    }


    static createInstance() {
        TemplateService.getInstance();
    }

    static getInstance() {
        return this._instance || (this._instance = new this());
    }

}

export default TemplateService;