<template>
  <div>
    <ValidationObserver ref="formNewInvoice">
      <!--start modal new project body -->
      <div class="col-md-12 u-pt-small">
        <div v-if="isError" class="alert alert-danger" role="alert" id="dashboard-project-log-time-error-message">
          Sorry, there was an error while creating this job. Please try again.
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row pt-2 pl-3">
              <div class="u-mb-xsmall mr-4">
                <div class="c-choice c-choice--radio mb-0">
                  <input class="c-choice__input" id="radio2" name="radios" type="radio" :value="'technician'" v-model="timeTrackingType" />
                  <label class="c-choice__label" for="radio2">Technician</label>
                </div>
              </div>
              <div class="u-mb-xsmall mr-4">
                <div class="c-choice c-choice--radio mb-0">
                  <input class="c-choice__input" id="radio1" name="radios" type="radio" :value="'equipment'" v-model="timeTrackingType" />
                  <label class="c-choice__label" for="radio1">Equipment</label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 ss--new-quote__modal_module u-mb-small" v-if="timeTrackingType === 'technician'">
            <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Choose a Technician
            </div>
            <ValidationProvider name="employee" rules="required" v-slot="{errors}">
              <v-select
                v-model="selectedEmployee"
                id="slidepanel-edit-work-order-tabs-time-tracking-modal-choose-technician"
                :options="uxEmployeesList"
                @input="onUpdateEmployee"
                code="employeeID"
                label="fullName"
              ></v-select>
              <span class="text-danger">{{ errors.length > 0 ? "Employee is required." : "" }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-6 ss--new-quote__modal_module u-mb-small" v-if="timeTrackingType === 'equipment'">
            <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Choose an Equipment
            </div>
            <ValidationProvider name="equipment" rules="required" v-slot="{errors}">
              <v-select
                v-model="selectedEquipment"
                id="slidepanel-edit-work-order-tabs-time-tracking-modal-choose-equipment"
                :options="uxInventoryList"
                @input="onUpdateEquipment"
                code="id"
                label="displayName"
              ></v-select>
              <span class="text-danger">{{ errors.length > 0 ? "Equipment is required." : "" }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
            <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Rate
            </div>
            <!-- <ValidationProvider name="rate" rules="required" v-slot="{ errors }"> -->
            <v-select v-model="selectedRate" id="slidepanel-edit-work-order-tabs-time-tracking-modal-rate" :options="uxRateList" @input="onUpdateRate" code="id" label="displayName"></v-select>
            <!-- <span class="text-danger">{{ errors.length > 0 ? "Rate is required." : "" }}</span> -->
            <!-- </ValidationProvider> -->
          </div>
        </div>
        <!-- row -->
        <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
                <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
                  Date
                </div>
                <!-- <DatePickerConvert format="DD-MMM-YYYY" :data-obj="modalDataDetails" :current-date="modalDataDetails.Date" style="width: 100%;" @onUpdate="updateDate" /> -->
                <ValidationProvider name="date" rules="required" v-slot="{errors}">
                  <date-picker
                    format="DD-MMM-YYYY"
                    id="slidepanel-edit-work-order-tabs-time-tracking-modal-date"
                    value-type="format"
                    style="width: 100%;"
                    v-model="modalDataDetails.Date"
                    @change="updateFieldSimplified('Date', modalDataDetails.Date, 'Date', true)"
                    lang="en"
                  >
                  </date-picker>
                  <span class="text-danger">{{ errors.length > 0 ? "Date is required." : "" }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
                <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">Time In</div>
                <ValidationProvider name="timeIn" rules="required" v-slot="{errors}">
                  <date-picker
                    show-time-header
                    format="hh:mm a"
                    id="slidepanel-edit-work-order-tabs-time-tracking-modal-time-in"
                    value-type="format"
                    style="width: 100%;"
                    v-model="modalDataDetails.TimeIn"
                    placeholder="Select Time"
                    type="time"
                    :time-picker-options="{
                      start: '3:00',
                      step: '00:30',
                      end: '23:00',
                    }"
                    @change="updateDateTimeFieldSimplified('TimeIn', modalDataDetails.TimeIn, 'Time In', true, formatDate(modalDataDetails.Date))"
                    lang="en"
                  ></date-picker>
                  <span class="text-danger">{{ errors.length > 0 ? "Time In is required." : "" }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-md-6  u-mb-small">
            <div class="row">
              <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
                <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
                  Time Out
                </div>
                <ValidationProvider name="outTime" rules="required" v-slot="{errors}">
                  <date-picker
                    format="hh:mm a"
                    id="slidepanel-edit-work-order-tabs-time-tracking-modal-time-out"
                    value-type="format"
                    style="width: 100%;"
                    v-model="modalDataDetails.TimeOut"
                    placeholder="Select Time"
                    type="time"
                    :time-picker-options="{
                      start: modalDataDetails.TimeIn ? modalDataDetails.TimeIn : '3:00',
                      step: '00:30',
                      end: '23:00',
                    }"
                    @change="updateDateTimeFieldSimplified('TimeOut', modalDataDetails.TimeOut, 'Time Out', true, formatDate(modalDataDetails.Date))"
                    lang="en"
                  ></date-picker>
                  <span class="text-danger">{{ errors.length > 0 ? "Time Out is required." : "" }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
                <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
                  Type
                </div>
                <ValidationProvider name="type" rules="required" v-slot="{errors}">
                  <v-select
                    :options="uxType"
                    id="slidepanel-edit-work-order-tabs-time-tracking-modal-type"
                    v-model="selectedType"
                    @input="onUpdateType"
                    code="value"
                    label="displayName"
                    :clearable="false"
                  >
                  </v-select>
                  <span class="text-danger">{{ errors.length > 0 ? "Type is required." : "" }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
            <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Break Time
            </div>
            <v-select
              v-model="selectedBreakTime"
              id="slidepanel-edit-work-order-tabs-time-tracking-modal-break-time"
              :options="breakTimeList"
              @input="onUpdateBreakTime"
              code="value"
              label="label"
              :clearable="false"
            ></v-select>
          </div>
          <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
            <!-- <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Label
            </div> -->
            <global-settings-modal
              id="edit-time-tracking-global-settings-label"
              comp-class="ss-primary-font-color u-text-bold u-mb-small u-inline-block"
              label="Label"
              :actionId="MODULE.TIMETRACKING.UX.TaskLabels"
            />
            <v-select v-model="selectedLabel" id="slidepanel-edit-work-order-tabs-time-tracking-modal-status" :options="labelList" @input="onUpdateLabel" code="id" label="displayName"></v-select>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-5 ss--new-quote__modal_module u-mb-small">
            <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Travel
            </div>
            <v-select :options="uxTimeList" id="slidepanel-edit-work-order-tabs-time-tracking-modal-travel"
              v-model="selectedTravel" @input="onUpdateTravel" code="value" label="displayName"></v-select>
          </div>
          <div class="col-md-5 ss--new-quote__modal_module u-mb-small">
            <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Premium / Overtime
            </div>
            <v-select :options="uxTimeList" id="slidepanel-edit-work-order-tabs-time-tracking-modal-overtime"
              v-model="selectedOverTime" @input="onUpdateOvertime" code="value" label="displayName"></v-select>
          </div>
          <div class="col-md-2 u-mb-xsmall align-items-end d-flex">
            <b-form-checkbox id="checkbox-1" name="checkbox-1" :value="true" :unchecked-value="false"
              v-model="modalDataDetails.Emergency"
              @change="updateFieldSimplified('Emergency', modalDataDetails.Emergency, 'Emergency', true)">
              <span class="ml-2">Emergency Call</span>
            </b-form-checkbox>
          </div>
        </div> -->

        <div class="row">
          <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
            <div class="ss-primary-font-color u-text-bold u-mb-small u-inline-block">
              Description
            </div>
            <textarea
              class="form-control"
              rows="6"
              id="slidepanel-edit-work-order-tabs-time-tracking-modal-description"
              v-model="modalDataDetails.Description"
              @keyup="
                updateFieldSimplified(
                  'Description', //PropertyName
                  modalDataDetails.Description, //Property Value
                  'Description', //Display Name
                  true
                )
              "
            ></textarea>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- col-md-12-->
      <!--end modal new project body -->
    </ValidationObserver>
  </div>
</template>

<script>
  import EVENTS from "@/constants/events";
  import editMixin from "@/mixin/edit-mixin";
  import DatePicker from "vue2-datepicker";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UXEmployeeService from "@/services/ux/uxemployee";
  // import DatePickerConvert from "@/components/_universal/date-picker-convert.vue";
  import moment from "moment";
  const global = new GlobalServices();

  export default {
    mixins: [editMixin],

    data() {
      return {
        isInfo: true,
        isSuccesful: false,
        isError: false,
        selectedEquipment: null,
        selectedEmployee: null,
        selectedRate: null,
        selectedOverTime: null,
        selectedTravel: null,
        selectedBreakTime: {label: "No Break", value: 0},
        selectedLabel: null,
        selectedType: {displayName: "Regular", value: "regular"},
        selectedFilter: "all",
        formData: {
          actionId: MODULE.TIMETRACKING.ActionId,
          moduleId: MODULE.PROJECTS.ModuleId,
          parentId: null,
          Date: null,
          Description: null,
          TimeIn: null,
          TimeOut: null,
          // Emergency: false,
          Type: null,
        },

        timeTrackingType: "technician",

        uxEmployeesList: [],
        uxInventoryList: [],
        uxRateList: [],
        uxType: [
          {displayName: "Regular", value: "regular"},
          {displayName: "Overtime", value: "overtime"},
          {displayName: "Travel", value: "travel"},
          {displayName: "Premium", value: "premium"},
          {displayName: "Emergency", value: "emergency"},
        ],
        breakTimeList: [
          {label: "No Break", value: 0},
          {label: "15 Minutes", value: 15},
          {label: "30 Minutes", value: 30},
          {label: "45 Minutes", value: 45},
          {label: "1 Hour", value: 60},
          {label: "1 Hour 15 Minutes", value: 75},
          {label: "1 Hour 30 Minutes", value: 90},
          {label: "1 Hour 45 Minutes", value: 105},
          {label: "2 Hours", value: 120},
        ],
        labelList: [],
        // labelList: [
        //   {label: "Timesheet Required", value: 1},
        //   {label: "Incorrect/Insufficient Details", value: 2},
        //   {label: "Process Pre-Payroll", value: 3},
        //   {label: "Approval Required", value: 4},
        //   {label: "Approved", value: 5},
        //   {label: "Remunerated", value: 6},
        //   {label: "Invoiced", value: 7},
        // ],

        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },
      };
    },
    watch: {
      modalDataDetails: {
        handler(val) {
          this.updateModalData(val);
        },
        deep: true,
      },
      timeTrackingType() {
        this.selectedEquipment = null;
        this.selectedEmployee = null;
      },
    },

    methods: {
      formatDate(date, format = "DD-MMM-YYYY") {
        return moment(date).format(format);
      },
      updateModalData(data) {
        this.$store.dispatch("setModalDataDetailsObject", data);
      },
      // updateDate(value) {
      //   this.modalDataDetails.Date = value;
      //   this.updateFieldSimplified("Date", value, "Date", true);
      // },
      onUpdateEmployee() {
        this.modalDataDetails.IntEquipmentID = null;
        this.modalDataDetails.IntEmployeeID = this.selectedEmployee.employeeID;
        this.modalDataDetails.TaskName = this.selectedEmployee.fullName;
        this.updateFieldSimplified("IntEmployeeID", this.modalDataDetails.IntEmployeeID, "Employee ID", true);
        setTimeout(() => {
          this.updateFieldSimplified("IntEquipmentID", this.modalDataDetails.IntEquipmentID, "Equipment ID", true);
        }, 500);
        // this.updateFieldSimplified("IntEquipmentID", this.modalDataDetails.IntEquipmentID, "Equipment ID", true);
      },
      onUpdateEquipment() {
        this.modalDataDetails.IntEmployeeID = null;
        this.modalDataDetails.IntEquipmentID = this.selectedEquipment.id;
        this.modalDataDetails.TaskName = this.selectedEquipment.displayName;
        this.updateFieldSimplified("IntEquipmentID", this.modalDataDetails.IntEquipmentID, "Equipment ID", true);
        setTimeout(() => {
          this.updateFieldSimplified("IntEmployeeID", this.modalDataDetails.IntEmployeeID, "Employee ID", true);
        }, 500);
      },
      onUpdateRate() {
        this.modalDataDetails.IntRateID = !this.selectedRate ? null : this.selectedRate.id;
        this.updateFieldSimplified("IntRateID", this.modalDataDetails.IntRateID, "Rate", true);
      },
      onUpdateOvertime() {
        this.modalDataDetails.PremiumTime = !this.selectedOverTime ? null : this.selectedOverTime.value;
        this.updateFieldSimplified("PremiumTime", this.modalDataDetails.PremiumTime, "Premium Time", true);
      },
      onUpdateTravel() {
        this.modalDataDetails.Travel = !this.selectedTravel ? null : this.selectedTravel.value;
        this.updateFieldSimplified("Travel", this.modalDataDetails.Travel, "Travel", true);
      },
      onUpdateBreakTime() {
        this.modalDataDetails.BreakTime = !this.selectedBreakTime ? null : this.selectedBreakTime.value;
        this.updateFieldSimplified("BreakTime", this.modalDataDetails.BreakTime, "Break Time", true);
      },
      onUpdateLabel() {
        this.modalDataDetails.IntTasksLabelID = !this.selectedLabel ? null : this.selectedLabel.id;
        this.updateFieldSimplified("IntTasksLabelID", this.modalDataDetails.IntTasksLabelID, "Label", true);
      },
      onUpdateType() {
        this.modalDataDetails.Type = !this.selectedType ? null : this.selectedType.value;
        this.updateFieldSimplified("Type", this.modalDataDetails.Type, "Type", true);
      },
      async initializeData() {
        //Get Employee List
        let vm = this;
        const employeeService = new UXEmployeeService();
        const apiemployee = await employeeService.getEmployees();
        const inventoryList = await global.getUXDropDown(MODULE.INVENTORY.ActionId);
        const rateList = await global.getUXDropDown(MODULE.CLIENTS.UX.ClientRates);
        const labelList = await global.getUXDropDown(MODULE.TIMETRACKING.UX.TaskLabels);
        this.uxEmployeesList = apiemployee || [];
        this.uxInventoryList = inventoryList || [];
        this.uxRateList = rateList || [];
        this.labelList = labelList || [];
        //Get Data From Modal
        this.modalStatus = this.$store.getters.getModalStatus;
        if (this.modalObj.isInEditMode) {
          //Load Details Details from API
          this.formData = await global.getEditDetails(MODULE.TIMETRACKING.ActionId, this.modalObj.data.id);
          this.formData.actionId = MODULE.TIMETRACKING.ActionId;
          this.formData.moduleId = MODULE.PROJECTS.ModuleId;

          if (this.formData.IntEmployeeID && this.uxEmployeesList.length > 0) {
            this.timeTrackingType = "technician";
            this.$nextTick(() => {
              vm.selectedEmployee = vm.uxEmployeesList.find((t) => {
                return parseInt(t.employeeID) === parseInt(vm.formData.IntEmployeeID);
              });
            });
          }
          if (this.formData.IntEquipmentID && this.uxInventoryList.length > 0) {
            this.timeTrackingType = "equipment";
            this.$nextTick(() => {
              vm.selectedEquipment = vm.uxInventoryList.find((t) => {
                return t.id === parseInt(vm.formData.IntEquipmentID);
              });
            });
          }

          // if (this.formData.Travel) {
          //   this.selectedTravel = this.uxTimeList.find((t) => {
          //     return parseInt(t.value) === parseInt(this.formData.Travel);
          //   });
          // }
          // if (this.formData.PremiumTime) {
          //   this.selectedOverTime = this.uxTimeList.find((t) => {
          //     return parseInt(t.value) === parseInt(this.formData.PremiumTime);
          //   });
          // }
          if (this.formData.BreakTime) {
            this.selectedBreakTime = this.breakTimeList.find((t) => {
              return parseInt(t.value) === parseInt(this.formData.BreakTime);
            });
          }

          if (this.formData.IntTasksLabelID) {
            this.selectedLabel = this.labelList.find((t) => {
              return parseInt(t.id) === parseInt(this.formData.IntTasksLabelID);
            });
          }
          if (this.formData.IntRateID) {
            this.selectedRate = this.uxRateList.find((t) => {
              return parseInt(t.id) === parseInt(this.formData.IntRateID);
            });
          }
          if (this.formData.Type) {
            this.selectedType = this.uxType.find((t) => {
              return t.value === this.formData.Type.toLowerCase();
            });
          }

          if (this.formData.Date) {
            this.formData.Date = moment(this.formData.Date).format("DD-MMM-YYYY");
          }
        }

        // IntTaskTypeID  is for TimeTracking
        this.formData.IntTaskTypeID = 13;
        // send parentID (IntProjectId)
        this.formData.parentId = this.modalStatus.data.id;
        this.updateModalData(this.formData);
      },
      resetForm() {
        this.selectedEmployee = null;
        this.selectedEquipment = null;
        this.selectedRate = null;
        this.selectedLabel = null;
        this.selectedBreakTime = {label: "No Break", value: 0};
        this.selectedType = {displayName: "Regular", value: "regular"};
      },
    },

    components: {
      DatePicker,
      // DatePickerConvert,
    },
    beforeDestroy() {
      this.$root.$off("on-save", this.resetForm);
    },
    mounted() {
      this.$root.$on("on-save", this.resetForm);
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
      this.MODULE = MODULE;
      this.initializeData();
    },
  };
</script>
