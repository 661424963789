<template>
  <div>
    <ValidationObserver ref="formNewSupplier">
      <!--start modal new client -->
      <div class="col-md-12">
        <b-alert show class="mb-2" variant="success" v-if="isSuccesful">
          <i class="fa fa-check-circle mr-2"></i>
          <span class="sub-title mr-2" id="modal-new-supplier">Succesfully created new supplier. You may close this
            window.</span>
        </b-alert>
        <div class="row">
          <div class="col-lg-6 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Company/Name</span>
            <ValidationProvider v-slot="v" name="company/name" rules="required">
              <input class="form-control" type="text" @input="trimSpace('businessName')" v-model="supplier.businessName"
                placeholder id="dashboard-top-nav-new-supplier-modal-company-name" />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Address</span>
            <ValidationProvider name="address" v-slot="v" rules="required">
              <textarea class="form-control" type="text" @input="trimSpace('primaryAddress')"
                v-model="supplier.primaryAddress" placeholder id="dashboard-top-nav-new-supplier-modal-address" />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">billing Email</span>
            <ValidationProvider name="billing email" v-slot="v" rules="required|email">
              <input class="form-control" type="text" @input="trimSpace('email')" v-model="supplier.email" placeholder
                id="dashboard-top-nav-new-supplier-modal-billing-email" />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Phone</span>
            <ValidationProvider name="phone" rules="required" v-slot="{errors}">
              <input class="form-control" type="text" v-model="supplier.phone" @input="formatPhoneNumber('phone')"
                placeholder id="dashboard-top-nav-new-supplier-modal-phone" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-12 u-mt-small u-mb-small u-height-2">
            <div class="border-bottom">
              <h5>Primary Contact</h5>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">First &amp; Last Name</span>
            <ValidationProvider name="primary contact name" rules="required" v-slot="{errors}">
              <input class="form-control" type="text" @input="trimSpace('primaryContactName')"
                v-model="supplier.primaryContactName" placeholder
                id="dashboard-top-nav-new-supplier-modal-first-last-name" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Email</span>
            <ValidationProvider name="billing email" v-slot="{errors}" rules="email">
              <input class="form-control" type="text" @input="trimSpace('primaryContactEmail')"
                v-model="supplier.primaryContactEmail" placeholder id="dashboard-top-nav-new-supplier-modal-email" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Mobile Number</span>
            <ValidationProvider name="mobile number" rules="required" v-slot="{errors}">
              <input class="form-control" @input="formatPhoneNumber('mobile')" type="text" v-model="supplier.mobile"
                placeholder id="dashboard-top-nav-new-supplier-modal-mobile" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-12 u-mt-small u-mb-small u-height-2">
            <div class="border-bottom">
              <h5>Additional Information</h5>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 ss--new-quote___modal_module u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Industry</span>
            <v-select v-model="selectedIndustry" :options="uxIndustryList" value="id" label="displayName"
              id="dashboard-top-nav-new-supplier-modal-industry"></v-select>
          </div>
          <div class="col-lg-4 col-md-6 ss--new-quote___modal_module u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Pay Term</span>
            <v-select v-model="selectedPayTerm" :options="uxPayTerm" value="id" label="displayName"
              id="dashboard-top-nav-new-supplier-pay-term"></v-select>
          </div>
          <div class="col-lg-4 col-md-6 ss--new-quote___modal_module u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Delivery Preference</span>
            <v-select v-model="selectedDeliveryPreference" :options="uxDeliveryPreference" value="id"
              label="displayName" id="dashboard-top-nav-new-supplier-modal-delivery-preference"></v-select>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- col-md-12-->
      <!--end modal new client -->
    </ValidationObserver>
  </div>
</template>

<script>
import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import UtilityString from "@/utilities/strings";
import hash from "object-hash";

const utility = new UtilityString();
const global = new GlobalServices();
import EVENTS from "@/constants/events";

export default {
  data() {
    return {
      EVENT_ACTION: {},
      isSuccesful: false,
      isError: false,
      selectedPayTerm: { displayName: "", id: 0 },
      selectedIndustry: { displayName: "", id: 0 },
      selectedDeliveryPreference: { displayName: "", id: 0 },
      uxPayTerm: [],
      uxIndustryList: [],
      uxDeliveryPreference: [],
      supplier: {},
    };
  },
  methods: {
    async onSave(data) {
      this.$refs.formNewSupplier.validate().then(async (success) => {
        if (!success) {
          return;
        }

        this.supplier.moduleId = MODULE.SUPPLIER.ModuleId;
        this.supplier.deliveryId = this.selectedDeliveryPreference.id;
        this.supplier.paymentTermId = this.selectedPayTerm.id;
        this.supplier.industryId = this.selectedIndustry.id;

        //Remove all spaces from properties
        this.supplier = utility.trimObjectPropertySpace(this.supplier);

        //Generate a Hash from the data to be submitted
        this.supplier.hash = hash(this.supplier);

        let result = await global.createNewActivity(MODULE.SUPPLIER.ActionId, this.supplier);

        if (result.success) {
          this.isSuccesful = true;
          this.isError = false;
          this.supplier = {};

          this.$refs.formNewSupplier.reset();
        } else {
          this.isSuccesful = false;
          this.isError = true;
        }

        setTimeout(() => {
          this.isSuccesful = false;
          this.isError = false;
        }, 30000);
      });
    },
    formatPhoneNumber(propertyName) {
      this.supplier[propertyName] = utility.formatPhoneNumber(this.supplier[propertyName]);
    },
    trimSpace(propertyName) {
      if (this.timeout) clearTimeout(this.timeout);

      //Only clear space after user stops typing
      this.timeout = setTimeout(() => {
        //Trim Space from poperty
        this.supplier[propertyName] = utility.trimSpace(this.supplier[propertyName]);
      }, 1500);
    },
    async setInitialDefaults() {
      this.uxPayTerm = await global.getUXDropDown(MODULE.PAYMENT.UX.PaymentTerms);

      this.uxDeliveryPreference = await global.getUXDropDown(MODULE.PURCHASEORDER.UX.DeliveryPreference);
      this.uxIndustryList = await global.getUXDropDown(MODULE.CLIENTS.UX.Industry);

      //Set pay term default selected value
      this.selectedPayTerm = global.setDropDownDefault(this.uxPayTerm);

      //Set delivery preference default selected value
      this.selectedDeliveryPreference = global.setDropDownDefault(this.uxDeliveryPreference);

      //Set industry default selected value
      this.selectedIndustry = global.setDropDownDefault(this.uxIndustryList);
    },
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    //Subscribe to save button "Dispatch" click event on modal box
    this.$root.$on("Save" + this.EVENT_ACTION.SUPPLIER.New, this.onSave);
  },
  async mounted() {
    await this.setInitialDefaults();
  },
  beforeDestroy() {
    //Unscribe from save button "Dispatch" click event on modal box
    this.$root.$off("Save" + this.EVENT_ACTION.SUPPLIER.New, this.onSave);
  },
};
</script>
