const EVENT = {
  INFO: {
    UpdateTotal: "UpdatedInfoStatTotal",
  },
  IFRAME: {
    Email: "ViewedIframeModalEmail",
    Print: "ViewedIframeModalPrint",
    PreviewImage: "ViewedIframeModalPreviewImage",
    LogTime: "ViewedIframeModalLogTime",
    AddTask: "ViewedIframeModalAddTask",
    AddDocument: "ViewedIframeModalAddDocument",
    AddClientDocument: "ViewedIframeModalAddClientDocument",
    AddQuoteDocument: "ViewedIframeModalAddQuoteDocument",
    AddPurchaseOrderDocument: "ViewedIframeModalAddPurchaseOrderDocument",
    AddInvoiceDocument: "ViewedIframeModalAddInvoiceDocument",
    AddTemplateDocument: "ViewedIframeModalAddTemplateDocument",
    AddInventoryDocument: "ViewedIframeModalAddInventoryDocument",
    AddSuppliers: "ViewedIframeModalAddSuppliers",
    AddServices: "ViewedIframeModalAddServices",
    AddEquipment: "ViewedIframeModalAddEquipment",
    AddPages: "ViewedIframeModalAddPages",
    ViewOldPurchaseOrder: "ViewedIframeModalOldPurchaseOrder",
    AssignTechnician: "ViewedIframeModalAssignTechnician",
    RecurrenceSchedule: "ViewedIframeModalRecurrenceSchedule",
    RecordPaymentOld: "ViewedIframeModalRecordPaymentOld",
  },
  DASHBOARD: {
    View: "ViewedDashboard",
    Update: "UpdatedDashboard",
    Hover: "ViewedDashboardHover",
    Modal: "ViewModalDashboard",
    Action: {
      QuickActionDispatchTechnician: "QuickActionDispatchTechnician",
      DispatchTechnician: "ActionDispatchTechnician",
      ExportExcel: "ActionExportExcel",
      Reset: "ResetDashboard",
    },
    Filter: {
      Client: "FilterByClient",
      ProjectType: "FilteredByProjectType",
      ProjectStatus: "FilteredByProjectStatus",
      TechnicianStatus: "FilteredByTechnicianStatus",
      Address: "FilteredByAddress",
      ProjectReferenceNumber: "FilteredByProjectReferenceNumber",
      DueDate: "FilterByDueDate",
      AssignedTo: "FilterByAssignedTo",
      CreatedBy: "FilterByCreatedBy",
      DateRangeAppointment: "FilterByDateRangeAppointment",
      DateRangeCompleted: "FilterByDateRangeCompleted",
      DateRangeCreated: "FilterByDateRangeCreated",
      DateRangeDueDate: "FilterByDateRangeDueDate",
      DateRangeEstCompletedDate: "FilterByDateRangeEstCompletedDate",
      DateRangeStartDate: "FilterByDateRangeStartDate",
    },
  },
  DISPATCH: {
    New: "NewDispatch",
    View: "ViewedDispatch",
    Update: "UpdatedDispatch",
  },
  ADMIN: {
    New: "NewAdmin",
    View: "ViewedAdmin",
    Update: "UpdatedAdmin",
  },
  CLIENTS: {
    New: "NewClient",
    View: "ViewedClient",
    ExternalView: "ViewedExternalTabClient",
    Update: "UpdatedClient",
    Delete: "DeletedClient",
  },
  LEADS: {
    New: "NewLead",
    View: "ViewedLead",
    Update: "UpdatedLead",
    Delete: "DeletedLead",
    ManageBoard: "ManageBoard",
  },
  PROJECTS: {
    New: "NewProject",
    NewFromQuote: "NewJobFromQuote",
    Save: "SaveNewProject",
    View: "ViewedProject",
    ViewOld: "ViewedOldProjectDetails",
    ViewQuickEdit: "ViewedProjectQuickEdit",
    Update: "UpdatedProject",
    Delete: "DeletedProject",
    Scheduler: "ViewedScheduler",
    AssignTech: "AssignTechnicianModal",
    EditLocation: "UpdateLocationProject",
  },
  PURCHASEORDER: {
    New: "NewPurchaseOrder",
    View: "ViewedPurchaseOrder",
    Update: "UpdatedPurchaseOrder",
    Delete: "DeletedPurchaseOrder",
  },
  INVOICE: {
    New: "NewInvoice",
    View: "ViewedInvoice",
    Update: "UpdatedInvoice",
    Delete: "DeletedInvoice",
    RecordPayment: "RecordPayment",
    RecordSinglePayment: "RecordSinglePayment",
    DuplicateInvoice: "DuplicateInvoice",
  },
  SUPPLIER: {
    New: "NewSupplier",
    View: "ViewedSupplier",
    Update: "UpdatedSupplier",
    Delete: "DeletedSupplier",
  },
  EMPLOYEE: {
    New: "NewEmployee",
    View: "ViewedEmployee",
    Update: "UpdatedEmployee",
    Delete: "DeletedEmployee",
  },
  // TEMPLATE: {
  //   New: "NewTemplate",
  //   View: "ViewedTemplate",
  //   Update: "UpdatedTemplate",
  //   Delete: "DeletedTemplate",
  // },
  INVENTORY: {
    New: "NewInventory",
    View: "ViewedInventory",
    Update: "UpdatedInventory",
    Delete: "DeletedInventory",
  },
  PAYMENT: {
    New: "NewPayment",
    View: "ViewedPayment",
    Update: "UpdatedPayment",
    Delete: "DeletedPayment",
  },
  EMAIL: {
    New: "NewEmail",
    View: "ViewedEmail",
    Update: "UpdatedEmail",
    Delete: "DeletedEmail",
  },
  QUOTATION: {
    New: "NewQuote",
    View: "ViewedQuote",
    Update: "UpdatedQuote",
    Delete: "DeletedQuote",
  },
  TEMPLATES: {
    New: "NewTemplate",
    View: "ViewedTemplate",
    Update: "UpdatedTemplate",
    Delete: "DeletedTemplate",
  },
  LOCATION: {
    New: "NewLocation",
    View: "ViewedLocation",
    Update: "UpdatedLocation",
    Delete: "DeletedLocation",
  },
  EQUIPMENT: {
    New: "NewEquipment",
    View: "ViewedEquipment",
    Update: "UpdatedEquipment",
    Delete: "DeletedEquipment",
  },
  DOCUMENTS: {
    New: "NewDocuments",
    View: "ViewedDocuments",
    Update: "UpdatedDocuments",
    Delete: "DeletedDocuments",
  },
  BILLINGRATES: {
    New: "NewBillingRates",
    View: "ViewedBillingRates",
    Update: "UpdatedBillingRates",
    Delete: "DeletedBillingRates",
  },
  ACCOUNTS_RECEIVABLE: {
    New: "NewAccountsReceivable",
    View: "ViewedAccountsReceivable",
    Update: "UpdatedAccountsReceivable",
    Delete: "DeletedAccountsReceivable",
  },
  CONTACTS: {
    New: "NewContacts",
    View: "ViewedContacts",
    Update: "UpdatedContacts",
    Delete: "DeletedContacts",
  },
  ACTIVITY: {
    New: "NewActivity",
    View: "ViewedActivity",
    ViewDetails: "ViewedActivityDetails",
  },
  MAP: {
    View: "ViewedMap",
    ExternalView: "ViewedExternalMap",
  },
  SUPPORT: {
    New: "Support",
    View: "ViewedSupport",
  },
  SERVICES_MATERIALS: {
    New: "AddServicesMaterials",
    View: "ViewedServicesMaterials",
    Update: "UpdatedServicesMaterials",
    Delete: "DeletedServicesMaterials",
  },
  DOCUMENTS_PHOTOS: {
    New: "NewDocumentUploader",
    Edit: "DocumentFormEditor",
  },
  TASK: {
    New: "AddTask",
  },
  TIME_TRACKING: {
    New: "AddTimeTracking",
  },
  ASSIGN_STAFF: {
    New: "AssignStaff",
  },
  SCHEDULING: {
    New: "Scheduling",
  },
  SPLIT: {
    New: "Split",
  },
  PRICING: {
    New: "NewPricing",
    View: "ViewedPricing",
    Update: "UpdatedPricing",
    Delete: "DeletedPricing",
  },
  PAGES: {
    New: "NewPages",
    View: "ViewedPages",
    Update: "UpdatedPages",
    Delete: "DeletedPages",
  },
  PDF: {
    View: "PDFViewer",
  },
  Image: {
    View: "ImageViewer",
  },
  SCHEDULER: {
    View: "ViewScheduler",
  },
  SETTINGS: {
    SpecificListing: "UpdateSpecificListing",
    BugReportModal: "BugReportModal",
    ClientRateGroupListing: "UpdateClientRateGroupListing",
    ClientRatingListing: "UpdateClientRatingListing"
  },
  COMBINATION: {
    CALLS: "NewCalls",
    MEETINGS: "NewMeeting",
    EMAILS: "NewEmailsTabModal",
  },
};

export default EVENT;
