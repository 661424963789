<template>
  <div>
    <ValidationObserver ref="formNewClient">
      <!--start modal new client -->
      <div class="row">
        <div class="col-md-12">
          <b-alert show class="mb-2" variant="success" v-if="isSuccesful">
            <i class="fa fa-check-circle mr-2"></i>
            <span class="sub-title mr-2" id="modal-new-lead-success-message">Succesfully created new lead. You may close this window.</span>
          </b-alert>
          <div class="row">
            <div class="col-lg-4 col-md-6 u-mb-xsmall">
              <span class="c-toolbar__state-title u-text-capitalize">Contact Name</span>
              <ValidationProvider name="contact name" rules="required" v-slot="{errors}">
                <input
                  class="form-control"
                  type="text"
                  @input="trimSpace('primaryContactName')"
                  v-model="client.primaryContactName"
                  placeholder
                  id="dashboard-top-nav-new-lead-modal-first-last-name"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-lg-4 col-md-6 u-mb-xsmall">
              <ValidationProvider name="email" v-slot="{errors}" rules="email">
                <span class="c-toolbar__state-title u-text-capitalize">
                  Email
                  <search-duplicate model="email" :search="client.primaryContactEmail" @response="handleErrors" />
                </span>

                <input class="form-control" type="text" @input="trimSpace('primaryContactEmail')" v-model="client.primaryContactEmail" placeholder id="dashboard-top-nav-new-lead-modal-email" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-lg-4 col-md-6 u-mb-xsmall">
              <span class="c-toolbar__state-title u-text-capitalize">Phone</span>
              <ValidationProvider name="phone" rules="required" v-slot="{errors}">
                <input class="form-control" type="text" v-model="client.phone" @input="formatPhoneNumber('phone')" placeholder id="dashboard-top-nav-new-lead-modal-phone" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-12 u-mt-small u-mb-small u-height-2">
              <div class="border-bottom">
                <h5>Company Information</h5>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 u-mb-xsmall">
              <span class="c-toolbar__state-title u-text-capitalize">
                Company
                <search-duplicate model="businessName" :search="client.businessName" @response="handleErrors" />
              </span>

              <input class="form-control" type="text" @input="trimSpace('businessName')" v-model="client.businessName" placeholder id="dashboard-top-nav-new-lead-modal-company-name" />
            </div>
            <div class="col-lg-4 col-md-6 ss--new-quote___modal_module u-mb-small">
              <span class="c-toolbar__state-title u-text-capitalize">Type</span>
              <v-select v-model="selectedClientType" :options="uxClientTypeList" :clearable="false" value="id" label="displayName" id="dashboard-top-nav-new-lead-modal-type"></v-select>
            </div>

            <div class="col-lg-8 col-md-12 u-mb-xsmall">
              <span class="c-toolbar__state-title u-text-capitalize">
                Address
                <search-duplicate model="address" :search="client.primaryAddress" @response="handleErrors" />
              </span>
              <textarea class="form-control" type="text" @input="trimSpace('primaryAddress')" v-model="client.primaryAddress" placeholder id="dashboard-top-nav-new-lead-modal-address" />
            </div>

            <div class="col-md-12 u-mt-small u-mb-small u-height-2">
              <div class="border-bottom">
                <h5>Additional Information</h5>
              </div>
            </div>
            <div class="col-md-8 u-mb-xsmall">
              <span class="c-toolbar__state-title u-text-capitalize">Notes/Comments</span>

              <textarea rows="4" class="form-control" type="text" @input="trimSpace('notes')" v-model="client.notes" placeholder id="dashboard-top-nav-new-lead-modal-notes" />
            </div>
            <div class="col-md-4 u-mb-xsmall">
              <div class="col-md-12 px-0 ss--new-quote___modal_module u-mb-small">
                <span class="c-toolbar__state-title u-text-capitalize">Lead Source</span>
                <v-select v-model="selectedLeadSource" :options="uxLeadSourceList" :clearable="false" value="id" label="displayName" id="dashboard-top-nav-new-lead-modal-lead-source"> </v-select>
              </div>
              <div class="col-md-12 px-0 ss--new-quote___modal_module u-mb-small">
                <span class="c-toolbar__state-title u-text-capitalize">Industry</span>
                <v-select v-model="selectedIndustry" :options="uxIndustryList" :clearable="false" value="id" label="displayName" id="dashboard-top-nav-new-lead-modal-industry"></v-select>
              </div>
            </div>
          </div>
          <!-- row -->
        </div>
      </div>
      <!-- col-md-12-->
      <!--end modal new client -->
    </ValidationObserver>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UtilityString from "@/utilities/strings";
  import hash from "object-hash";
  import SearchDuplicate from "@/components/_universal/utility/search-duplicate.vue";
  const utility = new UtilityString();
  const global = new GlobalServices();
  import EVENTS from "@/constants/events";

  export default {
    data() {
      return {
        EVENT_ACTION: {},
        isSuccesful: false,
        isError: false,
        selectedLeadSource: {displayName: "", id: 0},
        selectedClientType: {displayName: "", id: 0},
        selectedIndustry: {displayName: "", id: 0},
        uxLeadSourceList: [
          {displayName: "Website", id: 1},
          {displayName: "Email", id: 2},
          {displayName: "Referral", id: 3},
        ],
        uxClientTypeList: [],
        uxIndustryList: [],
        client: {},
        errors: [],
      };
    },
    components: {
      SearchDuplicate,
    },
    methods: {
      handleErrors(errObj) {
        if (errObj.error) {
          const idx = this.errors.findIndex((err) => err.propertyName === errObj.propertyName);
          if (idx >= 0) {
            this.errors[idx] = errObj;
          } else {
            this.errors.push(errObj);
          }
        } else {
          const idx = this.errors.findIndex((err) => err.propertyName === errObj.propertyName);
          if (idx >= 0) {
            this.errors.splice(idx, 1);
          }
        }
      },
      async onSave(data) {
        this.$refs.formNewClient.validate().then(async (success) => {
          if (!success || this.errors.length > 0) {
            return;
          }

          this.client.moduleId = MODULE.LEADS.ModuleId;
          this.client.industryId = this.selectedIndustry.id;
          this.client.clientTypeId = this.selectedClientType.id;
          this.client.intleadsourceId = this.selectedLeadSource.id;

          //Remove all spaces from properties
          this.client = utility.trimObjectPropertySpace(this.client);

          //Generate a Hash from the data to be submitted
          this.client.hash = hash(this.client);

          let result = await global.createNewActivity(MODULE.LEADS.ActionId, this.client);

          if (result.success) {
            this.isSuccesful = true;
            this.isError = false;
            this.client = {};

            this.$refs.formNewClient.reset();
          } else {
            this.isSuccesful = false;
            this.isError = true;
          }

          setTimeout(() => {
            this.isSuccesful = false;
            this.isError = false;

            this.$root.$emit(this.EVENT_ACTION.DASHBOARD.Update);
          }, 30000);
        });
      },
      formatPhoneNumber(propertyName) {
        this.client[propertyName] = utility.formatPhoneNumber(this.client[propertyName]);
      },
      trimSpace(propertyName) {
        if (this.timeout) clearTimeout(this.timeout);

        //Only clear space after user stops typing
        this.timeout = setTimeout(() => {
          //Trim Space from poperty
          this.client[propertyName] = utility.trimSpace(this.client[propertyName]);
        }, 1500);
      },
      async initializeData() {},
      async setInitialDefaults() {
        this.uxClientTypeList = await global.getUXDropDown(MODULE.CLIENTS.UX.ClientType, MODULE.SALES.ModuleId);

        this.uxIndustryList = await global.getUXDropDown(MODULE.CLIENTS.UX.Industry);

        //Set client Type default selected value
        this.selectedClientType = global.setDropDownDefault(this.uxClientTypeList);

        //Lead source
        this.selectedLeadSource = global.setDropDownDefault(this.uxLeadSourceList);

        //Set client Type default selected value
        this.selectedIndustry = {displayName: "Residential", id: 17};
        //global.setDropDownDefault(this.uxIndustryList);
      },
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      await this.initializeData();

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.LEADS.New, this.onSave);
    },
    async mounted() {
      await this.setInitialDefaults();

      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page(this.EVENT_ACTION.LEADS.View);
      // }
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.LEADS.New, this.onSave);
    },
  };
</script>
