const APP = {
  UI_TYPE: {
    Modal: 1,
    NewTab: 2,
    ModalIFrame: 3,
  },
  PAGE: {
    Clients: {
      List: "ClientList",
      Details: "ClientDetails",
    },
    Sales: {
      List: "SalesList",
      Details: "SalesDetails",
    },
    Projects: {
      List: "ProjectList",
      Details: "ProjectDetails",
    },
    PurchaseOrder: {
      List: "PurchaseOrderList",
      Details: "PurchaseOrderDetails",
    },
    Scheduler: {
      List: "SchedulerList",
      Details: "SchedulerDetails",
    },
    Billing: {
      List: "InvoiceList",
      Details: "InvoiceDetails",
    },
    Payment: {
      List: "PaymentList",
      Details: "PaymentDetails",
    },
    Inventory: {
      List: "InventoryList",
      Details: "InventoryDetails",
    },
    Pricing: {
      List: "PricingList",
      Details: "PricingDetails",
    },
    Suppliers: {
      List: "SupplierList",
      Details: "SupplierDetails",
    },
    Templates: {
      List: "TemplateList",
      Details: "TemplateDetails",
    },
    Pages: {
      List: "PagesList",
      Details: "PagesDetails",
      New: "PagesNew",
    },
    Employee: {
      List: "EmployeesList",
      Details: "EmployeesDetails",
    },
  },
  MODAL: {
    Location: "Location",
    Contacts: "Contacts",
    Equipment: "Equipment",
    Documents: "Documents",
    BillingRates: "BillingRates",
    AccountsReceivable: "AccountsReceivable",
    Notes: "Notes",
    History: "History",
    Terms: "Terms",
    AdditionalOptions: "AdditionalOptions",
    Materials: "Materials",
    InvoiceItems: "InvoiceItems",
  },
};

export default APP;
