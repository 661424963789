import Vue from "vue";
// import moment from "moment";
class AuthService {
  private static _instance: AuthService;

  private constructor() {}

  getQueryString(): string {
    if (typeof Vue.prototype.$auth != "undefined") {
      if (typeof Vue.prototype.$auth.user != "undefined") {
        return (
          "?t=" +
          Vue.prototype.$auth.user["https://snapsuite_tenantId"] +
          "&u=" +
          Vue.prototype.$auth.user["https://snapsuite_u"] +
          "&s=Jupiter" +
          "&uid=" +
          Vue.prototype.$auth.user["https://snapsuite_userId"] +
          "&userID=" +
          Vue.prototype.$auth.user["https://snapsuite_userId"]
          // "&timezone=" +
          // moment().format("Z")
        );
      }
    }

    return "";
  }

  //Special method to check if the current tenant is SnapSuite
  isSnapSuite() {
    let t = this.getAuthObject().t;
    let snapsuiteTenantId = "0476d6ff-8e21-48fd-9386-b8f4fd14178a";

    if (t == snapsuiteTenantId) return true;
    else return false;
  }

  getAuthObject() {
    if (typeof Vue.prototype.$auth != "undefined") {
      if (typeof Vue.prototype.$auth.user != "undefined") {
        return {
          t: Vue.prototype.$auth.user["https://snapsuite_tenantId"],
          u: Vue.prototype.$auth.user["https://snapsuite_u"],
          uid: Vue.prototype.$auth.user["https://snapsuite_userId"],
          userid: Vue.prototype.$auth.user["https://snapsuite_userId"],
          s: "Jupiter",
          // timezone: moment().format("Z"),
        };
      }
    }

    return null;
  }

  getAuthSchedulerObject() {
    if (typeof Vue.prototype.$auth != "undefined") {
      if (typeof Vue.prototype.$auth.user != "undefined") {
        return {
          t: Vue.prototype.$auth.user["https://snapsuite_tenantId"],
          u: Vue.prototype.$auth.user["https://snapsuite_u"],
          projectUniqueId: Vue.prototype.$auth.user["https://snapsuite_u"],
          uid: Vue.prototype.$auth.user["https://snapsuite_userId"],
          userid: Vue.prototype.$auth.user["https://snapsuite_userId"],
          s: "Jupiter",
          timezone: "-05:00",
        };
      }
    }

    return null;
  }

  static createInstance() {
    AuthService.getInstance();
  }

  static getInstance() {
    return this._instance || (this._instance = new this());
  }
}

export default AuthService;
