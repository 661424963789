<template>
  <div class="">
    <div v-if="$isDev && tableListData.length > 9 && isModuleEnabled" :class="['d-sm-flex', dataObj.actionButton ? 'section-pull-top' : 'section-fix-top']">
      <div class="ml-auto col-lg-4 col-md-6 pl-0">
        <input class="form-control h-42" type="text" v-model="searchTab" :placeholder="`Search ${dataObj.title}`" />
      </div>

      <div v-if="$route.name !== 'extended-list'" class="h-41">
        <a class="btn c-btn-outlined-gray" href="#" @click.prevent="openNewTab()">
          <i class="fa fa-expand"></i>
        </a>
      </div>
    </div>

    <b-card no-body>
      <div class="table-responsive table-responsive-md w-auto dashboard-table">
        <table class="table" :id="`${tableId}-tab-datatable`">
          <thead class="c-table__head c-table__head--slim">
            <tr class="c-table__row">
              <th v-if="dataObj.editButton" class="c-table__cell--head no-sort align-middle w-100px"></th>
              <th v-if="$route.name === 'purchase-order-details'" class="c-table__cell--head no-sort align-middle w-100px">Received</th>
              <!-- Table Header -->
              <th
                v-for="header in listDataObj.table.header"
                :key="header.id"
                class="c-table__cell c-table__cell--head"
                :class="{
                  'sort-class': header.isSortable,
                  'no-sort': !header.isSortable,
                  'text-center': header.rightAlign,
                }"
              >
                <template v-if="header.isSortable">
                  <div class="d-flex align-items-end">
                    <a :id="'table-header-' + header.title" v-if="header.isSortable" href="#">{{ header.title }}</a>
                    <div class="d-flex flex-column ml-2 small clickable">
                      <i class="fa fa-chevron-up"></i>
                      <i class="fa fa-chevron-down"></i>
                    </div>
                  </div>
                </template>

                <template v-else>{{ header.title }}</template>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Table Row -->
            <template v-if="tableListData.length > 0">
              <tr v-for="data in tableListData" :key="data.guid" class="c-table__row" :class="{rowSelected: checked}">
                <td v-if="dataObj.editButton" class="align-middle">
                  <!-- Edit Link -->
                  <a @click.prevent="onEditLinkClicked(data)" class="edit-link" target="_blank" :href="dynamicURL(data)" :id="`${tableId}-edit-button-row-id-${data.id}`">
                    <span v-if="data.editText"> <i class="fa fa-picture-o" aria-hidden="true"></i>&nbsp;{{ data.editText }}</span>
                    <span v-else>Edit</span>
                  </a>
                </td>

                <!-- Checkbox for PO -->
                <td v-if="$route.name === 'purchase-order-details'" class="align-middle">
                  <div class="c-choice c-choice--checkbox mb-0">
                    <input class="c-choice__input" :id="`tablecheck${data.id}`" name="checkboxes" type="checkbox" v-model="data.isReceived" @change="updateIsReceived(data)" />
                    <label class="c-choice__label" :for="`tablecheck${data.id}`"></label>
                  </div>
                </td>

                <!-- Standard Columns -->
                <td v-for="header in listDataObj.table.header" :key="header.id" class="align-middle" :class="{'text-right': header.rightAlign}">
                  <span v-html="tableColumnDataLookup(data, header.columnName)"></span>
                </td>
                <td>
                  <i
                    v-if="tableColumnDataLookup(data, 'isDeletable') === true || (tableColumnDataLookup(data, 'isDeletable') === null && isComponentAllowedToDelete)"
                    @click="deleteItem(data)"
                    class="fa fa-trash u-text-danger u-text-larger d-block clickable"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="c-table__row">
                <td class="align-middle text-center" :colspan="listDataObj.table.header.length + 2">No data found</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
  // import {VueAccordion, VueAccordionItem} from "vue-accordion-styless";

  import ApplicationService from "@/services/application";
  import EVENTS from "@/constants/events";
  import APPPLICATION from "@/constants/application";
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import {debounce} from "lodash/function";
  let global = new GlobalServices();
  const app = new ApplicationService();

  export default {
    mixins: [editMixin],
    props: {
      dataObj: {
        type: Object,
        required: false,
      },
      //Array list of results
      listDataObj: {
        type: Object,
        required: true,
      },
      tableId: {
        type: String,
        default: "datatable",
      },
    },
    data() {
      return {
        APP: {},
        EVENT_ACTION: {},
        dispatchDate: new Date().toISOString().slice(0, 10),
        isDispatching: false,
        isExportingStarted: false,
        downloadLink: "",
        isExporting: false,
        exportFileResponse: "",
        dispatchResponseVisible: false,
        displayDispatchResponse: "",
        selectedTechnician: null,
        selectedAction: null,
        selectedActionId: 0,
        technicianList: [],
        isClose: true,
        checked: null,
        alignRight: true,
        pageTitle: null,
        searchTab: null,
      };
    },
    watch: {
      searchTab() {
        this.debounceSearch();
      },
    },
    computed: {
      tableListData() {
        let list = this.listDataObj.table;

        return list.data;
      },
      isModuleEnabled() {
        const enabledModules = [
          MODULE.EQUIPMENT.ActionId,
          MODULE.PROJECTS.Equipment.ActionId,
          MODULE.MATERIALS.ActionId,
          MODULE.LOCATION.ActionId,
          MODULE.TIMETRACKING.ActionId,
          MODULE.TASKS.ActionId,
        ];
        return enabledModules.findIndex((module) => module === this.dataObj.param.actionId) >= 0;
      },
      isComponentAllowedToDelete() {
        const disabledDeleteModules = [MODULE.PAYMENT.ActionId];

        return disabledDeleteModules.findIndex((module) => module === this.dataObj.param.actionId);
      },
    },
    methods: {
      debounceSearch: debounce(function() {
        this.searchQuery();
      }, 1000),
      async searchQuery() {
        const results = await global.getSearchTabTableDataByActionId({...this.dataObj.param, q: this.searchTab});
        if (results) {
          this.$emit("update", results);
        }
      },
      openNewTab() {
        let url = `${window.location.origin}/tab/details/${this.dataObj.param.parentActionId}/${this.dataObj.param.id}/${this.dataObj.title.replace("/", " ").toLowerCase()}/${
          this.dataObj.param.actionId
        }`;
        window.open(url);
      },
      deleteItem(data) {
        this.updateField(
          "IsDeleted", //PropertyName
          true, //Property Value
          "Deleted Item", //Display Name
          "deleted", //Display Value
          null, //dataObjPropertyName?,
          null, //previousDisplayValue?,
          null, //previousValueId?,
          null, //isModal?,
          data.id, //Current Item Row Id
          null, // overActionId
          null, // ignore
          null, // parentActionId
          this.$route.name === "purchase-order-details" ? this.dataObj.param.id : null // override parentID for purchase order
        );
      },
      //Conditionally generate URL for open in new tab
      dynamicURL(data) {
        if (data && this.dataObj && this.dataObj.editButton) {
          //Get the action button properties
          let editAction = this.dataObj.editButton;

          //Set additional information based on the currently selected item
          editAction.data = data;
          editAction.dataObj = this.dataObj;

          return editAction.url;
        }

        return "#";
      },
      onEditLinkClicked(data) {
        //Get the action button properties
        let editAction = this.dataObj.editButton;

        //Set additional information based on the currently selected item
        editAction.data = data;
        editAction.dataObj = this.dataObj;

        //Pass action to global navigation action handler...
        app.actionNavigation(editAction);
      },

      tableColumnDataLookup(data, columnName) {
        return data[columnName];
      },
      onActionSelected() {},
      toggle() {
        this.isClose = !this.isClose;
      },
      updateIsReceived(data) {
        this.updateField(
          "IsReceived", //PropertyName
          data.isReceived, //Property Value
          "Is Received", //Display Name
          "received: " + data.isReceived, //Display Value
          null, //dataObjPropertyName?,
          null, //previousDisplayValue?,
          null, //previousValueId?,
          null, //isModal?,
          data.id //Current Item Row Id
        );
      },
    },
    async mounted() {
      this.EVENT_ACTION = EVENTS;
      this.APP = APPPLICATION;

      this.dataObj.actionId = this.dataObj.param.actionId;
    },
  };
</script>
