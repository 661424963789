import { render, staticRenderFns } from "./dispatch-modal.vue?vue&type=template&id=1cb388f7&scoped=true&"
import script from "./dispatch-modal.vue?vue&type=script&lang=ts&"
export * from "./dispatch-modal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb388f7",
  null
  
)

export default component.exports