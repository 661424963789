<template>
  <div class="customFooterModalBodyEmail">
    <div v-if="emailData" class="row">
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">To</span>
        <ValidationProvider v-slot="v" name="To" rules="required">
          <input class="form-control" type="text" v-model="emailData.toEmails" id="email-modal-email-tab-to" />
          <span class="text-danger">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">CC</span>
        <input class="form-control" type="text" v-model="emailData.ccEmails" id="email-modal-email-tab-cc" />
      </div>

      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Subject</span>
        <ValidationProvider v-slot="v" name="Subject" rules="required">
          <input class="form-control" type="text" v-model="emailData.subject" id="email-modal-email-tab-subject" />
          <span class="text-danger">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-md-6 mb-3">
        <span class="c-toolbar__state-title u-text-capitalize">Templates</span>
        <v-select
          id="email-modal-email-tab-templates"
          :options="templatesList"
          placeholder="Select Template"
          v-model="selectedTemplate"
          label="displayName"
          @input="onSelectTemplate"
          :clearable="false"
        ></v-select>
      </div>
      <div v-if="!isSnippetsLoading" class="col-md-12 mt-2 email-editor">
        <!-- <pre>{{ modalStatus }}</pre> -->
        <AiDropdown v-if="$isDev" @summarize="summarize" />
        <editor id="email-modal-email-tab-text-editor" :content="content" @position="onEditorPosition" :toolbar-options="toolBar" class="email-body" @onEditChange="onEditorChange" />
      </div>
      <!-- 
      <div class="col-12">
        <pre>{{ modalStatus }}</pre>
      </div> -->
    </div>
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";
  import editor from "@/components/_universal/global/editor.vue";
  import AiDropdown from "../_universal/ai-dropdown.vue";
  import {template} from "lodash";

  // import TemplateServices from "@/services/templates";
  // const templateApi = new TemplateServices();
  const global = new GlobalServices();
  export default {
    props: {
      templatesList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        content: null,
        selectClientInformation: null,
        clientInformationList: [
          {
            label: "Group One",
            options: [
              {value: {C: "3PO"}, text: "Option with object value"},
              {value: {R: "2D2"}, text: "Another option with object value"},
              {value: "John Doe", text: "Name"},
            ],
          },
          {
            label: "Group One",
            options: [
              {value: {C: "3PO"}, text: "Option with object value"},
              {value: {R: "2D2"}, text: "Another option with object value"},
            ],
          },
        ],
        insertTextObj: {
          index: 0,
          length: 0,
        },
        isSnippetsLoading: true,
        toolBar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
          [{size: ["small", false, "large", "huge"]}], // custom dropdown
          [{header: [1, 2, 3, 4, 5, 6, false]}],
          [{align: []}],

          // get icon unicode from here https://fontawesome.com/v5/cheatsheet
          // [{snippets: ["--- \uf007 Contacts  ---", "Name", "Name", "Name", "--- \uf041 Location ---", "Location ", "Location "]}], // my custom dropdown
          /*
           [
            {
              snippets: {
                label: "My Dropdown",
                icon: "fa fa-star",
                values: [
                  {label: "Option 1", value: "option1"},
                  {label: "Option 2", value: "option2"},
                  {label: "Option 3", value: "option3"},
                ],
                onSelect: function(value) {
                  // handle custom dropdown select event
                },
              },
            },
          ], // my custom dropdown
          */
        ],
      };
    },

    components: {
      // DatePicker
      editor,
      AiDropdown,
    },
    computed: {
      modalStatus() {
        return this.$store.getters.getModalStatus;
      },
      emailData: {
        get() {
          return this.$store.getters.getEmailData;
        },
        set(value) {
          this.$store.commit("setEmailData", value);
        },
      },
      templateDetails: {
        get() {
          return this.$store.getters.getTemplateDetails;
        },
        set(value) {
          this.$store.commit("setTemplateDetails", value);
        },
      },
      selectedTemplate: {
        get() {
          return this.$store.getters.getTemplate;
        },
        set(value) {
          this.$store.commit("setTemplate", value);
        },
      },
      computedContent() {
        return this.$store.getters.getContent;
      },
    },
    watch: {
      templatesList: {
        deep: true,
        immediate: true,
        handler() {
          this.initData();
        },
      },
    },
    methods: {
      onEditorChange(value) {
        this.$store.commit("setContent", value);
      },
      onEditorPosition(handler) {
        this.insertTextObj = handler;
      },
      async initData() {
        this.content = this.emailData.body;
        this.$store.commit("setContent", this.emailData.body);
        if (this.emailData && this.emailData.defaultTemplatedId && this.templatesList.length > 0) {
          const template = this.templatesList.find((t) => t.id === parseInt(this.emailData.defaultTemplatedId));
          this.$store.commit("setTemplate", template ? template : null);
        }
      },
      async loadSnippets() {
        const snippets = await global.getSnippets(this.modalStatus.data.id, this.modalStatus.actionId);
        // loop snippets
        for (const snip of snippets) {
          this.toolBar.push([snip]);
        }
        this.isSnippetsLoading = false;
      },
      async onSelectTemplate(e) {
        if (!e) return;

        const templateDetails = await global.getEmailTemplatePreset(this.modalStatus.data.id, e.id, this.modalStatus.actionId, this.modalStatus.moduleId);
        this.content = templateDetails.description;
        this.$store.commit("setContent", templateDetails.description);
        this.$store.dispatch("setTemplateDetails", templateDetails);
      },
      onSelectSnippet(e) {
        const result = this.replaceOrInsertText(this.insertTextObj, this.content, e);
        this.$store.commit("setContent", result);
      },

      async summarize() {
        const result = await global.magicWand({body: this.content});
        this.$store.commit("setContent", result.body);
      },
      replaceOrInsertText(spliceObj, originalString, replacement) {
        const prefix = originalString.slice(0, spliceObj.index);
        const suffix = originalString.slice(spliceObj.index + spliceObj.length);
        return prefix + replacement + suffix;
      },
    },
    created() {
      this.loadSnippets();
    },
  };
</script>
<style lang="scss">
  .email-editor {
    .ai-dropdown {
      position: absolute;
      top: 0px;
      right: 15.5px;
      background: #6e0677;
      border-top-right-radius: 8px;
      box-shadow: 1px 1px 4px 0px #00000021;

      img {
        width: 40px;
        height: 40px;
        padding: 6px;
      }
      button {
        padding: 0px !important;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  span[data-value*="---"]:not(.ql-picker-label) {
    color: #6e0677 !important;
    font: normal normal normal 14px/1 FontAwesome;
    pointer-events: none;
  }
</style>
