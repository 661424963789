<template>
  <ValidationObserver ref="formNewTemplates">
    <div class="mx-2 mb-2">
      <div class="row">
        <div v-if="isSuccesful" class="col-md-12 mb-2">
          <b-alert show class="mb-2" variant="success">
            <i class="fa fa-check-circle mr-2"></i>
            <span class="sub-title mr-2" id="modal-new-template-success-message">Succesfully created new template. You
              may
              close this window.</span>
          </b-alert>
        </div>
        <div class="col-lg-6 col-md-12 mb-2">
          <ValidationProvider v-slot="{ errors }" name="Template Name" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Template Name</span>
            <input class="form-control" type="text" v-model="formData.TypeName" id="new-template-modal-template-name" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-lg-6 col-md-12 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Description</span>
          <input class="form-control" type="text" id="new-template-modal-description" v-model="formData.Notes" />
        </div>
        <div class="col-lg-3 col-md-6 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Unit Price</span>
          <div class="input-group input-with-dollar-sign">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input class="form-control" type="text" id="new-template-modal-unit-price" v-model="formData.Amount" />
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-2">
          <ValidationProvider v-slot="{ errors }" name="Module" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Module</span>
            <v-select :options="uxModules" v-model="formData.IntModuleID" id="new-template-modal-module"
              :reduce="(module) => module.id" :clearable="false" label="displayName"></v-select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-lg-3 col-md-6 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Category</span>
          <v-select :options="uxCategory" v-model="formData.IntCategoryID" id="new-template-modal-category"
            :reduce="(cat) => cat.id" :clearable="false" label="displayName"></v-select>
        </div>
        <div class="col-lg-3 col-md-6 mb-2">
          <ValidationProvider v-slot="{ errors }" name="Type" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Type</span>
            <v-select :options="typeList" v-model="formData.IntTemplateTypeID" id="new-template-modal-type"
              :clearable="false" label="displayName" :reduce="(type) => type.id"></v-select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6 d-flex ml-2 mb-2 mt-3">
          <div class="mr-5">
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" id="radio1" name="radio1" type="radio" :value="true"
                v-model="formData.isHTML" />
              <label class="c-choice__label" for="radio1">Text Editor</label>
            </div>
          </div>
          <div class="mr-5">
            <div class="c-choice c-choice--radio mb-0">
              <input class="c-choice__input" id="radio2" name="radio2" type="radio" :value="false"
                v-model="formData.isHTML" />
              <label class="c-choice__label" for="radio2">Plain Text</label>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-2">
          <editor v-if="formData.isHTML" :content="formData.Description" id="new-template-modal-description-editor"
            @onEditChange="(html) => (formData.Description = html)" />
          <textarea class="form-control" v-else v-model="formData.PlainText" cols="30" rows="5"
            id="new-template-modal-description-plain" />
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import UtilityString from "@/utilities/strings";
import hash from "object-hash";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import editor from "@/components/_universal/global/editor.vue";
const global = new GlobalServices();
const utility = new UtilityString();
export default {
  name: "NewTemplates",
  data() {
    return {
      apiErrors: {
        hasError: false,
        message: null,
      },
      isSuccesful: false,
      mode: "rich",
      uxModules: [],
      uxCategory: [],
      formData: {
        actionId: MODULE.TEMPLATES.ActionId,
        moduleId: MODULE.TEMPLATES.ModuleId, // this is used for the whole template
        TypeName: null,
        Notes: null,
        Amount: 0,
        IntModuleID: null, // this is used for which module the template should be added
        IntCategoryID: null,
        Description: null,
        PlainText: null,
        isHTML: true,
        IntTemplateTypeID: null,
      },
      typeList: [
        { displayName: "Email Template", id: 14 },
        { displayName: "Quote Terms & Condition", id: 15 },
        { displayName: "Document Template", id: 5 },
      ],
    };
  },
  components: {
    editor,
  },
  methods: {
    async initializeData() {
      let uxModules = await global.getUXDropDown(MODULE.TEMPLATES.UX.Modules);
      this.uxModules = uxModules || [];
      let uxCategory = await global.getUXDropDown(MODULE.PROJECTS.Type);
      this.uxCategory = uxCategory || [];
    },
    getSelectedDropDownValueOverride(listing, field) {
      let item = null;
      if (listing === "type") {
        item = this.typeList.find((data) => {
          return data.id === this.currentDataObj.details[field];
        });
      }
      return item;
    },
    async onSave() {
      try {
        this.apiErrors = {
          hasError: false,
          message: null,
        };
        const isValid = await this.$refs.formNewTemplates.validate();

        if (isValid) {
          //Remove all spaces from properties
          this.formData = utility.trimObjectPropertySpace(this.formData);

          //Generate a Hash from the data to be submitted
          this.formData.hash = hash(this.formData);

          let result = await global.createNewActivity(MODULE.TEMPLATES.ActionId, this.formData);

          if (result.success) {
            this.isSuccesful = true;
            this.formData = {
              actionId: MODULE.TEMPLATES.ActionId,
              moduleId: MODULE.TEMPLATES.ModuleId,
              TypeName: null,
              Notes: null,
              Amount: 0,
              IntModuleID: null,
              IntCategoryID: null,
              Description: null,
              PlainText: null,
              isHTML: true,
              IntTemplateTypeID: null,
            };
          } else {
            this.isSuccesful = false;
          }
        }
      } catch (e) {
        this.apiErrors = {
          hasError: true,
          message: e,
        };
      }
    },
  },

  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.initializeData();

    //Subscribe to save button "Dispatch" click event on modal box
    this.$root.$on("Save" + this.EVENT_ACTION.TEMPLATES.New, this.onSave);
  },

  beforeDestroy() {
    //Unscribe from save button "Dispatch" click event on modal box
    this.$root.$off("Save" + this.EVENT_ACTION.TEMPLATES.New, this.onSave);
  },
};
</script>
