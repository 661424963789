import MODULE from "@/constants/modules";
const Decimal = require("decimal.js");
export default {
  methods: {
    capitalizeString(str) {
      const str2 = str.charAt(0).toUpperCase() + str.slice(1);
      return str2;
    },
    formatAmount(amount) {
      return amount.toFixed(this.getDecimalPoint(amount));
    },
    getDecimalPoint(amount) {
      const amountLength = (amount.toString().split(".")[1] || []).length;
      return amountLength === 0 ? 2 : amountLength > 4 ? 4 : amountLength;
    },
    formatCurrency(amount) {
      if (!amount) return `$0.00`;
      return `$${this.getFlooredFixed(amount)}`;
    },
    getFlooredFixed(amount, hasComma = true) {
      if (amount === null || typeof amount === "undefined") return 0;
      const parts = amount.toString().split(".");
      if (!parts[1] || (parts[1] && parseInt(parts[1]) === 0)) {
        parts[1] = "00"; // default 00 if no amount
      } else if (parts[1] && parts[1].length === 1) {
        parts[1] = `${parts[1]}0`;
      } else {
        parts[1] = parts[1].slice(0, 2); // Keep only two decimal places
      }
      return hasComma ? parts.join(".").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parts.join(".");
    },
    getInvoiceRoundinValue(roundingOption, value) {
      if (typeof value === "undefined" || !value) return 0;

      if (roundingOption === MODULE.INVOICE.ROUNDING.PRECISE) {
        return parseFloat(this.getFlooredFixed(value, false));
      } else {
        const val = typeof value === "string" ? value.replace("$", "").replace(",", "") : value;

        const rounded = (Math.round(val * 100) / 100).toFixed(2);
        return this.getFlooredFixed(rounded, false);
      }
    },
    decimalSumBy(collection, iteratee, roundingOption) {
      return collection.reduce((sum, item) => {
        const amount = this._.isFunction(iteratee) ? iteratee(item) : this._.get(item, iteratee);
        const value = this.getInvoiceRoundinValue(roundingOption, amount);
        return sum.plus(new Decimal(value ? value : 0));
      }, new Decimal(0));
    },
    decimalSumByTax(collection, iteratee, roundingOption, tax) {
      return collection.reduce((sum, item) => {
        const amount = this._.isFunction(iteratee) ? iteratee(item) : this._.get(item, iteratee);
        const value = this.getInvoiceRoundinValue(roundingOption, amount);
        const currentTax = new Decimal(tax);
        const result = currentTax.times(new Decimal(value ? value : 0));
        const finalResult = new Decimal(this.getInvoiceRoundinValue(roundingOption, result.toString()));
        return sum.plus(finalResult);
      }, new Decimal(0));
    },
  },
};
