<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-4 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Client Rate Group</span>
        <v-select :options="['abc', 'abc']" label="displayName"></v-select>
      </div>
      <div class="col-md-8 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Select Client Rate</span>
        <v-select :options="['abc', 'abc']" label="displayName"></v-select>
      </div>
      <div class="col-md-4 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Qty</span>
        <input class="form-control" type="text" placeholder />
      </div>
      <div class="col-md-8 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Selling Price</span>
        <input class="form-control" type="text" placeholder />
      </div>
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Amount</span>
        <v-select :options="['abc', 'abc']" label="displayName"></v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {}
};
</script>
