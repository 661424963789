<template>
  <div class="position-relative" style="top: -10px">
    <div class="sticky-tabs">
      <b-tabs content-class="mt-3">
        <b-tab title="New Board" active>
          <NewBoardTab />
        </b-tab>
        <b-tab title="Existing Board">
          <ExistingBoardTab />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
    name: "ManageBoard",
    components: {
        NewBoardTab: () => import("@/components/planner/new-board-tab.vue"),
        ExistingBoardTab: () => import("@/components/planner/existing-board-tab.vue"),
    }
  };
</script>