<template>
  <div class="input-group mb-2">
    <div class="input-group-prepend mr-1">
      <v-select :options="countryList" v-model="selectedCountry" id="component-country-dropdown" @input="onCountrySelect" :clearable="false">
        <template slot="option" slot-scope="option"> ({{ option.code }}) {{ option.name }} </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.code }}
        </template>
      </v-select>
    </div>
    <input v-bind="$attrs" type="text" class="form-control" id="inlineFormInputGroup" :placeholder="phoneMask" :value="value" @input="updateMobileNumber" v-mask="phoneMask" />
  </div>
</template>

<script>
  import UtilityString from "@/utilities/strings";
  const countries = require("@/utilities/country-list.json");
  const utility = new UtilityString();
  export default {
    props: {
      value: {
        default: "",
        type: [String, Number],
      },
      isRequired: {
        type: Boolean,
        default: true,
      },
      countrySelected: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        countryList: [],
        selectedCountry: {name: "United States", iso: "US", code: "+1", mask: "(###) ###-####"},
        content: this.value,
      };
    },
    watch: {
      countrySelected: {
        immediate: true,
        handler(e) {
          const defaultCountry = this.countryList.find((c) => c.iso === "US");
          this.selectedCountry = this.countrySelected && Object.keys(this.countrySelected).length > 0 ? this.countrySelected : defaultCountry;
        },
      },
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
      phoneMask() {
        if (!this.selectedCountry) return "### ###-####";
        if (Array.isArray(this.selectedCountry.mask)) {
          return this.selectedCountry.mask[0];
        } else {
          return this.selectedCountry.mask;
        }
      },
    },
    methods: {
      onCountrySelect() {
        this.$emit("countrySelect", this.selectedCountry);
      },
      updateMobileNumber(event) {
        this.$emit("input", event.target.value);
      },
    },
    mounted() {
      this.countryList = countries.sort((a, b) => {
        let fa = parseInt(utility.removeCharactersForMobile(a.code));
        let fb = parseInt(utility.removeCharactersForMobile(b.code));
        return fa - fb;
      });
      // const defaultCountry = this.countryList.find((c) => c.iso === "US");
      // this.selectedCountry = Object.keys(this.countrySelected).length > 0 ? this.countrySelected : defaultCountry;
      // if (Object.keys(this.countrySelected).length === 0) this.onCountrySelect();
    },
  };
</script>
