<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="mt-3" class="position-relative" style="top:-15px">
      <b-tab title="Basic" active id="slidepanel-client-details-tabs-equipment-basic">
        <div class="row">
          <!-- Top -->
          <div class="col-md-12">
            <!-- <div v-if="!modalObj.isInEditMode" class="row">
                <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
                  <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
                    Select Equipment
                  </div>
                  <v-select v-model="selectedEquipment" :options="uxEquipmentList" @input="onUpdateSelection" code="id" label="displayName" :clearable="false"></v-select>
                </div>
              </div> -->

            <div class="row">
              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Equipment Name</span>
                <ValidationProvider name="equipment_name" rules="required" v-slot="{errors}">
                  <input
                    class="form-control"
                    type="text"
                    id="slidepanel-client-details-tabs-equipment-basic-equipment-name"
                    v-model="modalDataDetails.Name"
                    @keyup="
                      updateFieldSimplified(
                        'Name', //PropertyName
                        modalDataDetails.Name, //Property Value
                        'Equipment Name', //Display Name
                        true
                      )
                    "
                  />
                  <span class="text-danger">{{ errors.length > 0 ? "Equipment Name is required." : "" }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Location</span>
                <v-select
                  id="slidepanel-client-details-tabs-equipment-basic-location"
                  :value="getSelectedDropDownValue('uxClientLocation', 'IntInventoryLocationID', true)"
                  :options="uxLocationList"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Location', //Display Name
                      'uxClientLocation', //Dropdown List Object
                      'IntInventoryLocationID', //Edit Details Property Name
                      true
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>
              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Category</span>
                <v-select
                  id="slidepanel-client-details-tabs-equipment-basic-category"
                  :options="modalUXData.uxInventoryCategory"
                  :value="getSelectedDropDownValue('uxInventoryCategory', 'IntInventoryCategoryID', true)"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Category', //Display Name
                      'uxInventoryCategory', //Dropdown List Object
                      'IntInventoryCategoryID', //Edit Details Property Name
                      true
                    )
                  "
                  label="displayName"
                ></v-select>
              </div>
              <div class="col-md-12 border-bottom u-mt-small u-mb-small u-height-2"></div>
            </div>
          </div>

          <!-- Middle -->

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Description</span>
                <input
                  class="form-control"
                  id="slidepanel-client-details-tabs-equipment-basic-description"
                  type="text"
                  v-model="modalDataDetails.Description"
                  @keyup="
                    updateFieldSimplified(
                      'Description', //PropertyName
                      modalDataDetails.Description, //Property Value
                      'Description', //Display Name
                      true
                    )
                  "
                />
              </div>

              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Make</span>
                <input
                  class="form-control"
                  id="slidepanel-client-details-tabs-equipment-basic-make"
                  type="text"
                  v-model="modalDataDetails.Make"
                  @keyup="
                    updateFieldSimplified(
                      'Make', //PropertyName
                      modalDataDetails.Make, //Property Value
                      'Make', //Display Name
                      true
                    )
                  "
                />
              </div>
              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Model Number</span>
                <input
                  class="form-control"
                  id="slidepanel-client-details-tabs-equipment-basic-model-number"
                  type="text"
                  v-model="modalDataDetails.ModelNumber"
                  @keyup="
                    updateFieldSimplified(
                      'ModelNumber', //PropertyName
                      modalDataDetails.ModelNumber, //Property Value
                      'Model Number', //Display Name
                      true
                    )
                  "
                />
              </div>

              <div class="col-md-12 my-2">
                <b-form-checkbox class="mb-2" id="checkbox-1" name="checkbox-1" v-model="shouldAutoAddToJob">
                  <span class="ml-2">Automatically add equipment to Job/Work Order when created.</span>
                </b-form-checkbox>

                <b-form-checkbox class="mb-2" id="checkbox-2" name="checkbox-2" v-model="isOutOfService">
                  <span class="ml-2">This equipment is out-of-service/not in use.</span>
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <!-- Address & Notes -->
              <div class="col-md-12 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Location on Site</span>
                <input
                  class="form-control"
                  id="slidepanel-client-details-tabs-equipment-basic-location-on-site"
                  type="text"
                  v-model="modalDataDetails.LocationOnSite"
                  @keyup="
                    updateFieldSimplified(
                      'LocationOnSite', //PropertyName
                      modalDataDetails.LocationOnSite, //Property Value
                      'Location on Site', //Display Name
                      true
                    )
                  "
                />
              </div>

              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Serial Number</span>
                <input
                  class="form-control"
                  type="text"
                  id="slidepanel-client-details-tabs-equipment-basic-serial-number"
                  v-model="modalDataDetails.SerialNumber"
                  @keyup="
                    updateFieldSimplified(
                      'SerialNumber', //PropertyName
                      modalDataDetails.SerialNumber, //Property Value
                      'Serial Number', //Display Name
                      true
                    )
                  "
                />
              </div>
              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Manufacturer/Supplier</span>
                <input
                  class="form-control"
                  type="text"
                  id="slidepanel-client-details-tabs-equipment-basic-manufacturer"
                  v-model="modalDataDetails.Manufacturer"
                  @keyup="
                    updateFieldSimplified(
                      'Manufacturer', //PropertyName
                      modalDataDetails.Manufacturer, //Property Value
                      'Manufacturer/Supplier', //Display Name
                      true
                    )
                  "
                />
              </div>
            </div>
          </div>

          <!-- col-md-12-->
          <div class="col-md-12">
            <span class="c-toolbar__state-title u-text-capitalize">Notes/Comments</span>

            <textarea
              class="form-control"
              type="text"
              rows="6"
              id="slidepanel-client-details-tabs-equipment-basic-notes"
              v-model="modalDataDetails.Notes"
              @keyup="
                updateFieldSimplified(
                  'Notes', //PropertyName
                  modalDataDetails.Notes, //Property Value
                  'Location Notes', //Display Name
                  true
                )
              "
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Details" id="slidepanel-client-details-tabs-equipment-details">
        <div class="row">
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Type</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-details-type" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Quantity</span>
            <input class="form-control" id="slidepanel-client-details-tabs-equipment-details-quantity" type="text" />
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Service Rate</span>
            <input class="form-control" id="slidepanel-client-details-tabs-equipment-details-service-rate" type="text" />
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Group</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-details-group" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Install Date</span>
            <date-picker format="DD-MMM-YYYY" id="slidepanel-client-details-tabs-equipment-details-install-date" style="width: 100%;" lang="en"></date-picker>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Last Service Date</span>
            <date-picker format="DD-MMM-YYYY" id="slidepanel-client-details-tabs-equipment-details-last-service-date" style="width: 100%;" lang="en"></date-picker>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Next Service Date</span>
            <date-picker format="DD-MMM-YYYY" id="slidepanel-client-details-tabs-equipment-details-next-service-date" style="width: 100%;" lang="en"></date-picker>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Warranty Expiry Date</span>
            <date-picker format="DD-MMM-YYYY" style="width: 100%;" lang="en" id="slidepanel-client-details-tabs-equipment-details-warranty-expiry-date"></date-picker>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Status</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-details-status" :clearable="false"></v-select>
          </div>
        </div>
      </b-tab>
      <b-tab title="Custom" id="slidepanel-client-details-tabs-equipment-custom">
        <div class="row">
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Filter Size</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-filter-size" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Quantity</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-quantity" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Belt Size</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-belt-size" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Refrigerant</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-refrigerant" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Tonnage</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-tonnage" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Year</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-year" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Area Served</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-area-served" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">BTU</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-BTU" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Type</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-type" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Expense Account</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-expense-account" :clearable="false"></v-select>
          </div>
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Income Account</span>
            <v-select :options="[]" label="displayName" id="slidepanel-client-details-tabs-equipment-custom-income-account" :clearable="false"></v-select>
          </div>
        </div>
      </b-tab>
      <b-tab title="Tenants" id="slidepanel-client-details-tabs-equipment-tenants">
        <div class="c-choice c-choice--checkbox u-mr-small mb-0 border-bottom py-3" v-for="client in clientsList" :key="`client-${client.id}`">
          <input class="c-choice__input" :id="`client-${client.id}`" name="test" type="checkbox" :value="client.id" v-model="clientsSelected" />
          <label class="c-choice__label" :for="`client-${client.id}`">{{ client.displayName }}</label>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";
  let global = new GlobalServices();
  import MODULE from "@/constants/modules";
  import APPPLICATION from "@/constants/application";
  import editMixin from "@/mixin/edit-mixin";

  export default {
    mixins: [editMixin],
    data() {
      return {
        isOutOfService: false,
        shouldAutoAddToJob: false,
        selectedEquipment: null,
        uxEquipmentList: [],
        uxLocationList: [],
        selectedLocation: null,
        tabIndex: 0,
        clientsList: [],
        clientsSelected: [],
      };
    },
    beforeDestroy() {
      this.$root.$off("on-save", this.resetForm);
    },
    mounted() {
      this.$root.$on("on-save", this.resetForm);
    },
    async created() {
      this.APP = APPPLICATION;
      let modalDetails = {};
      let modalUXData = {};
      let module = MODULE.EQUIPMENT;
      let page = this.APP.MODAL.EQUIPMENT;

      //Get edit data
      if (this.modalObj.isInEditMode) {
        //Load Details Details from API
        modalDetails = await global.getEditDetails(module.ActionId, this.modalObj.data.id);
        if (modalDetails.IntClientID) {
          modalDetails.clientId = modalDetails.IntClientID;
        }
        //TODO: Load primary contact on new modal
        //DropDown List Values - Call back function from controller, getTabSection to load related dropdown menu values...
        modalUXData = await this.modalObj.dataObj.uxdata(page);
        if (modalDetails) {
          this.isOutOfService = modalDetails.IsOutOfService ? true : false;
          this.shouldAutoAddToJob = modalDetails.shouldAutoAddToJob ? true : false;
        }
      } else {
        //New Item

        //Set Reference ID
        if (this.$route.name === "client-details") {
          // check if the route name is from client so let set it to module id of client
          modalDetails.moduleId = MODULE.CLIENTS.ModuleId;
        } else {
          modalDetails.moduleId = module.ModuleId;
        }
        modalUXData.uxInventoryCategory = await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryCategory, null, null, null);
        modalDetails.clientId = this.modalObj.data.id;
      }
      await this.$store.dispatch("setModalDataDetailsObject", modalDetails);
      await this.$store.dispatch("setModalUXData", modalUXData);
      await this.initializeData();
    },
    watch: {
      isOutOfService(value) {
        this.updateFieldSimplified("IsOutOfService", value, "Is Out of Service", true);
      },
      shouldAutoAddToJob(value) {
        this.updateFieldSimplified("ShouldAutoAddToJob", value, "Should Auto Add To Job", true);
      },
      tabIndex(value) {
        if (value === 3) {
          // tenants
          this.getClientList();
        }
      },
    },
    methods: {
      resetForm() {
        this.selectedEquipment = null;
      },
      async getClientList() {
        let clientsList = await global.getUXDropDown(MODULE.CLIENTS.ActionId, null, null, null, null, this.modalObj.data.id);
        this.clientsList = clientsList || [];
      },
      // async onUpdateSelection() {
      //   this.modalDataDetails.Name = this.selectedEquipment.displayName;
      //   // if client use MODULE.INVENTORY.ActionId
      //   const item = await global.getEditDetails(MODULE.INVENTORY.ActionId, this.selectedEquipment.id);
      //   this.modalDataDetails.LocationOnSite = item.LocationOnSite;
      //   this.modalDataDetails.Description = item.Description;
      //   this.modalDataDetails.Make = item.Make;
      //   this.modalDataDetails.ModelNumber = item.ModelNumber;
      //   this.modalDataDetails.SerialNumber = item.SerialNumber;
      //   this.modalDataDetails.Manufacturer = item.Manufacturer;
      //   this.modalDataDetails.Notes = item.Notes;
      // },
      async initializeData() {
        //Get Inventory List
        let equipmentList = await global.getUXDropDown(MODULE.INVENTORY.ActionId);
        this.uxEquipmentList = equipmentList || [];
        // location list
        if (this.modalDataDetails && this.modalDataDetails.clientId) {
          let locationList = await global.getUXDropDown(MODULE.CLIENTS.UX.Location, null, null, {
            clientId: this.modalDataDetails.clientId,
            projectId: 0,
          });
          this.uxLocationList = locationList || [];
        }
      },
    },
  };
</script>
