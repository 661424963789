<template>
    <div>
        <b-card class="notification mb-3 mx-2" v-for="activity in data" v-bind:key="activity.id">
            <b-card-text>
                <div class="d-flex">
                    <div>
                        <i :class="[activity.icon, activity.iconBackground, 'notif-icon fa mr-2 mt-1']"></i>
                    </div>
                    <div class="w-100">
                        <p class="mb-0 u-text-bold ss-primary-font-color">{{activity.title}}</p>
                        <p class="text-muted mb-0">{{activity.description}}</p>
                        <span class="text-muted u-text-xsmall d-block">{{activity.date}}</span>
                        <a href="#" class="text-right d-block link"
                            @click.prevent="showModal(EVENT_ACTION.ACTIVITY.ViewDetails, 'Activity Details', activity.id)">View
                            Details</a>
                    </div>
                </div>
            </b-card-text>
        </b-card>
    </div>
</template>
<script>
import EVENTS from "@/constants/events";

export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            EVENT_ACTION: {},
            modalStatus: {
                hideShowModal: false,
                modalName: "",
                isSaveButtonHidden: false,
                modalTitle: "",
                data: {},
            },
        }
    },
    created() {
        this.EVENT_ACTION = EVENTS;
    },
    methods: {
        showModal(paramModalName, modalTitle, id) {
            this.modalStatus.hideShowModal = true;
            this.modalStatus.modalName = paramModalName;
            this.modalStatus.data = {
                id: id,
            };
            this.modalStatus.modalTitle = modalTitle;
            this.modalStatus.isSaveButtonHidden = true;
            this.modalStatus.modalSaveButtonTitle = "";
            this.$store.dispatch("toggleModalStatus", this.modalStatus);
        },
    }
}
</script>
