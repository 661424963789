<template>
  <span class="margin-right-negative"><span v-if="!isCurrency">&nbsp;</span>{{isCurrency ? getFlooredFixed(tweened) : tweened.toFixed(0)}} </span>
</template>
<script>
  import gsap from "gsap";

  export default {
    props: {
      myNumber: {
        type: Number,
        default: 0,
      },
      isCurrency: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      myNumber(value) {
        if (value !== this.tweened) {
          this.reroll();
        }
      },
    },
    data() {
      return {
        tweened: 0,
      };
    },
    methods: {
      reroll() {
        gsap.fromTo(this, {duration: 0.5, tweened: 0}, {duration: 0.5, tweened: parseInt(this.myNumber) || 0});
      },
    },
    mounted() {
      if (this.myNumber) {
        this.reroll();
      }
    },
  };
</script>
<style scoped>
  .margin-right-negative {
    margin-right: -7px;
  }
</style>
