<template>
  <div class="pdf-container">
    <div v-if="selectedPDF && selectedPDF.url" class="modal-action-bar_pdf">
      <div class="d-flex align-items-center px-3 py-2">
        <div class="mr-auto">
          <a :href="selectedPDF.url" target="_blank" size="md" class="btn mr-3 c-btn-outlined-info"> <i class="fa  fa-file-pdf-o mr-2"></i> Open PDF in Browser </a>
        </div>
        <div class="ml-auto">
          <a :href="selectedPDF.url" @click.prevent="downloadPDF" size="md" class="btn mr-3 c-btn-outlined-info"> <i class="fa  fa-file-pdf-o mr-2"></i> Download </a>
          <a target="_blank" @click="openInNewTab" size="md" class="btn c-btn-outlined-info">
            <i class="fa fa-window-restore u-mr-xsmall"></i>
            Open in New Tab
          </a>
          <a target="_blank" :href="selectedPDF.url" @click.prevent="onOpenInWord" size="md" class="btn c-btn-outlined-info mx-3">
            <i class="fa fa-file-word-o u-mr-xsmall"></i>
            Open in MS-Word
          </a>
          <b-button @click="onPrint" size="md" class="ss-info-bg border-0">
            <i class="fa fa-print u-mr-xsmall"></i>
            Print
          </b-button>
        </div>
      </div>
    </div>
    <LoadingScreen v-if="loading" />
    <vue-pdf-app v-if="!loading && pdfURL" theme="dark" :config="config" :pdf="pdfURL" @open="openHandler" ref="vue-pdf" style="height: 100vh"></vue-pdf-app>
  </div>
</template>

<script>
  import VuePdfApp from "vue-pdf-app";
  import LoadingScreen from "@/components/_universal/global/loading-screen.vue";
  import "vue-pdf-app/dist/icons/main.css";
  import print from "print-js";
  import ApplicationService from "@/services/application";
  import axios from "axios";

  const app = new ApplicationService();

  const getSidebar = () => ({
    viewThumbnail: true,
    viewOutline: true,
    viewAttachments: true,
  });

  export default {
    name: "ViewPdf",
    data() {
      return {
        modalStatus: {},
        config: {
          toolbar: false,
          sidebar: getSidebar(),
        },
        loading: false,
        selectedPDF: null,
        document: null,
        timer: null,
      };
    },
    components: {
      VuePdfApp,
      LoadingScreen,
    },
    mounted() {
      this.initializeData();
    },
    beforeDestroy() {
      this.clearTimer();
    },
    computed: {
      pdfURL() {
        return this.selectedPDF && this.selectedPDF.url ? this.selectedPDF.url : null;
      },
    },
    methods: {
      openHandler(pdf) {
        let outerContainerHtml = document.getElementById("outerContainer");
        outerContainerHtml.classList.add("sidebarOpen");
        if (pdf && !pdf.pdfSidebar.isOpen) {
          this.config.sidebar = getSidebar();
          pdf.pdfSidebar.isOpen = true;
        }
      },
      async downloadPDF() {
        const response = await axios.get(this.selectedPDF.url, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.selectedPDF.documentName;
        link.click();
      },
      onOpenInWord() {
        if (!this.selectedPDF.wordURL) return;
        var lnk = "ms-word:ofe|u|" + this.selectedPDF.wordURL + "|a|Web";
        window.location.href = lnk;
      },
      openInNewTab() {
        if (!this.selectedPDF.url) return;
        let routeData = this.$router.resolve({name: "pdf-preview", query: {url: this.selectedPDF.url, wordURL: this.selectedPDF.wordURL, name: this.selectedPDF.documentName}});
        window.open(routeData.href, "_blank");
      },
      initializeData() {
        this.loading = true;
        this.modalStatus = this.$store.getters.getModalStatus;
        this.selectedPDF = this.modalStatus.data;

        if (this.selectedPDF.params && this.selectedPDF.type === "preview") {
          //Delay for 3 before loading pdf, this will give the PDF generator time to finish
          //This will help  prevent the PDF from loading before the PDF generator is finished
          const sleep = (time) => new Promise((res) => setTimeout(res, time, "done sleeping"));
          sleep(2000).then((msg) => this.getPreviewDocument());
        } else {
          this.loading = false;
        }
      },
      async getPreviewDocument() {
        try {
          this.document = await app.print(this.selectedPDF.params.moduleId, this.selectedPDF.params.id);

          if (this.document) {
            const updatedData = {
              ...this.modalStatus,
              data: this.document,
            };

            this.$store.commit("setModalStatus", updatedData);
            this.modalStatus = this.$store.getters.getModalStatus;
            this.selectedPDF = this.modalStatus.data;

            //Append a unit URL to the pdf to prevent browser caching
            this.selectedPDF.url = this.selectedPDF.url + "?v=" + new Date().getTime();

            this.loading = false;

            this.clearTimer();
          } else {
            this.timer = setTimeout(() => {
              this.getPreviewDocument();
            }, 2000);
          }
        } catch (e) {
          this.timer = setTimeout(() => {
            this.getPreviewDocument();
          }, 2000);
        }
      },
      clearTimer() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
      },
      onPrint() {
        if (!this.selectedPDF.url) return;
        print(this.selectedPDF.url);
      },
    },
  };
</script>
