<template>
  <div>
    <ValidationObserver ref="formNewClient">
      <!--start modal new client -->
      <div class="col-md-12">
        <b-alert show class="mb-2" variant="success" v-if="isSuccesful">
          <i class="fa fa-check-circle mr-2"></i>
          <span class="sub-title mr-2" id="modal-new-client-success-message">Succesfully created new client. You may
            close this window.</span>
        </b-alert>
        <b-alert show class="mb-2" variant="danger" v-if="isError">
          <i class="fa fa-check-circle mr-2"></i>
          <span class="sub-title mr-2">Error creating new client.</span>
        </b-alert>
        <div class="row">
          <div class="col-lg-4 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">
              Company/Name
              <search-duplicate model="businessName" :search="client.businessName" @response="handleErrors" />
            </span>
            <ValidationProvider v-slot="v" name="company/name" rules="required">
              <input class="form-control" type="text" @input="trimSpace('businessName')" v-model="client.businessName"
                placeholder id="dashboard-top-nav-new-client-modal-company-name" />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">
              Address
              <search-duplicate model="address" :search="client.primaryAddress" @response="handleErrors" />
            </span>
            <ValidationProvider name="address" v-slot="v" rules="required">
              <textarea class="form-control" type="text" @input="trimSpace('primaryAddress')"
                v-model="client.primaryAddress" placeholder id="dashboard-top-nav-new-client-modal-address" />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-md-6 u-mb-xsmall">
            <ValidationProvider name="billing email" v-slot="v" rules="required|email">
              <span class="c-toolbar__state-title u-text-capitalize">
                Billing Email
                <search-duplicate model="email" :search="client.email" @response="handleErrors" />
              </span>
              <input class="form-control" type="text" @input="trimSpace('email')" v-model="client.email" placeholder
                id="dashboard-top-nav-new-client-modal-billing-email" />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Phone</span>
            <ValidationProvider name="phone" rules="required" v-slot="{ errors }">
              <input class="form-control" type="text" v-model="client.phone" @input="formatPhoneNumber('phone')"
                placeholder id="dashboard-top-nav-new-client-modal-phone" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-12 u-mt-small u-mb-small u-height-2">
            <div class="border-bottom">
              <h5>Primary Contact</h5>
            </div>
          </div>
          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">First &amp; Last Name</span>
            <ValidationProvider name="primary contact name" rules="required" v-slot="{ errors }">
              <input class="form-control" type="text" @input="trimSpace('primaryContactName')"
                v-model="client.primaryContactName" placeholder
                id="dashboard-top-nav-new-client-modal-first-last-name" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Email </span>
            <ValidationProvider name="billing email" v-slot="{ errors }" rules="email">
              <input class="form-control" type="text" @input="trimSpace('primaryContactEmail')"
                v-model="client.primaryContactEmail" placeholder id="dashboard-top-nav-new-client-modal-email" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Mobile Number</span>
            <ValidationProvider name="mobile number" rules="required" v-slot="{ errors }">
              <input class="form-control" @input="formatPhoneNumber('mobile')" type="text" v-model="client.mobile"
                placeholder id="dashboard-top-nav-new-client-modal-mobile" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-12 u-mt-small u-mb-small u-height-2">
            <div class="border-bottom">
              <h5>Additional Information</h5>
            </div>
          </div>
          <div class="col-lg-4 col-md-6  ss--new-quote___modal_module u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Type</span>
            <v-select v-model="selectedClientType" :options="uxClientTypeList" :clearable="false" value="id"
              label="displayName" id="dashboard-top-nav-new-client-modal-type"></v-select>
          </div>
          <div class="col-lg-4 col-md-6 ss--new-quote___modal_module u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Industry</span>
            <v-select v-model="selectedIndustry" :options="uxIndustryList" :clearable="false" value="id"
              label="displayName" id="dashboard-top-nav-new-client-modal-industry"></v-select>
          </div>
          <!-- <div class="col-lg-4 col-md-6  ss--new-quote___modal_module u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Sales Person</span>
            <v-select :options="['First','Second']" v-model="client.intIndustryID"></v-select>
          </div>-->
        </div>
        <!-- row -->
      </div>
      <!-- col-md-12-->
      <!--end modal new client -->
    </ValidationObserver>
  </div>
</template>

<script>
import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import UtilityString from "@/utilities/strings";
import hash from "object-hash";
const utility = new UtilityString();
const global = new GlobalServices();
import EVENTS from "@/constants/events";
import SearchDuplicate from "@/components/_universal/utility/search-duplicate.vue";
export default {
  data() {
    return {
      EVENT_ACTION: {},
      isSuccesful: false,
      isError: false,
      selectedClientType: { displayName: "", id: 0 },
      selectedIndustry: { displayName: "", id: 0 },
      uxClientTypeList: [],
      uxIndustryList: [],
      client: {},
      errors: [],
    };
  },
  components: {
    SearchDuplicate,
  },
  methods: {
    handleErrors(errObj) {
      if (errObj.error) {
        const idx = this.errors.findIndex((err) => err.propertyName === errObj.propertyName);
        if (idx >= 0) {
          this.errors[idx] = errObj;
        } else {
          this.errors.push(errObj);
        }
      } else {
        const idx = this.errors.findIndex((err) => err.propertyName === errObj.propertyName);
        if (idx >= 0) {
          this.errors.splice(idx, 1);
        }
      }
    },
    async onSave(data) {
      this.$refs.formNewClient.validate().then(async (success) => {
        if (!success || this.errors.length > 0) {
          return;
        }

        this.client.moduleId = MODULE.CLIENTS.ModuleId;
        this.client.industryId = this.selectedIndustry.id;
        this.client.clientTypeId = this.selectedClientType.id;

        //Remove all spaces from properties
        this.client = utility.trimObjectPropertySpace(this.client);

        //Generate a Hash from the data to be submitted
        this.client.hash = hash(this.client);

        let result = await global.createNewActivity(MODULE.CLIENTS.ActionId, this.client);

        if (result.success) {
          this.isSuccesful = true;
          this.isError = false;
          this.client = {};

          this.$refs.formNewClient.reset();
        } else {
          this.isSuccesful = false;
          this.isError = true;
        }

        setTimeout(() => {
          this.isSuccesful = false;
          this.isError = false;

          this.$root.$emit(this.EVENT_ACTION.DASHBOARD.Update);
        }, 30000);
      });
    },
    formatPhoneNumber(propertyName) {
      this.client[propertyName] = utility.formatPhoneNumber(this.client[propertyName]);
    },
    trimSpace(propertyName) {
      if (this.timeout) clearTimeout(this.timeout);

      //Only clear space after user stops typing
      this.timeout = setTimeout(() => {
        //Trim Space from poperty
        this.client[propertyName] = utility.trimSpace(this.client[propertyName]);
      }, 1500);
    },
    async initializeData() { },
    async setInitialDefaults() {
      this.uxClientTypeList = await global.getUXDropDown(MODULE.CLIENTS.UX.ClientType, MODULE.CLIENTS.ModuleId);

      this.uxIndustryList = await global.getUXDropDown(MODULE.CLIENTS.UX.Industry);

      //Set client Type default selected value
      this.selectedClientType = global.setDropDownDefault(this.uxClientTypeList);

      //Set client Type default selected value
      this.selectedIndustry = global.setDropDownDefault(this.uxIndustryList);
    },
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.initializeData();

    //Subscribe to save button "Dispatch" click event on modal box
    this.$root.$on("Save" + this.EVENT_ACTION.CLIENTS.New, this.onSave);
  },
  async mounted() {
    await this.setInitialDefaults();

    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window.analytics.page(this.EVENT_ACTION.CLIENTS.View);
    // }
  },
  beforeDestroy() {
    //Unscribe from save button "Dispatch" click event on modal box
    this.$root.$off("Save" + this.EVENT_ACTION.CLIENTS.New);
  },
};
</script>
