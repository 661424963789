<template>
  <b-dropdown ref="dropdown" split size="sm" class="m-2 split-drop-down split-drop-down-warning" id="top-nav-button-new" @click="showModal(EVENT_ACTION.PROJECTS.New, 'New Work Order')">
    <template v-slot:button-content> <span class="fa fa-plus mr-2 text-light"></span> New </template>

    <!-- New Quote -->
    <b-dropdown-item href="#" id="top-nav-new-quote" @click="showModal(EVENT_ACTION.QUOTATION.New, 'New Quote')">New Quote</b-dropdown-item>

    <!-- New Client -->
    <b-dropdown-item href="#" id="top-nav-new-client" @click="showModal(EVENT_ACTION.CLIENTS.New, 'New Client')">New Client</b-dropdown-item>

    <!-- New Lead -->
    <b-dropdown-item href="#" id="top-nav-new-lead" @click="showModal(EVENT_ACTION.LEADS.New, 'New Lead/Prospect')">New Lead/Prospect </b-dropdown-item>

    <!-- New Project -->
    <b-dropdown-item href="#" id="top-nav-new-work-order" @click="showModal(EVENT_ACTION.PROJECTS.New, 'New Work Order')">New Work Order</b-dropdown-item>
    <!-- new inventory -->
    <b-dropdown-item href="#" id="top-nav-new-inventory" @click="showModal(EVENT_ACTION.INVENTORY.New, 'New Inventory')">New Inventory</b-dropdown-item>
    <b-dropdown-item href="#" id="top-nav-new-pricing" @click="showModal(EVENT_ACTION.PRICING.New, 'New Pricing')">New Pricing</b-dropdown-item>
    <b-dropdown-item href="#" id="top-nav-new-templates" @click="showModal(EVENT_ACTION.TEMPLATES.New, 'New Templates')">New Templates</b-dropdown-item>
    <!-- <b-dropdown-item v-if="$isDev" href="#" id="top-nav-view-image" @click="showModal(EVENT_ACTION.Image.View, 'Image Viewer')">Image Viewer</b-dropdown-item> -->
    <!-- <b-dropdown-item v-if="$isDev" href="#" id="top-nav-view-pdf"
      @click="showModal(EVENT_ACTION.PDF.View, 'PDF Viewer')">PDF Viewer</b-dropdown-item> -->
    <b-dropdown-item v-if="$isDev" href="#" id="top-nav-new-supplier" @click="showModal(EVENT_ACTION.SUPPLIER.New, 'New Supplier')">New Supplier</b-dropdown-item>
    <b-dropdown-item v-if="$isDev" href="#" id="top-nav-new-employee" @click="showModal(EVENT_ACTION.EMPLOYEE.New, 'New Employee')">New Employee</b-dropdown-item>
    <b-dropdown-item v-if="$isDev" href="#" id="top-nav-new-scheduler" @click="showModal(EVENT_ACTION.SCHEDULER.View, 'Scheduler')">Scheduler</b-dropdown-item>
    <!-- <b-dropdown-item v-if="$isDev" href="#" id="top-nav-modal-dashboard" @click="showModal(EVENT_ACTION.DASHBOARD.Modal, 'Modal Dashboard')">Modal Dashboard</b-dropdown-item> -->
    <!-- New Invoice -->
    <!-- <b-dropdown-item
        href="#"
        @click="showModal(EVENT_ACTION.INVOICE.New, 'New Invoice')"
    >New Invoice</b-dropdown-item>-->

    <!-- New Email -->
    <b-dropdown-item v-if="$isDev" href="#" @click="showModal(EVENT_ACTION.EMAIL.New, 'New Email')">New Email</b-dropdown-item>

    <b-dropdown-item v-if="$isDev" href="#" @click="showModal(EVENT_ACTION.DOCUMENTS_PHOTOS.New, 'New Documents')">New Document</b-dropdown-item>

    <!-- Add Equipment -->
    <!-- <b-dropdown-item
        href="#"
        @click="showModal(EVENT_ACTION.EQUIPMENT.New, 'Add Equipment')"
    >Add Equipment</b-dropdown-item>-->

    <!-- Add Service/Materials -->
    <!-- <b-dropdown-item
        href="#"
        @click="showModal(EVENT_ACTION.SERVICES_MATERIALS.New, 'Add Service/Materials')"
    >Add Services/Materials</b-dropdown-item>-->

    <!-- Add Documents/Photos -->
    <!-- <b-dropdown-item
        href="#"
        @click="showModal(EVENT_ACTION.DOCUMENTS_PHOTOS.New, 'Add Documents/Photos')"
    >Add Documents/Photos</b-dropdown-item>-->

    <!-- Add Task -->
    <!-- <b-dropdown-item href="#" @click="showModal(EVENT_ACTION.TASK.New, 'Add Task')">Add Task</b-dropdown-item> -->

    <!-- Add Time Tracking -->
    <!-- <b-dropdown-item
        href="#"
        @click="showModal(EVENT_ACTION.TIME_TRACKING.New, 'Add Time Tracking')"
    >Add Time Tracking</b-dropdown-item>-->

    <!-- Add Assign Staff -->
    <!-- <b-dropdown-item
        href="#"
        @click="showModal(EVENT_ACTION.ASSIGN_STAFF.New, 'Assign Staff')"
    >Assign Staff</b-dropdown-item>-->

    <!-- Add Scheduling -->
    <!-- <b-dropdown-item
        href="#"
        @click="showModal(EVENT_ACTION.SCHEDULING.New, 'Scheduling')"
    >Scheduling</b-dropdown-item>-->

    <!-- Split Screen -->
    <!-- <b-dropdown-item
      href="#"
      id="top-nav-split"
      @click="showModal(EVENT_ACTION.SPLIT.New, 'Split')"
    >Split Screen</b-dropdown-item> -->
  </b-dropdown>
</template>

<script>
  import EVENTS from "@/constants/events";

  export default {
    data() {
      return {
        EVENT_ACTION: {},
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          modalTitle: "",
          data: {},
        },
      };
    },
    methods: {
      showModal(paramModalName, modalTitle) {
        this.modalStatus.hideShowModal = true;
        this.modalStatus.modalName = paramModalName;
        this.modalStatus.data = {id: 4};
        this.modalStatus.modalTitle = modalTitle;
        this.modalStatus.modalSaveButtonTitle = "Save";
        this.$store.dispatch("toggleModalStatus", this.modalStatus);
      },
    },
    computed: {},
    components: {},
    created() {
      this.EVENT_ACTION = EVENTS;
    },
  };
</script>
