<template>
  <vue-pdf-app v-if="selectedPDF" theme="dark" :config="config" :pdf="selectedPDF" @open="openHandler" ref="vue-pdf"></vue-pdf-app>
</template>

<script>
  import VuePdfApp from "vue-pdf-app";
  import "vue-pdf-app/dist/icons/main.css";
  const getSidebar = () => ({
    viewThumbnail: true,
    viewOutline: true,
    viewAttachments: true,
  });

  export default {
    name: "PreviewPDF",
    props: {
      pdfUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        modalStatus: {},
        config: {
          toolbar: false,
          sidebar: getSidebar(),
        },
        selectedPDF: null,
      };
    },
    components: {
      VuePdfApp,
    },
    mounted() {
      this.initializeData();
    },
    methods: {
      openHandler(pdf) {
        let outerContainerHtml = document.getElementById("outerContainer");
        outerContainerHtml.classList.add("sidebarOpen");
        if (pdf && !pdf.pdfSidebar.isOpen) {
          this.config.sidebar = getSidebar();
          pdf.pdfSidebar.isOpen = false;
        }
      },
      initializeData() {
        if (this.pdfUrl) {
          //Delay for 3 before loading pdf, this will give the PDF generator time to finish
          //This will help  prevent the PDF from loading before the PDF generator is finished
          const sleep = (time) => new Promise((res) => setTimeout(res, time, "done sleeping"));
          sleep(2000).then((msg) => this.getPreviewDocument());
        }
      },
      async getPreviewDocument() {
        //Append a unit URL to the pdf to prevent browser caching
        this.selectedPDF = this.pdfUrl + "?v=" + new Date().getTime();
      },
    },
  };
</script>
