import axios from "axios";
import AuthService from "@/auth/authService";
import API_CONFIG from "@/config/API_CONFIG";
import MODULE from "@/constants/modules";
import {isDev} from "@/config/prototypes";
class EarthApplicationService {
  baseUrl = "https://earth.snapsuite.io/";
  jupiterBaseUrl = "https://jupiter.snapsuite.io/#/";
  // portalUrl = isDev() ? "http://localhost:9693" : "https://portal.snapsuite.io";
  portalUrl = isDev() ? "https://dev.jupiter.pages.dev" : "https://portal.snapsuite.io";
  auth = null;
  authQueryString = "";

  constructor() {
    this.auth = AuthService.getInstance();
  }

  getModalUrl(data, modalIframe, id) {
    let componentName = data.modalName;
    let returnUrl = "";

    //Special condition to hide Save and close buttons if in iframe
    if (componentName == "ViewedIframeModalPrint") {
      modalIframe.showToolbar = true;
      //Get Print Preview Url
      returnUrl = this.getPrintUrl(data, modalIframe, id);
    }
    if (componentName == "ViewedIframeModalPreviewImage") {
      modalIframe.showToolbar = false;
      //Get Print Preview Url
      returnUrl = this.getPrintUrl(data, modalIframe, id);
    } else if (componentName == "ViewedIframeModalEmail") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Email Module Url
      returnUrl = this.getEmailUrl(data, modalIframe, id);
    } else if (componentName === "ViewedIframeModalAssignTechnician") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Assign Tech Url
      returnUrl = this.getAssignTechUrl(data, modalIframe, id);
    } else if (componentName === "ViewedIframeModalRecurrenceSchedule") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Recurring Schedule
      returnUrl = this.getRecurringScheduleUrl(data, id);
    } else if (componentName === "ViewedIframeModalRecordPaymentOld") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Recurring Schedule
      returnUrl = this.getOldRecordPaymentURL(data);
    } else if (componentName == "ViewedIframeModalOldPurchaseOrder") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;
      //Get OldPurchase Order Module Url
      returnUrl = this.getPurchaseOrderDetailsUrlV3(data.data);
    } else if (componentName == "ViewedIframeModalAddEquipment") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Equipment Modal Details
      returnUrl = this.getEquipmentDetailsUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddServices") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getServiceMaterialModalDetailsUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddDocument") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getDocumentsModalPhotosUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddClientDocument") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getDocumentsClientModalPhotosUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddQuoteDocument") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getDocumentsQuoteModalPhotosUrl(data, id);
      //   returnUrl = this.getGenericDocumentsModalPhotosUrl(data, "Quotation", 31, 10, {param1: "Attachments", param2: "Quotation"});
    } else if (componentName == "ViewedIframeModalAddPurchaseOrderDocument") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getDocumentsPurchaseOrderModalPhotosUrl(data, id);
      //   returnUrl = this.getGenericDocumentsModalPhotosUrl(data, "PurchaseOrder", 7, 10, {param1: "Attachments", param2: "PurchaseOrder"});
    } else if (componentName == "ViewedIframeModalAddInvoiceDocument") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getDocumentsInvoiceModalPhotosUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddSuppliers") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Task Modal
      returnUrl = this.getDocumentsSupplierModalPhotosUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddPages") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Equipment Modal Details
      returnUrl = this.getDocumentsPagesModalPhotosUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddTemplateDocument") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getDocumentsTemplateModalPhotosUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddInventoryDocument") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Service/Material Details
      returnUrl = this.getDocumentsInventoryModalPhotosUrl(data, id);
    } else if (componentName == "ViewedIframeModalAddTask") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Task Modal
      returnUrl = this.getTaskModalDetailsUrl(data, id);
    } else if (componentName == "ViewedIframeModalLogTime") {
      //Hide Print/Download Toolbar
      modalIframe.showToolbar = false;

      //Get Log Time Modal
      returnUrl = this.getTimeTrackingModalDetailsUrl(data, id);
    }

    return returnUrl;
  }

  getPrintUrl(data, modalIframe, id) {
    let url = "https://preview.snapsuite.io/filepreview?title=" + modalIframe.title + "&url=" + modalIframe.url;

    return url;
  }

  getEmailUrl(data, modalIframe, id) {
    let fullUrl = "";

    //Check if a specific module should be loaded... i.e. sales quote, invoice, po etc..
    if (data.moduleId) {
      if (data.moduleId == MODULE.PROJECTS.ModuleId) {
        fullUrl = this.getModalProjectEmailUrl(id);
      } else if (data.moduleId == MODULE.QUOTATION.ModuleId) {
        fullUrl = this.getModalSalesEmailUrl(data);
      } else if (data.moduleId == MODULE.PURCHASEORDER.ModuleId) {
        fullUrl = this.getModalPurchaseOrderEmailUrl(data);
      } else if (data.moduleId == MODULE.INVOICE.ModuleId) {
        fullUrl = this.getModalInvoiceEmailUrl(data);
      }
    } else {
      //In theory we shouldnt' reach this block, but just incase... use old implementaion.
      //Original implementation for Project support
      fullUrl = this.getModalProjectEmailUrl(id);
    }

    return fullUrl;
  }

  getAssignTechUrl(data, modalIframe, id) {
    this.authQueryString = this.auth.getQueryString();
    let fullUrl = "https://f.sslnk.co/assign-tech/" + this.authQueryString + `&id=${id}&noheader=true`;

    return fullUrl;
  }

  getModalProjectEmailUrl(id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "usercontrols/email/emaileditor.aspx";
    let moduleParam = "&actionName=WorkOrder&navigationID=23&moduleID=2&s=Jupiter";
    let idParam = "&cid=&lid=&iid=&poid=&tid=&id=" + id + "&pid=" + id;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  getModalSalesEmailUrl(data) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "usercontrols/email/emaileditor.aspx";
    let moduleParam = "&actionName=Quote&navigationID=22&moduleID=6&s=Jupiter";
    let idParam = "&id=" + data.data.id;
    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;
    return fullUrl;
  }

  getModalPurchaseOrderEmailUrl(data) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "usercontrols/email/emaileditor.aspx";
    let moduleParam = "&actionName=PurchaseOrder&navigationID=31&moduleID=7&s=Jupiter";
    let idParam = "&poid=" + data.data.id;
    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;
    return fullUrl;
  }

  getModalInvoiceEmailUrl(data) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "usercontrols/email/emaileditor.aspx";
    let moduleParam = "&actionName=Invoice&navigationID=37&moduleID=3&s=Jupiter";
    let idParam = "&iid=" + data.data.id;
    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;
    return fullUrl;
  }

  getClientDetailsUrl(clientId) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "clientmodule.aspx";

    let idParam = "&s=Jupiter&moduleId=8&navigationId=1&cid=" + clientId;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  getViewQuoteDetailsUrl(salesId) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "quotemodule.aspx";

    let idParam = "&s=Jupiter&moduleId=6&navigationId=22&ActionName=Quote&id=" + salesId;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;
    return fullUrl;
  }

  getViewQuoteDetailsUrlJupiterPortal(salesId) {
    let fullUrl = this.portalUrl + "/sales/details/" + salesId;

    return fullUrl;
  }

  getPurchaseOrderDetailsUrlV2(data) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "purchaseordermodule.aspx";

    let idParam = "&s=Jupiter&moduleId=7&navigationId=31&ActionName=PurchaseOrder&pid=" + data.idRefNumber1 + "&poid=" + data.id + "&purchaseguid=" + data.guidId + "&ref=" + data.referenceNumber;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  getPurchaseOrderDetailsUrlV3(data) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "purchaseordermodule.aspx";

    let idParam = "&s=Jupiter&moduleId=7&navigationId=31&ActionName=PurchaseOrder&poid=" + data.id + "&purchaseguid=" + data.GuidID;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  getPurchaseOrderDetailsUrl(purchaseOrder) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "purchaseordermodule.aspx";

    let idParam =
      "&s=Jupiter&moduleId=7&navigationId=31&ActionName=PurchaseOrder&pid=" +
      purchaseOrder.projectId +
      "&poid=" +
      purchaseOrder.id +
      "&purchaseguid=" +
      purchaseOrder.guidId +
      "&ref=" +
      purchaseOrder.purchaseRef;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  getInvoiceDetailsUrlV2(invoice) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "invoicemodule.aspx";

    let idParam = "&s=Jupiter&moduleId=3&navigationId=37&ActionName=Invoice&pid=" + invoice.idRefNumber1 + "&iid=" + invoice.id + "&invoiceguid=" + invoice.guidId + "&ref=" + invoice.referenceNumber;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  getSupplierDetailsUrl(supplier) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "suppliermodule.aspx";

    let idParam = `&s=Jupiter&moduleId=19&navigationId=68&ActionName=Supplier&suid=${supplier.id}`;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  getTemplateDetailsUrl(template) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "templatemodule.aspx";

    let idParam = `&s=Jupiter&moduleId=17&ActionName=&navigationId=64&tid=${template.id}`;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  getInvoiceDetailsUrl(invoice) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "invoicemodule.aspx";

    let idParam = "&s=Jupiter&moduleId=3&navigationId=37&ActionName=Invoice&pid=" + invoice.projectId + "&iid=" + invoice.invoiceId + "&invoiceguid=" + invoice.guidId + "&ref=" + invoice.invoiceNo;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Purchase Order
  getWorkOrderDetailsUrl(projectId, refTitle) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodule.aspx";
    let moduleParam = "&s=Jupiter&iniframe=true&moduleId=2&navigationId=23&ActionName=WorkOrder";
    let idParam = "&pid=" + projectId + "&ref=" + refTitle;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  //Scheduler
  getSchedulerURL(projectId, data) {
    this.authQueryString = this.auth.getQueryString();
    const projectRefNumber = data.projectRefNumber;
    let moduleParam = "&actionId=23";
    let idParam = "&id=" + projectId + "&projRef=" + projectRefNumber;

    let fullUrl = "https://schedulerv2.snapsuite.io/" + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }
  //Scheduler
  getNewSchedulerURL(projectId, data) {
    this.authQueryString = this.auth.getQueryString();
    const projectRefNumber = data.projectRefNumber;
    let moduleParam = "&actionId=23";
    let idParam = "&id=" + projectId + "&projRef=" + projectRefNumber;

    let fullUrl = "https://jupiter-scheduler-2023-v2.pages.dev/" + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  // Recurring Schedule
  getRecurringScheduleUrl(data, projectId) {
    let clientId = data.intClientID;
    let locationtId = data.intLocationID;

    this.authQueryString = this.auth.getQueryString();
    let pageName = "LoadModalDialog.aspx";
    let idParam = "&s=Jupiter&ActionName=WorkOrder&moduleid=2&action=28&navigationId=23&id=" + projectId + "&pid=" + projectId;
    idParam +=
      "&cid=" +
      clientId +
      "&lid=" +
      locationtId +
      "&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" +
      projectId +
      "&param1=project&param2=&TB_iframe=true&height=400&width=550&windowtitle=Setup Recurrence";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  // Recurring Schedule
  getOldRecordPaymentURL(data) {
    let clientId = !data.details ? data.data.idRefNumber2 : data.details.clientId;
    let invoiceId = data.data.id;

    this.authQueryString = this.auth.getQueryString();
    let fullUrl = `${this.baseUrl}LoadModule.aspx${this.authQueryString}&moduleId=3&navigationId=46&ActionName=Payment&cid=${clientId}&iid=${invoiceId}&userid=1`;

    return fullUrl;
  }

  //Modal New Purchase Order
  getNewPurchaseOrderNavigationUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();

    let pageName = "purchaseordermodule.aspx";
    let moduleParam = "&s=Jupiter&moduleId=7&ActionName=PurchaseOrder&navigationId=31";
    let idParam = "&pid=" + projectId;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  //List Custom Url
  getCustomUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "project/custom/form";
    let idParam = "&s=Jupiter&projectID=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Purchase Order
  getPurchaseOrderUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "purchaseordermodule.aspx";
    let moduleParam = "&s=Jupiter&moduleId=7&ActionName=PurchaseOrder&navigationId=31";
    let idParam = "&pid=" + projectId;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  //Invoice Url
  getInvoiceUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "purchaseordermodule.aspx";
    let moduleParam = "&s=Jupiter&moduleId=7&ActionName=PurchaseOrder&navigationId=31";
    let idParam = "&pid=" + projectId;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  //Modal Task
  getTaskModalDetailsUrl(data, projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "tasks/create";
    let idParam = "&s=Jupiter&projectID=" + projectId + "&pid=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Modal Time Tracking
  getTimeTrackingModalDetailsUrl(data, projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "timetracking/form";
    let idParam = "&s=Jupiter&moduleId=2&projectID=" + projectId + "&pid=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Equipment Details Url
  getEquipmentDetailsUrl(data, projectId) {
    let clientId = data.intClientID;
    let pageName = "equipments/project/form";
    let idParam = "&s=Jupiter&type=equipment&projectID=" + projectId + "&pid=" + projectId;
    idParam += "&clientID=" + clientId;

    this.authQueryString = this.auth.getQueryString();

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Equipment Url
  getEquipmentUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "equipments/project/table";
    let idParam = "&s=Jupiter&projectID=" + projectId + "&pid=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Modal Service Details
  getServiceMaterialModalDetailsUrl(data, projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "material";
    let idParam = "&s=Jupiter&moduleId=2&type=material&typeId=1&projectID=" + projectId + "&pid=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Service/Material Url
  getServicesMaterialUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "material_lists";
    let moduleParam = "type=material&typeId=1&moduleId=2";
    let idParam = "&s=Jupiter&projectID=" + projectId + "&pid=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  //Projects Documents Photos Url
  getDocumentsModalPhotosUrl(data, projectId) {
    let clientId = data.intClientID;
    let locationtId = data.intLocationID;

    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Projects&moduleid=2&action=10&navigationId=1&id=" + projectId + "&pid=" + projectId;
    idParam += "&cid=" + clientId + "&lid=" + locationtId + "&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + projectId + "&param1=Attachment&param2=Projects";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Client Documents Upload Photos IFrame URL
  getDocumentsClientModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Client&moduleid=8&action=10&navigationId=1&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=Client";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Quote Documents Upload Photos IFrame URL
  getDocumentsQuoteModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Quotation&moduleid=31&action=10&navigationId=1&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=Quotation";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Suppliers Documents Upload Photos IFrame URL
  getDocumentsSuppliersModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Suppliers&moduleid=7&action=10&navigationId=22&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=Suppliers";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //PurchaseOrder Documents Upload Photos IFrame URL
  getDocumentsPurchaseOrderModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=PurchaseOrder&moduleid=7&action=10&navigationId=31&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=PurchaseOrder";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Invoice Documents Upload Photos IFrame URL
  getDocumentsInvoiceModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";

    let urlQuery = `&s=Jupiter&action=10&navigationId=37&id=${action.data.id}&windowtitle=Add%20Attachment&ActionName=Invoice&moduleid=3&pid=${action.data.projectid}&iid=${action.data.id}&guid=${action.data.id}&param1=Attachment&param2=Invoice`;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + urlQuery;

    return fullUrl;
  }

  //Templates Upload Photos IFrame URL
  getDocumentsTemplateModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Templates&moduleid=17&action=10&navigationId=1&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=Templates";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Supplier Upload Photos IFrame URL
  getDocumentsSupplierModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Suppliers&moduleid=7&action=10&navigationId=1&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=Suppliers";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Inventory Documents Upload Photos IFrame URL
  getDocumentsInventoryModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Inventory&moduleid=21&action=10&navigationId=1&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=Inventory";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Pages Documents Upload Photos IFrame URL
  getDocumentsPagesModalPhotosUrl(action, id) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=Pages&moduleid=34&action=10&navigationId=1&id=" + action.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + action.data.id + "&param1=Attachment&param2=Pages";

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //   used for generic, might replace all other URLs
  getGenericDocumentsModalPhotosUrl(item, actionName, moduleId, action = 10, params = {param1: "Attachment", param2: ""}) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "loadmodaldialog.aspx";
    let idParam = "&s=Jupiter&ActionName=" + actionName + "&moduleid=" + moduleId + "&action=" + action + "&navigationId=1&id=" + item.data.id;
    idParam += "&cid=&lid=&iid=&poid=&tmpid=&tskid=&suid=0&logHistoryID=0&sessionKey=&guid=" + item.data.id + "&param1=" + params.param1 + "&param2=" + params.param2;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //List Tasks
  getTasksListUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "tasks";
    let idParam = "&s=Jupiter&projectID=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //Time Tracking Url
  getTimeTrackingListUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "timetracking/table";
    let idParam = "&s=Jupiter&moduleId=2&projectID=" + projectId + "&pid=" + projectId;

    let fullUrl = this.jupiterBaseUrl + pageName + this.authQueryString + idParam;

    return fullUrl;
  }

  //New Quote
  getNewQuoteNavigationUrl(projectId) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "quotemodule.aspx";
    let moduleParam = "&moduleId=6&ActionName=Quote&navigationId=22";
    let idParam = "&s=Jupiter&param1=" + projectId;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam;

    return fullUrl;
  }

  //View Client Details
  getViewClientNavigationUrl(clientId, pageTitle) {
    this.authQueryString = this.auth.getQueryString();
    let pageName = "clientmodule.aspx";
    let moduleParam = "&moduleId=8&navigationId=1";
    let idParam = "&s=Jupiter&cid=" + clientId;
    let refParam = "&ref=" + pageTitle;

    let fullUrl = this.baseUrl + pageName + this.authQueryString + moduleParam + idParam + refParam;

    return fullUrl;
  }

  //View Client Details
  getViewClientNavigationUrlJupiterPortal(clientId, pageTitle) {
    let fullUrl = this.portalUrl + "/clients/details/" + clientId;

    return fullUrl;
  }

  //View Client Details
  getViewWorkOrderNavUrlJupiterProtal(intProjectID, pageTitle) {
    let fullUrl = this.portalUrl + "/projects/details/" + intProjectID;

    return fullUrl;
  }
}

export default EarthApplicationService;
