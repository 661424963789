<template>
<div class="col-md-12"> 
    <div class="row">
        <div class="col-md-8 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Filter By</span>
            <div class="d-flex mt-2">
            <div class="c-choice c-choice--radio flex-margin mr-3  mb-0">
                <input class="c-choice__input" checked id="radio1" name="radios" type="radio" />
                <label class="c-choice__label mb-0" for="radio1">Stocking Item</label>
            </div>
            <div class="c-choice c-choice--radio flex-margin mr-3 mb-0">
                <input class="c-choice__input" id="radio2" name="radios" type="radio" />
                <label class="c-choice__label mb-0" for="radio2">Non-Stocking Item</label>
            </div>
            <div class="c-choice c-choice--radio flex-margin mr-3  mb-0">
                <input class="c-choice__input"  id="radio3" name="radios" type="radio" />
                <label class="c-choice__label mb-0" for="radio3">All Items</label>
            </div>
            </div>
        </div>
        <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Select Material</span>
            <v-select :options="['abc', 'abc']"  label="displayName"></v-select>
        </div>
        <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Material Name <a href="#">[abc]</a></span>
            <textarea class="form-control" type="text" placeholder="" rows="3"/>
        </div>
        <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Quantity</span>
            <input class="form-control" type="text" placeholder=""/>
        </div>
        <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Cost</span>
            <input class="form-control" type="text" placeholder=""/>
        </div>
        <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Note</span>
            <textarea class="form-control" type="text" placeholder="" rows="3"/>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data () {
        return {

        };
    },
}
</script>
