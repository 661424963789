<template>
  <div>
    <ValidationObserver ref="formNewInvoice">
      <!--start modal new project body -->
      <div v-if="view === 'form'" class="col-md-12 u-pt-small customFooterModalBody">
        <div v-if="isError" class="alert alert-danger" role="alert">
          Sorry, there was an error while creating this job. Please try again.
        </div>
        <div v-if="invoice && invoice.invoiceBillingRuleMessage" class="alert alert-warning" role="alert" v-html="invoice.invoiceBillingRuleMessage"></div>
        <div v-if="invoice && invoice.isPurchaseOrderRequired" class="alert alert-danger" role="alert">
          Purchase Order is required.
        </div>
        <div class="row">
          <div class="col-md-4 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">1</span>Choose a Client</div>
            <ValidationProvider name="client" rules="required" v-slot="{errors}">
              <v-select id="client-invoice" :disabled="isDisabled" v-model="selectedClient" :options="uxClientList" @input="onSelectClient" code="id" label="displayName"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-4 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">2</span>Select a Work Order</div>
            <ValidationProvider name="location" rules="required" v-slot="{errors}">
              <v-select id="work-order-invoice" :disabled="isDisabled" v-model="selectedProject" :options="uxProjectList" @input="onSelectProject" code="id" label="displayName"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-4 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small"><span class="u-mr-small">3</span>Invoice Date</div>
            <ValidationProvider name="invoice date" rules="required" v-slot="{errors}">
              <span class="c-toolbar__state-title u-text-capitalize"></span>
              <date-picker id="date-invoice" format="DD-MMM-YYYY" style="width: 100%;" v-model="invoice.invoiceDate" lang="en"> </date-picker>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <!-- row -->
        <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

        <div class="row">
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Summary</span>
            <input id="summary-invoice" class="form-control" type="text" v-model="invoice.summary" />
          </div>

          <div class="col-md-2 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Tax Rate</span>
            <ValidationProvider name="location" rules="required" v-slot="{errors}">
              <v-select id="tax-rate-invoice" v-model="selectedTax" :options="uxTaxList" @input="onChangeTax" code="id" label="displayName" :clearable="false"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-md-3 u-mb-xsmall mt-4">
            <b-form-checkbox id="bill-invoice" name="checkbox-2" v-model="invoice.sfhouldBillParentCompany">
              Bill to Parent Company
            </b-form-checkbox>
          </div>

          <div class="col-md-3 u-mb-xsmall u-border-left">
            <div class="info-box">
              <div class="ss-primary-light-bg u-border-rounded text-right pr-3 py-1">
                <p class="mb-0 ss-white-font-color mb-0">Sub Total</p>
                <h4 id="subtotal-text-invoice" class="ss-white-font-color mb-0">
                  {{ !isRoundingUP ? formatCurrency(subTotal) : subTotal | currency }}
                </h4>
                <p class="mb-0 ss-white-font-color">With Tax: {{ !isRoundingUP ? formatCurrency(invoiceTotal) : invoiceTotal | currency }}</p>
                <input id="tax-total-invoice" type="hidden" :value="invoiceTotal" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 u-mb-xsmall pt-4">
            <UniversalList
              :used-in="'INVOICE'"
              v-bind:propItemList="propItemList"
              v-bind:lookupList="lookupList"
              v-on:EVENT_ReCalculate="onUpdateSubTotal"
              v-on:EVENT_ListUpdated="onUpdateList"
              v-on:EVENT_ReCalculateTax="onUpdateTax"
              :client="client"
              :invoice="invoice"
              @get-list="onLoadInventory"
              table-id="datatable-modal-list-invoice"
            ></UniversalList>
          </div>
        </div>
        <!-- row -->
      </div>

      <SuccessScreen v-if="view !== 'form'" class="invoice-footer-custom" :amount="0" :main-text="mainText"></SuccessScreen>

      <div class="modal-invoice-footer" v-if="view === 'form'">
        <div>
          <b-button size="md" @click="onSave" :disabled="isSaving" class="ss-primary-bg mr-2 border-0" id="projects-details-create-new-invoice-save-button">
            <!-- Normal State -->
            <i v-if="!isSaving" class="fa fa-save u-mr-xsmall"></i>
            <span v-if="!isSaving">
              Save
            </span>

            <!-- Processing State -->
            <img v-if="isSaving" src="@/assets/images/loading-spinner.gif" class="loading" alt="Loading..." />
            <span v-if="isSaving">&nbsp;Processing...</span>
          </b-button>

          <!-- Cancel Button -->
          <b-button size="md" class="ss-info-bg border-0" @click="onClose" :disabled="isSaving" id="projects-details-create-new-invoice-cancel-button">
            <i class="fa fa-times u-mr-xsmall"></i>Close
          </b-button>
        </div>
      </div>
      <!-- col-md-12-->
      <!--end modal new project body -->
    </ValidationObserver>
  </div>
</template>

<script>
  import moment from "moment";
  import InvoiceService from "@/services/invoice";
  import EVENTS from "@/constants/events";
  import UniversalList from "@/components/_universal/list";
  import SuccessScreen from "../_universal/global/success-screen.vue";

  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";

  const global = new GlobalServices();
  const api = new InvoiceService();

  const Decimal = require("decimal.js");
  export default {
    data() {
      return {
        view: "form",
        isSaving: false,
        isDisabled: true,
        subTotal: 0,
        taxRate: 0,
        invoiceTotal: 0,
        selectedTax: null,
        propItemList: [],
        lookupList: [],
        isInfo: true,
        isSuccesful: false,
        isError: false,
        client: {},
        invoice: {
          id: 0,
          invoiceDate: null,
          attentionTo: "",
        },
        moduleFilter: {clientId: 0},
        selectedClient: {displayName: "", id: 0},
        selectedProject: {displayName: "", id: 0},

        uxProjectList: [],
        uxClientList: [],
        uxTaxList: [],

        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },
        mainText: "Invoice successfully created. <br> You may close this window.",
      };
    },
    computed: {
      isRoundingUP() {
        return this.invoice.applicationCurrencyRoundingOption === MODULE.INVOICE.ROUNDING.ROUNDUP;
      },
    },

    methods: {
      onUpdateList(list) {
        this.invoice.invoiceItems = list;
      },
      onUpdateSubTotal(value) {
        this.subTotal = value;
        // this.recomputeInvoiceTotal();
      },
      onUpdateTax(value) {
        const subTotal = new Decimal(this.subTotal);
        const result = value.add(subTotal);
        this.invoiceTotal = result.toString();
      },
      async onChangeTax(evt) {
        if (!evt) return;
        this.taxRate = this.invoice.taxRate = evt.unitPrice;
        this.invoice.taxCodeId = evt.id;
        // this.recomputeInvoiceTotal();
        this.$root.$emit("update-tax", this.taxRate);
      },
      recomputeInvoiceTotal() {
        const subTotal = new Decimal(this.subTotal);
        const taxRate = new Decimal(this.taxRate);
        const timesResult = taxRate.times(subTotal);
        const sumResult = subTotal.add(timesResult);
        // this.invoiceTotal = _.add(this._.multiply(parseFloat(this.subTotal), this.taxRate), parseFloat(this.subTotal));
        this.invoiceTotal = sumResult.toString();
      },
      onClose() {
        this.$root.$emit("close-parent-modal");
      },
      async onSave(data) {
        if (this.invoice && this.invoice.isPurchaseOrderRequired) return;
        this.$refs.formNewInvoice.validate().then(async (success) => {
          if (!success) {
            return;
          }

          this.invoice.moduleId = MODULE.INVOICE.ModuleId;
          this.invoice.clientId = this.selectedClient.id;
          this.invoice.projectId = this.selectedProject.id;
          //Remove all spaces from properties
          // this.invoice = utility.trimObjectPropertySpace(this.invoice);

          const inv = {...this.invoice};

          let result = await global.createNewActivity(MODULE.INVOICE.ActionId, inv);
          if (result.success) {
            this.view = "success";
            this.isError = false;
            this.isInfo = false;

            // Wait until the models are updated in the UI
            this.$refs.formNewInvoice.reset();
          } else {
            this.isError = true;
            this.view = "form";
          }

          // setTimeout(() => {
          //   this.isSuccesful = false;
          //   this.isError = false;
          // }, 30000);
        });
      },
      async onSelectClient(e) {
        //reset client info
        this.client = null;

        //Set Clignt Module Filter
        this.moduleFilter.clientId = e.id;

        //Get Client Locations
        this.uxLocationList = await global.getUXDropDown(MODULE.LOCATION.ActionId, null, null, this.moduleFilter);

        //Set Location Default
        this.selectedLocation = global.setDropDownDefault(this.uxLocationList);

        //Get Client Details
        // this.client = await client.getClientById(e.id);
        this.client = await global.getEditDetails(MODULE.CLIENTS.ActionId, e.id);
        this.quote.attentionTo =
          this.client && Object.keys(this.client).includes("primaryContact") ? this.client.primaryContact : `${this.client.PrimaryContactFirstName} ${this.client.PrimaryContactLastName}`;
      },

      async onSelectProject(e) {},

      async initializeData() {
        //Get Data From Modal
        this.modalStatus = this.$store.getters.getModalStatus;

        let projectId = this.modalStatus.data.intProjectID;

        this.invoice = await api.getInvoicePresetByProjectId(projectId);

        this.subTotal = this.invoice.subTotal;

        this.invoiceTotal = this.invoice.grandTotal;

        // this.taxRate = this.invoice.taxRate;

        //Get Client List
        this.uxClientList = this.invoice.clients;

        this.uxProjectList = this.invoice.projects;

        this.uxTaxList = await global.getUXDropDown(MODULE.INVOICE.UX.InvoiceTaxRates);

        //Set Default Dropdown values
        this.selectedProject = global.setDropDownDefault(this.uxProjectList);
        this.selectedClient = global.setDropDownDefault(this.uxClientList);
        this.onSelectClient(this.selectedClient);
        this.selectedTax = !this.invoice.taxCodeId ? global.setDropDownDefault(this.uxTaxList) : this.uxTaxList.find((tax) => tax.id === this.invoice.taxCodeId);
        this.onChangeTax(this.selectedTax);

        //Get Project List
        this.uxProjectList = this.invoice.projects;

        this.onLoadInventory();

        //Set List Items
        this.propItemList = this.invoice.invoiceItems
          ? this.invoice.invoiceItems.map((data) => {
              return {
                ...data,
                qty: data.qty === 0 || !data.qty ? 1 : data.qty,
                guid: `${data.id}`,
              };
            })
          : [];

        this.invoice.invoiceDate = moment().format("DD-MMM-YYYY");
      },
      async onLoadInventory(mode) {
        // let inventoryService = new UXInventoryService();

        // //Hack to pass additional paramaters to inventory list
        // let queryString = "&actionId=" + MODULE.QUOTATION.ActionId;
        let defaultActionId = MODULE.PRICING.ActionId;
        if (mode) defaultActionId = mode;
        this.lookupList = await global.getUXDropDown(defaultActionId);
      },
    },

    components: {
      UniversalList,
      SuccessScreen,
    },
    async mounted() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      this.initializeData();

      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page(this.EVENT_ACTION.INVOICE.View);
      // }

      //Subscribe to save button "Dispatch" click event on modal box
      // this.$root.$on("Save" + this.EVENT_ACTION.INVOICE.New, this.onSave);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      // this.$root.$off("Save" + this.EVENT_ACTION.INVOICE.New, this.onSave);
    },
  };
</script>
