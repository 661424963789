








import Vue from "vue";
import QuoteServices from "@/services/quotes";
import TemplateServices from "@/services/templates";
import EditDataObject from "@/interfaces/EditDataObject";
import UXInventoryService from "@/services/ux/uxinventory";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";

const templateApi = new TemplateServices();
const quoteApi = new QuoteServices();
const global = new GlobalServices();

const templateTypeId = 5;

export default {
  name: "QuoteDetails",

  data() {
    return {
      id: 0,
      selectedItem: {},
      quoteDetails: {},
      showErrorWarning: false,
      subTotal: 0,
      uxTemplateList: [],
      config: {
        events: {
          "froalaEditor.initialized": function() {},
        },
        key: "GPD2tA5A7B2C2C3B2sh1B-7j1guyB2dvxjhitjzF4hmC-7J2A4D4B3C6E2G2F4G1B1==",
        attribution: false,
      },
    };
  },
  computed: {
    terms: {
      get: function() {
        return this.quoteDetails.termsConditions;
      },
      set: function(newValue) {
        this.updateField("termsConditions", newValue, "Terms & Conditions");
      },
    },
  },
  methods: {
    async updateData(dataObj) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        global.postData(dataObj);
      }, 500);
    },
    updateField(propertyName, propertyValue, displayName) {
      let editObj = {} as EditDataObject;
      editObj.propertyName = propertyName;
      editObj.propertyValue = propertyValue;
      editObj.displayName = displayName;
      editObj.id = this.$route.query.qid;
      editObj.actionId = 19; //TOD DO: Change hard coded number to constant value.
      editObj.referenceNumber = this.quoteDetails.qutoteNumber;

      this.updateData(editObj);
    },
    async onSelectTerms(event) {
      this.loadSelectedTemplate(event.id);
    },
    async loadSelectedTemplate(id) {
      //On Dropdown change call API to get template description
      this.template = await templateApi.getTemplateById(id);

      //Update Quote Details
      this.quoteDetails.termsConditions = this.template.description;

      //Update Databse
      this.updateField("termsConditions", this.quoteDetails.termsConditions, "Terms & Conditions");
    },
    async initializeData() {
      this.quoteDetails = await quoteApi.getQuoteById();
      this.uxTemplateList = await global.getUXDropDown(MODULE.TEMPLATES.ActionId, MODULE.QUOTATION.ModuleId, templateTypeId);

      //If no terms and conditions exist load the default
      if (!this.quoteDetails.termsConditions) {
        //Get the first default template in the list
        this.selectedItem = this.uxTemplateList.find((list) => list.isDefault == true);

        if (this.selectedItem != undefined) {
          this.loadSelectedTemplate(this.selectedItem.id);
        }
      }
    },
  },
  async mounted() {
    //Temp. Implementation to support Iframe from ProjectEarth
    let isIframe = this.$route.query.isiframe;

    if (isIframe) {
      //Hide navigation for details page - because it's being loaded from an iframe.
      this.$store.dispatch("showfullNavigation", false);
    } else {
      this.$store.dispatch("showfullNavigation", true);
    }
  },
  async created() {
    this.initializeData();
  },
};
