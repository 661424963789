<template>
  <div v-if="!loading">
    <div id="toolbar" v-if="false">
      <!-- Add a bold button -->
      <!-- <button class="ql-bold"></button> -->
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
      <!-- Add font size dropdown -->
      <select class="ql-size">
        <option value="small"></option>
        <!-- Note a missing, thus falsy value, is used to reset to default -->
        <option selected></option>
        <option value="large"></option>
        <option value="huge"></option>
      </select>
      <!-- Add subscript and superscript buttons -->
      <button class="ql-script" value="sub"></button>
      <button class="ql-script" value="super"></button>
      <!-- header -->
      <select class="ql-header">
        <option value="1"></option>
        <option value="2"></option>
        <option value="3"></option>
        <option value="4"></option>
        <option value="5"></option>
      </select>
      <button class="ql-bullet"></button>

      <select class="ql-custom tooltip">
        <option value="1">value1</option>
        <option value="2">value2</option>
        <option value="3">value3</option>
        <option value="4">value4</option>
      </select>
    </div>
    <quill-editor ref="myTextEditor" :options="editorOption" :content="content" @change="onEditorChange($event)" @blur="onEditorBlur" @focus="onEditorFocus" />
  </div>
</template>
<script>
  // https://github.com/surmon-china/vue-quill-editor
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import "quill/dist/quill.bubble.css";

  import {quillEditor} from "vue-quill-editor";
  export default {
    name: "editor",
    components: {
      quillEditor,
    },
    props: {
      content: {
        type: String,
        default: "",
      },
      toolbarOptions: {
        type: Array,
        default: () => {
          return [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{header: 1}, {header: 2}], // custom button values
            [{list: "ordered"}, {list: "bullet"}],
            [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
            [{size: ["small", false, "large", "huge"]}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{align: []}],
          ];
        },
      },
    },
    data() {
      return {
        editorOption: {
          modules: {
            matchVisual: false,
            toolbar: {
              container: this.toolbarOptions,
              handlers: {
                snippets: (value) => {
                  if (value) {
                    const cursorPosition = this.quill.getSelection().index;
                    this.quill.insertText(cursorPosition, value);
                    this.quill.setSelection(cursorPosition + value.length);
                  }
                },
              },
            },
            // [
            //   ["bold", "italic", "underline", "strike"], // toggled buttons
            //   [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
            //   [{size: ["small", false, "large", "huge"]}], // custom dropdown
            //   [{header: [1, 2, 3, 4, 5, 6, false]}],
            //   [{align: []}],
            // ],
          },
        },
        contentComponent: null,
        loading: false,
      };
    },
    computed: {
      quill() {
        return this.$refs.myTextEditor.quill;
      },
    },
    watch: {
      content(value) {
        this.contentComponent = value;
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      onEditorChange({quill, html, text}) {
        this.contentComponent = html;
        this.$emit("onEditChange", html);
      },
      onEditorBlur(quill) {
        this.$emit("position", quill.selection.savedRange);
      },
      onEditorFocus(quill) {
        this.$emit("position", quill.selection.savedRange);
      },
      init() {
        const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll(".ql-snippets .ql-picker-item"));

        if (placeholderPickerItems && placeholderPickerItems.length > 0) {
          placeholderPickerItems.forEach((item) => (item.textContent = item.dataset.value));

          document.querySelector(".ql-snippets .ql-picker-label").innerHTML = "Insert Snippet" + document.querySelector(".ql-snippets .ql-picker-label").innerHTML;
        }
      },
    },
  };
</script>

<style scoped>
  .ql-editor {
    min-height: 200px;
    overflow: auto;
  }
</style>
