<template>
  <div>
    <!--start modal new project body -->
    <div class="col-md-12 u-pt-small">
      <div v-if="isError" class="alert alert-danger" role="alert">
        Sorry, there was an error while creating this job. Please try again.
      </div>
      <div class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Select Employee
          </div>
          <v-select
            v-model="selectedEmployee"
            id="slidepanel-edit-work-order-tabs-assign-task-modal-select-employees"
            :options="uxEmployeesList"
            @input="onUpdateSelection"
            label="fullName"
          ></v-select>
        </div>
      </div>
      <!-- row -->
      <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

      <div class="row">
        <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Name
          </div>
          <ValidationProvider name="name" rules="required" v-slot="{errors}">
            <input
              class="form-control"
              id="slidepanel-edit-work-order-tabs-assign-task-modal-name"
              type="text"
              v-model="modalDataDetails.TaskName"
              @keyup="
                updateFieldSimplified(
                  'TaskName', //PropertyName
                  modalDataDetails.TaskName, //Property Value
                  'TaskName', //Display Name
                  true
                )
              "
            />
            <span class="text-danger">{{ errors.length > 0 ? "Name is required." : "" }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Start Date
          </div>
          <ValidationProvider name="startDate" rules="required" v-slot="{errors}">
            <date-picker
              format="DD-MMM-YYYY hh:mm a"
              value-type="format"
              id="slidepanel-edit-work-order-tabs-assign-task-modal-start-date"
              :time-picker-options="{
                start: '06:30',
                step: '00:30',
                end: '19:30',
              }"
              type="datetime"
              :shortcuts="shortcuts"
              style="width: 100%;"
              v-model="modalDataDetails.StartDate"
              @change="updateFieldSimplified('startDate', modalDataDetails.StartDate, 'Start Date', true)"
              lang="en"
            ></date-picker>
            <span class="text-danger">{{ errors.length > 0 ? "Start Date is required." : "" }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-3 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">End Date</div>
          <ValidationProvider name="endDate" rules="required" v-slot="{errors}">
            <date-picker
              format="DD-MMM-YYYY hh:mm a"
              value-type="format"
              id="slidepanel-edit-work-order-tabs-assign-task-modal-end-date"
              :time-picker-options="{
                start: '06:30',
                step: '00:30',
                end: '19:30',
              }"
              type="datetime"
              :shortcuts="shortcuts"
              style="width: 100%;"
              v-model="modalDataDetails.EndDate"
              @change="updateFieldSimplified('endDate', modalDataDetails.EndDate, 'End Date', true)"
              lang="en"
            ></date-picker>
            <span class="text-danger">{{ errors.length > 0 ? "End Date is required." : "" }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Status
          </div>
          <v-select
            v-model="modalDataDetails.IntTaskStatusID"
            id="slidepanel-edit-work-order-tabs-assign-task-modal-status"
            :options="uxStatusList"
            label="displayName"
            code="id"
            :reduce="(data) => data.id"
            @input="updateFieldSimplified('IntTaskStatusID', modalDataDetails.IntTaskStatusID, 'Status', true)"
          ></v-select>
        </div>
        <div class="col-md-4 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Category
          </div>
          <v-select
            v-model="modalDataDetails.IntTaskCategoryID"
            id="slidepanel-edit-work-order-tabs-assign-task-modal-category"
            :options="uxCategoryList"
            label="displayName"
            code="id"
            :reduce="(data) => data.id"
            @input="updateFieldSimplified('IntTaskCategoryID', modalDataDetails.IntTaskCategoryID, 'Category', true)"
          ></v-select>
        </div>
        <div class="col-md-4 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Priority
          </div>
          <v-select
            v-model="modalDataDetails.IntPriorityID"
            id="slidepanel-edit-work-order-tabs-assign-task-modal-priority"
            :options="uxPriorityList"
            label="displayName"
            code="id"
            :reduce="(data) => data.id"
            @input="updateFieldSimplified('IntPriorityID', modalDataDetails.IntPriorityID, 'Priority', true)"
          ></v-select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Description
          </div>
          <ValidationProvider name="description" rules="required" v-slot="{errors}">
            <textarea
              class="form-control"
              rows="8"
              id="slidepanel-edit-work-order-tabs-assign-task-modal-description"
              v-model="modalDataDetails.Description"
              @keyup="
                updateFieldSimplified(
                  'Description', //PropertyName
                  modalDataDetails.Description, //Property Value
                  'Description', //Display Name
                  true
                )
              "
            ></textarea>
            <span class="text-danger">{{ errors.length > 0 ? "Description is required." : "" }}</span>
          </ValidationProvider>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- col-md-12-->
    <!--end modal new project body -->
  </div>
</template>

<script>
  import EVENTS from "@/constants/events";
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UXEmployeeService from "@/services/ux/uxemployee";
  const global = new GlobalServices();
  export default {
    mixins: [editMixin],

    data() {
      return {
        isInfo: true,
        isSuccesful: false,
        isError: false,
        selectedEmployee: null,
        selectedCategory: null,
        selectedPriority: null,
        selectedStatus: null,
        selectedFilter: "all",
        formData: {
          actionId: MODULE.TASKS.ActionId,
          moduleId: MODULE.TASKS.ModuleId,
          parentId: null,
          EmployeeId: null,
          TaskName: null,
          DueDate: null,
          Description: null,
          IntPriorityID: null,
          IntTaskCategoryID: null,
          IntTaskStatusID: null,
        },

        uxStatusList: [],
        uxPriorityList: [],
        uxCategoryList: [],
        uxEmployeesList: [],
        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },

        //Date picker shortcuts
        shortcuts: [
          {
            text: "Today",
            onClick() {
              const date = new Date();
              // return a Date
              return date;
            },
          },
          {
            text: "Tomorrow",
            onClick() {
              const date = moment()
                .add(1, "days")
                .calendar();
              return date;
            },
          },
        ],
      };
    },
    watch: {
      modalDataDetails: {
        handler(val) {
          this.updateModalData(val);
        },
        deep: true,
      },
    },

    methods: {
      updateModalData(data) {
        this.$store.dispatch("setModalDataDetailsObject", data);
      },
      onUpdateSelection() {
        this.modalDataDetails.EmployeeName = this.selectedEmployee.fullName;
        this.modalDataDetails.EmployeeId = this.selectedEmployee.employeeID;

        this.updateFieldSimplified(
          "IntEmployeeID", //PropertyName
          this.modalDataDetails.EmployeeId, //Property Value
          "Employee ID", //Display Name
          true
        );
      },
      async getEmployeeList() {
        //Get Employee List
        const employeeService = new UXEmployeeService();
        const apiemployee = await employeeService.getEmployees();
        //   let employeeList = await global.getUXDropDown(MODULE.CLIENTS.ActionId)
        this.uxEmployeesList = apiemployee || [];
      },
      async loadDropdowns() {
        this.uxStatusList = await global.getUXDropDown(MODULE.TASKS.UX.Status);
        this.uxPriorityList = await global.getUXDropDown(MODULE.TASKS.UX.Priority);
        this.uxCategoryList = await global.getUXDropDown(MODULE.TASKS.UX.Category);
      },
      async initializeData() {
        this.modalStatus = this.$store.getters.getModalStatus;
        this.loadDropdowns();
        this.getEmployeeList();

        //Get Data From Modal
        if (this.modalObj.isInEditMode) {
          //Load Details Details from API
          this.formData = await global.getEditDetails(MODULE.TASKS.ActionId, this.modalObj.data.id);
          this.formData.actionId = MODULE.TASKS.ActionId;
          this.formData.moduleId = MODULE.TASKS.ModuleId;
          if (this.formData.IntEmployeeID) {
            let employee = this.uxEmployeesList.find((data) => {
              return parseInt(data.employeeID) === parseInt(this.formData.IntEmployeeID);
            });
            this.selectedEmployee = employee;
          }
        }
        this.formData.parentActionId = this.modalStatus && this.modalStatus.parentActionId ? this.modalStatus.parentActionId : MODULE.PROJECTS.ActionId;
        // TODO check module / page; to pass the correct parent id
        this.formData.parentId = this.modalStatus.data.intProjectID;
        // IntTaskTypeID  is To-do Task
        this.formData.IntTaskTypeID = 1;

        this.updateModalData(this.formData);
      },
      resetForm() {
        this.selectedEmployee = null;
      },
    },
    beforeDestroy() {
      this.$root.$off("on-save", this.resetForm);
    },
    mounted() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
      this.$root.$on("on-save", this.resetForm);

      this.initializeData();
    },
  };
</script>
