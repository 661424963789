<template>
  <div>
    <ValidationObserver ref="formDuplicateInvoice">
      <!--start modal new project body -->
      <b-alert show class="mb-2" variant="success" v-if="isSuccesful">
        <i class="fa fa-check-circle mr-2"></i>
        <span class="sub-title mr-2" id="modal-duplicate-success-message">Succesfully duplicated invoice. You may close this window.</span>
      </b-alert>
      <b-alert show class="mb-2" variant="danger" v-if="isError">
        <i class="fa fa-check-circle mr-2"></i>
        <span class="sub-title mr-2" id="modal-duplicate-error-message">You did not enter the correct confirmation word.</span>
      </b-alert>
      <div class="col-md-12 ">
        <p>To duplicate this invoice, please enter 'DUPLICATE' in the textbox below to confirm</p>
        <div class="row">
          <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
            <ValidationProvider name="duplicate" rules="required" v-slot="{errors}">
              <input id="duplicate-form-input" class="form-control" v-model="query" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
            <ValidationProvider name="Work Order" rules="required" v-slot="{errors}">
              <span class="c-toolbar__state-title u-text-capitalize">Work Order</span>
              <v-select v-model="selectedWO" id="edit-location-choose-wo" :options="uxJobOrders" :reduce="(project) => project.id" code="id" label="displayName" :clearable="false"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import EVENTS from "@/constants/events";
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  export default {
    name: "DuplicateInvoice",
    data() {
      return {
        isSuccesful: false,
        isError: false,
        query: "",
        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },
        uxJobOrders: [],
        selectedWO: null,
      };
    },
    mounted() {
      this.loadData();
    },
    methods: {
      async loadData() {
        //Get Data From Modal
        this.modalStatus = this.$store.getters.getModalStatus;
        this.uxJobOrders = await global.getUXDropDown(MODULE.PROJECTS.ActionId);
      },

      async onSave(data) {
        this.isError = false;
        this.isSuccesful = false;
        const isConfirm = this.query === "DUPLICATE";
        if (!isConfirm) {
          this.isError = true;
          return;
        }
        const isValid = await this.$refs.formDuplicateInvoice.validate();
        if (isValid && isConfirm) {
          const payload = {
            id: this.modalStatus.data.id,
            moduleId: MODULE.INVOICE.ModuleId,
            newProjectId: this.selectedWO,
          };

          await global.createNewActivity(MODULE.INVOICE.UX.DuplicateActionId, payload);
          this.isSuccesful = true;
          this.query = "";
          this.$refs.formDuplicateInvoice.reset();
        }
      },
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.INVOICE.DuplicateInvoice, this.onSave);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.INVOICE.DuplicateInvoice, this.onSave);
    },
  };
</script>
