

















import editMixin from "@/mixin/edit-mixin";
export default {
  mixins: [editMixin],
  components: {},
  data() {
    return {
      content: null,
    };
  },
  methods: {
    getData() {
      this.content = this.dataObj.details.Notes;
    },
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {},
};
