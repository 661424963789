<template>
  <div class="sidebar pt-4 u-height-100vh">
    <b-card v-for="nav in sideNavigation.leftNav" v-bind:key="nav.id" no-body v-bind:class="'mb-1 sidebar-item main-bar-item scrollbar' + nav.displayName" v-bind:id="'side-nav-' + nav.className">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <!-- <pre class="text-white">{{nav}}</pre> -->
        <b-button
          :id="'side-nav-main-' + nav.className"
          block
          href="#"
          v-b-toggle="'accordion-' + nav.id"
          class="text-left"
          @click.prevent="navigation(nav.id, nav.navigationURL, nav.iframe, nav.friendlyURL)"
        >
          <i v-bind:class="renderIcon(nav.icon)"></i>
          {{ nav.displayName }}
          <i v-if="nav.childItems" class="fa fa-caret-down float-right mt-1"></i>
        </b-button>
      </b-card-header>
      <b-collapse v-bind:id="'accordion-' + nav.id" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <span
            v-for="subitem in nav.childItems"
            v-bind:key="subitem.id"
            v-bind:id="'side-nav-sub-' + subitem.className"
            @click.prevent="navigation(subitem.id, subitem.navigationURL, subitem.iframe, subitem.friendlyURL)"
            class="d-block clickable"
            ><i class="fa fa-chevron-right small"></i> {{ subitem.displayName }}</span
          >
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
  import EVENTS from "@/constants/events";

  export default {
    data() {
      return {
        EVENT_ACTION: {},
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          modalTitle: "",
          data: {},
        },
      };
    },
    components: {},
    methods: {
      showModal(paramModalName, modalTitle) {
        this.modalStatus.hideShowModal = true;
        this.modalStatus.modalName = paramModalName;
        this.modalStatus.data = {id: 4};
        this.modalStatus.modalTitle = modalTitle;
        this.modalStatus.modalSaveButtonTitle = "Save";
        this.$store.dispatch("toggleModalStatus", this.modalStatus);
      },
      renderIcon(icon) {
        return "fa " + icon + " float-left mt-1 mr-3";
      },

      navigation(id, url, iframe, friendlyUrl) {
        //Ensure url is not blank
        if (url) {
          //There are two types of url, iframe and the vuejs url
          if (iframe) {
            //Iframe url, set the vuex store properties
            this.$store.dispatch("setApplicationIframe", {
              showIframe: true,
              iframeUrl: url,
              friendlyUrl: friendlyUrl,
            });
          } else {
            //Reset iframe state to display false
            this.$store.dispatch("setApplicationIframe", {
              showIframe: false,
              iframeUrl: "",
            });

            if (url === "new-quote") {
              this.showModal(this.EVENT_ACTION.QUOTATION.New, "New Quote");
            } else if (url === "new-client") {
              this.showModal(this.EVENT_ACTION.CLIENTS.New, "New Client");
            } else if (url === "new-job") {
              this.showModal(this.EVENT_ACTION.PROJECTS.New, "New Work Order");
            } else if (url === "new-po") {
              this.showModal(this.EVENT_ACTION.PURCHASEORDER.New, "New Purchase Order");
            } else if (url === "new-inventory") {
              this.showModal(this.EVENT_ACTION.INVENTORY.New, "New Inventory");
            } else if (url === "new-pricing") {
              this.showModal(this.EVENT_ACTION.PRICING.New, "New Pricing");
            } else if (url === "new-template") {
              this.showModal(this.EVENT_ACTION.TEMPLATES.New, "New Template");
            } else if (url === "new-supplier") {
              this.showModal(this.EVENT_ACTION.SUPPLIER.New, "New Supplier");
            } else if (url === "add-employee") {
              this.showModal(this.EVENT_ACTION.EMPLOYEE.New, "New Employee");
            }
            //All other routes..
            else {
              // check if url has http or https
              // if so redirect

              if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
                location.href = url;
              } else {
                //Route to Vuejs component
                const nav = {path: url};
                //Ensure the current path is not the same
                if (this.$route.path !== nav.path) {
                  this.$router.push(nav.path).catch((error) => {
                    //Don't log navigation duplicate errors
                    if (error.name != "NavigationDuplicated") {
                      throw error;
                    }
                  });
                }
              }
            }
          }
        }
      },
    },
    watch: {
      $route: {
        immediate: true,
        handler(to, from) {
          document.title = to.meta.title || "SnapSuite";
        },
      },
    },
    computed: {
      sideNavigation() {
        return this.$store.getters.getNavigation;
      },
    },
    created() {
      this.EVENT_ACTION = EVENTS;
    },
  };
</script>
