<template>
  <div v-if="startImage !== null">
    <div class="modal-action-bar">
      <div class="d-flex align-items-center px-3 py-2">
        <div class="ml-auto">
          <a target="_blank" :href="selectedImage ? selectedImage.src : ''" size="md" class="btn c-btn-outlined-info">
            <i class="fa fa-window-restore u-mr-xsmall"></i>
            Open in New Tab
          </a>
          <b-button @click="onPrint" size="md" class="ss-info-bg border-0 mx-3">
            <i class="fa fa-print u-mr-xsmall"></i>
            Print
          </b-button>
        </div>
      </div>
    </div>
    <lingallery ref="imageViewer" class="image-slider mt-5 pt-1" :iid.sync="currentId" :start-image="startImage" :items="items" responsive :addons="{enableLargeView: true}" accentColor="#6e0677" />
  </div>
</template>
<script>
  // https://bestofvue.com/repo/ChristophAnastasiades-Lingallery-vuejs-carousel
  import Lingallery from "lingallery";
  import print from "print-js";
  export default {
    data() {
      return {
        modalStatus: {},
        currentId: null,
        startImage: null,
        items: [],
      };
    },
    computed: {
      selectedImage() {
        return this.items.find((document) => document.id === this.currentId);
      },
    },
    components: {
      Lingallery,
    },
    mounted() {
      this.initializeData();
    },
    methods: {
      initializeData() {
        this.modalStatus = this.$store.getters.getModalStatus;
        this.currentId = this.modalStatus.data.id;

        this.items = this.modalStatus.dataListing
          .filter((document) => {
            return document.icon !== "pdf" && document.icon !== "word" && document.icon !== "excel" && document.icon !== "ppt" && document.icon !== "file";
          })
          .map((document) => {
            return {
              src: document.url,
              largeViewSrc: document.url,
              thumbnail: document.url,
              caption: document.documentName,
              id: document.id,
            };
          });

        this.startImage = this.items.findIndex((document) => {
          return document.id === this.currentId;
        });
      },
      onPrint() {
        print(this.$refs.imageViewer.currentImage, "image");
      },
    },
  };
</script>
