//Standard Components

import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import APP from "@/constants/application";
import {isDev} from "@/config/prototypes";
import {IUXSidePanel, ISidePanelHeader, IActionPrint, ILabelTitle, IButtonAction, ISidePanelInfoSection, IJupiterIFrame, ISidePanelTabs} from "@/interfaces/UXSidePanel";

class BaseController {
  private earth = new EarthApplicationService();

  //Import event constants and set local variable
  private EVENT_ACTION = EVENTS;

  constructor() {}

  //Page Header / Title
  getTitle(labelModule, labelReferenceNumber, labelStatus): String {
    let title = labelModule;

    if (labelReferenceNumber) {
      title += " (" + labelReferenceNumber + ")";
    }

    if (labelStatus) {
      title += " - " + labelStatus;
    }

    return title;
  }

  //Secondary Header
  getSubTitle(labelBolded, labelStandard) {
    let subtitle;
    if (labelStandard) {
      subtitle = "<strong>" + labelBolded + "</strong> - " + labelStandard;
    } else {
      subtitle = "<strong>" + labelBolded + "</strong>";
    }

    return subtitle;
  }

  //Generate the Details responsible for showing the email dialog
  getEmailAction(data, moduleId, actionId?): IButtonAction {
    let emailAction: IButtonAction = 
    // !isDev()
    //   ? {
    //       id: 4,
    //       eventType: this.EVENT_ACTION.IFRAME.Email,
    //       menuTitle: "Email",
    //       modalTitle: "Send Email",
    //       saveButtonTitle: "Send Email",
    //       moduleId: moduleId,
    //       // url:
    //       componentName: this.EVENT_ACTION.IFRAME.Email,
    //       data: data,
    //       UI_TYPE: APP.UI_TYPE.ModalIFrame,
    //     }
    //   : 
      {
          id: 4,
          eventType: this.EVENT_ACTION.EMAIL.New,
          menuTitle: "Email",
          modalTitle: "Send Email",
          saveButtonTitle: "Send",
          data: data,
          moduleId: moduleId,
          actionId: actionId ? actionId : 0,
          componentName: "NewEmail",
          url: "",
          icon: "fa fa-truck",
          UI_TYPE: APP.UI_TYPE.Modal,
        };

    return emailAction;
  }

  //Generate the Details responsible for showing the email dialog
  getOldPurchaseOrderAction(data, moduleId): IButtonAction {
    let purchaseOrderAction: IButtonAction = {
      id: 4,
      eventType: this.EVENT_ACTION.IFRAME.ViewOldPurchaseOrder,
      menuTitle: "Old Purchase Order",
      modalTitle: "Old Purchase Order",
      saveButtonTitle: "Send Email",
      moduleId: moduleId,
      // url:
      componentName: this.EVENT_ACTION.IFRAME.ViewOldPurchaseOrder,
      data: data,
      UI_TYPE: APP.UI_TYPE.ModalIFrame,
    };

    return purchaseOrderAction;
  }

  //Print Preview
  getPrintAction(id, moduleId): IActionPrint {
    let print: IActionPrint = {
      moduleId: moduleId,
      id: id,
    };

    return print;
  }

  //IFrame Urls and Links
  getIFrameDetails(data, id): IJupiterIFrame {
    let iframe: IJupiterIFrame = {
      customUrl: "",
      purchaseOrderUrl: "",
      invoicesUrl: "",
      equipmentUrl: "",
      serviceMaterialUrl: "",
      documentPhotoslUrl: "",
      tasksUrl: "",
      timeTrackingUrl: "",
    };

    //Load Data for older Iframe components
    iframe.customUrl = this.earth.getCustomUrl(id);

    iframe.purchaseOrderUrl = this.earth.getPurchaseOrderUrl(id);

    iframe.invoicesUrl = this.earth.getInvoiceUrl(id);

    iframe.equipmentUrl = this.earth.getEquipmentUrl(id);

    iframe.serviceMaterialUrl = this.earth.getServicesMaterialUrl(id);

    iframe.documentPhotoslUrl = this.earth.getDocumentsModalPhotosUrl(data, id);

    iframe.tasksUrl = this.earth.getTasksListUrl(id);

    iframe.timeTrackingUrl = this.earth.getTimeTrackingListUrl(id);

    return iframe;
  }
}

export default BaseController;
