










import editMixin from "@/mixin/edit-mixin";
import editor from "@/components/_universal/global/editor.vue";
export default {
  mixins: [editMixin],
  components: {
    editor,
  },
  data() {
    return {
      content: null,
    };
  },
  watch: {},
  methods: {
    updateHere(html) {
      this.content = html;
      this.updateField(
        "PageContent", //PropertyName
        html, //Property Value
        "PageContent", //Display Name,
        html, //Display Value
        "PageContent", //Data object Property Name
        null,
        null,
        null,
        this.dataObj.param.id,
        this.dataObj.param.actionId
      );
    },
    getData() {
      this.content = this.dataObj.details.PageContent;
    },
  },
  mounted() {
    this.getData();
  },
};
