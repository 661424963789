<template>
  <b-card class="dash-grey-card border-0">
    <h4>{{ header }}</h4>
    <span class="text-muted">{{ subheader }}</span>
    <Pie
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </b-card>
</template>

<script>
  import {Pie} from "vue-chartjs/legacy";

  import {Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale} from "chart.js";

  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

  export default {
    name: "PieChart",
    components: {
      Pie,
    },
    props: {
      header: {
        type: String,
        default: "Time-Tracking Type Breakdown",
      },
      subheader: {
        type: String,
        default: "",
      },
      datasets: {
        type: Object,
        default: () => {
          return {
            datasets: [
              {
                backgroundColor: ["#6e0677", "#f78a11", "#2ea1f8", "#27ae5f"],
                data: [40, 20, 80, 10],
              },
            ],
            labels: ["Material", "Labor", "Overhead", "Subcontracting"],
          };
        },
      },
      chartId: {
        type: String,
        default: "pie-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 400,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {};
    },
    computed: {
      chartData() {
        return {
          labels: this.datasets.labels,
          datasets: this.datasets.datasets,
        };
      },
      chartOptions() {
        return {
          responsive: true,
          maintainAspectRatio: false,
        };
      },
    },
  };
</script>
