import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
let global = new GlobalServices();

export default {
  methods: {
    async getChartsData(params, type = "bar", viewby, yearly = false, dataLabelArray, isDataHiddenArray) {
      if (yearly) {
        params.DateFilter = "thisyear";
      }

      if (viewby) {
        params.viewby = viewby;
      }

      const results = await global.getChartsData(params);
      let labels = [];
      let bgColor = {
        0: [],
        1: [],
        2: [],
        3: [],
      };

      const data = {
        0: [],
        1: [],
        2: [],
        3: [],
      };

      results.forEach((d) => {
        labels.push(d.labels ? this.capitalizeString(d.labels) : "");

        bgColor[0].push(d.backgroundColor);
        bgColor[1].push(d.backgroundColor2);
        bgColor[2].push(d.backgroundColor3);
        bgColor[3].push(d.backgroundColor4);
        data[0].push(d.data);
        data[1].push(d.dataSet2);
        data[2].push(d.dataSet3);
        data[3].push(d.dataSet4);
      });

      const datasets = [];
      if (type !== "pie") {
        dataLabelArray.forEach((label, index) => {
          const isHidden = isDataHiddenArray && isDataHiddenArray.length > 0 ? isDataHiddenArray[index] : false;
          const set = this.getDataSetFormat(type, data[index], bgColor[index], label, isHidden);
          datasets.push(set);
        });
      } else {
        const set = this.getDataSetFormat(type, data[0], bgColor[0]);
        datasets.push(set);
      }

      return {
        datasets,
        labels,
      };
    },
    getDataSetFormat(type, data, backgroundColor = "#6e0677", label, hidden = false) {
      switch (type) {
        case "bar":
          return {
            label,
            backgroundColor,
            data,
            borderRadius: 4,
            hidden,
          };
        case "pie":
          return {
            backgroundColor,
            data,
            hidden,
          };
        case "line":
          return {
            label,
            backgroundColor,
            data,
            borderColor: backgroundColor,
            fill: false,
            tension: 0.4,
            hidden,
          };
      }
    },
  },
};
