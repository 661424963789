<template>
  <div>
    <ValidationObserver ref="formNewEmployee">
      <div class="col-md-12">
        <b-alert show class="mb-2" variant="success" v-if="isSuccesful">
          <i class="fa fa-check-circle mr-2"></i>
          <span class="sub-title mr-2" id="modal-new-employee">Succesfully created new employee. You may close this window.</span>
        </b-alert>
        <div class="row">
          <div class="col-lg-12 col-md-12 u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Role</span>
            <ValidationProvider name="Role" rules="required" v-slot="{errors}">
              <v-select label="displayName" :options="uxRoles" :clearable="false" v-model="selectedRoles" id="dashboard-top-nav-new-employee-modal-role"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-6 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">First Name</span>
            <ValidationProvider name="First Name" rules="required" v-slot="{errors}">
              <input class="form-control" type="text" v-model="employee.FirstName" placeholder id="dashboard-top-nav-new-employee-modal-first-name" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-lg-6 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Last Name</span>
            <input class="form-control" type="text" v-model="employee.LastName" placeholder id="dashboard-top-nav-new-employee-modal-last-name" />
          </div>
          <div class="col-lg-6 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Email</span>
            <ValidationProvider name="email" rules="email" v-slot="{errors}">
              <input class="form-control" id="dashboard-top-nav-new-employee-modal-email" type="text" v-model="employee.EmailAddress" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-6 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Mobile</span>
            <country-dropdown v-if="selectedCountry" v-model="maskedMobileNumber" @countrySelect="onSelectCountry" :country-selected="selectedCountry" />
            <span v-if="isMobileMissing" class="text-danger">Mobile Number is required</span>
          </div>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-lg-8 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title">
              Password
              <small class="text-danger">
                (Please copy this password to a safe place, you will not have access to it again)
              </small>
            </span>
            <input class="form-control" type="text" v-model="employee.Password" id="dashboard-top-nav-new-employee-modal-password" readonly />
          </div>
          <div class="col-lg-4 col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize  opacity-0">.</span>
            <b-button @click="generatePassword" size="md" class="btn c-btn-outlined-primary w-100">
              <i class="fa fa-refresh u-mr-xsmall"></i>
              Reset Password
            </b-button>
          </div>
        </div>
      </div>
      <!-- col-md-12-->
      <!--end modal new client -->
    </ValidationObserver>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import EmployeeService from "@/services/employee";
  import UtilityString from "@/utilities/strings";
  import hash from "object-hash";
  import CountryDropdown from "@/components/_universal/utility/country-dropdown";
  import EVENTS from "@/constants/events";
  import editMixin from "@/mixin/edit-mixin";
  const CountryList = require("@/utilities/country-list.json");

  const utility = new UtilityString();
  const global = new GlobalServices();
  const emp = new EmployeeService();

  export default {
    mixins: [editMixin],
    components: {
      CountryDropdown,
    },
    data() {
      return {
        EVENT_ACTION: {},
        isSuccesful: false,
        isError: false,
        selectedRoles: null,
        selectedCountry: null,
        uxRoles: [],
        employee: {
          FirstName: null,
          LastName: null,
          EmailAddress: null,
          MobileNumber: null,
          Password: null,
          Country: null,
          areaCode: null,
        },
        countryList: CountryList,
        maskedMobileNumber: null,
        isMobileMissing: false,
      };
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.EMPLOYEE.New, this.onSave);
    },
    mounted() {
      this.setInitialDefaults();
      const defaultCountry = this.countryList.find((c) => c.iso === "US");
      this.onSelectCountry(defaultCountry);
    },
    methods: {
      onSelectCountry(country) {
        this.selectedCountry = country;
        this.employee.Country = this.selectedCountry.name;
        const areaCode = this.selectedCountry.code;
        this.employee.areaCode = areaCode.replace("+", "");
      },
      async onSave(data) {
        /*
        we can't use the validationProvider on this one
        because we are using a component
        and if we add the validation on the component
        since its not wrap to a form, it automatically triggers 
        the validation

        -added 01-27-2023 ticket#1104
      */
        this.isMobileMissing = false;
        if (!this.maskedMobileNumber) {
          this.isMobileMissing = true;
          return;
        }

        this.$refs.formNewEmployee.validate().then(async (success) => {
          if (!success) {
            return;
          }

          this.employee.RoleID = this.selectedRoles.id;

          if (this.maskedMobileNumber) {
            this.employee.MobileNumber = utility.removeCharactersForMobile(`${this.employee.areaCode}${this.maskedMobileNumber}`);
          }

          //Remove all spaces from properties
          this.employee = utility.trimObjectPropertySpace(this.employee);
          //Generate a Hash from the data to be submitted
          this.employee.hash = hash(this.employee);

          let result = await emp.createNewEmployee(this.employee);

          if (result.success) {
            this.isSuccesful = true;
            this.isError = false;
            this.employee = {
              FirstName: null,
              LastName: null,
              EmailAddress: null,
              MobileNumber: null,
              Password: null,
              Country: null,
              areaCode: null,
            };

            this.maskedMobileNumber = null;
            this.selectedRoles = {displayName: "", id: 0};

            this.$refs.formNewEmployee.reset();
          } else {
            this.isSuccesful = false;
            this.isError = true;
          }

          setTimeout(() => {
            this.isSuccesful = false;
            this.isError = false;
          }, 30000);
        });
      },
      formatPhoneNumber(propertyName) {
        this.employee[propertyName] = utility.formatPhoneNumber(this.employee[propertyName]);
      },
      trimSpace(propertyName) {
        if (this.timeout) clearTimeout(this.timeout);

        //Only clear space after user stops typing
        this.timeout = setTimeout(() => {
          //Trim Space from poperty
          this.employee[propertyName] = utility.trimSpace(this.employee[propertyName]);
        }, 1500);
      },
      async setInitialDefaults() {
        this.uxRoles = await global.getUXDropDown(MODULE.ROLES.ActionId);
        this.generatePassword();
      },
      generatePassword() {
        const generatedPassword = utility.generatePassword();
        this.employee.Password = generatedPassword;
      },
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.EMPLOYEE.New, this.onSave);
    },
  };
</script>
