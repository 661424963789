<template>
  <div>
    <!--start modal new project body -->
    <div class="col-md-12 u-pt-small">
      <div v-if="isError" class="alert alert-danger" role="alert">
        Sorry, there was an error while creating this job. Please try again.
      </div>
      <div v-if="!modalObj.isInEditMode" class="d-flex mb-2">
        <div class="mr-5">
          <div class="c-choice c-choice--radio mb-0">
            <input class="c-choice__input" id="radio2" name="radio2" type="radio" :value="MODULE.PRICING.ActionId" v-model="mode" />
            <label class="c-choice__label" for="radio2">Pricing</label>
          </div>
        </div>

        <div class="mr-5">
          <div class="c-choice c-choice--radio mb-0">
            <input class="c-choice__input" id="radio1" name="radio1" type="radio" :value="MODULE.INVENTORY.ActionId" v-model="mode" />
            <label class="c-choice__label" for="radio1">Inventory</label>
          </div>
        </div>
      </div>
      <div v-if="!modalObj.isInEditMode" class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Select Material
          </div>
          <v-select v-model="selectedMaterial" id="add-edit-service-materials-modal-select-material" :options="uxInventory" @input="onUpdateSelection" code="id" label="displayName"></v-select>
        </div>
      </div>
      <!-- row -->
      <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

      <div class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Material Name
          </div>
          <ValidationProvider name="name" rules="required" v-slot="{errors}">
            <textarea
              class="form-control"
              id="add-edit-service-materials-modal-material-name"
              type="text"
              v-model="modalDataDetails.Name"
              rows="3"
              @keyup="
                updateFieldSimplifiedByPassParentID(
                  'Name', //PropertyName
                  modalDataDetails.Name, //Property Value
                  'Name', //Display Name
                  true
                )
              "
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Quantity
          </div>
          <ValidationProvider name="quantity" rules="required|min_value:1" v-slot="{errors}">
            <input
              type="number"
              class="form-control"
              id="add-edit-service-materials-modal-quantity"
              v-model="modalDataDetails.Qty"
              @keyup="
                updateFieldSimplifiedByPassParentID(
                  'Qty', //PropertyName
                  modalDataDetails.Qty, //Property Value
                  'Quantity', //Display Name
                  true
                )
              "
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Price
          </div>

          <div class="input-group input-with-dollar-sign">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="add-edit-service-materials-modal-price"
              v-model="modalDataDetails.UnitPrice"
              @keyup="
                updateFieldSimplifiedByPassParentID(
                  'UnitPrice', //PropertyName
                  modalDataDetails.UnitPrice, //Property Value
                  'Unit Price', //Display Name
                  true
                )
              "
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 ss--new-quote__modal_module u-mb-small">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
            Note
          </div>
          <textarea
            class="form-control"
            type="text"
            id="add-edit-service-materials-modal-note"
            v-model="modalDataDetails.Notes"
            rows="5"
            @keyup="
              updateFieldSimplifiedByPassParentID(
                'Notes', //PropertyName
                modalDataDetails.Notes, //Property Value
                'Notes', //Display Name
                true
              )
            "
          />
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- col-md-12-->
    <!--end modal new project body -->
  </div>
</template>

<script>
  import EVENTS from "@/constants/events";
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  export default {
    mixins: [editMixin],

    data() {
      return {
        isInfo: true,
        isSuccesful: false,
        isError: false,
        selectedMaterial: null,
        selectedFilter: "all",
        formData: {
          actionId: MODULE.MATERIALS.ActionId,
          moduleId: MODULE.PROJECTS.ModuleId,
          Name: null,
          Qty: 1,
          UnitPrice: 0,
          Notes: null,
        },
        mode: MODULE.PRICING.ActionId,
        uxInventory: [],

        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },
      };
    },
    watch: {
      modalDataDetails: {
        handler(val) {
          this.updateModalData(val);
        },
        deep: true,
      },
      mode(value) {
        this.selectedMaterial = null;
        this.getInventoryList();
      },
    },
    methods: {
      updateModalData(data) {
        this.$store.dispatch("setModalDataDetailsObject", data);
      },
      async onUpdateSelection(evt) {
        const item = await global.getEditDetails(this.mode, this.selectedMaterial.id);
        this.modalDataDetails.Name = `${this.selectedMaterial.displayName}${item.Description ? "\n" + item.Description : ""}`;
        this.modalDataDetails.Description = item.Description;
        this.modalDataDetails.Qty = item.Qty ? item.Qty : 1;

        const salePrice = item.SalePrice ? item.SalePrice : 0;
        const costPrice = item.ItemCost ? item.ItemCost : salePrice;
        this.modalDataDetails.UnitPrice = this.$route.name === "purchase-order-details" ? costPrice : salePrice;
        this.modalDataDetails.Notes = item.Notes;
        this.modalDataDetails.IntInventoryID = item.IntInventoryID;
      },
      async getInventoryList() {
        //Get Inventory List
        let inventoryList = await global.getUXDropDown(this.mode);
        this.uxInventory = inventoryList || [];
      },
      async initializeData() {
        //Get Data From Modal
        this.modalStatus = this.$store.getters.getModalStatus;
        if (this.modalObj.isInEditMode) {
          //Load Details Details from API
          this.formData = await global.getEditDetails(this.MODULE.MATERIALS.ActionId, this.modalObj.data.id);
        }

        if (this.$route.name === "projects-details") {
          this.formData.actionId = MODULE.MATERIALS.ActionId;
          this.formData.moduleId = MODULE.PROJECTS.ModuleId;
          this.formData.parentId = this.modalStatus.data.intProjectID;
        } else if (this.$route.name === "purchase-order-details") {
          this.formData.actionId = MODULE.MATERIALS.ActionId;
          this.formData.moduleId = MODULE.PURCHASEORDER.ModuleId;
          this.formData.parentActionId = MODULE.PURCHASEORDER.ActionId;
          this.formData.parentId = this.modalStatus.data.id;
        }
        this.getInventoryList();

        this.updateModalData(this.formData);
      },
      resetForm() {
        this.selectedMaterial = null;
        this.mode = MODULE.PRICING.ActionId;
        this.getInventoryList();
      },
    },

    created() {
      this.MODULE = MODULE;
    },
    beforeDestroy() {
      this.$root.$off("on-save", this.resetForm);
    },
    mounted() {
      this.$root.$on("on-save", this.resetForm);
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      this.initializeData();
    },
  };
</script>
