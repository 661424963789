<template>
  <span :class="compClass">
    {{ label }}
    <span class="clickable ml-2" @click="showModal(eventSelected, `Edit ${label}`, actionId, moduleId, immediateInit)">
      <i class="fa fa-pencil-square-o u-text-large vertical-middle text-muted"></i>
    </span>
    <slot></slot>
  </span>
</template>
<script>
  import globalSettings from "@/mixin/global-settings-mixin";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  export default {
    name: "CTAGlobalSettings",
    mixins: [globalSettings],
    props: {
      label: {
        required: true,
        type: String,
      },
      actionId: {
        required: true,
        type: Number,
      },
      moduleId: {
        type: Number,
        default: null,
      },
      immediateInit: {
        type: Boolean,
        default: false,
      },
      compClass: {
        type: String,
        default: "c-toolbar__state-title u-text-capitalize d-flex align-items-center",
      },
    },
    data() {
      return {
        eventSelected: EVENTS.SETTINGS.SpecificListing,
      };
    },
    created() {
      this.EVENT_ACTION = EVENTS;
      if (this.actionId === MODULE.CLIENTS.UX.ClientRateGroup) {
        this.eventSelected = EVENTS.SETTINGS.ClientRateGroupListing;
      } else if (this.actionId === MODULE.CLIENTS.UX.ClientRates) {
        this.eventSelected = EVENTS.SETTINGS.ClientRatingListing;
      } else {
        this.eventSelected = EVENTS.SETTINGS.SpecificListing;
      }
    },
  };
</script>
