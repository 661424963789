<template>
  <div>
    <div class="row pt-2">
      <div class="col-md-12 mb-2">
        <!-- show components here -->
        <TabQuoteDetailsDescription v-if="mode === 'paragraph'" :data-obj="dataObj" />
        <TabQuoteDetailsList v-else :data-obj="dataObj" />
      </div>
    </div>
  </div>
</template>

<script>
import editMixin from "@/mixin/edit-mixin";
import TabQuoteDetailsDescription from "@/components/sales/tab-quote-details-description";
import TabQuoteDetailsList from "@/components/sales/tab-quote-details-list";
export default {
  name: "TabQuoteDetailsCompiled",
  mixins: [editMixin],
  components: {
    TabQuoteDetailsDescription,
    TabQuoteDetailsList,
  },
  props: {
    dataObj: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      mode: "line",
      item: {
        line: {
          componentName: "TabQuoteDetailsList",
        },
        paragraph: {
          componentName: "TabQuoteDetailsDescription",
        },
      },
    };
  },
  mounted() {
    this.mode = "paragraph";
  },
};
</script>
