<template>
  <div>
    <div
      v-if="this.modalIframe.showToolbar"
      class="iframe-top-bar ss-light-bg  position-sticky m-n3"
     
    >
      <div class="d-flex align-items-center px-3 py-2">
        <p class="text-muted mb-0">PDF Viewer</p>

        <div class="ml-auto">
          <a
            :href="modalIframe.url"
            target="_blank"
            size="md"
            class="btn c-btn-outlined-info"
          >
            <i class="fa fa-window-restore u-mr-xsmall"></i>
            Open in New Tab
          </a>
          &nbsp; &nbsp;
          <b-button
            @click="onOpenInWord"
            size="md"
            class="btn c-btn-outlined-info"
          >
            <i class="fa fa-file-word-o u-mr-xsmall"></i>
            Open In MS-Word
          </b-button>

          <b-button @click="onPrint" size="md" class="ss-info-bg border-0 mx-3">
            <i class="fa fa-print u-mr-xsmall"></i>
            Print
          </b-button>
        </div>
      </div>
    </div>
    <div class="embed-responsive embed-responsive-16by9">
      <vue-friendly-iframe
        frameborder="0"
        scrolling="no"
        data-hj-allow-iframe="true"
        class="embed-responsive-item"
        :src="iframesrc"
      ></vue-friendly-iframe>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import print from "print-js";

const earth = new EarthApplicationService();

export default {
  data() {
    return {
      EVENT_ACTION: {},
      iframesrc: "",
      modalIframe: "",
      downloadUrl: "",
      modal: {
        hideShowModal: false,
        message: "",
        modalSaveButtonTitle: "",
        data: {}
      }
    };
  },
  computed: {
    ...mapState(["modalStatus"])
  },
  watch: {},
  methods: {
    onOpenInWord() {
      var lnk = "ms-word:ofe|u|" + this.downloadUrl + "|a|Web";
      window.location.href = lnk;
    },
    onPrint() {
      printJS(this.modal.data.url);
    },
    //Initialize Data
    loadData() {
      this.modal = this.$store.getters.getModalStatus;
      this.modalIframe = this.$store.getters.getModalIframe;
      this.downloadUrl = this.modalIframe.data.wordURL;

      let componentName = this.modal.modalName;
      let id = this.modal.data.intProjectID;

      // //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page(componentName);
      // }

      this.$nextTick(() => {
        this.iframesrc = earth.getModalUrl(this.modal, this.modalIframe, id);
      });
    }
  },

  mounted() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    this.loadData();
  },

  components: {}
};
</script>
