<template>
    <div>
        <div class="col-md-12 u-mb-small">
            <span class="c-toolbar__state-title u-text-capitalize">Overview/Job Description</span>
            <textarea
                class="form-control"
                type="text"
                rows="6"
            />
        </div>
        <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Scheduling Notes / Timing / Equipment Requirements</span>
            <textarea
                class="form-control"
                type="text"
                rows="6"
            />
        </div>
    </div>
</template>


<script>
export default {
    data () {
        return {

        };
    },
}
</script>
