











































































































































































































import QuoteServices from "@/services/quotes";
import UXInventoryService from "@/services/ux/uxinventory";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS
import editMixin from "@/mixin/edit-mixin";
import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
const global = new GlobalServices();
const api = new QuoteServices();

export default {
  mixins: [editMixin],
  name: "QuoteDetails",

  data() {
    return {
      editingRow: false,
      defaultInventorySelected: {
        displayName: "List (Optional)",
        id: "0",
      },
      itemDescriptionLength: 20,
      selectedItem: {},
      quoteDetailsItems: [],
      showErrorWarning: false,
      subTotal: 0,
      inventoryList: [],
      categoryList: [],
      secondaryLookupItem: {},
      secondaryLookupList: [],
      id: 0,
      mode: MODULE.PRICING.ActionId,
    };
  },
  computed: {
    primaryLookupTitle() {
      if (this.mode === MODULE.PRICING.ActionId) {
        return "Pricing List";
      } else if (this.mode === MODULE.INVENTORY.ActionId) {
        return "Inventory List";
      } else if (this.mode === MODULE.CLIENTS.UX.ClientRateGroup) {
        return "Group List";
      } else {
        return "List";
      }
    },
  },
  watch: {
    mode(value) {
      (this.defaultInventorySelected = {
        displayName: "List (Optional)",
        id: "0",
      }),
        this.onLoadInventory();
    },
  },

  methods: {
    onSelectCategorySuggestion(selectedObj) {
      if (this.selectedItem) {
        if (selectedObj) {
          this.selectedItem.intQuoteTypeID = selectedObj.id;
        }
      }
    },
    async onSelectSuggestion(selectedObj) {
      if (this.mode === MODULE.CLIENTS.UX.ClientRateGroup && selectedObj) {
        this.loadSecondDropdown();
      } else {
        let item = await global.getEditDetails(selectedObj.actionId, selectedObj.id);
        this.selectedItem.title = item && item.Name ? item.Name : selectedObj.displayName;
        this.selectedItem.description =
          item && item.Name && item.Description
            ? `${item.Name}\n${item.Description ? item.Description : ""}`
            : `${selectedObj.displayName}\n${selectedObj.description ? selectedObj.description : ""}`;
        this.selectedItem.qty = item && item.qty && parseFloat(item.qty) !== 0 ? parseFloat(item.qty) : selectedObj.qty && parseFloat(selectedObj.qty) !== 0 ? parseFloat(selectedObj.qty) : 1;
        this.selectedItem.unitPrice = item && item.SalePrice ? item.SalePrice : selectedObj.unitPrice && parseFloat(selectedObj.unitPrice) !== 0 ? parseFloat(selectedObj.unitPrice) : 0;
        // this.selectedItem.description = selectedObj.displayName;
        // this.selectedItem.unitPrice = selectedObj.unitPrice;
        this.selectedItem.amount = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);
        this.calculateTotalSum();
      }
    },
    async onSelectSecondarySuggestion(selectedObj) {
      if (selectedObj && this.selectedItem) {
        const item = await global.getEditDetails(MODULE.CLIENTS.UX.ClientRates, selectedObj.id);

        // title
        this.selectedItem.title = item && item.Name ? item.Name : selectedObj.displayName;
        // description
        this.selectedItem.description =
          item && (item.Name || item.Description)
            ? `${item.Name}\n${item.Description ? item.Description : ""}`
            : `${selectedObj.displayName}\n${selectedObj.description ? selectedObj.description : ""}`;
        // qty
        this.selectedItem.qty =
          item && item.Quantity && parseFloat(item.Quantity) !== 0 ? parseFloat(item.Quantity) : selectedObj.qty && parseFloat(selectedObj.qty) !== 0 ? parseFloat(selectedObj.qty) : 1;
        // unit price
        this.selectedItem.unitPrice = item && item.Amount ? item.Amount : selectedObj.unitPrice && parseFloat(selectedObj.unitPrice) !== 0 ? parseFloat(selectedObj.unitPrice) : 0;

        this.selectedItem.amount = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);

        this.calculateTotalSum();
      }
    },
    async loadSecondDropdown() {
      this.secondaryLookupItem = {};
      const results = await global.getSimpleUXDropDown(MODULE.CLIENTS.UX.ClientRates, this.mode, this.defaultInventorySelected.id);
      this.secondaryLookupList = results.map((item) => {
        return {
          ...item,
          qty: item.qty ? parseFloat(item.qty) : 0,
          unitPrice: item.unitPrice ? parseFloat(item.unitPrice) : 0,
          amount: item.total ? item.total : 0,
        };
      });
    },
    async onLoadInventory() {
      // let inventoryService = new UXInventoryService();

      // this.inventoryList = await inventoryService.getInventoryList("");
      this.inventoryList = await global.getUXDropDown(this.mode);
    },
    async saveRow(index) {
      this.selectedItem = null;
      this.editingRow = false;
      const payload = {
        ...this.quoteDetailsItems[index],
        actionId: MODULE.QUOTATION.ActionId,
        parentActionID: MODULE.QUOTATIONDETAILS.ActionId,
        parentId: this.dataObj.data.id,
      };
      await api.updateData(payload);

      await this.reloadItemList();
    },
    async reloadItemList() {
      this.quoteDetailsItems = await api.getQuoteDetails(this.dataObj.data.id);
    },
    editRow(index) {
      this.selectedItem = this.quoteDetailsItems[index];
      this.editingRow = true;
    },
    calculateItemTotal(index) {
      if (this.selectedItem) {
        let qty = this.selectedItem.qty;
        let unitPrice = this.selectedItem.unitPrice;
        this.selectedItem.amount = this._.multiply(qty, unitPrice);
      }
      this.calculateTotalSum();
    },
    calculateTotalSum() {
      this.subTotal = this._.sumBy(this.quoteDetailsItems, (item) => item.amount);
      let formattedValue = this.$options.filters.currency(this.subTotal);
      //Broadcast to any component that care, that the total was changes...
      this.$root.$emit(this.EVENT_ACTION.INFO.UpdateTotal, formattedValue);
    },
    showHideLabel(index) {
      //If we're in disable edit mode, hide options
      if (this.isDisabled) {
        return false;
      } else {
        if (this.isEditingCurrentRow(index)) return false;
        else return true;
      }
    },
    showHideTextBox(index) {
      if (this.isEditingCurrentRow(index)) return true;
      else return false;
    },
    isEditingCurrentRow(index) {
      if (this.selectedItem === this.quoteDetailsItems[index]) return true;
      else return false;
    },
    async deleteItem(index) {
      await api.deleteItem(this.quoteDetailsItems[index]);
      this.quoteDetailsItems.splice(index, 1);
      this.selectedItem = null;
      this.calculateTotalSum();
    },
    async cancelRow(index) {
      if (this.isEditingLastRow()) {
        if (!this.isLastItemRowValid()) this.deleteItem(index);
      }
      this.showErrorWarning = false;
      this.selectedItem = null;
      this.editingRow = false;

      await this.reloadItemList();
    },
    async addNewItem() {
      const newQuoteLineItem = {
        qid: this.dataObj.data.id,
        quoteDetailId: 0,
        title: "",
        description: "",
        intQuoteTypeID: 1,
        qty: 1,
        unitPrice: 0,
        amount: null,
      };

      let shouldAddNewItem = false;

      //Evaluate the last row before adding new item row
      if (this.isLastItemRowValid()) {
        shouldAddNewItem = true;
      } else if (this.quoteDetailsItems.length === 0) {
        shouldAddNewItem = true;
      }

      if (shouldAddNewItem) {
        this.quoteDetailsItems.push(newQuoteLineItem);
        this.editRow(this.quoteDetailsItems.length - 1);
      }
    },
    isLastItemRowValid() {
      //Get the last Item row and ensure that it has values, before allowing user to add additional rows
      let allowAddingNewRow = false;

      if (this.quoteDetailsItems.length > 0) {
        //If current select item is the last row
        if (this.isEditingLastRow()) {
          //If they are no items in the last row then show error message.
          if (this.selectedItem.description === "" && this.selectedItem.qty === 1 && this.selectedItem.unitPrice === 0) {
            this.showErrorWarning = true;
            setTimeout(() => (this.showErrorWarning = false), 4000);
          } else {
            allowAddingNewRow = true;

            //If the last row is not blank, then save it to databse.
            this.saveRow(this.quoteDetailsItems.length - 1);
          }
        } else {
          allowAddingNewRow = true;
        }
      }

      return allowAddingNewRow;
    },
    isEditingLastRow() {
      if (this.selectedItem == this.quoteDetailsItems[this.quoteDetailsItems.length - 1]) return true;

      return false;
    },
    initializeData() {
      const newQuoteLineItem = {
        qid: this.dataObj.data.id,
        quoteDetailId: 0,
        description: "",
        intQuoteTypeID: 1,
        qty: 1,
        unitPrice: 0,
        amount: null,
      };

      this.quoteDetailsItems.push(newQuoteLineItem);
      this.editRow(this.quoteDetailsItems.length - 1);
    },
  },
  async mounted() {
    if (this.currentDataObj.details.JobID) {
      this.isDisabled = this.currentDataObj.details.IsLockedFromEditing !== null ? this.currentDataObj.details.IsLockedFromEditing : true;
    } else {
      this.isDisabled = false;
    }
    await this.onLoadInventory();
  },
  async created() {
    this.MODULE = MODULE;
    this.quoteDetailsItems = await api.getQuoteDetails(this.dataObj.data.id);

    if (this.quoteDetailsItems.length === 0) this.initializeData();
    else this.calculateTotalSum();
  },
};
