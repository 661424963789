<template>
  <div v-if="modalStatus && modalStatus.modalName">
    <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block">
      Add New Group
    </div>

    <div class="ss-light-bg mb-2 p-2 radius-4">
      <div class="row">
        <div class="col-md-12">
          <b-alert show class="mb-2" variant="success" v-if="success.show">
            <i class="fa fa-check-circle mr-2"></i>
            <span class="sub-title mr-2" v-html="success.msg"></span>
          </b-alert>
        </div>

        <div class="col-md-3">
          <span class="c-toolbar__state-title u-text-capitalize">Display Name</span>
          <input class="form-control" type="text" v-model="form.DisplayAs" />
        </div>
        <div class="col pl-0">
          <span class="c-toolbar__state-title u-text-capitalizeh opacity-0 d-block">hidden</span>
          <button class="btn ss-warning-bg ss-white-font-color u-border-zero" @click="createNewItem"><i class="fa fa-plus u-mr-xsmall ss-white-font-color"></i>Add</button>
        </div>
      </div>
    </div>
    <div class="ss--modal-step ss-primary-font-color u-text-bold mt-3 u-mb-small u-inline-block">
      Edit Groups
    </div>
    <table class="table" id="datatable-modal-specific-listing">
      <thead class="c-table__head c-table__head--slim">
        <tr class="c-table__row">
          <th class="c-table__cell c-table__cell--head no-sort w-50px"></th>
          <th class="c-table__cell c-table__cell--head no-sort w-200px">Display Name</th>
          <th class="c-table__cell c-table__cell--head no-sort w-50px">Sort Order</th>
          <th class="c-table__cell c-table__cell--head no-sort text-center w-150px">System</th>
          <th v-if="!isLabelModule" class="c-table__cell c-table__cell--head no-sort text-center w-150px">Set Default</th>
          <th class="c-table__cell c-table__cell--head no-sort w-150px text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="c-table__row" v-for="(item, idx) in list" :key="item.id">
          <td>
            <div v-if="!showSaveButton(idx) && !item.isSystem">
              <a class="edit-link" href="#" @click="editItem(item)">Edit</a>
            </div>
            <span v-else-if="showSaveButton(idx)">
              <a class="edit-link" href="#" @click="saveItem(idx, item)">Save</a>
            </span>
            <span v-else>&nbsp;</span>
          </td>
          <td>
            <span v-if="!showSaveButton(idx)" :class="[{'strike-through': item.isDisabled}]">{{ item.displayName }}</span>
            <span v-else><input type="text" class="form-control" v-model="item.displayName"/></span>
          </td>
          <td>
            <span v-if="!showSaveButton(idx)">{{ item.sortOrder ? item.sortOrder : 0 }}</span>
            <span v-else><input type="text" class="form-control" v-model="item.sortOrder"/></span>
          </td>
          <td class="align-middle">
            <div class=" c-choice c-choice--checkbox mb-0 text-center disabled">
              <input class="c-choice__input" :id="`is-system-${item.id}`" name="checkboxes" type="checkbox" :checked="item.isSystem" disabled />
              <label class="c-choice__label" :for="`is-system-${item.id}`"></label>
            </div>
          </td>
          <td v-if="!isLabelModule" class="align-middle">
            <div class=" c-choice c-choice--checkbox mb-0 text-center">
              <input
                class="c-choice__input"
                :id="`isDefault-${item.id}`"
                name="checkboxes"
                type="checkbox"
                :value="item.isDefault ? true : false"
                v-model="item.isDefault"
                @change="updateDefault(item)"
              />
              <label class="c-choice__label" :for="`isDefault-${item.id}`"></label>
            </div>
          </td>
          <td class="text-right d-flex align-items-center justify-content-end">
            <button :class="['btn mr-2 px-2', {'c-btn-outlined-gray': !item.isDisabled, 'c-btn-outlined-success': item.isDisabled}]" @click="disableItem(idx, item)">
              <i :class="['fa u-text-large', {'fa-ban': !item.isDisabled, 'fa-check': item.isDisabled}]"></i>
              {{ item.isDisabled ? "Enable" : "Disable" }}
            </button>

            <span v-if="!item.isSystem" class="clickable" @click="deleteItem(idx, item)"><i class="fa fa-trash u-text-danger u-text-larger vertical-middle"></i></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  const global = new GlobalServices();
  export default {
    name: "SpecificListing",
    data() {
      return {
        form: {
          DisplayAs: null,
          UnitPrice: null,
          Qty: 1,
          HTMLColour: "#6e0677",
          Status: null,
          moduleId: undefined,
        },
        success: {
          show: false,
          msg: "",
        },
        modalStatus: {},
        listing: [],
        metadata: null,
        error: null,
        selected: null,
        timer: null,
      };
    },
    components: {},
    computed: {
      list() {
        return this.listing;
      },
      isLabelModule() {
        return this.modalStatus.data && this.modalStatus.data.actionId === MODULE.INVOICE.UX.InvoiceLabels;
      },
    },
    methods: {
      async createNewItem() {
        try {
          if (this.modalStatus.data.moduleId) {
            this.form.moduleId = this.modalStatus.data.moduleId;
          }

          if (this.form.UnitPrice === null) {
            delete this.form.UnitPrice;
          }

          if (this.form.DisplayAs) {
            this.form.Status = this.form.DisplayAs;
            this.form.Name = this.form.DisplayAs;
          }

          const actionId = this.modalStatus.data.actionId;
          let result = await global.createNewActivity(actionId, this.form);

          if (result.success) {
            this.success = {
              show: true,
              msg: `Successfully added new item: <strong>${this.form.DisplayAs}</strong>`,
            };

            this.form.DisplayAs = null;
            this.form.Status = null;
            this.form.HTMLColour = null;
            this.form.moduleId = undefined;
            this.form.UnitPrice = null;
            const item = result.resources;

            this.listing = [
              {
                displayName: item.DisplayAs,
                htmlColour: item.HTMLColour,
                sortOrder: 0,
                icon: "",
                isDefault: false,
              },
            ].concat(this.listing);

            setTimeout(() => {
              this.success.show = false;
              this.initializeData();
            }, 3000);
          }
        } catch (err) {
          this.error = err.response;
        }
      },
      showSaveButton(idx) {
        return this.selected === this.list[idx];
      },
      async initializeData() {
        this.modalStatus = this.$store.getters.getModalStatus;
        this.listing = await global.getUXDropDown(this.modalStatus.data.actionId, this.modalStatus.data.moduleId, null, null, null, null, {
          deleted: true,
          disabled: true,
        });
        this.metadata = await global.getUXDropDownMetadata(this.modalStatus.data.actionId);
      },
      editItem(item) {
        this.selected = item;
      },
      async saveItem(index, item) {
        await this.updateField(this.metadata.displayAsColumnName ? this.metadata.displayAsColumnName.trim() : "Name", item.displayName, "Display Name", item.id, true);
        await this.updateField("HTMLColour", item.htmlColour, "Colour", item.id, true);
        await this.updateField("Icon", item.icon, "Icon", item.id, true);
        await this.updateField(this.metadata.sortColumnName ? this.metadata.sortColumnName.trim() : "SortOrder", item.sortOrder ? item.sortOrder : 0, "Sort Order", item.id, true);

        this.success = {
          show: true,
          msg: `Successfully updated item: <strong>${item.displayName}</strong>`,
        };

        this.list[index] = item;
        this.selected = null;

        setTimeout(() => {
          this.success.show = false;
        }, 3000);
      },
      async disableItem(index, item) {
        const isDisabled = item.isDisabled !== null ? !item.isDisabled : true;
        this.list[index].isDisabled = isDisabled;
        await this.updateField("isDisabled", isDisabled, "is Disable", item.id);
        // setTimeout(() => {
        //   this.initializeData();
        // }, 1000);
      },
      async deleteItem(index, item) {
        const isDeleted = item.isDeleted !== null ? !item.isDeleted : true;
        this.list.splice(index, 1);
        await this.updateField("isDeleted", isDeleted, "is Deleted", item.id);
        setTimeout(() => {
          this.initializeData();
        }, 1000);
      },
      async updateDefault(item) {
        // this.selected = item;
        for (const i of this.list) {
          if (parseInt(i.id) !== parseInt(item.id) && i.isDefault) {
            await this.updateField("isDefault", false, "Is Default", i.id, true);
          }
        }
        await this.updateField("isDefault", item.isDefault, "Is Default", item.id, true);

        setTimeout(() => {
          this.initializeData();
          // this.selected = null;
        }, 1000);
      },
      async updateField(propertyName, propertyValue, displayName, itemId = null, immediate = false) {
        let editObj = {};
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.id = itemId;
        editObj.actionId = this.modalStatus.data.actionId;
        if (this.modalStatus.data.moduleId) {
          editObj.moduleId = this.modalStatus.data.moduleId;
        }
        editObj.isUXProperty = true;
        let isValid = await this.isValidated(propertyName, propertyValue);

        if (isValid) {
          this.postAPIData(editObj, immediate);
        }
      },
      async isValidated(propertyName, propertyValue) {
        //Prevent empty value from being sent
        if (propertyValue == null || propertyValue.length == 0) return false;

        return true;
      },
      async postAPIData(dataObj, immediate = false) {
        if (immediate) {
          await global.postData(dataObj);
        } else {
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          this.timer = setTimeout(() => {
            global.postData(dataObj);
          }, 500);
        }
      },
    },
    created() {
      this.MODULE = MODULE;
    },
    mounted() {
      this.initializeData();
    },
  };
</script>

<style lang="scss">
  .strike-through {
    text-decoration: line-through;
  }
  .c-choice.c-choice--checkbox.disabled .c-choice__input+.c-choice__label::before{
    background: #e5e5e5;
    border-color: #b0b0b0;
    color: #b0b0b0;
  }
</style>
