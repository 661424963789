<template>
  <div class="h-100">
    <div class="col-md-12" v-if="!paymentSuccess">
      <ValidationObserver ref="formInvoiceRecordPayment">
        <b-card no-body class="px-3 pt-3 pb-2 mb-3 dash-grey-card border-0">
          <div class="row">
            <div class="col-md-4 ss--new-quote__modal_module u-mb-small">
              <ValidationProvider name="Client" v-slot="{errors}" rules="required">
                <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">1</span>Choose a Client</div>
                <v-select
                  code="id"
                  label="displayName"
                  id="modal-billing-invoice-record-payment-choose-client"
                  :clearable="false"
                  :options="UXClientList"
                  @input="onSelectClient"
                  v-model="selectedClient"
                  :disabled="disabledClientSelection"
                >
                </v-select>
                <span class="text-danger">{{ errors[0] ? "Client is required." : "" }}</span>
              </ValidationProvider>
            </div>

            <div class="col-lg-4 col-md-6 u-mb-xsmall u-border-left">
              <div class="info-box">
                <div class="ss-success-bg u-border-rounded text-right pr-3 py-2">
                  <p class="mb-0 ss-white-font-color mb-0">Total Outstanding</p>
                  <h3 class="ss-white-font-color mb-1">
                    $0.00
                  </h3>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 u-mb-xsmall">
              <div class="info-box">
                <div class="ss-info-bg u-border-rounded text-right pr-3 py-2">
                  <p class="mb-0 ss-white-font-color mb-0">Total Payment Amount</p>
                  <h3 class="ss-white-font-color mb-1">
                    $0.00
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </b-card>

        <!-- <div class="col-lg-3 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Total OutStanding</span>
        <h5 class="font-weight-bold">$ 0.00</h5>
      </div>

      <div class="col-lg-3 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Available Credits</span>
        <h5 class="font-weight-bold">$ 0.00</h5>
      </div> -->
        <b-card no-body class="px-3 pt-3 pb-2 mb-3 overflow-hidden dash-grey-card border-0">
          <div class="ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">2</span>Select Invoices to Pay</div>

            <div class="table-responsive table-responsive-md w-auto dashboard-table scrollbar record-payment-modal-table mb-0">
              <table class="table" id="datatable">
                <thead class="c-table__head c-table__head--slim">
                  <tr class="c-table__row">
                    <th class="c-table__cell c-table__cell--head pl-2 no-sort">
                      <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                        <input class="c-choice__input" id="modal-billing-invoice-record-payment-apply-credits" name="checkboxes" type="checkbox" />
                        <label class="c-choice__label mb-0" for="modal-billing-invoice-record-payment-apply-credits"></label>
                      </div>
                    </th>
                    <th class="c-table__cell c-table__cell--head no-sort w-150px">Payment Amount</th>
                    <th class="c-table__cell c-table__cell--head no-sort">InvoiceNo</th>
                    <th class="c-table__cell c-table__cell--head no-sort">Description</th>
                    <th class="c-table__cell c-table__cell--head no-sort">Invoice Total</th>
                    <th class="c-table__cell c-table__cell--head no-sort">Outstanding</th>
                    <th class="c-table__cell c-table__cell--head no-sort">Invoice Date</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="invoiceList && invoiceList.length > 0">
                    <tr class="c-table__row" v-for="invoice in invoiceList" :key="invoice.intInvoiceID">
                      <td class="text-left pl-2 pr-0 w-50px">
                        <!-- <a href="#" @click="editing = !editing">{{ editing ? 'Save' : 'Edit' }}</a> -->
                        <div class="c-choice c-choice--checkbox u-mr-small mb-0 mt-2">
                          <input class="c-choice__input" id="modal-billing-invoice-record-payment-apply-credits" name="checkboxes" type="checkbox" />
                          <label class="c-choice__label" for="modal-billing-invoice-record-payment-apply-credits"></label>
                        </div>
                      </td>
                      <td class="align-middle">
                        <div class="input-group input-with-dollar-sign">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input type="number" class="form-control" v-model="invoice.amountTobePaid" />
                        </div>
                      </td>
                      <td class="align-middle">{{ invoice.invoiceNo }}</td>
                      <td class="text-nowrap align-middle">{{ invoice.invoiceReference | truncate(40) }}</td>
                      <td class="align-middle">
                        <span v-if="!editing">{{ invoice.amount | currency }}</span>
                        <span v-else><input type="number" class="form-control"/></span>
                      </td>

                      <td class="align-middle">
                        {{ invoice.balance | currency }}
                      </td>
                      <td class="align-middle">{{ invoice.invoiceDate }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="7" class="text-center">No invoices available</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </b-card>

        <b-card no-body class="px-3 pt-3 pb-2 dash-grey-card border-0">
          <div class="row">
            <div class="col-md-12 ss--new-quote__modal_module">
              <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">3</span>Enter Payment Details</div>
            </div>

            <div class="col-lg-3 col-md-6 u-mb-xsmall">
              <ValidationProvider name="paymentType" v-slot="{errors}" rules="required">
                <span class="c-toolbar__state-title u-text-capitalize">Payment Type</span>
                <v-select v-model="selectedPaymentMethod" code="id" label="displayName" id="modal-billing-invoice-record-payment-invoice-to-pay" :options="UXPaymentList"></v-select>
                <span class="text-danger">{{ errors[0] ? "Payment type is required." : "" }}</span>
              </ValidationProvider>
            </div>

            <div class="col-lg-3 col-md-6 u-mb-xsmall">
              <ValidationProvider name="paymentDate" v-slot="{errors}" rules="required">
                <span class="c-toolbar__state-title u-text-capitalize">Received Payment Date</span>
                <date-picker v-model="dateReceived" value-type="format" format="DD-MMM-YYYY" type="date" lang="en" id="modal-billing-invoice-record-payment-received-payment-date"> </date-picker>
                <span class="text-danger">{{ errors[0] ? "Payment date is required." : "" }}</span>
              </ValidationProvider>
            </div>

            <!-- <div class="col-lg-3 col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">Total Amount Being Paid</span>
        <h5 class="font-weight-bold">$ 0.00</h5>
      </div> -->

            <div class="col-lg-3 col-md-6 u-mb-xsmall">
              <span class="c-toolbar__state-title u-text-capitalize">Balance After Payment</span>
              <h2 class="font-weight-bold">$0.00</h2>
            </div>

            <div class="col-md-12 u-mb-xsmall">
              <span class="c-toolbar__state-title u-text-capitalize">Note</span>
              <textarea class="form-control" type="text" v-model="notes" rows="1" />
            </div>
            <!-- <div class="col-md-12 c-choice c-choice--checkbox u-mr-small mb-0 mt-2">
        <input class="c-choice__input" id="modal-billing-invoice-record-payment-apply-credits" name="checkboxes"
          type="checkbox" />
        <label class="c-choice__label" for="modal-billing-invoice-record-payment-apply-credits">Apply available credits
          to selected invoice(s)</label>
      </div> -->
          </div>
        </b-card>
      </ValidationObserver>
    </div>
    <SuccessScreen v-else />
  </div>
</template>
<script>
  import SuccessScreen from "@/components/_universal/global/success-screen.vue";
  import GlobalServices from "@/services/global";
  import InvoiceServices from "@/services/invoice";
  import MODULE from "@/constants/modules";
  import EVENTS from "@/constants/events";
  import UtilityString from "@/utilities/strings";
  import moment from "moment";
  const utility = new UtilityString();
  const global = new GlobalServices();
  const invoice = new InvoiceServices();
  export default {
    name: "InvoiceRecordPaymentModal",
    data() {
      return {
        EVENT_ACTION: null,
        editing: false,
        disabledClientSelection: false,
        selectedClient: null,
        selectedPaymentMethod: null,
        dateReceived: null, //Add 5 days to current date by default,
        UXClientList: [],
        UXPaymentList: [],
        invoiceList: [],
        paymentSuccess: false,
        notes: null,
        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },
      };
    },
    components: {
      SuccessScreen,
    },
    methods: {
      async onSelectClient(e) {
        this.invoiceList = await invoice.getOutstandingInvoiceByClientId(e.id);
      },
      async init() {
        this.modalStatus = this.$store.getters.getModalStatus;

        this.UXClientList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
        this.UXPaymentList = await global.getUXDropDown(MODULE.PAYMENT.UX.PaymentType);

        // client id = idRefNumber2
        if (this.modalStatus.data && this.modalStatus.data.idRefNumber2) {
          this.selectedClient = this.UXClientList.find((client) => client.id === this.modalStatus.data.idRefNumber2);
          this.disabledClientSelection = true;
          this.onSelectClient(this.selectedClient);
        }
        this.dateReceived = moment().format("DD-MMM-YYYY");
      },
      async onSave() {
        const isValid = await this.$refs.formInvoiceRecordPayment.validate();

        if (isValid) {
          for (const invoice of this.invoiceList) {
            if (parseFloat(invoice.amountTobePaid) > 0) {
              let payload = {
                ClientID: this.selectedClient.id,
                ModuleID: MODULE.INVOICE.ActionId, // id 27
                InvoiceID: invoice.intInvoiceID, // invoice loop intInvoiceID
                ProjectID: invoice.intProjectID, // invoice loop intProjectID
                ReceivedPaymentDate: this.dateReceived,
                PaymentTypeID: this.selectedPaymentMethod.id,
                Notes: this.notes,
                Amount: parseFloat(invoice.amountTobePaid), // invoice loop
              };

              //Remove all spaces from properties
              payload = utility.trimObjectPropertySpace(payload);

              await global.createNewActivity(MODULE.PAYMENT.ActionId, payload);
            }
          }
          this.paymentSuccess = true;
        }
      },
    },
    mounted() {
      this.init();
    },
    created() {
      this.EVENT_ACTION = EVENTS;
      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.INVOICE.RecordPayment, this.onSave);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.INVOICE.RecordPayment, this.onSave);
    },
  };
</script>
<style>
  .record-payment-modal-table {
    height: 280px;
  }
</style>
