<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="mt-3" class="position-relative" style="top:-15px">
      <b-tab title="Basic" active>
        <div class="row">
          <!-- Top -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Site/Store/Location Name</span>

                <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-store-name" type="text"
                  v-model="modalDataDetails.StoreName" @keyup="
                    updateFieldSimplified(
                      'StoreName', //PropertyName
                      modalDataDetails.StoreName, //Property Value
                      'StoreName', //Display Name
                      true
                    )
                  " />
              </div>
              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Override Client Billing Name</span>
                <input class="form-control"
                  id="slidepanel-client-details-tabs-add-new-location-overrride-client-billing-name" type="text"
                  v-model="modalDataDetails.OverideBillingName" @keyup="
                    updateFieldSimplified(
                      'OverideBillingName', //PropertyName
                      modalDataDetails.OverideBillingName, //Property Value
                      'Overide BillingName', //Display Name
                      true
  )
                  " />
              </div>
              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Primary Contact</span>
                <v-select v-if="modalObj.isInEditMode" :options="modalUXData.uxContacts"
                  id="slidepanel-client-details-tabs-add-new-location-primary-contact"
                  :value="this.getSelectedDropDownValue('uxContacts', 'IntContactID', true)" @input="
                    onDropdownSelectionChange(
                      $event,
                      'Contact', //Display Name
                      'uxContacts', //Dropdown List Object
                      'IntContactID', //Edit Details Property Name
                      true
                    )
                  " label="displayName"></v-select>
                <v-select v-else :options="modalUXData.uxContacts" v-model="modalDataDetails.IntContactID"
                  :reduce="(location) => location.id"
                  id="slidepanel-client-details-tabs-add-new-location-primary-contact" label="displayName"></v-select>
              </div>
              <div class="col-md-12 border-bottom u-mt-small u-mb-small u-height-2"></div>
            </div>
          </div>

          <!-- Middle -->
          <div class="col-md-6">
            <div class="row">
              <!-- Address & Notes -->
              <div class="col-md-12 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Address</span>
                <ValidationProvider v-slot="v" name="address" rules="required">
                  <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-address" type="text"
                    v-model="modalDataDetails.Address" @keyup="
                      updateFieldSimplified(
                        'Address', //PropertyName
                        modalDataDetails.Address, //Property Value
                        'Address', //Display Name
                        true
                      )
                    " />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">City</span>
                <ValidationProvider v-slot="v" name="city" rules="required">
                  <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-stor-city" type="text"
                    v-model="modalDataDetails.City" @keyup="
                    updateFieldSimplified(
                      'City', //PropertyName
                      modalDataDetails.City, //Property Value
                      'City', //Display Name
                      true
                    )
                  " />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">State/Province</span>
                <ValidationProvider v-slot="v" name="state/province" rules="required">
                  <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-state-province"
                    type="text" v-model="modalDataDetails.Province" @keyup="
                    updateFieldSimplified(
                      'Province', //PropertyName
                      modalDataDetails.Province, //Property Value
                      'Province', //Display Name
                      true
                    )
                  " />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Zip/Postal Code</span>
                <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-zip-code" type="text"
                  v-model="modalDataDetails.PostalCode" @keyup="
                  updateFieldSimplified(
                    'PostalCode', //PropertyName
                    modalDataDetails.PostalCode, //Property Value
                    'Zip/Postal Code', //Display Name
                    true
                  )
                " />
              </div>
              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Country</span>
                <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-country" type="text"
                  v-model="modalDataDetails.Country" @keyup="
                  updateFieldSimplified(
                    'Country', //PropertyName
                    modalDataDetails.Country, //Property Value
                    'Country', //Display Name
                    true
                  )
                " />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Overide Client Billing Email</span>
                <input class="form-control"
                  id="slidepanel-client-details-tabs-add-new-location-override-client-billing-email" type="text"
                  v-model="modalDataDetails.OverideBillingEmailAddress" @keyup="
                  updateFieldSimplified(
                    'OverideBillingEmailAddress', //PropertyName
                    modalDataDetails.OverideBillingEmailAddress, //Property Value
                    'Overide Billing Email Address', //Display Name
                    true
                  )
                " />
              </div>
              <div class="col-md-12 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Email</span>
                <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-email" type="text"
                  v-model="modalDataDetails.Email" @keyup="
                  updateFieldSimplified(
                    'Email', //PropertyName
                    modalDataDetails.Email, //Property Value
                    'Location Email', //Display Name
                    true
                  )
                " />
              </div>

              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Phone</span>
                <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-phone" type="text"
                  v-model="modalDataDetails.Phone" @input="formatPhoneNumber('Phone')" @keyup="
                    updateFieldSimplified(
                      'Phone', //PropertyName
                      modalDataDetails.Phone, //Property Value
                      'Location Phone', //Display Name
                      true
                    )
                  " />
              </div>
              <div class="col-md-6 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Fax</span>
                <input class="form-control" id="slidepanel-client-details-tabs-add-new-location-fax" type="text"
                  v-model="modalDataDetails.Fax" @input="formatPhoneNumber('Fax')" @keyup="
                    updateFieldSimplified(
                      'Fax', //PropertyName
                      modalDataDetails.Fax, //Property Value
                      'Location Fax', //Display Name
                      true
                    )
                  " />
              </div>
            </div>
          </div>

          <!-- col-md-12-->
          <div class="col-md-12">
            <span class="c-toolbar__state-title u-text-capitalize">Location Specific Notes</span>

            <textarea class="form-control" id="slidepanel-client-details-tabs-add-new-location-specific-notes"
              type="text" rows="6" v-model="modalDataDetails.Notes" @keyup="
              updateFieldSimplified(
                'Notes', //PropertyName
                modalDataDetails.Notes, //Property Value
                'Location npNotes', //Display Name
                true
              )
            " />
          </div>
        </div>
      </b-tab>
      <b-tab v-if="modalObj.isInEditMode" title="Equipment">
        <div class="c-choice c-choice--checkbox u-mr-small mb-0 border-bottom py-3" v-for="eq in equipmentListTab"
          :key="eq.id">
          <input class="c-choice__input" :id="eq.id" name="checkboxes" type="checkbox" />
          <label class="c-choice__label align-items-start mb-0" :for="eq.id">
            <div class="position-relative" style="top: -5px">
              <h5 class="mb-0">{{ eq.name }}</h5>
              <span><strong>{{ eq.modelNumber }}</strong> - {{ eq.locationOnSite }}</span>
            </div>
          </label>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UtilityString from "@/utilities/strings";
  const utility = new UtilityString();
  let global = new GlobalServices();

  import editMixin from "@/mixin/edit-mixin";

  export default {
    mixins: [editMixin],
    data() {
      return {
        modalDetails: {},
        tabIndex: 0,
        equipmentListTab: [],
      };
    },
    watch: {
      modalDataDetails: {
        handler(val) {
          //Ensure that the moduleId and client are always set.
          //After each submit, the store is cleared
          //If the user decides to add another record this will
          //append the necessary ids to connect to record to the parent.
          val.moduleId = MODULE.LOCATION.ModuleId;
          val.clientId = this.modalObj.data.id;

          this.updateStore(val);
        },
        deep: true,
      },
      tabIndex(value) {
        if (value === 1) {
          // other items tab
          this.getEquipmentWithParent();
        }
      },
    },
    methods: {
      formatPhoneNumber(propertyName) {
        this.modalDataDetails[propertyName] = utility.formatPhoneNumber(this.modalDataDetails[propertyName]);
      },
      updateStore(data) {
        this.$store.dispatch("setModalDataDetailsObject", data);
      },
      async getEquipmentWithParent() {
        const payload = {
          id: parseInt(this.$route.params.id),
          parentActionId: MODULE.CLIENTS.ActionId,
          actionId: MODULE.EQUIPMENT.ActionId,
        };
        this.equipmentListTab = await global.getTabTableleDataByActionId(payload);
      },
    },
    async mounted() {},
    async created() {
      //Get edit data
      let clientId = this.modalObj.isInEditMode ? this.$route.params.id : this.modalObj.data.id;
      if (this.modalObj.isInEditMode) {
        //Load Details Details from API
        this.modalDetails = await global.getEditDetails(MODULE.LOCATION.ActionId, this.modalObj.data.id);
      }
      this.modalUXData.uxContacts = await global.getUXDropDown(MODULE.CONTACTS.ActionId, MODULE.LOCATION.ModuleId, null, {
        clientId: clientId,
        projectId: 0,
      });
      //New Item
      //Set Reference ID
      this.modalDetails.moduleId = MODULE.LOCATION.ModuleId;
      this.modalDetails.clientId = this.modalObj.data.id;

      this.updateStore(this.modalDetails);

      //this.$store.dispatch("setModalDataDetailsObject", this.modalDetails);
      this.$store.dispatch("setModalUXData", this.modalUXData);
    },
  };
</script>
