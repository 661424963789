import axios from 'axios';
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";


let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;


class UXEmployeeService {

	  constructor() {

        apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }

    }

	async getEmployees() {
		let results;
		let auth = AuthService.getInstance();
		let queryString = auth.getQueryString();

		let config = new API_CONFIG();
		axios.defaults.baseURL = config.API_URL;

		results = await axios.get('employees/list/get' + queryString, apiHeaderObj);

		return results.data.resources;
	}
}

export default UXEmployeeService;
