<template>
  <div class="row customFooterModalBodyEmail">
    <!-- <div v-if="loading" class="col-md-7 text-center align-items-center">
      <h3>Generating Document... Please wait...</h3>
    </div> -->
    <div v-if="attachments && attachments.length > 0" class="col-md-7">
      <span class="u-text-mute">Select one or more file(s) below to send/attach with this email.</span>

      <div v-for="n in attachments" :key="n.id" class="blue-hover pl-2 pb-2 pt-2 u-border-bottom" @mouseover="showFilePreview(n)" @mouseout="preview.show = false">
        <div class="c-choice c-choice--checkbox u-mr-small mb-0 email-attachments d-flex w-100">
          <input class="c-choice__input" :id="`checkbox-${n.id}-email-modal-attachment-tab-checkbox`" name="checkboxes" type="checkbox" @change="handleCheckboxChange(n)" v-model="n.isSelected"  />
          <label class="c-choice__label mb-0" :for="`checkbox-${n.id}-email-modal-attachment-tab-checkbox`">
            <div class="d-flex align-items-center w-100">
              <i :class="getIcon(n)" id="email-modal-attachment-tab-document-file-type"></i>
              <div>
                <h5 href="#" class="mr-2 mb-0" id="email-modal-attachment-tab-document-name">{{ n.documentName }}</h5>
                <!-- for major types, use the below colors and for test of it, use  bg-secondary -->
                <!-- ss-primary-bg, ss-warning-bg, ss-success-bg, ss-danger-bg, ss-info-bg -->

                <!-- <div v-if="n.documentType === 'Generated'" class="badge c-badge--xsmall ss-primary-bg mr-1" id="email-modal-attachment-tab-document-type">{{ n.documentType }}</div> -->
                <div class="badge c-badge--xsmall bg-secondary" id="email-modal-attachment-tab-document-type">{{ n.type }}</div>
              </div>
            </div>
          </label>
          <div class="pt-1 ml-auto col-3 p-0 text-right">
            <p v-if="getTypeBaseOnIcon(n.icon) !== 'docs'" :id="`email-modal-attachment-tab-document-view-${n.id}`" class="u-color-info clickable pr-2" @click.stop="goToURL(n)">
              View <i class="fa fa-external-link"></i>
            </p>
            <p v-if="getTypeBaseOnIcon(n.icon) === 'docs'" :id="`email-modal-attachment-tab-document-download-${n.id}`" class="u-color-info clickable pr-2" @click.stop="downloadWordDoc(n.url)">
              Download <i class="fa fa-download"></i>
            </p>
            <!-- <p class="u-color-danger clickable pr-2">Close View <i class="fa fa-close"></i></p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5">
      <div class="sticky-section">
        <div class="file-output p-2 position-relative" v-if="preview.show">
          <span :class="[preview.loading ? 'opacity-1' : 'opacity-0', 'position-absolute text-center w-100 top-0 bottom-0 mb-3']">
            <LoadingScreen />
          </span>
          <span :class="[preview.loading ? 'opacity-0' : 'opacity-1']">
            <b-img-lazy v-if="preview.type === 'image'" :src="preview.url" :alt="preview.type" />
            <div v-else-if="preview.type === 'docs'">Preview Unavailable</div>
            <pdf-loader v-else :pdf-url="preview.url" class="email-pdf-preview" />
          </span>
        </div>
        <div class="file-uploader email-attachment-uploader" v-else>
          <div id="email-modal-attachment-tab-document-uploader">
            <uploader :moduleId="modalStatus.moduleId" :actionId="modalStatus.actionId" @uploadSuccess="onUploadAttachment" :id="modalStatus.data.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import uploader from "@/components/_universal/uploader/filepond.vue";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import ApplicationService from "@/services/application";
  import DefaultImage from "@/assets/images/support-staff.png";
  import PdfLoader from "@/components/_universal/utility/pdf-loader";
  import LoadingScreen from "../_universal/global/loading-screen.vue";

  const app = new ApplicationService();
  const global = new GlobalServices();
  export default {
    components: {
      uploader,
      PdfLoader,
      LoadingScreen,
    },
    data() {
      return {
        loading: false,
        attachmentList: [],
        preview: {
          show: false,
          loading: false,
          url: DefaultImage,
          type: "image", // pdf, image etc.
        },
      };
    },
    computed: {
      modalStatus() {
        return this.$store.getters.getModalStatus;
      },
      
      emailData: {
        get() {
          return this.$store.getters.getEmailData;
        },
        set(value) {
          this.$store.commit("setEmailData", value);
        },
      },
      attachments() {
        return this.$store.getters.getAttachment;
      },
    },
  methods: {
 
 handleCheckboxChange(changedAttachment) {
  const updatedList = this.attachmentList.map(attachment => {
    if (attachment.id === changedAttachment.id) {    
      // Toggle isSelected directly here
      let changed = { ...changedAttachment };
   ;
      return changed;
    }else{
      return attachment;
    } 
  });

  this.attachmentList = updatedList;
  this.$store.commit("setAttachment", updatedList);
},
      showFilePreview(file) {
        this.preview.show = true;
        this.preview.loading = true;

        this.preview.url = file.url;
        this.preview.type = this.getTypeBaseOnIcon(file.icon);

        setTimeout(() => {
          this.preview.loading = false;
        }, 3000);
      },
      getTypeBaseOnIcon(icon) {
        switch (icon) {
          case "image":
            return "image";
          case "pdf":
            return "pdf";
          default:
            return "docs";
        }
      },
      downloadWordDoc(url) {
        if (!url) return;
        var lnk = "ms-word:ofe|u|" + url + "|a|Web";
        window.location.href = lnk;
      },
      goToURL(file) {
        let url = null;
        if (file.icon === "pdf") {
          url = `/pdf/preview?url=${file.url}&name=${file.documentName}&wordURL=${file.wordUrl}`;
        } else {
          url = `/image/preview?url=${file.url}&name=${file.documentName}`;
        }

        if (url) window.open(url, "_blank");
      },
      getIcon(data) {
        if (data.icon === "pdf") {
          return "fa fa-file-pdf-o mb-0 text-danger mr-3 ml-1";
        } else if (data.icon === "word") {
          return "fa fa-file-word-o mb-0 text-info mr-3 ml-1";
        } else if (data.icon === "excel") {
          return "fa fa-file-excel-o mb-0 text-success mr-3 ml-1";
        } else if (data.icon === "video") {
          return "fa fa-file-video-o mb-0 mr-3 ml-1";
        } else if (data.icon === "audio") {
          return "fa fa-file-audio-o mb-0 text-warning mr-3 ml-1";
        } else if (data.icon === "image") {
          return "fa fa-file-image-o ss-primary-font-color mb-0 mr-3 ml-1";
        } else {
          return "fa fa-file-o mr-3 ml-1";
        }
      },
      onUploadAttachment(file) {
        this.loadAttachments();
      },
      async loadData() {
        this.loading = true;
        this.$emit("loading", true);
        this.loadAttachments();
      },

      async loadAttachments() {
        const id = this.modalStatus.data.id;
        const parentActionId = this.modalStatus.actionId;
        const actionId = MODULE.DOCUMENTS.ActionId;
        const moduleId = this.modalStatus.moduleId;
        const results = await global.getDocumentDataByActionId({
          id,
          parentActionId,
          moduleId,
          actionId,
          param1: "emailModal",
        });

        this.attachmentList = results
          .filter((d) => d.type !== "Internal Use Only")
          .map((document) => {
            return {
              documentId: parseInt(document.id),
              id: parseInt(document.id),
              documentName: document.name,
              url: document.url,
              wordUrl: document.wordUrl,
              displayCreatedDate: document.createdDate,
              description: document.description,
              size: document.size || 0,
              documentType: document.url.split(".").pop() === "pdf" ? "Generated" : "Attachments",
              type: document.type,
              isDeletable: document.isDeletable,
              icon: document.icon,
              isSelected: true,
            };
          });
        this.$store.commit("setAttachment", this.attachmentList);
      },

      async getPreviewDocument() {
        const id = this.modalStatus.data.id;
        const moduleId = this.modalStatus.moduleId;
        const documentGenerated = await app.print(moduleId, id);
        if (!documentGenerated) {
          this.loading = true;
          // this.$emit("loading", true);
        } else {
          // if loading was set to true
          // load attachments to get the new document
          if (this.loading) {
            this.loadAttachments();
          }
          this.loading = false;
          this.$emit("loading", false);
        }
      },
      clearTimer() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
      },
    },
    mounted() {
      this.loadData();
      //Delay for 3 before loading pdf, this will give the PDF generator time to finish
      //This will help  prevent the PDF from loading before the PDF generator is finished
      const sleep = (time) => new Promise((res) => setTimeout(res, time, "done sleeping"));
      sleep(3000).then((msg) => this.getPreviewDocument());

      if (!this.timer) {
        this.timer = setInterval(() => {
          this.getPreviewDocument();
        }, 5000);
      }
    },
    beforeDestroy() {
      this.clearTimer();
    },
  };
</script>
<style lang="scss">
  .email-attachments i {
    font-size: 21px !important;
  }

  i.fa-external-link {
    font-size: 12px !important;
  }

  i.fa-close {
    font-size: 14px !important;
  }

  .email-attachment-uploader .filepond--root.filepond--hopper {
    height: 50.5vh !important;
  }

  .file-output {
    border: 1px dashed #b9b8b6;
    background: #f0f7f7 !important;
    border-radius: 4px;
    height: 50.5vh;
  }

  .email-pdf-preview {
    #sidebarContainer {
      display: none;
    }

    #viewerContainer {
      left: -15px !important;
      overflow: hidden;
    }
  }

  .sticky-section {
    position: sticky;
    top: 40px;
  }
</style>
