<template>
  <div>
    <!-- Header Section -->
    <panelheader v-if="sidePanelDataObj.headerDataObj" :header-data-obj="sidePanelDataObj.headerDataObj"></panelheader>
    <!-- Infor Bar -->
    <info v-if="sidePanelDataObj.info" :data-obj="sidePanelDataObj" class="px-2"></info>

    <!-- Action Bar Section -->
    <actionbar v-if="sidePanelDataObj.actionBarDataObj" :action-bar-data-obj="sidePanelDataObj.actionBarDataObj"></actionbar>

    <!-- Summary Section -->
    <panelsummary v-if="sidePanelDataObj.infoSectionDataObj" :info-section-data-obj="sidePanelDataObj.infoSectionDataObj"></panelsummary>

    <!-- Tabs Section -->
    <tabs v-if="sidePanelDataObj.iFrame" :tab-data-obj="sidePanelDataObj.tabSectionDataObj" :iFrame="sidePanelDataObj.iFrame"></tabs>
  </div>
</template>

<script>
  //Standard Components
  import EVENTS from "@/constants/events";

  //Template Layout Components
  import panelheader from "@/components/_universal/sidepanel/sidepanel-header";
  import actionbar from "@/components/_universal/sidepanel/sidepanel-action-bar";
  import panelsummary from "@/components/_universal/sidepanel/sidepanel-summary-info";
  import tabs from "@/components/_universal/sidepanel/sidepanel-tabs";
  import info from "@/components/_universal/details-info.vue";

  //Controller
  import ProjectController from "@/controllers/projects-controller";
  import {mapActions, mapState} from "vuex";
  export default {
    data() {
      return {
        sidePanelDataObj: {},
        EVENT_ACTION: {},
      };
    },
    computed: {
      ...mapState({
        previousRoute: (state) => state.Projects.previousRoute,
      }),
    },
    methods: {
      ...mapActions(["setPreviousRoute"]),
      async loadData() {
        let sidePanel = this.$store.getters.getSidePanel;

        //Ensure the side bar is open
        if (sidePanel.isOpen === true) {
          //Initialize Controller and pass data to be loaded/displayed
          let projectController = new ProjectController();

          //Set Header Properties to be passed in as prop values
          this.sidePanelDataObj = await projectController.main(sidePanel.data);
        }
      },
    },
    components: {
      panelheader,
      actionbar,
      panelsummary,
      tabs,
      info,
    },
    async mounted() {
      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window["analytics"].page(this.EVENT_ACTION.PROJECTS.View);
      // }

      await this.loadData();
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        // access any getter/action here via vm.$store
        // avoid importing the store singleton and thus creating hard dependencies
        vm.setPreviousRoute(from);
      });
    },
    beforeDestroy() {
      if (this.previousRoute.name === "SearchPage") {
        this.$router.push(this.previousRoute.fullPath);
      }
    },
  };
</script>
