import axios from 'axios';
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";


let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;


class UXInventoryService {

      constructor() {

        apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }

    }
    
    async getInventoryList(query) {
        let results;
        let q = query;

        if (q == undefined)
            q = "";

        let auth = AuthService.getInstance();
        let queryString = auth.getQueryString() + "&displayname=" + q.trimEnd();



        let config = new API_CONFIG();
        axios.defaults.baseURL = config.API_URL;

        results = await axios.get('/ux/inventory' + queryString, apiHeaderObj);

        return results.data.resources;
    }

}

export default UXInventoryService;
