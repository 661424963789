<template>
  <date-picker value-type="format" v-bind="$attrs" v-model="internalDate" lang="en" @change="setDateUTC"></date-picker>
</template>
<script>
  import DatePicker from "vue2-datepicker";
  import moment from "moment";
  export default {
    name: "DatePickerConverter",
    inheritAttrs: false,
    components: {DatePicker},
    props: {
      currentDate: {
        type: String,
        default: "",
      },
      dateType: {
        type: String,
        default: "datetime",
      },
    },
    data() {
      return {
        internalDate: null,
      };
    },
    computed: {},
    watch: {
      currentDate(value) {
        if (value) {
          let vm = this;
          this.$nextTick(() => {
            vm.initialize();
          });
        }
      },
    },
    mounted() {
      this.initialize();
    },
    methods: {
      initialize() {
        if (this.currentDate) {
          this.internalDate = this.formatDate(`${this.currentDate}+00:00`, this.$attrs.format);
        }
      },
      formatDate(date, format = "YYYY-MM-DD hh:mm a") {
        return moment
          .parseZone(date)
          .utcOffset(moment().format("Z"))
          .format(format);
      },
      setDateUTC() {
        if (moment(this.internalDate, this.$attrs.format).isValid()) {
          let format = "YYYY-MM-DDTHH:mm:ss";
          if (this.dateType === "time") {
            format = "HH:mm:ss";
          }

          this.$emit(
            "onUpdate",
            moment(this.internalDate, this.$attrs.format)
              .utc()
              .format(format)
          );
        }
      },
    },
  };
</script>
