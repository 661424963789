<template>
  <div class="table-responsive table-responsive-md w-auto dashboard-table">
    <table class="table" id="datatable">
      <thead class="c-table__head c-table__head--slim">
        <tr class="c-table__row">
          <th class="c-table__cell c-table__cell--head no-sort"></th>
          <th class="c-table__cell c-table__cell--head no-sort">InvoiceNo</th>

          <th class="c-table__cell c-table__cell--head no-sort">Paid</th>
          <th class="c-table__cell c-table__cell--head no-sort">Description</th>
          <th class="c-table__cell c-table__cell--head no-sort">Amount</th>
          <th class="c-table__cell c-table__cell--head no-sort">Outstanding</th>
          <th class="c-table__cell c-table__cell--head no-sort">
            Invoice Date
          </th>
          <th class="c-table__cell c-table__cell--head no-sort">
            Created Date
          </th>
          <th class="c-table__cell c-table__cell--head no-sort">Created By</th>
        </tr>
      </thead>
      <tbody>
        <tr class="c-table__row" v-for="invoice in invoices" :key="invoice.IntInvoiceID">
          <td class="text-left menu-icons pl-2">
            <a @click.prevent="onViewInvoice(invoice)" href="#">View/Edit</a>
          </td>
          <td class="align-middle">{{ invoice.invoiceNo }}</td>
          <td class="align-middle">
            <i v-if="invoice.isPaidInFull" class="fa fa-check-circle text-success" aria-hidden="true"></i>
          </td>
          <td class="text-nowrap align-middle">{{ invoice.description }}</td>
          <td class="align-middle">{{ invoice.amount | currency }}</td>

          <td class="align-middle">
            {{ invoice.outstandingBalance | currency }}
          </td>
          <td class="align-middle">{{ invoice.displayInvoiceDate }}</td>
          <td class="align-middle">{{ invoice.displayCreatedDate }}</td>
          <td class="align-middle">{{ invoice.createdBy }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import InvoiceService from "@/services/invoice";
  import MODULE from "@/constants/modules";
  import EVENTS from "@/constants/events";
  import EarthApplicationService from "@/services/earth-application";
  import {isDev} from "@/config/prototypes";
  const api = new InvoiceService();
  const earth = new EarthApplicationService();

  export default {
    props: {
      dataObj: Object,
    },
    data() {
      return {
        projectId: 0,
        EVENT_ACTION: {},
        invoices: null,
      };
    },
    methods: {
      onViewInvoice(invoice) {
        const portalUrl = isDev() ? "https://dev.jupiter.pages.dev" : "https://portal.snapsuite.io";
        // const portalUrl = isDev() ? "http://localhost:9693" : "https://portal.snapsuite.io";
        window.open(`${portalUrl}/invoices/details/${invoice.invoiceId}`);
      },
      async loadData() {
        this.projectId = this.dataObj.data.intProjectID;
        this.invoices = await api.getInvoiceByProjectId(this.projectId);
      },
    },
    async created() {
      await this.loadData();
    },
    async mounted() {
      this.EVENT_ACTION = EVENTS;

      this.intervalApi = setInterval(async () => {
        await this.loadData();
      }, 3000);
    },
    beforeDestroy() {
      clearInterval(this.intervalApi);
    },
  };
</script>
