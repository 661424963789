import axios from "axios";
import {DashboardFilter} from "@/interfaces/filters/DashboardFilters";
import UtilityString from "@/utilities/strings";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;
let previousRequest = null;

class DashboardService {
  config = new API_CONFIG();
  auth = AuthService.getInstance();

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async initializeAndConfigureDashboard() {
    let queryString = this.auth.getQueryString();

    //Trigger dashboard configuration
    await axios.get("dashboard/configuration" + queryString + "&id=1", apiHeaderObj);

    //Update cache of changes
    await axios.get("dashboard/update/stats" + queryString + "&id=1", apiHeaderObj);
  }

  //Call Dashboard api and return full results to display
  async getDashboardStats(versionNumber, isFirstTimeLoad) {
    let utility = new UtilityString();
    let param = this.getFilter(null);
    //param.version = versionNumber;
    let dataResults;
    let paramString = utility.serializeObject(param);
    let config = new API_CONFIG();
    let callRealDataApi: boolean = false;

    axios.defaults.baseURL = config.API_URL;

    dataResults = await axios.get("dashboard/stats?" + paramString, apiHeaderObj);

    return dataResults.data;
  }

  async getExportExcel(filter: DashboardFilter) {
    let utility = new UtilityString();
    let param = this.getFilter(filter);
    let dataResults;
    let paramString = utility.serializeObject(param);
    let config = new API_CONFIG();

    axios.defaults.baseURL = config.API_URL;

    dataResults = await axios.get("v2/export/projects?" + paramString, apiHeaderObj);

    return dataResults.data;
  }

  async getProjects(filter: DashboardFilter) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (previousRequest) {
      // Cancel the previous request if it exists
      previousRequest.cancel("Request canceled due to new API call");
    }

    let utility = new UtilityString();
    let param = this.getFilter(filter);
    param.maximumRows = param.maximumRows ? param.maximumRows : filter.page === 1 ? 100 : 50;
    let dataResults;
    let paramString = utility.serializeObject(param);
    let config = new API_CONFIG();

    axios.defaults.baseURL = config.API_URL;
    const withCancelHeader = {
      ...apiHeaderObj,
      cancelToken: source.token,
    };
    dataResults = await axios.get("search/dashboard?" + paramString, withCancelHeader);
    // Store the new request for potential cancellation
    previousRequest = source;
    return dataResults.data;
  }

  async dispatchTechnicians(technidianId: number, dispatchDate: Date, projectIds: [], dispatchMessage) {
    let dispatchFiler = {
      employeeId: technidianId,
      schedulerStartDate: dispatchDate,
      projects: `${projectIds.toString()}`,
      message: dispatchMessage,
    };

    let auth = AuthService.getInstance();
    dispatchFiler = Object.assign(dispatchFiler, auth.getAuthObject());

    let dispatchResult = await axios.post("dispatch/projects", dispatchFiler, apiHeaderObj).catch(() => {
      return null;
    });

    return dispatchResult;
  }

  getFilter(filterObj) {
    let filter = {} as DashboardFilter;

    if (filterObj !== null) {
      filter = filterObj;
    }

    filter.id = 0;
    // filter.maximumRows = 100;

    let auth = AuthService.getInstance();
    return Object.assign(filter, auth.getAuthObject());
  }
}

export default DashboardService;
