<template>
  <div>
    <div class="row">
      <!-- Top -->
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">First Name</span>

            <input
              class="form-control"
              type="text"
              id="slidepanel-client-details-tabs-add-new-contact-first-name"
              v-model="modalDataDetails.FirstName"
              @keyup="
                updateFieldSimplified(
                  'FirstName', //PropertyName
                  modalDataDetails.FirstName, //Property Value
                  'FirstName', //Display Name
                  true
                )
              "
            />
          </div>
          <div class="col-lg-3 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Last Name</span>
            <input
              class="form-control"
              id="slidepanel-client-details-tabs-add-new-contact-last-name"
              type="text"
              v-model="modalDataDetails.LastName"
              @keyup="
                updateFieldSimplified(
                  'LastName', //PropertyName
                  modalDataDetails.LastName, //Property Value
                  'LastName', //Display Name
                  true
                )
              "
            />
          </div>
          <div class="col-lg-3 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Position</span>
            <v-select
              :options="uxPositionList"
              v-model="modalDataDetails.IntRoleID"
              id="slidepanel-client-details-tabs-add-new-contact-position"
              :reduce="(role) => role.id"
              :clearable="false"
              @input="
                updateFieldSimplified(
                  'IntRoleID', //PropertyName
                  modalDataDetails.IntRoleID, //Property Value
                  'Position', //Display Name
                  true
                )
              "
              label="displayName"
            ></v-select>
          </div>
          <div class="col-lg-3 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Location</span>

            <v-select
              v-model="modalDataDetails.IntLocationId"
              id="slidepanel-client-details-tabs-add-new-contact-location"
              :reduce="(location) => location.id"
              :options="uxLocationList"
              label="displayName"
              :clearable="false"
              @input="
                updateFieldSimplified(
                  'IntLocationId', //PropertyName
                  modalDataDetails.IntLocationId, //Property Value
                  'Location', //Display Name
                  true
                )
              "
            ></v-select>
          </div>
          <div class="col-md-12 border-bottom u-mt-small u-mb-small u-height-2"></div>
        </div>
      </div>

      <!-- Middle -->

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Email</span>
            <input
              class="form-control"
              type="text"
              id="slidepanel-client-details-tabs-add-new-contact-email"
              v-model="modalDataDetails.Email"
              @keyup="
                updateFieldSimplified(
                  'Email', //PropertyName
                  modalDataDetails.Email, //Property Value
                  'Contact Email', //Display Name
                  true
                )
              "
            />
          </div>

          <div class="col-md-6 mb-2">
            <span class="c-toolbar__state-title u-text-capitalize">Mobile</span>
            <input
              class="form-control"
              type="text"
              id="slidepanel-client-details-tabs-add-new-contact-mobile"
              v-model="modalDataDetails.Mobile"
              @input="formatPhoneNumber('Mobile')"
              @keyup="
                updateFieldSimplified(
                  'Mobile', //PropertyName
                  modalDataDetails.Mobile, //Property Value
                  'Mobile Phone', //Display Name
                  true
                )
              "
            />
          </div>
          <div class="col-md-6 mb-2">
            <span class="c-toolbar__state-title u-text-capitalize">Work</span>
            <input
              class="form-control"
              type="text"
              id="slidepanel-client-details-tabs-add-new-contact-work"
              v-model="modalDataDetails.Phone"
              @input="formatPhoneNumber('Phone')"
              @keyup="
                updateFieldSimplified(
                  'Phone', //PropertyName
                  modalDataDetails.Phone, //Property Value
                  'Work Phone', //Display Name
                  true
                )
              "
            />
          </div>
          <div class="col-md-12 my-2">
            <b-form-checkbox
              class="mb-2"
              id="chkAutoCCEmailOnQuote"
              name="chkAutoCCEmailOnQuote"
              v-model="modalDataDetails.AutoCCEmailOnQuote"
              @change="
                checkboxChanged(
                  'AutoCCEmailOnQuote', //PropertyName
                  !modalDataDetails.AutoCCEmailOnQuote, //Property Value
                  'Copy contact on every quote email', //Display Name
                  true
                )
              "
            >
              <span class="ml-2">Copy contact on every quote email</span>
            </b-form-checkbox>

            <b-form-checkbox
              class="mb-2"
              id="chkAutoCCEmailOnInvoice"
              name="chkAutoCCEmailOnInvoice"
              v-model="modalDataDetails.AutoCCEmailOnInvoice"
              @change="
                checkboxChanged(
                  'AutoCCEmailOnInvoice', //PropertyName
                  !modalDataDetails.AutoCCEmailOnInvoice, //Property Value
                  'Copy contact on every invoice email', //Display Name
                  true
                )
              "
            >
              <span class="ml-2">Copy contact on every invoice email</span>
            </b-form-checkbox>

            <b-form-checkbox
              class="mb-2"
              id="chkAutoCCEmailOnProjects"
              name="chkAutoCCEmailOnProjects"
              v-model="modalDataDetails.AutoCCEmailOnProjects"
              @change="
                checkboxChanged(
                  'AutoCCEmailOnProjects', //PropertyName
                  !modalDataDetails.AutoCCEmailOnProjects, //Property Value
                  'Copy contact on every Job/Work Order email', //Display Name
                  true
                )
              "
            >
              <span class="ml-2">Copy contact on every Job/Work Order email</span>
            </b-form-checkbox>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <!-- Address & Notes -->
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Address</span>
            <textarea
              style="padding-bottom:12px"
              class="form-control"
              id="slidepanel-client-details-tabs-add-new-contact-address"
              type="text"
              rows="4"
              v-model="modalDataDetails.AddressLn1"
              @keyup="
                updateFieldSimplified(
                  'AddressLn1', //PropertyName
                  modalDataDetails.AddressLn1, //Property Value
                  'Address', //Display Name
                  true
                )
              "
              placeholder
            />
          </div>

          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Website/LinkedIn</span>
            <input
              class="form-control"
              type="text"
              id="slidepanel-client-details-tabs-add-new-contact-website-linkedin"
              v-model="modalDataDetails.WebAddress"
              @keyup="
                updateFieldSimplified(
                  'WebAddress', //PropertyName
                  modalDataDetails.WebAddress, //Property Value
                  'Website/LinkedIn', //Display Name
                  true
                )
              "
            />
          </div>
        </div>
      </div>

      <!-- col-md-12-->
      <div class="col-md-12">
        <span class="c-toolbar__state-title u-text-capitalize">Notes/Comments</span>

        <textarea
          class="form-control"
          type="text"
          rows="6"
          v-model="modalDataDetails.Notes"
          @keyup="
            updateFieldSimplified(
              'Notes', //PropertyName
              modalDataDetails.Notes, //Property Value
              'Notes', //Display Name
              true
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";
  let global = new GlobalServices();
  import CONSTANT_MODULE from "@/constants/modules";
  import APPPLICATION from "@/constants/application";
  import editMixin from "@/mixin/edit-mixin";
  import UtilityString from "@/utilities/strings";
  const utility = new UtilityString();
  export default {
    mixins: [editMixin],
    data() {
      return {
        modalDetails: {},
        uxPositionList: [],
        uxLocationList: [],
      };
    },
    modalDataDetails: {
      handler(val) {
        //Ensure that the moduleId and client are always set.
        //After each submit, the store is cleared
        //If the user decides to add another record this will
        //append the necessary ids to connect to record to the parent.
        val.moduleId = module.ModuleId;
        val.clientId = this.modalObj.data.id;

        this.updateStore(val);
      },
      deep: true,
    },
    methods: {
      formatPhoneNumber(propertyName) {
        this.modalDataDetails[propertyName] = utility.formatPhoneNumber(this.modalDataDetails[propertyName]);
      },
      updateStore(data) {
        this.$store.dispatch("setModalDataDetailsObject", data);
      },
      async initializeData() {
        const positionList = await global.getUXDropDown(this.MODULE.ROLES.ActionId);
        this.uxPositionList = positionList || [];

        if (this.$route.name === "client-details") {
          let locationList = await global.getUXDropDown(this.MODULE.CLIENTS.UX.Location, null, null, {
            clientId: this.$route.params.id,
            projectId: 0,
          });
          this.uxLocationList = locationList || [];
        }
      },
    },
    async mounted() {
      await this.initializeData();
    },
    async created() {
      this.APP = APPPLICATION;
      this.MODULE = CONSTANT_MODULE;

      //Get edit data
      if (this.modalObj.isInEditMode) {
        //Load Details Details from API
        this.modalDetails = await global.getEditDetails(this.MODULE.CONTACTS.ActionId, this.modalObj.data.id);
      } else {
        //New Item

        //Set Reference ID
        this.modalDetails.ModuleID = this.MODULE.CONTACTS.ModuleId;
        this.modalDetails.clientId = this.modalObj.data.id;
      }

      this.updateStore(this.modalDetails);
      //this.$store.dispatch('setModalDataDetailsObject', modalDetails);
      // this.$store.dispatch("setModalUXData", this.modalUXData);
    },
  };
</script>
