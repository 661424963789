import html2canvas from 'html2canvas';
import EVENTS from "@/constants/events";

export default {
  data() {
    return {
      screenshotUrl: '',
      modalStatus: {
        hideShowModal: false,
        modalName: "",
        isSaveButtonHidden: false,
        modalTitle: "",
        data: {},
      },
    };
  },
  methods: {
    captureScreen() {
      const slidePanelOpen = document.body.classList.contains("slideout-panel-open");
      // const element = slidePanelOpen ? document.body : document.getElementById("content-wrapper");

      const options = {
        width: slidePanelOpen ? window.innerWidth : window.innerWidth - 250,
        height: window.innerHeight,
        scrollY: slidePanelOpen ? 0: -window.scrollY,
        scrollX: 0,
        x: slidePanelOpen ? 0 : 250,
        imageTimeout: 0,
      }

      this.showBugReportModal(EVENTS.SETTINGS.BugReportModal, 'Bug Report')
      html2canvas(document.body, options).then(canvas => {
        this.screenshotUrl = canvas.toDataURL();
        this.showScreenShot()
      });
      },
    
    showScreenShot() {
      const modalStatus = {
        ...this.modalStatus,
        data: {screenshotUrl: this.screenshotUrl},
      }
      this.$store.dispatch("toggleModalStatus", modalStatus);
    },

    showBugReportModal(paramModalName, modalTitle, actionId?, moduleId?, immediateInit?, isSaveButtonHidden?) {
      this.modalStatus.hideShowModal = true;
      this.modalStatus.modalName = paramModalName;
      this.modalStatus.data = {};

      if (actionId) {
        this.modalStatus.data.actionId = actionId;
      }

      if (moduleId) {
        this.modalStatus.data.moduleId = moduleId;
      }

      this.modalStatus.modalTitle = modalTitle;
      this.modalStatus.isSaveButtonHidden = typeof isSaveButtonHidden != undefined || isSaveButtonHidden !== null ? isSaveButtonHidden : false;
      this.modalStatus.modalSaveButtonTitle = this.modalStatus.isSaveButtonHidden ? "" : "Send";
      if (immediateInit) {
        this.modalStatus.immediateInit = immediateInit;
      }
      this.$store.dispatch("toggleModalStatus", this.modalStatus);
    },
  },
};