



































































































































































































































































































































import editMixin from "@/mixin/edit-mixin";
import globalSettings from "@/mixin/global-settings-mixin";
import UtilityString from "@/utilities/strings";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
const utility = new UtilityString();
export default {
  mixins: [editMixin, globalSettings],
  data() {
    return {};
  },

  created() {
    this.EVENT_ACTION = EVENTS;
    this.MODULE = MODULE;
  },

  methods: {
    formatPhoneNumber(propertyName) {
      this.currentDataObj.details[propertyName] = utility.formatPhoneNumber(this.currentDataObj.details[propertyName]);
    },
    removeCompany() {
      this.updateField("IntParentClientID", 0, "Parent / Reseller Company");
      this.currentDataObj.details.IntParentClientID = 0;
    },
  },
};
