import he from "he";

export default {
    data() {
        return {
        };
    },
    methods: {
      htmlEncodeCharacters(str) {
        if (!str) return
        //Special case to handle degrese, since c# is not parsing it properly
        str = str.replaceAll(/°/g, "&#176;");

        return str;
      },
     htmlDecodeCharacters(str){
          if (!str) return
          str = str.replaceAll("&amp;#176;", "°");
          str = he.decode(str)

        return str;
      },
    },
    async mounted() {

    },


}
