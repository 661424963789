class config {
  private _apiURL: string = process.env.VUE_APP_API;
  private _mockApiURL: string = process.env.VUE_APP_MOCK_API;
  private _apiKey: string = process.env.VUE_APP_API_KEY;

  public get API_URL(): string {
    return this._apiURL;
  }

  public get MOCK_API_URL(): string {
    return this._mockApiURL;
  }

  public get API_KEY(): string {
    return this._apiKey;
  }
  public get SNAPSUITE_KEY(): string {
    return process.env.VUE_APP_SNAPSUITE_KEY ? process.env.VUE_APP_SNAPSUITE_KEY : "786c050484d64b16bd2cfa19d16ed0c6";
  }
}
export default config;
