<template>
  <div>
    <div class="row">
      <small-card v-for="(stat, idx) in mockStats" :key="`stat-${stat.id}`" :border-top="stat.color" :item="stat" :always-highlighted="idx === 0 ? true : false" />
    </div>

    <div class="row mb-3">
      <ChartFilters @reload="setFilters" />
    </div>

    <div class="row">
      <div class="col-lg-6 mb-3 mb-3">
        <BarChart v-if="Object.keys(barDataSets).length > 0" :datasets="barDataSets" :header="jobCostBreakdownLabel" :subheader="''" />
        <b-skeleton-img v-else height="500px"></b-skeleton-img>
      </div>
      <div class="col-lg-6 mb-3 pl-lg-0">
        <PieChart v-if="Object.keys(pieDataSets).length > 0" :datasets="pieDataSets" :header="'Job Value by Status'" :subheader="''" />
        <b-skeleton-img v-else height="500px"></b-skeleton-img>
      </div>
      <div class="col-lg-6 mb-3">
        <LineChart v-if="Object.keys(lineDataSet1).length > 0" :datasets="lineDataSet1" :header="'Job Value Comparison'" :subheader="''" />
        <b-skeleton-img v-else height="500px"></b-skeleton-img>
      </div>
      <div class="col-lg-6 mb-3 pl-lg-0">
        <BarChart v-if="Object.keys(barDataSets2).length > 0" :datasets="barDataSets2" :header="'Top Clients by Job Value'" :subheader="''" />
        <b-skeleton-img v-else height="500px"></b-skeleton-img>
      </div>

      <!-- not done yet -->
      <!-- <div class="col-lg-6 mb-3">
        <ConeChart />
      </div> -->
    </div>
  </div>
</template>

<script>
  import BarChart from "@/components/charts/bar-chart.vue";
  import PieChart from "@/components/charts/pie-chart.vue";
  import LineChart from "@/components/charts/line-chart.vue";
  // import ConeChart from "@/components/charts/cone-chart.vue";
  import charts from "@/mixin/charts-mixin";
  import SmallCard from "@/components/_cards/small-card.vue";
  import ChartFilters from "@/components/_universal/filters/chart-filters.vue";
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  let global = new GlobalServices();
  export default {
    name: "Charts",
    mixins: [charts],
    components: {
      BarChart,
      PieChart,
      LineChart,
      SmallCard,
      ChartFilters,
    },
    data() {
      return {
        mockStats: [
          {
            id: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Unbilled Jobs",
            primaryNumber: "0",
            primarySymbol: "",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "purple",
          },
          {
            id: "4116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Completed - To be Invoiced",
            primaryNumber: "0",
            primarySymbol: "",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "orange",
          },
          {
            id: "3116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Invoiced",
            primaryNumber: "0",
            primarySymbol: "",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "red",
          },
          {
            id: "2116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Gross Profit",
            primaryNumber: "0",
            primarySymbol: "",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "green",
          },
        ],
        actionId: MODULE.PROJECTS.ActionId,
        barDataSets: {},
        pieDataSets: {},
        lineDataSet1: {},
        barDataSets2: {},
        timer: null,
        filters: null,
      };
    },
    computed: {
      jobCostBreakdownLabel() {
        return `Job Cost Breakdown - ${this.dateFilterLabel}`;
      },
      dateFilterLabel() {
        switch (this.filters.DateFilter) {
          case "thisweek":
            return "This Week";
          case "lastweek":
            return "Last Week";
          case "thismonth":
            return "This Month";
          case "thisyear":
            return "This Year";
          default:
            return "This Month";
        }
      },
    },
    methods: {
      setFilters(filters = null) {
        this.filters = filters;
      },
      loadAllRequiredData() {
        this.getUtilizationReport();
        this.reloadCharts();
      },
      async reloadCharts() {
        const params = {
          actionId: MODULE.COMBINATION.MANAGER_REPORT,
          moduleId: MODULE.PROJECTS.ModuleId,
          ...this.filters,
        };
        this.barDataSets = await this.getChartsData(params, "bar", "JobBreakdown", false, ["Job Value", "Total Cost", "Material Cost", "Labor Cost"]);
        this.pieDataSets = await this.getChartsData(params, "pie", "Status");
        this.lineDataSet1 = await this.getChartsData(params, "line", "JobValueComparison", true, ["Job Value", "Quoted", "Invoiced"]);
        this.barDataSets2 = await this.getChartsData(params, "bar", "TopClientsbyJobValue", false, ["Job Value"]);
      },
      async getUtilizationReport() {
        const params = {
          actionId: MODULE.COMBINATION.UTILIZATION,
          moduleId: MODULE.PROJECTS.ModuleId,
          ...this.filters,
        };
        const results = await global.getStatsReportDataByActionId(params);
        this.mockStats = results;
      },
    },
    mounted() {
      this.loadAllRequiredData();
      this.timer = setInterval(async () => {
        await this.loadAllRequiredData();
      }, 5000);
    },
    beforeDestroy() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = false;
    },
  };
</script>
