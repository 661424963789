<template>
  <b-alert :show="successCountDown" @dismissed="hideNotification" class="mb-2 position-sticky top-10 z-index-5" :variant="variant">
    <i class="fa fa-check-circle mr-2"></i>
    <span class="sub-title mr-2"> {{ message }} </span>
  </b-alert>
</template>

<script>
  export default {
    name: "AlertCountdown",
    data() {
      return {
        successCountDown: 0,
        message: null,
        variant: "success",
      };
    },

    methods: {
      setNotification(obj) {
        this.message = obj.msg;
        this.variant = obj.variant;
        this.successCountDown = 5;
      },
      hideNotification() {
        this.successCountDown = 0;
        this.message = null;
        this.variant = "success";
      },
    },
  };
</script>
