<template>
  <div class="col-md-12" style="background:#FFF;">
    <div class="row">
      <div class="col-md-12">
        <b-tabs content-class="mt-3">
          <b-tab active>
            <template v-slot:title>
              <strong>Details</strong>
            </template>
            <div class="row">
              <div class="col-lg-4 col-md-4 u-mb-xsmall">
                <h3>
                  <span v-show="!isEditingRefNumber" @click="editRefNumber">{{ project.projectRefNumber }}</span>
                  <input
                    @keyup.enter="isEditingRefNumber = !isEditingRefNumber"
                    @blur="isEditingRefNumber = !isEditingRefNumber"
                    v-show="isEditingRefNumber"
                    ref="ref_number"
                    class="form-control"
                    type="text"
                    v-model="project.projectRefNumber"
                    @keyup="updateField('projectRefNumber', project.projectRefNumber, 'Job Reference Number')"
                  />
                </h3>
              </div>
              <div class="col-lg-4 col-md-4 u-mb-xsmall">
                <span>{{ txtValidatation }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Status/Priority</span>

                <v-select v-model="selectedProjectStatus" :options="projectStatusList" :value="selectedProjectStatusId" @input="onProjectStatusSelected" code="id" label="displayName"></v-select>
              </div>

              <div class="col-lg-4 col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Job/Project Type</span>

                <v-select
                  v-model="selectedProjectType"
                  :options="projectTypeList"
                  :value="selectedProjectTypeId"
                  @input="onProjectTypeSelected"
                  code="projectTypeID"
                  label="projectTypeName"
                ></v-select>
              </div>

              <div class="col-lg-4 col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Tech/Crew Status</span>
                <v-select
                  v-model="selectedTechnicianStatus"
                  :options="technicianStatusList"
                  :value="selectedTechnicianStatusId"
                  @input="onTechnicianStatusSelected"
                  code="id"
                  label="displayName"
                ></v-select>
              </div>

              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Primary Contact Name</span>
                <input class="form-control" type="text" v-model="project.primaryContactName" @keyup="updateField('primaryContactName', project.primaryContactName, 'Primary Contact Name')" />
              </div>

              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Primary Contact Phone</span>
                <input class="form-control" type="text" v-model="project.primaryContactPhone" @keyup="updateField('primaryContactPhone', project.primaryContactPhone, 'Primary Contact Phone')" />
              </div>
              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Est. Completion Date</span>
                <date-picker
                  format="DD-MMM-YYYY"
                  style="width: 100%;"
                  v-model="project.projectEndDate"
                  @keyup="updateField('projectEndDate', project.projectEndDate, 'Est. Completion Date')"
                  lang="en"
                ></date-picker>
              </div>

              <div class="col-md-4 mb-4">
                <span class="c-toolbar__state-title u-text-capitalize">Start Date</span>
                <date-picker
                  format="DD-MMM-YYYY"
                  style="width: 100%;"
                  v-model="project.projectStartDate"
                  @keyup="updateField('projectStartDate', project.projectStartDate, 'Start Date')"
                  lang="en"
                ></date-picker>
              </div>

              <div class="col-md-4 mb-4">
                <span class="c-toolbar__state-title u-text-capitalize">Due Date</span>
                <date-picker format="DD-MMM-YYYY" style="width: 100%;" v-model="project.dueDate" @keyup="updateField('dueDate', project.dueDate, 'Due Date')" lang="en"></date-picker>
              </div>

              <div class="col-md-4 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Appointment Date & Time</span>
                <date-picker
                  format="DD-MMM-YYYY - HH:mm a"
                  :minute-step="15"
                  style="width: 100%;"
                  type="datetime"
                  v-model="project.confirmationDate"
                  @keyup="updateField('confirmationDate', project.confirmationDate, 'Appointment Date')"
                  lang="en"
                ></date-picker>
              </div>

              <div class="col-md-12 u-mb-xsmall">
                <span class="c-toolbar__state-title u-text-capitalize">Job Description</span>
                <textarea class="form-control" type="text" rows="8" v-model="project.jobDescription" @keyup="updateField('jobDescription', project.jobDescription, 'Job Description')" />
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <strong> <span class="badge badge-warning">New</span> &nbsp;Notes </strong>
            </template>

            <Notes v-if="noteModuleId > 0" :propProjectId="noteModuleId" :dataObj="modalStatus"></Notes>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import ProjectServices from "@/services/projects";
  import DatePicker from "vue2-datepicker";
  import EditDataObject from "@/interfaces/EditDataObject";
  import UXProjectsService from "@/services/ux/uxprojects";
  import UXTechnicianService from "@/services/ux/uxtechnicians";
  import Notes from "@/components/_universal/notes.vue";
  import EVENTS from "@/constants/events";

  const api = new ProjectServices();

  export default {
    data() {
      return {
        EVENT_ACTION: {},
        txtValidatation: "",
        isEditingRefNumber: false,
        noteModuleId: 0, //Keeps the reference to the id for the module being loaded... eg. projectId, invoiceId, quoteId etc..
        project: {
          primaryContactName: "",
          primaryContactPhone: "",
        },
        selectedTechnicianStatus: null,
        selectedProjectStatus: null,
        selectedProjectType: null,
        technicianStatusList: [],
        projectStatusList: [],
        projectTypeList: [],
        modal: {
          hideShowModal: false,
          message: "",
          modalSaveButtonTitle: "",
          data: {},
        },
      };
    },
    computed: {
      startDate() {
        return this.project.projectStartDate;
      },
      dueDate() {
        return this.project.dueDate;
      },
      completionDate() {
        return this.project.projectEndDate;
      },
      appointmentDate() {
        return this.project.confirmationDate;
      },
      actualCompletedDate() {
        return this.project.projectActualEndDate;
      },
      selectedProjectTypeId() {
        return this.selectedProjectType;
      },
      selectedProjectStatusId() {
        return this.selectedProjectStatus;
      },
      selectedTechnicianStatusId() {
        return this.selectedTechnicianStatus;
      },
    },
    watch: {
      startDate() {
        this.updateField("projectStartDate", this.project.projectStartDate, "Start Date");
      },
      dueDate() {
        this.updateField("dueDate", this.project.dueDate, "Due Date");
      },
      completionDate() {
        this.updateField("projectEndDate", this.project.projectEndDate, "Est. Completion Date");
      },
      appointmentDate() {
        this.updateField("confirmationDate", this.project.confirmationDate, "Appointment Date");
      },
      actualCompletedDate() {
        this.updateField("projectActualEndDate", this.project.projectActualEndDate, "Actual Completed Date & Time");
      },
    },
    methods: {
      editRefNumber() {
        this.isEditingRefNumber = !this.isEditingRefNumber;
        this.$refs.ref_number.focus();
      },
      onClose(data) {
        this.$root.$emit(this.EVENT_ACTION.DASHBOARD.Update);
      },
      async loadDropdowns() {
        const projects = new UXProjectsService();
        const techs = new UXTechnicianService();

        const apiProjectType = await projects.getProjectType();
        const apiProjectStatus = await projects.getProjectStatus();
        const apiTechStatus = await techs.getTechnicianStatus();

        this.projectTypeList = apiProjectType;
        this.projectStatusList = apiProjectStatus;
        this.technicianStatusList = apiTechStatus;
      },
      onTechnicianStatusSelected(event) {
        if (event) {
          this.updateField("IntSMSMessageStatusId", event.id, "Tech/Crew Status");
        }
      },
      onProjectTypeSelected(event) {
        if (event) {
          this.updateField("IntProjectTypeID", event.projectTypeID, "Job Type");
        }
      },
      onProjectStatusSelected(event) {
        if (event) {
          this.updateField("IntProjectStatusID", event.id, "Status/Priority");
        }
      },

      //Generic Update Method to update any database property column
      async updateField(propertyName, propertyValue, displayName) {
        let editObj = {};
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.id = this.project.intProjectID;

        let isValid = await this.isValidated(propertyName, propertyValue);

        if (isValid) {
          this.postAPIData(editObj);
        }
      },

      //Run validation on edit for certain fields
      async isValidated(propertyName, propertyValue) {
        this.txtValidatation = "";

        //Prevent empty value from being sent
        if (propertyValue == null || propertyValue.length == 0) return false;

        //Validate Project Reference Number
        if (propertyName === "projectRefNumber") {
          let lookupResult = await api.lookupReference(this.project.intProjectID, propertyValue);

          //If a reference with the same value exist then fail validation
          if (lookupResult) {
            this.txtValidatation = "Reference number already exist!";

            return false;
          } else return true;
        }

        return true;
      },
      async setSelectedDropdownValues() {
        //Set Technician Status
        this.selectedTechnicianStatus = this.technicianStatusList.filter((t) => {
          return t.id === this.project.intSmsmessageStatusId;
        });

        //Project Status
        this.selectedProjectStatus = this.projectStatusList.filter((t) => {
          return t.id === this.project.intProjectStatusID;
        });

        //Set Job/Project Type
        this.selectedProjectType = this.projectTypeList.filter((t) => {
          return t.projectTypeID === this.project.intProjectTypeID;
        });
      },

      async postAPIData(dataObj) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          api.updateData(dataObj);
        }, 500);
      },

      //Initialize Data
      async loadData() {
        await this.loadDropdowns();

        this.modalStatus = this.$store.getters.getModalStatus;
        this.technicians = this.$store.getters.getTechnicianDropDown;

        this.noteModuleId = this.modalStatus.data.intProjectID;

        //Get Project details passed from parent page
        this.project = await api.getProjectDetails(this.modalStatus.data.intProjectID);
        await this.setSelectedDropdownValues();
      },
    },
    mounted() {
      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page(this.EVENT_ACTION.PROJECTS.ViewQuickEdit);
      // }
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      await this.loadData();

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.PROJECTS.ViewQuickEdit, this.onClose);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.PROJECTS.ViewQuickEdit, this.onClose);
    },

    components: {
      DatePicker,
      Notes,
    },
  };
</script>
