<template>
  <ValidationObserver ref="formNewInventory">
    <div class="mx-2 mb-2">
      <div class="row">
        <div v-if="isSuccesful" class="col-md-12 mb-2">
          <b-alert show class="mb-2" variant="success">
            <i class="fa fa-check-circle mr-2"></i>
            <span class="sub-title mr-2" id="modal-new-inventory-success-message">Succesfully created new inventory. You may close this window.</span>
          </b-alert>
        </div>
        <div class="col-md-12 mb-2">
          <ValidationProvider v-slot="{errors}" name="Name" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Item Name</span>
            <input class="form-control" id="dashboard-top-nav-new-inventory-modal-item-name" type="text" v-model="formData.Name" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-4 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Model Number</span>
          <input class="form-control" id="dashboard-top-nav-new-inventory-modal-model-number" type="text" v-model="formData.ModelNumber" />
        </div>
        <div class="col-md-4 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">SKU/Lookup Code</span>
          <input class="form-control" id="dashboard-top-nav-new-inventory-modal-lookup-code" type="text" v-model="formData.SKU" />
        </div>
        <div class="col-md-4 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Serial Number</span>
          <input class="form-control" id="dashboard-top-nav-new-inventory-modal-serial-number" type="text" v-model="formData.SerialNumber" />
        </div>
        <!-- 
        <div class="col-lg-2 col-md-4 mb-2">
          <ValidationProvider v-slot="{errors}" name="Unit Price" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Unit Price</span>
            <input class="form-control" id="dashboard-top-nav-new-inventory-modal-item-name" type="text" v-model="formData.UnitPrice" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div> -->
        <div class="col-lg-3 col-md-4 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Sale Price</span>
          <div class="input-group input-with-dollar-sign">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input class="form-control" id="dashboard-top-nav-new-inventory-modal-sale-price" type="text" v-model="formData.SalePrice" />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mb-2">
          <ValidationProvider v-slot="{errors}" name="Item Cost" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Cost Price</span>
            <div class="input-group input-with-dollar-sign">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input class="form-control" id="dashboard-top-nav-new-inventory-modal-cost-price" type="text" v-model="formData.ItemCost" />
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-lg-3 col-md-4 mb-2">
          <ValidationProvider v-slot="{errors}" name="Quantitity On Hand" rules="numeric|min_value:1">
            <span class="c-toolbar__state-title u-text-capitalize">Quantity On Hand</span>
            <input class="form-control" id="dashboard-top-nav-new-inventory-modal-quantity-on-hand" type="text" v-model="formData.QuantitityOnHand" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-lg-3 col-md-4 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Unit Type</span>
          <v-select
            :options="uxUnitType"
            v-model="formData.IntUnitMeasurementID"
            id="dashboard-top-nav-new-inventory-modal-unit-type"
            :clearable="false"
            label="displayName"
            :reduce="(unit) => unit.id"
          ></v-select>
        </div>
        <div class="col-md-12 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Description</span>
          <textarea rows="8" id="dashboard-top-nav-new-inventory-modal-description" class="form-control text-left" v-model="formData.Description" />
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import UtilityString from "@/utilities/strings";
  import hash from "object-hash";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  const utility = new UtilityString();
  export default {
    name: "NewInventory",
    data() {
      return {
        apiErrors: {
          hasError: false,
          message: null,
        },
        isSuccesful: false,
        uxUnitType: [],
        formData: {
          actionId: MODULE.INVENTORY.ActionId,
          moduleId: MODULE.INVENTORY.ModuleId,
          Name: null,
          ModelNumber: null,
          SKU: null,
          SalePrice: 0,
          ItemCost: 0,
          UnitPrice: 0,
          QuantitityOnHand: 1,
          Description: null,
          IntUnitMeasurementID: null,
        },
      };
    },
    methods: {
      async initializeData() {
        this.uxUnitType = (await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryUnitType)) || [];
      },
      async onSave() {
        try {
          this.apiErrors = {
            hasError: false,
            message: null,
          };
          const isValid = await this.$refs.formNewInventory.validate();

          if (isValid) {
            //Remove all spaces from properties
            this.formData = utility.trimObjectPropertySpace(this.formData);

            //Generate a Hash from the data to be submitted
            this.formData.hash = hash(this.formData);

            let result = await global.createNewActivity(MODULE.INVENTORY.ActionId, this.formData);

            if (result.success) {
              this.isSuccesful = true;
              this.formData = {
                actionId: MODULE.INVENTORY.ActionId,
                moduleId: MODULE.INVENTORY.ModuleId,
                Name: null,
                ModelNumber: null,
                SKU: null,
                SalePrice: 0,
                ItemCost: 0,
                QuantitityOnHand: 1,
                Description: null,
              };
            } else {
              this.isSuccesful = false;
            }
          }
        } catch (e) {
          this.apiErrors = {
            hasError: true,
            message: e,
          };
        }
      },
    },

    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      await this.initializeData();

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.INVENTORY.New, this.onSave);
    },

    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.INVENTORY.New, this.onSave);
    },
  };
</script>
