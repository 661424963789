<template>
  <div class="custom-table">
    <!-- Headers of the table -->
    <div class="row headers py-2" v-if="false">
      <div class="col-1"></div>
      <div class="col-6">Description</div>
      <div class="col">Delete</div>
    </div>

    <div class="c-table__head c-table__head--slim py-2 px-3" v-if="!editingRow">
      <!-- List -->
      <div class="row">
        <div class="col pr-0 py-1" v-if="!isDisabled">
          <p></p>
        </div>
        <div class="col-7">
          <p class="d-block mb-0 py-1 c-table__cell--head invoice-tab-details-description">Description</p>
        </div>
        <div class="col text-center">
          <p class="d-block mb-0 py-1 c-table__cell--head">Qty</p>
        </div>
        <div class="col text-center">
          <p class="d-block mb-0 py-1 c-table__cell--head">Unit Price</p>
        </div>
        <div class="col text-right">
          <p class="d-block mb-0 py-1 c-table__cell--head">Total</p>
        </div>
        <div class="col text-right mw-80px" v-if="!isDisabled">
          <p class="d-block mb-0 py-1 c-table__cell--head">Delete</p>
        </div>
      </div>
    </div>

    <div class="field-rows py-3 px-3 border-bottom-grey" :class="[showHideTextBox(i) === true ? 'editingRowClass' : '']" v-for="(item, i) in invoiceDetailsItems" v-bind:key="item.quoteDetailId">
      <!-- List -->
      <div class="row">
        <div class="col pr-0" v-if="!isDisabled">
          <a class="invoice-tab-details-edit" v-if="showHideLabel(i) == true && item.id" href="#" @click.prevent="editRow(i)">Edit</a>
          <a class="invoice-tab-details-save" v-else-if="showHideLabel(i) == true" href="#"><small>Saving...</small></a>
        </div>
        <div class="col-7">
          <p class="d-block font-weight-bold mb-0 invoice-tab-details-title" v-if="showHideTextBox(i) !== true && item.hasOwnProperty('title')">{{ item.title }}</p>
          <span class="invoice-tab-details-description" v-if="showHideTextBox(i) !== true">{{ item.description }}</span>
        </div>
        <div class="col text-center">
          <span class="invoice-tab-details-qty" v-if="showHideTextBox(i) !== true">{{ item.qty }}</span>
        </div>
        <div class="col text-center text-nowrap">
          <span class="invoice-tab-details-unit-price" v-if="showHideTextBox(i) !== true">{{ item.unitPrice | currency({fractionCount: getDecimalPoint(item.unitPrice)}) }}</span>
        </div>
        <div class="col text-right text-nowrap">
          <span class="invoice-tab-details-total" v-if="showHideTextBox(i) !== true">{{ item.amount | currency }}</span>
        </div>
        <div class="col text-right mw-80px" v-if="!isDisabled">
          <i v-if="showHideLabel(i) == true" @click="deleteItem(i)" class="invoice-tab-details-delete fa fa-trash u-text-danger u-text-larger d-block clickable" aria-hidden="true"></i>
        </div>
      </div>
      <!-- Form -->
      <div class="row" v-if="showHideTextBox(i) === true">
        <div class="col-md-12 mb-2">
          <div class="alert alert-warning text-center" role="alert" v-if="showErrorWarning">
            {{ warningMessage }}
          </div>
        </div>
        <div class="col-md-12 mb-2">
          <div v-if="showHideTextBox(i) == true" class="w-100 mr-3 d-flex">
            <div class="mr-5">
              <div class="c-choice c-choice--radio mb-0">
                <input class="c-choice__input" id="radio2" name="radio2" type="radio" :value="MODULE.PRICING.ActionId" v-model="mode" />
                <label class="c-choice__label" for="radio2">Pricing</label>
              </div>
            </div>

            <div class="mr-5">
              <div class="c-choice c-choice--radio mb-0">
                <input class="c-choice__input" id="radio1" name="radio1" type="radio" :value="MODULE.INVENTORY.ActionId" v-model="mode" />
                <label class="c-choice__label" for="radio1">Inventory</label>
              </div>
            </div>

            <div class="mr-5">
              <div class="c-choice c-choice--radio mb-0">
                <input class="c-choice__input" id="radio3" name="radio3" type="radio" :value="MODULE.CLIENTS.UX.ClientRateGroup" v-model="mode" />
                <label class="c-choice__label" for="radio3">Group Rates</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-2">
          <div v-if="showHideTextBox(i) == true" class="w-100 mr-3">
            <span class="c-toolbar__state-title u-text-capitalize">Select Service/Material/Rate</span>
            <v-select
              class="bg-white"
              id="slidepanel-edit-invoice-tabs-details-primary-list"
              v-if="showHideTextBox(i) == true"
              v-model="selectedPrimaryDropdown"
              :clearable="false"
              :options="primaryList"
              @input="onSelectPrimaryDropdown"
              code="id"
              label="displayName"
            ></v-select>
          </div>
        </div>

        <div class="col-md-12 mb-2">
          <div v-if="showHideTextBox(i) == true && mode === MODULE.CLIENTS.UX.ClientRateGroup" class="w-100">
            <span class="c-toolbar__state-title u-text-capitalize">Select Rates</span>
            <v-select
              class="bg-white"
              v-if="showHideTextBox(i) == true"
              :clearable="false"
              :disabled="selectedPrimaryDropdown && selectedPrimaryDropdown.id === 0"
              v-model="selectedSecondaryDropdown"
              id="slidepanel-edit-invoice-tabs-details-secondary-list"
              :options="secondaryList"
              @input="onSelectSecondDropdown"
              code="id"
              label="displayName"
            ></v-select>
          </div>
        </div>

        <div class="col-md-2 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize" v-if="showHideTextBox(i) == true">Qty</span>
          <input
            v-if="showHideTextBox(i) == true"
            v-on:keyup="calculateItemTotal(i)"
            id="slidepanel-edit-invoice-tabs-details-qty"
            class="form-control"
            v-model="item.qty"
            type="text"
            @input="
              updateField(
                'qty', //PropertyName
                item.qty, //Property Value
                'Quantity', //Display Name,
                item.qty, //Display Value
                item.id,
                MODULE.INVOICE.UX.InvoiceDetails
              )
            "
          />
        </div>

        <div class="col-md-2 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize" v-if="showHideTextBox(i) == true">Unit Price</span>
          <div class="input-group input-with-dollar-sign">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              v-if="showHideTextBox(i) == true"
              v-on:keyup="calculateItemTotal(i)"
              id="slidepanel-edit-invoice-tabs-details-unit-price"
              class="form-control"
              v-model="item.unitPrice"
              type="number"
              @input="
                updateField(
                  'unitPrice', //PropertyName
                  item.unitPrice, //Property Value
                  'Unit Price', //Display Name,
                  item.unitPrice, //Display Value
                  item.id,
                  MODULE.INVOICE.UX.InvoiceDetails
                )
              "
            />
          </div>
        </div>

        <div class="col-md-4 mb-2">
          <div v-if="showHideTextBox(i) == true" class="w-100">
            <span class="c-toolbar__state-title u-text-capitalize">Account</span>
            <v-select
              class="bg-white"
              v-if="showHideTextBox(i) == true"
              id="slidepanel-edit-invoice-tabs-details-accounts-list"
              :options="accountsList"
              code="id"
              v-model="selectedAccount"
              label="displayName"
              @input="onAccountUpdate($event, i)"
            ></v-select>
          </div>
        </div>

        <div class="col-md-2 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize" v-if="showHideTextBox(i) == true">Sort Order</span>
          <input
            v-if="showHideTextBox(i) == true"
            id="slidepanel-edit-invoice-tabs-details-sort-order"
            class="form-control"
            v-model="item.sortOrder"
            type="number"
            @input="
              updateField(
                'sortOrder', //PropertyName
                item.sortOrder, //Property Value
                'Sort Order', //Display Name,
                item.sortOrder, //Display Value
                item.id,
                MODULE.INVOICE.UX.InvoiceDetails
              )
            "
          />
        </div>

        <div class="col-md-2 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize" v-if="showHideTextBox(i) == true">Tax Rate</span>
          <v-select
            class="bg-white"
            id="slidepanel-edit-invoice-tabs-details-tax-rate"
            code="id"
            label="displayName"
            :options="taxList"
            v-model="selectedTaxRate"
            @input="onTaxLineUpdate($event, i)"
          ></v-select>
        </div>

        <div class="col-12" v-if="showHideTextBox(i) == true">
          <span class="c-toolbar__state-title u-text-capitalize" v-if="showHideTextBox(i) == true">Description</span>
          <div class="form-group">
            <textarea
              colspan="12"
              class="form-control"
              v-model="item.description"
              id="slidepanel-edit-invoice-tabs-details-description"
              :rows="itemDescriptionLength"
              @input="
                updateField(
                  'description', //PropertyName
                  item.description, //Property Value
                  'Description', //Display Name,
                  item.description, //Display Value
                  item.id,
                  MODULE.INVOICE.UX.InvoiceDetails
                )
              "
            ></textarea>
          </div>
        </div>

        <div class="col-12 d-flex align-items-center" v-if="!isDisabled">
          <b-button size="md" class="c-btn-outlined-primary mb-3" id="slidepanel-edit-invoice-tabs-details-save" @click.prevent="saveRow(i)"> <i class="fa fa-save u-mr-xsmall"></i>Save </b-button>

          <b-button size="md" class="c-btn-outlined-info mx-3 mb-3" id="slidepanel-edit-invoice-tabs-details-cancel" @click.prevent="cancelRow(i)">
            <i class="fa fa-times u-mr-xsmall"></i>Cancel
          </b-button>

          <b-button size="md" class="c-btn-outlined-danger mb-3 ml-auto" id="slidepanel-edit-invoice-tabs-details-delete" @click.prevent="deleteItem(i)">
            <i class="fa fa-trash u-mr-xsmall"></i>Delete
          </b-button>
        </div>
      </div>
    </div>

    <!-- Total -->
    <div class="text-right mt-4 pr-3" :style="!isDisabled ? 'margin-right: 80px;' : ''">
      <h5 class="font-weight-normal" :id="$route.name + '-' + 'tab' + '-sub-total'">Sub Total: {{ subTotal }}</h5>
    </div>

    <!-- Add new item -->
    <div class="ss--add-new__item o-line mt-4" v-if="!isDisabled">
      <div class="c-orange-line"></div>
      <div role="button" @click="addNewItem" id="slidepanel-edit-invoice-tabs-details-add-new-item" class="c-add-new-item__btn u-mr-xsmall u-ml-xsmall">
        <i aria-hidden="true" class="fa fa-plus-circle u-mr-xsmall"></i> Add New Item
      </div>
      <div class="c-orange-line"></div>
    </div>
  </div>
</template>

<script>
  import "vue-simple-suggest/dist/styles.css"; // Optional CSS
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UtilityString from "@/utilities/strings";
  const utility = new UtilityString();
  const global = new GlobalServices();

  export default {
    mixins: [editMixin],
    name: "TabInvoiceDetails",

    data() {
      return {
        intervalApi: null,
        delayer: null,
        editingRow: false,
        isSavingRow: false,
        selectedPrimaryDropdown: {
          displayName: "Service/Material/Rate (Optional)",
          id: 0,
        },
        selectedSecondaryDropdown: {
          displayName: "Client Rates",
          id: 0,
        },
        selectedAccount: null,
        itemDescriptionLength: 10,
        selectedItem: {},
        selectedRowForSaving: null,
        invoiceDetailsItems: [],
        showErrorWarning: false,
        subTotal: 0,
        primaryList: [],
        secondaryList: [],
        accountsList: [],
        id: 0,
        MODULE: null,
        mode: MODULE.PRICING.ActionId,
        warningMessage: `Please enter a valid description, quantity and price for the last row before adding new items.`,
        taxList: [],
        selectedTaxRate: null,
      };
    },

    watch: {
      mode() {
        this.selectedPrimaryDropdown = {
          displayName: "Service/Material/Rate (Optional)",
          id: 0,
        };
        this.onLoadFirstDropdown();
      },
    },

    methods: {
      // this will send immediate update
      async updateEssentialField(propertyName, propertyValue, displayName, displayValue, overideId, overrideActionId) {
        const editObj = {
          actionId: 0,
          id: 0,
          referenceNumber: "",
          propertyName: "",
          propertyValue: "",
          displayName: "",
          displayValue: "",
          previousDisplayValue: "",
          previousValueId: 0,
          moduleId: 0,
          ignore: false,
        };

        // ID in this component is expected as InvoiceID
        if (this.dataObj && this.dataObj.data && this.dataObj.data.id) {
          editObj.invoiceID = this.dataObj.data.id;
        }
        //Overide Id if specified
        if (overideId) editObj.id = overideId;
        //Overide Action Id if specified
        if (overrideActionId) editObj.actionId = overrideActionId;
        //Ensure that the original value and the new change is not the same
        //This will prevent posting to server on initial load
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.displayValue = displayValue;
        await this.immediateSendDataChange(editObj);
      },
      async updateField(propertyName, propertyValue, displayName, displayValue, overideId, overrideActionId, parentActionId, parentId, ignore) {
        if (!overideId || overideId === 0) return;
        const editObj = {
          actionId: 0,
          id: 0,
          referenceNumber: "",
          propertyName: "",
          propertyValue: "",
          displayName: "",
          displayValue: "",
          previousDisplayValue: "",
          previousValueId: 0,
          moduleId: 0,
          ignore: false,
        };

        // ID in this component is expected as InvoiceID
        if (this.dataObj && this.dataObj.data && this.dataObj.data.id) {
          editObj.invoiceID = this.dataObj.data.id;
        }
        //Overide Id if specified
        if (overideId) editObj.id = overideId;
        // ignore update
        if (ignore) editObj.ignore = ignore;
        //Overide Action Id if specified
        if (overrideActionId) editObj.actionId = overrideActionId;
        if (parentActionId) editObj.parentActionId = parentActionId;
        if (parentId) editObj.parentId = parentId;
        //Ensure that the original value and the new change is not the same
        //This will prevent posting to server on initial load
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.displayValue = displayValue;

        await this.sendDataChange(editObj);
      },
      async onSelectPrimaryDropdown(selectedObj) {
        if (selectedObj && this.selectedItem) {
          if (this.mode === MODULE.CLIENTS.UX.ClientRateGroup) {
            // get the second dropdown
            this.loadSecondDropdown();
          } else {
            const item = await global.getEditDetails(this.mode, selectedObj.id);
            // description
            this.selectedItem.description =
              item && item.Name && item.Description
                ? `${item.Name}\n${item.Description ? item.Description : ""}`
                : `${selectedObj.displayName}\n${selectedObj.description ? selectedObj.description : ""}`;
            // qty
            this.selectedItem.qty = item && item.qty && parseFloat(item.qty) !== 0 ? parseFloat(item.qty) : selectedObj.qty && parseFloat(selectedObj.qty) !== 0 ? parseFloat(selectedObj.qty) : 1;
            // unit price
            this.selectedItem.unitPrice = item && item.SalePrice ? item.SalePrice : selectedObj.unitPrice && parseFloat(selectedObj.unitPrice) !== 0 ? parseFloat(selectedObj.unitPrice) : 0;

            this.selectedItem.amount = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);
            this.calculateTotalSum();

            if (this.selectedItem && this.selectedItem.id) this.updateEssentials(this.selectedItem);
          }
        }
      },
      async onSelectSecondDropdown(selectedObj) {
        if (selectedObj && this.selectedItem) {
          const item = await global.getEditDetails(MODULE.CLIENTS.UX.ClientRates, selectedObj.id);
          // description
          this.selectedItem.description =
            item && (item.Name || item.Description)
              ? `${item.Name}\n${item.Description ? item.Description : ""}`
              : `${selectedObj.displayName}\n${selectedObj.description ? selectedObj.description : ""}`;
          // qty
          this.selectedItem.qty =
            item && item.Quantity && parseFloat(item.Quantity) !== 0 ? parseFloat(item.Quantity) : selectedObj.qty && parseFloat(selectedObj.qty) !== 0 ? parseFloat(selectedObj.qty) : 1;
          // unit price
          this.selectedItem.unitPrice = item && item.Amount ? item.Amount : selectedObj.unitPrice && parseFloat(selectedObj.unitPrice) !== 0 ? parseFloat(selectedObj.unitPrice) : 0;

          this.selectedItem.amount = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);

          if (selectedObj.IntAccountID) {
            this.selectedAccount = this.accountsList.find((account) => account.id === selectedObj.IntAccountID);
          }
          this.calculateTotalSum();
        }
      },
      async loadSecondDropdown() {
        const results = await global.getSimpleUXDropDown(MODULE.CLIENTS.UX.ClientRates, this.mode, this.selectedPrimaryDropdown.id);
        this.secondaryList = results.map((item) => {
          return {
            ...item,
            qty: item.qty ? parseFloat(item.qty) : 0,
            unitPrice: item.unitPrice ? parseFloat(item.unitPrice) : 0,
            amount: item.total ? item.total : 0,
          };
        });
      },
      async onLoadFirstDropdown() {
        const results = await global.getUXDropDown(this.mode);
        this.primaryList = results.map((item) => {
          return {
            ...item,
            qty: item.qty ? parseFloat(item.qty) : 0,
            unitPrice: item.unitPrice ? parseFloat(item.unitPrice) : 0,
            amount: item.total ? item.total : 0,
          };
        });
      },
      async loadAccountsList() {
        this.accountsList = await global.getSimpleUXDropDown(MODULE.CLIENTS.Type.Commercial);
      },
      onTaxLineUpdate(evt, index) {
        const item = this.invoiceDetailsItems[index];
        if ((!item.id || item.id === 0) && evt) {
          this.invoiceDetailsItems[index].intTaxCodeID = evt.id;
          this.invoiceDetailsItems[index].taxRate = evt.unitPrice;
        } else if (evt) {
          // update line
          this.updateEssentialField(
            "intTaxCodeID", //PropertyName
            evt.id, //Property Value
            "Tax Rate ID", //Display Name,
            evt.id, //Display Value
            item.id,
            MODULE.INVOICE.UX.InvoiceDetails
          );
          this.updateEssentialField(
            "taxRate", //PropertyName
            evt.unitPrice, //Property Value
            "Tax Rate", //Display Name,
            evt.unitPrice, //Display Value
            item.id,
            MODULE.INVOICE.UX.InvoiceDetails
          );
        } else {
          // update line
          this.updateEssentialField(
            "intTaxCodeID", //PropertyName
            null, //Property Value
            "Tax Rate ID", //Display Name,
            null, //Display Value
            item.id,
            MODULE.INVOICE.UX.InvoiceDetails
          );

          this.updateEssentialField(
            "taxRate", //PropertyName
            0, //Property Value
            "Tax Rate", //Display Name,
            0, //Display Value
            item.id,
            MODULE.INVOICE.UX.InvoiceDetails
          );
        }
      },
      onAccountUpdate(evt, index) {
        const item = this.invoiceDetailsItems[index];
        if (!item.id || item.id === 0) {
          this.invoiceDetailsItems[index].accountName = evt.displayName;
          this.invoiceDetailsItems[index].AccountID = evt.id;
        } else {
          // update line
          this.updateField(
            "intAccountID", //PropertyName
            evt.id, //Property Value
            "Account ID", //Display Name,
            evt.id, //Display Value
            item.id,
            MODULE.INVOICE.UX.InvoiceDetails
          );
        }

        // wait for 1s
        setTimeout(() => {
          this.calculateItemTotal(index);
        }, 1000);
      },
      updateEssentials(item) {
        this.updateEssentialField(
          "qty", //PropertyName
          item.qty, //Property Value
          "Quantity", //Display Name,
          item.qty, //Display Value
          item.id,
          MODULE.INVOICE.UX.InvoiceDetails
        );

        this.updateEssentialField(
          "unitPrice", //PropertyName
          item.unitPrice, //Property Value
          "Unit Price", //Display Name,
          item.unitPrice, //Display Value
          item.id,
          MODULE.INVOICE.UX.InvoiceDetails
        );

        this.updateEssentialField(
          "description", //PropertyName
          item.description, //Property Value
          "Description", //Display Name,
          item.description, //Display Value
          item.id,
          MODULE.INVOICE.UX.InvoiceDetails
        );
      },
      async saveRow(index) {
        if (!this.isLastItemRowValid()) return;
        const item = this.invoiceDetailsItems[index];
        if (!item.id || item.id === 0) {
          try {
            this.selectedRowForSaving = index;
            this.selectedItem = null;
            this.isSavingRow = true;
            await this.createNewInvoiceDetailsEntry(item);
          } catch (e) {
            this.editRow(this.selectedRowForSaving);
            this.selectedRowForSaving = null;
            this.warningMessage = `There was a problem saving your item. Please try again`;
            this.showErrorWarning = true;
            setTimeout(() => (this.showErrorWarning = false), 5000);
          } finally {
            this.isSavingRow = false;
          }
        }

        this.clearSelectedFields();
        // wait for 1s
        await new Promise((resolve) => {
          setTimeout(() => {
            this.calculateItemTotal(index);
            resolve();
          }, 1000);
        });
        this.setIntervalAPICall();
      },
      clearSelectedFields() {
        this.selectedItem = null;
        this.editingRow = false;
        this.selectedAccount = null;
        this.selectedPrimaryDropdown = {
          displayName: "Service/Material/Rate (Optional)",
          id: "0",
        };
        this.selectedSecondaryDropdown = {
          displayName: "Client Rates",
          id: "0",
        };
        this.mode = MODULE.PRICING.ActionId;
      },
      async reloadItemList() {
        const results = await global.getTableDataByActionId({
          actionId: MODULE.INVOICE.UX.InvoiceDetails,
          parentId: this.dataObj.param.id,
        });

        this.invoiceDetailsItems = results.map((item) => {
          return {
            ...item,
            qty: parseFloat(item.qty),
            unitPrice: parseFloat(utility.stripDollarSign(item.unitPrice)),
            amount: parseFloat(utility.stripDollarSign(item.total)),
            sortOrder: item.sortOrder ? parseInt(item.sortOrder) : 0,
          };
        });
      },
      editRow(index) {
        this.selectedItem = this.invoiceDetailsItems[index];
        this.editingRow = true;
        this.setAccounts(index);
        this.setTaxRate(index);
        this.removeIntervalAPICall();
      },
      setAccounts(index) {
        if (this.selectedItem.IntAccountID) {
          this.selectedAccount = this.accountsList.find((data) => data.id === this.selectedItem.IntAccountID);
        } else {
          this.selectedAccount = global.setDropDownDefault(this.accountsList);
        }

        this.onAccountUpdate(this.selectedAccount, index);
      },
      setTaxRate(index) {
        if (this.selectedItem.intTaxCodeID) {
          this.selectedTaxRate = this.taxList.find((data) => data.id === parseInt(this.selectedItem.intTaxCodeID));
        } else {
          this.selectedTaxRate = null;
        }
      },
      calculateItemTotal(index) {
        if (this.selectedItem) {
          let qty = parseFloat(this.selectedItem.qty);
          let unitPrice = parseFloat(this.selectedItem.unitPrice);
          this.selectedItem.amount = this._.multiply(qty, unitPrice);
        }
        this.delayer = setTimeout(() => {
          this.calculateTotalSum();
        }, 500);
      },
      async calculateTotalSum() {
        if (this.delayer) {
          clearTimeout(this.delayer);
          this.delayer = null;
        }
        const results = await global.recalculateInvoice(this.dataObj.data.id);
        this.subTotal = results.displaySubTotal;
        // let formattedValue = this.$options.filters.currency(this.subTotal);
        //Broadcast to any component that care, that the total was changes...
        this.$root.$emit(this.EVENT_ACTION.INFO.UpdateTotal, this.subTotal);
      },
      showHideLabel(index) {
        //If we're in disable edit mode, hide options
        if (this.isDisabled) {
          return false;
        } else {
          if (this.isEditingCurrentRow(index)) return false;
          else return true;
        }
      },
      showHideTextBox(index) {
        return this.isEditingCurrentRow(index);
      },
      isEditingCurrentRow(index) {
        return this.selectedItem === this.invoiceDetailsItems[index];
      },
      deleteItem(index) {
        const item = this.invoiceDetailsItems[index];
        if (item.id && item.id > 0) {
          new Promise(async (resolve) => {
            await this.updateField(
              "IsDeleted", //PropertyName
              true, //Property Value
              "Deleted Item", //Display Name,
              "deleted", //Display Value
              item.id,
              MODULE.INVOICE.UX.InvoiceDetails
            );
            resolve();
          }).then(() => {
            this.removeFromListandRecalculate(index);
          });
        } else {
          this.removeFromListandRecalculate(index);
        }
      },
      removeFromListandRecalculate(index) {
        // check if index is valid or has an id then delete it else only remove it on our list
        this.invoiceDetailsItems.splice(index, 1);
        this.selectedItem = null;
        this.calculateTotalSum();
      },
      async cancelRow(index) {
        if (this.isEditingLastRow()) {
          if (!this.isLastItemRowValid()) this.deleteItem(index);
        }
        this.showErrorWarning = false;
        this.selectedItem = null;
        this.editingRow = false;

        this.setIntervalAPICall();
      },
      async addNewItem() {
        const newInvoiceItem = {
          InvoiceID: this.dataObj.data.id,
          accountName: "",
          AccountID: null,
          description: "",
          qty: 1,
          unitPrice: 0,
          amount: 0,
          ClientId: this.dataObj.details.clientId,
          actionId: MODULE.INVOICE.UX.InvoiceDetails,
          intTaxCodeID: null,
          taxRate: null,
        };

        let shouldAddNewItem = false;

        //Evaluate the last row before adding new item row
        if (this.isLastItemRowValid()) {
          shouldAddNewItem = true;
        } else if (this.invoiceDetailsItems.length === 0) {
          shouldAddNewItem = true;
        }

        if (shouldAddNewItem) {
          this.invoiceDetailsItems.push(newInvoiceItem);
          this.editRow(this.invoiceDetailsItems.length - 1);
        }
      },
      async createNewInvoiceDetailsEntry(invoiceItem) {
        const result = await global.createNewActivity(MODULE.INVOICE.UX.InvoiceDetails, invoiceItem);
        return result.resources;
      },
      isLastItemRowValid() {
        //Get the last Item row and ensure that it has values, before allowing user to add additional rows
        let allowAddingNewRow = false;

        if (this.invoiceDetailsItems.length > 0) {
          //If current select item is the last row
          if (this.isEditingLastRow()) {
            //If they are no items in the last row then show error message.
            if (this.selectedItem.description === "" && this.selectedItem.qty === 1 && this.selectedItem.unitPrice === 0) {
              this.warningMessage = `Please enter a valid description, quantity and price for the last row before adding new items.`;
              this.showErrorWarning = true;
              setTimeout(() => (this.showErrorWarning = false), 4000);
            } else {
              allowAddingNewRow = true;
            }
          } else {
            allowAddingNewRow = true;
          }
        }

        return allowAddingNewRow;
      },
      isEditingLastRow() {
        if (this.selectedItem == this.invoiceDetailsItems[this.invoiceDetailsItems.length - 1]) return true;

        return false;
      },
      async initializeData() {
        const newInvoiceItem = {
          InvoiceID: this.dataObj.data.id,
          accountName: "",
          AccountID: null,
          description: "",
          qty: 1,
          unitPrice: 0,
          amount: 0,
          ClientId: this.dataObj.details.clientId,
          actionId: MODULE.INVOICE.UX.InvoiceDetails,
          intTaxCodeID: null,
          taxRate: null,
        };

        this.invoiceDetailsItems.push(newInvoiceItem);
        this.editRow(this.invoiceDetailsItems.length - 1);
      },
      setIntervalAPICall() {
        this.intervalApi = setInterval(async () => {
          await this.reloadItemList();
          await this.calculateTotalSum();
        }, 5000);
      },
      removeIntervalAPICall() {
        clearInterval(this.intervalApi);
        this.intervalApi = null;
      },
    },
    async mounted() {
      this.isDisabled = this.currentDataObj.details.isLockedFromEditing !== null ? this.currentDataObj.details.isLockedFromEditing : false;
      this.taxList = await global.getUXDropDown(MODULE.INVOICE.UX.InvoiceTaxRates);
      await this.onLoadFirstDropdown();
      await this.loadAccountsList();
    },
    async created() {
      this.MODULE = MODULE;
      await this.reloadItemList();
      this.setIntervalAPICall();

      if (this.invoiceDetailsItems.length === 0) this.initializeData();
      else this.calculateTotalSum();
    },
    beforeDestroy() {
      this.removeIntervalAPICall();
    },
  };
</script>

<style scoped></style>
