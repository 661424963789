<template>
  <b-card class="dash-grey-card border-0">
    <h4>{{ header }}</h4>
    <span class="text-muted">{{ subheader }}</span>
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </b-card>
</template>

<script>
  import {Bar} from "vue-chartjs/legacy";

  import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from "chart.js";

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

  export default {
    name: "BarChart",
    components: {
      Bar,
    },
    props: {
      header: {
        type: String,
        default: "Labor Hours",
      },
      subheader: {
        type: String,
        default: "",
      },
      datasets: {
        type: Object,
        default: () => {
          return {
            datasets: [
              {
                label: "All Technicians",
                backgroundColor: "#6e0677",
                data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                borderRadius: 4,
              },
            ],
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          };
        },
      },
      chartId: {
        type: String,
        default: "bar-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 400,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },
    computed: {
      chartData() {
        return {
          labels: this.datasets.labels,
          datasets: this.datasets.datasets,
        };
      },
      // chartOptions() {
      //   return {
      //     responsive: true,
      //     maintainAspectRatio: false,
      //     tooltips: {
      //       enabled: true,
      //       callbacks: {
      //         label: (tooltipItems, data) => {
      //           return tooltipItems.yLabel + "h";
      //         },
      //       },
      //     },
      //   };
      // },
    },
  };
</script>
