<template>
  <div class="d-flex">
    <b-card class="col-md-6">
      <h5 class="mb-0">Edit Quote</h5>
      <small class="text-muted mb-3 d-block">You can lock/disable this quote from being edited. The system will
        automatically lock a quote if it is associated with a job.</small>
      <div class="d-flex">
        <div class="c-choice c-choice--radio mb-0 mr-4">
          <input v-model="isEditing" class="c-choice__input" id="radio1" name="radios" type="radio" :value="false"
            @change="reload" :disabled="isDisabled" />
          <label class="c-choice__label" for="radio1">Allow Edit</label>
        </div>
        <div class="c-choice c-choice--radio mb-0">
          <input v-model="isEditing" class="c-choice__input" id="radio2" name="radios" type="radio" :value="true"
            @change="reload" :disabled="isDisabled" />
          <label class="c-choice__label" for="radio2">Disable Edit</label>
        </div>
      </div>
    </b-card>
    <!-- DISABLED TICKET#1152 -->
    <!-- <b-card class="col-md-6 ml-2">
      <h5 class="mb-0">Editing Style</h5>
      <small class="text-muted mb-3 d-block">For a short line item description with pricing for each item, choose "line
        style." For paragraphs with longer quote item descriptions, choose "paragraph style."</small>
      <div class="d-flex">
        <div class="c-choice c-choice--radio mb-0 mr-4">
          <input v-model="mode" class="c-choice__input" id="mode1" name="modes" type="radio" value="line" />
          <label class="c-choice__label" for="mode1">Line Style</label>
        </div>
        <div class="c-choice c-choice--radio mb-0">
          <input v-model="mode" class="c-choice__input" id="mode2" name="modes" type="radio" value="paragraph" />
          <label class="c-choice__label" for="mode2">Paragraph Style</label>
        </div>
      </div>
    </b-card> -->
  </div>
</template>
<script>
import editMixin from "@/mixin/edit-mixin";

export default {
  name: "SettingsTab",
  mixins: [editMixin],
  data() {
    return {
      isEditing: true,
      isDisabled: false,
      mode: "paragraph",
    };
  },
  watch: {
    isEditing(value) {
      this.updateField("IsLockedFromEditing", value, "Is Locked From Editing");
    },
    // mode(value) {
    //   if (value === "line") {
    //     this.updateField(
    //       "isLineItemStyle",
    //       value === "line", //Property Value
    //       "Line Item Style", //Display Name,
    //       value === "line", //Display Value
    //       "isLineItemStyle", //Data object Property Name
    //       null,
    //       null,
    //       null,
    //       this.dataObj.param.id,
    //       this.dataObj.param.actionId,
    //       true // ignore
    //     );
    //     setTimeout(() => {
    //       this.updateField(
    //         "isParagraphPlainTextStyle ",
    //         value === "paragraph", //Property Value
    //         "Paragraph Item Style", //Display Name,
    //         value === "paragraph", //Display Value
    //         "isParagraphPlainTextStyle ", //Data object Property Name
    //         null,
    //         null,
    //         null,
    //         this.dataObj.param.id,
    //         this.dataObj.param.actionId,
    //         true // ignore
    //       );
    //     }, 500);
    //   } else {
    //     this.updateField(
    //       "isParagraphPlainTextStyle ",
    //       value === "paragraph", //Property Value
    //       "Paragraph Item Style", //Display Name,
    //       value === "paragraph", //Display Value
    //       "isParagraphPlainTextStyle ", //Data object Property Name
    //       null,
    //       null,
    //       null,
    //       this.dataObj.param.id,
    //       this.dataObj.param.actionId,
    //       true // ignore
    //     );

    //     setTimeout(() => {
    //       this.updateField(
    //         "isLineItemStyle",
    //         value === "line", //Property Value
    //         "Line Item Style", //Display Name,
    //         value === "line", //Display Value
    //         "isLineItemStyle", //Data object Property Name
    //         null,
    //         null,
    //         null,
    //         this.dataObj.param.id,
    //         this.dataObj.param.actionId,
    //         true // ignore
    //       );
    //     }, 500);
    //   }
    // },
  },
  mounted() {
    if (this.dataObj && this.dataObj.details && this.dataObj.details.hasOwnProperty("JobID")) {
      this.isDisabled = !this.dataObj.details.JobID // disable update of editing if no job id
    }
    if (this.dataObj && this.dataObj.details && this.dataObj.details.hasOwnProperty("IsLockedFromEditing")) {
      if (this.dataObj.details.JobID && this.dataObj.details.IsLockedFromEditing !== null) {
        this.isEditing = this.dataObj.details.IsLockedFromEditing // has job id and isLockedFromediting is not null use what ever value is there
      } else if (!this.dataObj.details.JobID && this.dataObj.details.IsLockedFromEditing !== null) {
        this.isEditing = this.dataObj.details.IsLockedFromEditing !== null ? this.dataObj.details.IsLockedFromEditing : false; // set to false which means in frontend its allow editing
      } else {
        this.isEditing = true // it has a job id but its IslockedfromEditing is null
      }
    }

    // if (this.dataObj && this.dataObj.details && !this.dataObj.details.isLineItemStyle && this.dataObj.details.isParagraphPlainTextStyle) {
    //   this.mode = "paragraph";
    // }
  },
  methods: {
    reload() {
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
  },
};
</script>
