<template>
  <div>
    <ValidationObserver ref="formNewQuote">
      <!--start modal new project body -->
      <div class="col-md-12 u-pt-small">
        <b-alert show class="mb-2" variant="success" v-if="isSavingSuccessful">
          <i class="fa fa-check-circle mr-2"></i>
          <span class="sub-title mr-2" id="modal-new-quote-success-message"> Successfully saved your new quote. You may close this window or create another quote.</span>
        </b-alert>
        <div v-if="isError" class="alert alert-danger" role="alert">
          Sorry, there was an error while creating this job.
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">1</span>Choose a Client/Lead</div>
            <ValidationProvider name="client" rules="required" v-slot="{errors}">
              <v-select
                v-model="selectedClient"
                :options="uxClientList"
                @input="onSelectClient"
                :disabled="disableDropdown"
                code="id"
                label="displayName"
                id="dashboard-top-nav-new-quote-modal-choose-client"
                :clearable="false"
              ></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6  mt-2 mt-lg-0 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">2</span>Location</div>
            <ValidationProvider name="location" rules="required" v-slot="{errors}">
              <v-select
                v-model="selectedLocation"
                :disabled="disabledLocation"
                :options="uxLocationList"
                :clearable="false"
                @input="onSelectLocation"
                code="id"
                label="displayName"
                id="dashboard-top-nav-new-quote-modal-location"
              ></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6 ss--new-quote__modal_module u-mb-small">
            <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small"><span class="u-mr-small">3</span>Quote Type</div>
            <ValidationProvider name="location" rules="required" v-slot="{errors}">
              <v-select disabled v-model="selectedQuoteType" :options="uxQuoteTypeList" code="id" label="displayName" id="dashboard-top-nav-new-quote-modal-quote-type"></v-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <!-- row -->
        <div class="ss--border-bottom u-mt-small u-mb-small u-height-2"></div>

        <div class="row">
          <div class="col-lg-4 col-md-12 col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Subject</span>
            <ValidationProvider name="subject" v-slot="v" rules="required">
              <input class="form-control" type="text" v-model="quote.subject" id="dashboard-top-nav-new-quote-modal-subject" />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-lg-4 col-md-6  u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Attention</span>
            <input class="form-control" type="text" v-model="quote.attentionTo" id="dashboard-top-nav-new-quote-modal-attention" />
          </div>
          <!-- <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize"
              >Quote Date</span
            >
            <date-picker
              format="DD-MMM-YYYY"
              style="width: 100%;"
              v-model="quote.quoteDate"
              lang="en"
              id="dashboard-top-nav-new-quote-modal-date"
            ></date-picker>
          </div> -->

          <div class="col-lg-4 col-md-6 u-mb-xsmall u-border-left">
            <div class="info-box">
              <div class="ss-success-bg u-border-rounded text-right pr-3 py-1">
                <p class="mb-0 ss-white-font-color mb-0">Sub Total</p>
                <h4 class="ss-white-font-color mb-0" id="new-quote-modal-sub-total">
                  {{ subTotal | currency }}
                </h4>
                <p class="mb-0 ss-white-font-color">With Tax: {{ quoteTotal | currency }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-12 u-mb-xsmall pt-4">
            <UniversalList
              v-bind:propItemList="propItemList"
              v-bind:lookupList="lookupList"
              v-on:EVENT_ReCalculate="onUpdateSubTotal"
              v-on:EVENT_ListUpdated="onUpdateList"
              :client="client"
              @get-list="onLoadInventory"
              table-id="datatable-modal-list-quote"
            ></UniversalList>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- col-md-12-->
      <!--end modal new project body -->
    </ValidationObserver>
  </div>
</template>

<script>
  import ClientService from "@/services/clients";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UtilityString from "@/utilities/strings";
  import hash from "object-hash";
  import UniversalList from "@/components/_universal/list";
  import UXInventoryService from "@/services/ux/uxinventory";
  const utility = new UtilityString();

  const global = new GlobalServices();
  const client = new ClientService();

  export default {
    data() {
      return {
        isInfo: true,
        isSavingSuccessful: false,
        isError: false,
        client: {},
        quote: {
          id: 0,
          quoteDate: new Date().toISOString().slice(0, 10),
          attentionTo: "",
          quoteDetails: [],
        },
        moduleFilter: {clientId: 0},
        selectedClient: null,
        selectedLocation: null,
        selectedQuoteType: null,

        uxClientList: [],
        uxLocationList: [],
        uxQuoteTypeList: [{displayName: "Standard Quote", id: 1}],

        subTotal: 0,
        quoteTotal: 0,

        //Item List
        propItemList: [],
        lookupList: [],

        modalStatus: {
          modalName: "",
          modalTitle: "",
          data: null,
        },

        disableDropdown: false,
      };
    },
    computed: {
      disabledLocation() {
        if (this.uxLocationList && this.uxLocationList.length && this.uxLocationList.length <= 1) {
          return true;
        } else {
          return false;
        }
      },
    },
    methods: {
      async onSave(data) {
        this.$refs.formNewQuote.validate().then(async (success) => {
          if (!success) {
            return;
          }

          this.quote.moduleId = MODULE.QUOTATION.ModuleId;
          this.quote.clientId = this.selectedClient.id;
          this.quote.locationId = this.selectedLocation.id;
          if (this.$route.name === "projects-details" && this.modalStatus.data.intClientID) this.quote.projectId = parseInt(this.$route.params.id);
          //Remove all spaces from properties
          this.quote = utility.trimObjectPropertySpace(this.quote);

          //Generate a Hash from the data to be submitted
          this.quote.hash = hash(this.quote);

          let result = await global.createNewActivity(MODULE.QUOTATION.ActionId, this.quote);

          if (result.success) {
            this.isSavingSuccessful = true;
            this.isError = false;
            this.isInfo = false;

            //Reset board
            this.selectedClient = null;
            this.selectedClient = null;
            this.selectedLocation = null;

            this.quote.subject = "";
            this.quote.attentionTo = "";
            this.quoteTotal = 0;
            this.subTotal = 0;
            this.propItemList = [];

            // Wait until the models are updated in the UI
            this.$refs.formNewQuote.reset();
          } else {
            this.isError = true;
            this.isSavingSuccessful = false;
          }

          setTimeout(() => {
            this.isSavingSuccessful = false;
            this.isError = false;
          }, 8000);
        });
      },
      async onSelectClient(e) {
        //reset client info
        this.client = null;

        //Set Clignt Module Filter
        this.moduleFilter.clientId = e.id;

        //Get Client Locations
        this.uxLocationList = await global.getUXDropDown(MODULE.LOCATION.ActionId, null, null, this.moduleFilter);

        const location = this.uxLocationList.find((loc) => {
          return this.modalStatus.data.intLocationID === loc.id;
        });

        if (location) this.selectedLocation = location;
        else this.selectedLocation = this.uxLocationList[0];
        // if (this.uxLocationList.length === 1) {
        //   this.selectedLocation = this.uxLocationList[0];
        // } else {
        //   //Set Location Default
        //   this.selectedLocation = global.setDropDownDefault(this.uxLocationList);
        // }

        //Get Client Details
        // this.client = await client.getClientById(e.id);
        this.client = await global.getEditDetails(MODULE.CLIENTS.ActionId, e.id);

        this.quote.attentionTo = this.client.primaryContact ? this.client.primaryContact : `${this.client.PrimaryContactFirstName} ${this.client.PrimaryContactLastName}`;
      },
      async onLoadInventory(mode) {
        // let inventoryService = new UXInventoryService();

        // //Hack to pass additional paramaters to inventory list
        // let queryString = "&actionId=" + MODULE.QUOTATION.ActionId;
        let defaultActionId = MODULE.PRICING.ActionId;
        if (mode) defaultActionId = mode;
        this.lookupList = await global.getUXDropDown(defaultActionId);
        // @TODO preselect dropdown
      },
      async onSelectLocation(e) {
        this.client = await client.getClientByIdAndLocationId(this.selectedClient.id, e.id);

        this.quote.attentionTo = this.client.primaryContact;
      },
      onUpdateList(list) {},
      onUpdateSubTotal(value) {
        this.isError = false; //Reset error message.
        this.subTotal = value;

        this.quoteTotal = this.subTotal * 1.13;

        this.quote.quoteDetails = this.propItemList;
      },

      async initializeData() {
        this.modalStatus = this.$store.getters.getModalStatus;
        //Get Client List
        this.uxClientList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
        this.disableDropdown = false;
        if (this.$route.name === "projects-details" && this.modalStatus.data.intClientID) {
          //Get Data From Modal

          this.selectedClient = this.uxClientList.find((client) => {
            return client.id === this.modalStatus.data.intClientID;
          });
          this.disableDropdown = true;
          this.moduleFilter.clientId = this.modalStatus.data.intClientID;
          //Get Client Locations
          this.uxLocationList = await global.getUXDropDown(MODULE.LOCATION.ActionId, null, null, this.moduleFilter);

          if (this.uxLocationList.length > 0) {
            const location = this.uxLocationList.find((loc) => {
              return this.modalStatus.data.intLocationID === loc.id;
            });

            if (location) this.selectedLocation = location;
            else this.selectedLocation = this.uxLocationList[0];
          } else {
            //Set Location Default
            // this.selectedLocation = global.setDropDownDefault(this.uxLocationList);
          }

          // this.client = await client.getClientById(this.modalStatus.data.intClientID);
          this.client = await global.getEditDetails(MODULE.CLIENTS.ActionId, this.modalStatus.data.intClientID);

          this.quote.attentionTo = this.client.primaryContact;
          // automatically set the client
        }

        //Populate Quote Type Dropdown List
        //this.uxProjectTypeList = await global.getUXDropDown(MODULE.PROJECTS.Type);

        //Set Quote Type Defaut Value
        this.selectedQuoteType = global.setDropDownDefault(this.uxQuoteTypeList);
        //Set List Items
        this.propItemList = this.quote.quoteDetails;

        this.onLoadInventory();
      },
    },

    components: {UniversalList},
    mounted() {
      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page(this.EVENT_ACTION.QUOTATION.View);
      // }
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      this.initializeData();

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.QUOTATION.New, this.onSave);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.QUOTATION.New, this.onSave);
    },
  };
</script>
