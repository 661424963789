


































import Vue from "vue";
import { VueAccordion, VueAccordionItem } from "vue-accordion-styless";

export default Vue.extend({
  name: "AudioNotes",
  props: {
    dataObj: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isClose: true,
    }
  },

  components: { VueAccordion, VueAccordionItem },

  computed: {
    translatedText() {
      return this.dataObj.notes
    },
    isStringLengthMoreThan250() {
      return Boolean(this.translatedText.length > 400);
    }
  },

  methods: {
    toggle() {
      this.isClose = !this.isClose;
    },
  }

});
