
































import editMixin from "@/mixin/edit-mixin";
import editor from "@/components/_universal/global/editor.vue";
export default {
  mixins: [editMixin],
  components: {
    editor,
  },
  data() {
    return {
      content: null,
      mode: "rich",
    };
  },
  watch: {
    mode(value) {
      this.updateField(
        "isHTML",
        value === "rich", //Property Value
        "Is HTML", //Display Name,
        value === "rich", //Display Value
        "isHTML", //Data object Property Name
        null,
        null,
        null,
        this.dataObj.param.id,
        this.dataObj.param.actionId
      );

      setTimeout(() => {
        this.updateField(
          "isPlainText",
          value === "plain", //Property Value
          "Is Plain Text", //Display Name,
          value === "plain", //Display Value
          "isPlainText", //Data object Property Name
          null,
          null,
          null,
          this.dataObj.param.id,
          this.dataObj.param.actionId
        );
      }, 500);
    },
  },
  methods: {
    updateHere(html) {
      this.dataObj.details.Description = html;
      this.updateField(
        "Description", //PropertyName
        html, //Property Value
        "Description", //Display Name,
        html, //Display Value
        "Description", //Data object Property Name
        null,
        null,
        null,
        this.dataObj.param.id,
        this.dataObj.param.actionId
      );
    },
    getData() {
      this.mode = this.dataObj.details.isPlainText !== null && (this.dataObj.details.isPlainText === "true" || this.dataObj.details.isPlainText === true) ? "plain" : "rich";
      // this.content = this.dataObj.details.isHTML && !this.dataObj.details.isPlainText ? this.dataObj.details.Description : this.dataObj.details.PlainText;
    },
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {},
};
