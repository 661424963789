<template>
  <div class="w-auto dashboard-table" style="background-color:#FFF;">
    <table class="table" :id="tableId">
      <thead class="c-table__head c-table__head--slim">
        <tr>
          <th class="c-table__cell--head text-center" width="05%"></th>
          <th class="c-table__cell--head text-left">Description</th>
          <th class="c-table__cell--head text-center" width="10%">Qty</th>
          <th class="c-table__cell--head text-center" width="15%">Unit Price1</th>
          <th class="c-table__cell--head text-center" width="15%">Total</th>
          <th class="c-table__cell--head text-right" width="5%"></th>
          <th class="c-table__cell--head text-right" width="5%">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in itemList" v-bind:key="item.guid" :class="['position-relative', 'row' + i]">
          <td class="text-center pt-3">
            <a v-if="showHideLabel(i) == true" href="#" @click.prevent="editRow(i)" :id="$route.name + '-modal-list-edit'">Edit</a>
            <a v-if="showHideTextBox(i) == true" href="#" @click.prevent="saveRow(i)" :id="$route.name + '-modal-list-save'">Save</a>
          </td>

          <td class="text-left">
            <!-- Radio buttons -->
            <div v-if="showHideTextBox(i) == true" class="w-100 mr-3 d-flex h-41 align-items-center mb-2">
              <div class="mr-3">
                <div class="c-choice c-choice--radio mb-0">
                  <input class="c-choice__input" id="radio2" name="radio2" type="radio" :value="MODULE.PRICING.ActionId" v-model="mode" />
                  <label class="c-choice__label mb-0" for="radio2">Pricing</label>
                </div>
              </div>

              <div class="mr-3">
                <div class="c-choice c-choice--radio mb-0">
                  <input class="c-choice__input" id="radio1" name="radio1" type="radio" :value="MODULE.INVENTORY.ActionId" v-model="mode" />
                  <label class="c-choice__label mb-0" for="radio1">Inventory</label>
                </div>
              </div>

              <div v-if="usedIn !== 'PURCHASE_ORDER'" class="mr-3">
                <div class="c-choice c-choice--radio mb-0">
                  <input class="c-choice__input" id="radio3" name="radio3" type="radio" :value="MODULE.CLIENTS.UX.ClientRateGroup" v-model="mode" />
                  <label class="c-choice__label mb-0" for="radio3">Group Rates</label>
                </div>
              </div>
            </div>
            <v-select
              v-if="showHideTextBox(i) == true"
              v-model="selectedLookupItem"
              :options="lookupList"
              @input="onSelectSuggestion"
              code="id"
              label="displayName"
              :id="$route.name + '-modal-list-description-dropdown'"
              :placeholder="dropdownPlaceholder"
            ></v-select>
            <v-select
              v-if="showHideTextBox(i) == true && mode === MODULE.CLIENTS.UX.ClientRateGroup"
              v-model="secondaryLookupItem"
              :options="secondaryLookupList"
              @input="onSelectSecondarySuggestion"
              code="id"
              label="displayName"
              :id="$route.name + '-modal-list-secondary-dropdown'"
              class="mt-2"
              :disabled="selectedLookupItem && !selectedLookupItem.id"
              placeholder="Select Rate"
            ></v-select>
            <div class="form-group mb-0 list-description-custom" v-if="showHideTextBox(i) == true">
              <textarea class="form-control mt-2 no-resize" :id="$route.name + '-modal-list-description-textarea'" v-model="item.description" rows="2"></textarea>
            </div>
            <span v-if="showHideTextBox(i) !== true" v-html="item.description"></span>
          </td>
          <td class="text-center">
            <span v-if="showHideLabel(i) == true" class="qty">{{ item.qty }}</span>
            <input
              v-if="showHideTextBox(i) == true"
              v-on:keyup="calculateItemTotal(i)"
              :id="$route.name + '-modal-list-qty'"
              class="form-control text-center qty-edit"
              v-model="item.qty"
              type="text"
            />
          </td>
          <td class="text-center">
            <div :class="[{'input-group': showHideTextBox(i) == true}, 'input-with-dollar-sign']">
              <div class="input-group-prepend" v-if="showHideTextBox(i) == true">
                <span class="input-group-text">$</span>
              </div>
              <span v-if="showHideLabel(i) == true" class="unitPrice">{{ item.unitPrice | currency({fractionCount: getDecimalPoint(item.unitPrice)}) }}</span>
              <input
                v-if="showHideTextBox(i) == true"
                :id="$route.name + '-modal-list-unit-price'"
                v-on:keyup="calculateItemTotal(i)"
                class="form-control text-center unitPriceEdit"
                v-model="item.unitPrice"
                type="text"
              />
            </div>
          </td>
          <td class="text-center">
            <!-- if we are using invoice lets format the currency without rounding else do the normal way -->
            <span class="total">{{
              usedIn === "INVOICE" && isInvoiceHasData ? formatCurrency(getInvoiceRoundinValue(invoice.applicationCurrencyRoundingOption, item.amount)) : item.amount | currency
            }}</span>
          </td>
          <td class="text-right">
            <a v-if="showHideTextBox(i) == true" href="#" @click="cancelRow(i)" :id="$route.name + '-modal-list-cancel'">Cancel</a>
          </td>
          <td class="text-right">
            <i @click="deleteItem(i)" class="fa fa-trash u-text-danger u-text-larger" aria-hidden="true" :id="$route.name + '-modal-list-delete'"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="alert alert-warning text-center" role="alert" v-if="showErrorWarning == true" key="warning">
      Please enter a valid description, quantity and price for the last row before adding new items.
    </div>

    <div class="ss--add-new__item o-line u-pt-small">
      <div class="c-orange-line"></div>
      <div role="button" @click="addNewItem" class="c-add-new-item__btn u-mr-xsmall u-ml-xsmall" :id="$route.name + '-modal-list-add-new-item'">
        <i aria-hidden="true" class="fa fa-plus-circle u-mr-xsmall"></i> Add New Item
      </div>
      <div class="c-orange-line"></div>
    </div>
  </div>
</template>

<script>
  import "vue-simple-suggest/dist/styles.css"; // Optional CSS
  import UtilityString from "@/utilities/strings";
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  const global = new GlobalServices();
  const utility = new UtilityString();
  const Decimal = require("decimal.js");
  export default {
    name: "UniversalList",
    props: {
      propItemList: Array,
      lookupList: Array,
      usedIn: {
        type: String,
        default: "Default",
      },
      tableId: {
        type: String,
        default: "datatable-modal",
      },
      client: {
        type: Object,
        default: () => {
          return {};
        },
      },
      invoice: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        itemList: this.propItemList,
        selectedItem: {},
        selectedLookupItem: null,
        secondaryLookupItem: null,
        secondaryLookupList: [],
        showErrorWarning: false,
        mode: null,
      };
    },
    computed: {
      dropdownPlaceholder() {
        if (this.mode === MODULE.INVENTORY.ActionId) {
          return "Select Inventory";
        } else if (this.mode === MODULE.CLIENTS.UX.ClientRateGroup) {
          return "Select Group Rate";
        } else {
          return "Select Pricing";
        }
      },
      isInvoiceHasData() {
        return Object.keys(this.invoice).length > 0;
      },
    },
    watch: {
      propItemList: {
        handler: function(propItemList) {
          this.itemList = this.propItemList;
          this.calculateTotalSum();
        },
        immediate: true,
      },
      mode(value) {
        this.selectedLookupItem = null;
        this.$emit("get-list", value);
      },
      lookupList(val) {
        if (
          val &&
          this.mode === MODULE.CLIENTS.UX.ClientRateGroup &&
          Object.keys(this.client).length > 0 &&
          this.client.IntClientRateGroupID &&
          (this.usedIn === "Default" || this.usedIn === "INVOICE")
        ) {
          this.selectedLookupItem = val.find((d) => parseInt(d.id) === parseInt(this.client.IntClientRateGroupID));
          this.loadSecondDropdown();
        }
      },
    },
    methods: {
      calculateTotalSum() {
        const subTotal = this.decimalSumBy(this.itemList, "amount", this.isInvoiceHasData ? this.invoice.applicationCurrencyRoundingOption : null);
        this.subTotal = subTotal.toString();
        if (this.usedIn === "INVOICE" && this.isInvoiceHasData) {
          this.calculateTotalTax();
        }
        this.$emit("EVENT_ReCalculate", this.subTotal);
        this.$emit("EVENT_ListUpdated", this.itemList);
      },
      calculateTotalTax() {
        const taxTotal = this.decimalSumByTax(this.itemList, "amount", this.invoice.applicationCurrencyRoundingOption, this.invoice.taxRate);
        this.$emit("EVENT_ReCalculateTax", taxTotal);
      },
      async loadSecondDropdown() {
        this.secondaryLookupItem = null;
        const results = await global.getSimpleUXDropDown(MODULE.CLIENTS.UX.ClientRates, this.mode, this.selectedLookupItem.id);
        this.secondaryLookupList = results.map((item) => {
          return {
            ...item,
            qty: item.qty ? parseFloat(item.qty) : 0,
            unitPrice: item.unitPrice ? parseFloat(item.unitPrice) : 0,
            amount: item.total ? item.total : 0,
          };
        });
      },
      async onSelectSuggestion(selectedObj) {
        if (this.selectedItem && this.mode !== MODULE.CLIENTS.UX.ClientRateGroup) {
          let item = await global.getEditDetails(this.mode, selectedObj.id);
          if (item) {
            this.selectedItem.description = `${item.Name}${item.Description ? "\n" + item.Description : ""}`;
            const {qty, unitPrice} = this.getPriceQtyDetails(item);
            this.selectedItem.unitPrice = unitPrice;
            this.selectedItem.qty = qty;
          } else {
            this.selectedItem.description = selectedObj.displayName;
            this.selectedItem.qty = 1;
            this.selectedItem.unitPrice = selectedObj.unitPrice ? selectedObj.unitPrice : 0;
          }

          this.selectedItem.amount = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);
          this.calculateTotalSum();
        } else {
          this.loadSecondDropdown();
        }
      },
      async onSelectSecondarySuggestion(selectedObj) {
        if (selectedObj && this.selectedItem) {
          const item = await global.getEditDetails(MODULE.CLIENTS.UX.ClientRates, selectedObj.id);
          // description
          this.selectedItem.description =
            item && (item.Name || item.Description)
              ? `${item.Name}\n${item.Description ? item.Description : ""}`
              : `${selectedObj.displayName}\n${selectedObj.description ? selectedObj.description : ""}`;

          const amount = item.Amount ? item.Amount : 0;
          const qtyProperty = item.hasOwnProperty("Qty") ? item.Qty : item.hasOwnProperty("Quantity") ? item.Quantity : 1;
          const qty = qtyProperty && parseFloat(qtyProperty) !== 0 ? parseFloat(qtyProperty) : 1;
          // qty
          this.selectedItem.qty = qty;
          // unit price
          this.selectedItem.unitPrice = amount;

          this.selectedItem.amount = this._.multiply(this.selectedItem.qty, this.selectedItem.unitPrice);

          this.calculateTotalSum();
        }
      },
      getPriceQtyDetails(item) {
        const salePrice = item.SalePrice ? item.SalePrice : 0;
        const costPrice = item.ItemCost ? item.ItemCost : salePrice;
        const amount = item.Amount ? item.Amount : 0;
        const qtyProperty = item.hasOwnProperty("Qty") ? item.Qty : item.hasOwnProperty("Quantity") ? item.Quantity : 1;
        const qty = qtyProperty && parseFloat(qtyProperty) !== 0 ? parseFloat(qtyProperty) : 1;
        switch (this.usedIn) {
          case "PURCHASE_ORDER":
            // we are using cost price for purchase order
            return {qty, unitPrice: costPrice};
          case "INVOICE":
            // we check if Amount is present in the item
            // if its present it means its from Client Rates and we should use it as unitPrice
            // else we use our salePrice from inventory / pricing
            return {qty, unitPrice: item.hasOwnProperty("Amount") ? amount : salePrice};
          default:
            // by default we are using sale price in any modules
            return {qty, unitPrice: salePrice};
        }
      },

      async saveRow(index) {
        this.selectedItem = null;
        this.$emit("EVENT_ListUpdated", this.itemList);
      },
      editRow(index) {
        this.selectedItem = this.itemList[index];
      },
      calculateItemTotal(index) {
        this.selectedItem = this.itemList[index];
        if (this.selectedItem) {
          let qty = new Decimal(parseFloat(this.selectedItem.qty));
          let unitPrice = new Decimal(parseFloat(this.selectedItem.unitPrice));
          const amount = qty.times(unitPrice);
          this.selectedItem.amount = this.getInvoiceRoundinValue(this.invoice.applicationCurrencyRoundingOption, parseFloat(amount.toString()));
          this.itemList.splice(index, 1, this.selectedItem);
        }
        this.calculateTotalSum();
      },

      showHideLabel(index) {
        if (this.isEditingCurrentRow(index)) return false;
        else return true;
      },
      showHideTextBox(index) {
        if (this.isEditingCurrentRow(index)) return true;
        else return false;
      },
      isEditingCurrentRow(index) {
        if (this.selectedItem === this.itemList[index]) return true;
        else return false;
      },

      async deleteItem(index) {
        //Delete from API
        //await api.deleteItem(this.itemList[index]);

        //Delete from list if succesful
        this.itemList.splice(index, 1);

        //Reset Selected Item
        this.selectedItem = null;

        //Recalculate total
        this.calculateTotalSum();
      },
      cancelRow(index) {
        if (this.isEditingLastRow()) {
          if (!this.isLastItemRowValid()) this.deleteItem(index);
        }
        this.showErrorWarning = false;
        this.selectedItem = null;
      },
      async addNewItem() {
        const newItem = {
          id: 0,
          guid: "",
          description: "",
          qty: 1,
          unitPrice: 0,
          amount: null,
          utcCreatedDate: new Date().toJSON().slice(0, 10),
        };

        let shouldAddNewItem = false;
        await this.saveRow();

        //Evaluate the last row before adding new item row
        if (this.isLastItemRowValid()) {
          shouldAddNewItem = true;
        } else if (this.itemList.length === 0) {
          shouldAddNewItem = true;
        }

        if (shouldAddNewItem) {
          newItem.guid = utility.generateGUID();

          this.itemList.push(newItem);
          this.editRow(this.itemList.length - 1);
        }
      },
      isLastItemRowValid() {
        //Get the last Item row and ensure that it has values, before allowing user to add additional rows
        let allowAddingNewRow = false;

        if (this.itemList.length > 0) {
          //If current select item is the last row
          if (this.isEditingLastRow()) {
            //If they are no items in the last row then show error message.
            if (this.selectedItem.description === "" && this.selectedItem.qty === 1 && this.selectedItem.unitPrice === 0) {
              this.showErrorWarning = true;
              setTimeout(() => (this.showErrorWarning = false), 4000);
            } else {
              allowAddingNewRow = true;

              //If the last row is not blank, then save it to databse.
              this.saveRow(this.itemList.length - 1);
            }
          } else {
            allowAddingNewRow = true;
          }
        }

        return allowAddingNewRow;
      },

      //Check if we're editing the last item in the list.
      isEditingLastRow() {
        if (this.selectedItem == this.itemList[this.itemList.length - 1]) return true;

        return false;
      },

      //Setup Data
      async initializeData() {
        //Recalculate results
        this.calculateTotalSum();
      },
    },

    async created() {
      this.MODULE = MODULE;
      this.mode = MODULE.PRICING.ActionId;
      await this.initializeData();
      this.$root.$on("update-tax", this.calculateTotalTax);
    },
    beforeDestroy() {
      this.$root.$off("update-tax", this.calculateTotalTax);
    },
  };
</script>
