import axios from "axios";
import {IResults} from "@/interfaces/IResults";
import UtilityString from "@/utilities/strings";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class SearchService {
  config = new API_CONFIG();
  auth = AuthService.getInstance();
  utility = new UtilityString();

  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  async getResults(api, filter) {
    let results;

    //Convert object to query string
    let param = this.getFilter(filter);

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    let paramString = this.utility.parseQueryString(param);

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get(api + "?" + paramString + correlationId, apiHeaderObj);

    return results.data;
  }

  getFilter(filterObj) {
    if (filterObj) {
      if (!filterObj.id) {
        filterObj.id = 0;
        filterObj.maximumRows = 100;
      }
    } else {
      filterObj = {
        id: 0,
        maximumRows: 100,
      };
    }

    let auth = AuthService.getInstance();
    return Object.assign(filterObj, auth.getAuthObject());
  }
}

export default SearchService;
