




























import Vue from "vue";
import { DashboardStats } from "@/interfaces/modules/dashboard/DashboardStats";

export default Vue.extend({
  name: "Highlights",
  data() {
    return {
      dashboardStatsResults: {}
    };
  },
  methods: {
    statsIcon(iconName) {
      return "fa ss-white-font-color u-mr-auto" + iconName;
    },
    arrowIndicatorCss(showPositiveIndicator) {
      if (showPositiveIndicator) return "fa fa-arrow-up u-text-success";
      else return "fa fa-arrow-down u-text-danger";
    },
    indicatorStatusCss(showPositiveIndicator) {
      if (showPositiveIndicator) return "percent u-text-success u-text-bold";
      else return "percent u-text-danger u-text-bold";
    }
  },
  computed: {},
  async created() {
    //Watch for Dashboard stats changes, to update local variable
    this.$store.watch(
      state => {
        return this.$store.state.Dashboard.dashboardStats;
      },
      (newValue, oldValue) => {
        //something changed do something
        this.dashboardStatsResults = newValue.highlights.stats;
      }
    );
  }
});
