<template>
  <ValidationObserver ref="formNewPricing">
    <div class="mx-2 mb-2">
      <div class="row">
        <div v-if="isSuccesful" class="col-md-12 mb-2">
          <b-alert show class="mb-2" variant="success">
            <i class="fa fa-check-circle mr-2"></i>
            <span class="sub-title mr-2" id="modal-new-pricing-success-message">Succesfully created new pricing. You may
              close this window.</span>
          </b-alert>
        </div>
        <div class="col-md-12 mb-2">
          <ValidationProvider v-slot="{errors}" name="Name" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Name</span>
            <input class="form-control" id="modal-new-pricing-name" type="text" v-model="formData.Name" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6 mb-2">
          <ValidationProvider v-slot="{errors}" name="Qty" rules="required|numeric|min_value:1">
            <span class="c-toolbar__state-title u-text-capitalize">Quantity</span>
            <input class="form-control" id="modal-new-pricing-quantity" type="text" v-model="formData.Qty" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-3 mb-2">
          <ValidationProvider v-slot="{errors}" name="Price" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Sale Price</span>
              <div class="input-group input-with-dollar-sign">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input class="form-control" id="modal-new-pricing-sale-price" type="text" v-model="formData.SalePrice" />
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-3 mb-2">
          <ValidationProvider v-slot="{errors}" name="CostPrice" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Cost Price</span>
          <div class="input-group input-with-dollar-sign">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input class="form-control" id="modal-new-pricing-cost-price" type="text" v-model="formData.ItemCost" />
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
          </ValidationProvider>
        </div>
        <div class="col-md-12 mb-2">
          <span class="c-toolbar__state-title u-text-capitalize">Description</span>
          <textarea rows="8" id="modal-new-pricing-description" class="form-control text-left"
            v-model="formData.Description" />
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import UtilityString from "@/utilities/strings";
  import hash from "object-hash";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  const utility = new UtilityString();
  export default {
    name: "NewPricing",
    data() {
      return {
        apiErrors: {
          hasError: false,
          message: null,
        },
        isSuccesful: false,
        formData: {
          actionId: MODULE.PRICING.ActionId,
          moduleId: 0,
          Name: null,
          SalePrice: 0,
          ItemCost: 0,
          Qty: 1,
          Description: null,
        },
      };
    },
    methods: {
      async initializeData() {},
      async onSave() {
        try {
          this.apiErrors = {
            hasError: false,
            message: null,
          };
          const isValid = await this.$refs.formNewPricing.validate();

          if (isValid) {
            //Remove all spaces from properties
            this.formData = utility.trimObjectPropertySpace(this.formData);

            //Generate a Hash from the data to be submitted
            this.formData.hash = hash(this.formData);

            let result = await global.createNewActivity(MODULE.PRICING.ActionId, this.formData);

            if (result.success) {
              this.isSuccesful = true;
              this.formData = {
                actionId: MODULE.PRICING.ActionId,
                moduleId: 0,
                Name: null,
                SalePrice: 0,
                ItemCost: 0,
                Qty: 1,
                Description: null,
              };
            } else {
              this.isSuccesful = false;
            }
          }
        } catch (e) {
          this.apiErrors = {
            hasError: true,
            message: e,
          };
        }
      },
    },

    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      await this.initializeData();

      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.PRICING.New, this.onSave);
    },

    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.PRICING.New, this.onSave);
    },
  };
</script>
