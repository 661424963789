<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 mb-2">
        <span class="c-toolbar__state-title u-text-capitalize">
          Type:
          <span
            class="ss-primary-light-font-color small"
          >(eg. choose if a document should be for internal use only.)</span>
        </span>
        <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
      </div>
      <div class="col-md-12 u-mb-xsmall">
        <span
          class="ss-primary-light-font-color small"
        >Click the "Upload a file" button below, then select a file to upload or drag files into the purple box, then click save and close:</span>
        <textarea class="form-control" type="text" rows="5" />
      </div>
    </div>
  </div>
</template>


<script>
// import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {};
  },
  components: {}
};
</script>
