import { render, staticRenderFns } from "./tab-terms.vue?vue&type=template&id=4eb51566&scoped=true&"
import script from "./tab-terms.vue?vue&type=script&lang=ts&"
export * from "./tab-terms.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb51566",
  null
  
)

export default component.exports