export default {
  data() {
    return {
      modalStatus: {
        hideShowModal: false,
        modalName: "",
        isSaveButtonHidden: false,
        modalTitle: "",
        data: {},
      },
    };
  },
  methods: {
    showModal(paramModalName, modalTitle, actionId?, moduleId?, immediateInit?, isSaveButtonHidden?) {
      this.modalStatus.hideShowModal = true;
      this.modalStatus.modalName = paramModalName;
      this.modalStatus.data = {};

      if (actionId) {
        this.modalStatus.data.actionId = actionId;
      }

      if (moduleId) {
        this.modalStatus.data.moduleId = moduleId;
      }

      this.modalStatus.modalTitle = modalTitle;
      this.modalStatus.isSaveButtonHidden = typeof isSaveButtonHidden != undefined || isSaveButtonHidden !== null ? isSaveButtonHidden : false;
      this.modalStatus.modalSaveButtonTitle = this.modalStatus.isSaveButtonHidden ? "" : "Save";
      if (immediateInit) {
        this.modalStatus.immediateInit = immediateInit;
      }
      this.$store.dispatch("toggleModalStatus", this.modalStatus);
    },
  },
};
