import axios from "axios";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";

let auth = AuthService.getInstance();
let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class ActivityService {
  private static _instance: ActivityService;

  constructor() {
    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
      },
    };
  }

  //Used for loading data from the universal notes component
  //Supporting all types of modules, eg... Permits, Deposits etc... (Except the actual notes)
  async getActivity(id, moduleId, additionalParams = {} as any) {
    axios.defaults.baseURL = apiUrl;

    let queryString = auth.getQueryString() + "&moduleId=" + moduleId + "&id=" + id;

    if (additionalParams && additionalParams.parentId) {
      queryString += "&parentID=" + additionalParams.parentId
    }

    if (additionalParams && additionalParams.EmployeeID) {
      queryString += "&EmployeeID=" + additionalParams.EmployeeID
    }

    let dataResults = await axios.get("activity/list" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  async getAllActivity(query, filters, dateTimeFilter) {
    axios.defaults.baseURL = apiUrl;

    let queryString = auth.getQueryString();

    if (query) {
      queryString += `&q=${query}`;
    }

    if (filters.length > 0) {
      queryString += `&actionIds=${filters.join(",")}`;
    }

    if (dateTimeFilter) {
      queryString += `&dateTime=${dateTimeFilter}`;
    }

    let dataResults = await axios.get("activity/list" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Get activity details
  async getDetailActivity(id, moduleId) {
    axios.defaults.baseURL = apiUrl;

    let queryString = auth.getQueryString() + "&moduleId=" + moduleId + "&_Id=" + id;

    let dataResults = await axios.get("activity/details" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Get activity details
  async getDetailActivityV2(id) {
    axios.defaults.baseURL = apiUrl;

    let queryString = auth.getQueryString() + "&_Id=" + id;

    let dataResults = await axios.get("activity/details" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Used specifically for the project notes component
  async getEmailActivity(id, moduleId, additionalParams = {} as any) {
    axios.defaults.baseURL = apiUrl;

    let queryString = auth.getQueryString() + "&moduleId=" + moduleId + "&Id=" + id;

    if (additionalParams && additionalParams.parentId) {
      queryString += "&parentID=" + additionalParams.parentId
    }

    let dataResults = await axios.get("activity/emails" + queryString, apiHeaderObj);

    return dataResults.data.resources;
  }

  //Update Item Record
  async updateLogActivity(item) {
    this.setAPIDefaults(item);

    let result = null;

    result = await axios.post("activity/add", item, apiHeaderObj).catch(function(error) {});

    return result.data.resources;
  }

  private setAPIDefaults(item: any) {
    axios.defaults.baseURL = apiUrl;

    item = Object.assign(item, auth.getAuthObject());
  }

  static createInstance() {
    ActivityService.getInstance();
  }

  static getInstance() {
    return this._instance || (this._instance = new this());
  }
}

export default ActivityService;
