






















































































































































































































import editMixin from "@/mixin/edit-mixin";
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
let global = new GlobalServices();

export default {
  mixins: [editMixin],
  data() {
    return {
      uxPreferredLanguage: [],
    };
  },

  async mounted() {
    await this.initializeData();
  },

  computed: {
    languageDisplayValue() {
      const lang = this.uxPreferredLanguage.find((value) => {
        return value.id === this.currentDataObj.details.IntPreferredLanguageID;
      });

      return lang.displayName;
    },
  },
  methods: {
    async initializeData() {
      const uxPreferredLanguage = await global.getUXDropDown(MODULE.CLIENTS.UX.PreferredLanguage);
      this.uxPreferredLanguage = uxPreferredLanguage || [];
    },
  },

  async created() {
    //All Update logic is performed in the editMixin vue file
    //This logic loads all dropdown menu items
    this.UXData = await this.currentDataObj.uxdata(this.APP.PAGE.Clients.Details);
  },
};
