<template>
  <!-- Tabs -->
  <b-card no-body class="px-3 py-3 mx-2 slide-panel-tabs">
    <b-tabs content-class="mt-4">
      <b-tab v-for="tab in tabDataObj" v-bind:key="tab.id" :title="tab.title" lazy
        :title-link-class="$route.name + '-tab-' + modalTitleAsID(tab.title)" :id="`tab-${modalTitleAsID(tab.title)}`">
        <TabComponent :data-obj="tab" slot-scope="data">{{ data.title }}</TabComponent>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import UtilityString from "@/utilities/strings";

//Tab
import TabComponent from "@/components/_universal/tab-component-render.vue";

const utility = new UtilityString();
const app = new ApplicationService();

export default {
  props: {
    tabDataObj: {
      type: Array,
      required: true,
    },
    iFrame: {
      type: Object,
    },
  },
  data() {
    return {
      project: {},
      EVENT_ACTION: {},

      modalStatus: {
        hideShowModal: false,
        modalName: "",
        modalTitle: "",
        data: {},
      },
    };
  },
  methods: {
    async loadData() { },
    showModal(paramModalName, modalTitle, sendButtonText, data) {
      this.modalStatus.hideShowModal = true;
      this.modalStatus.modalName = paramModalName;
      this.modalStatus.modalTitle = modalTitle;
      this.modalStatus.modalSaveButtonTitle = sendButtonText;
      this.modalStatus.data = data;

      this.$store.dispatch("toggleModalStatus", this.modalStatus);
    },
    modalTitleAsID(modalName) {
      let title = modalName
      title = title.toLowerCase()
      return title.replace(' &', '').replace('&', '').split(' ').join('-').replace('/', '-')
    },
  },
  computed: {

  },
  components: {
    TabComponent,
  },
  mounted() {
    // This is to set width on the selectbox based on the lengh of the placeholder
    let selectBox = document.querySelectorAll(".no-box-select input");
    for (let i = 0; i < selectBox.length; i++) {
      let placeholderLength = selectBox[i].placeholder.length;
      selectBox[i].style.width = placeholderLength * 15 + "px";
    }
  },
  async created() {
    this.EVENT_ACTION = EVENTS;
    await this.loadData();
  },
};
</script>
