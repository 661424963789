<template>
  <div class="col-md-12">
    <div class="mb-3">
      <span class="c-toolbar__state-title u-text-capitalize">Type: (eg. choose if a document should be for internal use only.)</span>
      <v-select v-model="selectedDocumentType" :options="uxDocumentTypeList" :clearable="false" value="id" label="displayName" id="modal-document-type"></v-select>
    </div>

    <div class="file-uploader">
      <div v-if="selectedDocumentType">
        <uploader
          :moduleId="modalStatus.moduleId"
          :actionId="modalStatus.parentActionId"
          @uploadSuccess="onUploadAttachment"
          :id="modalStatus.data.id"
          :document-type-id="selectedDocumentType.id"
          :is-file-share="modalStatus.data && modalStatus.data.category && modalStatus.data.category === 'DocumentTemplate'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import uploader from "@/components/_universal/uploader/filepond.vue";
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  const global = new GlobalServices();
  export default {
    name: "DocumentModal",
    components: {
      uploader,
    },
    data() {
      return {
        attachments: [],
        uxDocumentTypeList: [],
        selectedDocumentType: null,
      };
    },
    computed: {
      modalStatus() {
        return this.$store.getters.getModalStatus;
      },
    },
    methods: {
      onUploadAttachment(file) {
        this.attachments.push(file);
      },
      async loadData() {
        this.uxDocumentTypeList = await global.getUXDropDown(MODULE.DOCUMENTS.UX.DOCTYPES);
        this.selectedDocumentType = global.setDropDownDefault(this.uxDocumentTypeList);
      },
    },
    mounted() {
      this.loadData();
    },
  };
</script>
