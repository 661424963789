<template>
  <div class="col-md-12">
    <div class="table-responsive table-responsive-md w-auto dashboard-table">
      <table class="table" id="datatable">
        <thead class="c-table__head c-table__head--slim">
          <tr class="c-table__row">
            <th class="c-table__cell--head no-sort table-checkbox">
              <div class="c-choice c-choice--checkbox mb-0">
                <input class="c-choice__input" id="tablecheck1" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="tablecheck1"></label>
              </div>
            </th>
            <th class="c-table__cell c-table__cell--head no-sort">Employee</th>
            <th class="c-table__cell c-table__cell--head no-sort">Role</th>
          </tr>
        </thead>
        <tbody>
          <tr class="c-table__row" v-for="n in 20" :key="n">
            <td class="align-middle table-checkbox">
              <div class="c-choice c-choice--checkbox u-mr-small mb-0">
                <input class="c-choice__input" id="1" name="checkboxes" type="checkbox" />
                <label class="c-choice__label" for="1"></label>
              </div>
            </td>
            <td class="align-middle">test</td>
            <td class="align-middle">Technician</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
// import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {
      date: null
    };
  },
  components: {}
};
</script>
