var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-md-12 u-pt-small"},[(_vm.isError)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Sorry, there was an error while creating this job. Please try again. ")]):_vm._e(),(!_vm.modalObj.isInEditMode)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Select Equipment ")]),_c('v-select',{attrs:{"id":"slidepanel-edit-work-order-tabs-equipment-modal-select-equipment","options":_vm.uxEquipmentList,"code":"id","label":"displayName","clearable":false},on:{"input":_vm.onUpdateSelection},model:{value:(_vm.selectedEquipment),callback:function ($$v) {_vm.selectedEquipment=$$v},expression:"selectedEquipment"}})],1)]):_vm._e(),_c('div',{staticClass:"ss--border-bottom u-mt-small u-mb-small u-height-2"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Equipment Name ")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Name),expression:"modalDataDetails.Name"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-work-order-tabs-equipment-modal-equipment-name","type":"text"},domProps:{"value":(_vm.modalDataDetails.Name)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
                'Name', //PropertyName
                _vm.modalDataDetails.Name, //Property Value
                'Equipment Name', //Display Name
                true
              )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "Name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Equipment Name is required." : ""))])]}}])})],1),_c('div',{staticClass:"col-md-6 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Location On Site ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.LocationOnSite),expression:"modalDataDetails.LocationOnSite"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-work-order-tabs-equipment-modal-location-on-site"},domProps:{"value":(_vm.modalDataDetails.LocationOnSite)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'LocationOnSite', //PropertyName
              _vm.modalDataDetails.LocationOnSite, //Property Value
              'Location on site', //Display Name
              true
            )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "LocationOnSite", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Description ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Description),expression:"modalDataDetails.Description"}],staticClass:"form-control",attrs:{"id":"slidepanel-edit-work-order-tabs-equipment-modal-description"},domProps:{"value":(_vm.modalDataDetails.Description)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'Description', //PropertyName
              _vm.modalDataDetails.Description, //Property Value
              'Description', //Display Name
              true
            )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "Description", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Make ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Make),expression:"modalDataDetails.Make"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-work-order-tabs-equipment-modal-make"},domProps:{"value":(_vm.modalDataDetails.Make)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'Make', //PropertyName
              _vm.modalDataDetails.Make, //Property Value
              'Make', //Display Name
              true
            )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "Make", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Model Number ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.ModelNumber),expression:"modalDataDetails.ModelNumber"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-work-order-tabs-equipment-modal-model-number"},domProps:{"value":(_vm.modalDataDetails.ModelNumber)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'ModelNumber', //PropertyName
              _vm.modalDataDetails.ModelNumber, //Property Value
              'Model Number', //Display Name
              true
            )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "ModelNumber", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Serial Number ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.SerialNumber),expression:"modalDataDetails.SerialNumber"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-work-order-tabs-equipment-modal-serial-number"},domProps:{"value":(_vm.modalDataDetails.SerialNumber)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'SerialNumber', //PropertyName
              _vm.modalDataDetails.SerialNumber, //Property Value
              'Serial Number', //Display Name
              true
            )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "SerialNumber", $event.target.value)}}})]),_c('div',{staticClass:"col-md-3 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Manufacturer / Supplier ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Manufacturer),expression:"modalDataDetails.Manufacturer"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-work-order-tabs-equipment-modal-manufacturer-supplier"},domProps:{"value":(_vm.modalDataDetails.Manufacturer)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'Manufacturer', //PropertyName
              _vm.modalDataDetails.Manufacturer, //Property Value
              'Manufacturer/Supplier', //Display Name
              true
            )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "Manufacturer", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 ss--new-quote__modal_module u-mb-small"},[_c('div',{staticClass:"ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"},[_vm._v(" Notes ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalDataDetails.Notes),expression:"modalDataDetails.Notes"}],staticClass:"form-control",attrs:{"type":"text","id":"slidepanel-edit-work-order-tabs-equipment-modal-notes","rows":"5"},domProps:{"value":(_vm.modalDataDetails.Notes)},on:{"keyup":function($event){return _vm.updateFieldSimplified(
              'Notes', //PropertyName
              _vm.modalDataDetails.Notes, //Property Value
              'Notes', //Display Name
              true
            )},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.modalDataDetails, "Notes", $event.target.value)}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }