<template>
  <div class="col-md-12 u-mb-xsmall">
    <vue-json-to-table :data="jsonObjData"></vue-json-to-table>
  </div>
</template>
<script>
  export default {
    props: {
      dataObj: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        jsonObjData: {}, //Stores the generic json object to be used by the dynamic table.
      };
    },
    async mounted() {
      //Calls a call back function that looks up the real information to be loaded.
      this.jsonObjData = await this.dataObj.onLoad(this.dataObj.data);
    },
  };
</script>
