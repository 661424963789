<template>
  <!-- title card -->
  <div class="c-card u-p-small u-mb-small mx-2 pb-2 pt-3 sticky-card">
    <div class="row position-relative">
      <div class="col-md-6 pr-6">
        <h4 v-show="$route.name === 'projects-details' && !isEditingRefNumber" class="mb-1" @click="editProjectReference">
          Work Order ({{ project.projectRefNumber }}) - {{ headerDataObj.titleSection.status }}
        </h4>
        <h4 v-show="isEditingRefNumber" class="mb-1">
          <input
            @keyup.enter="isEditingRefNumber = !isEditingRefNumber"
            @blur="isEditingRefNumber = !isEditingRefNumber"
            v-show="isEditingRefNumber"
            ref="ref_number"
            class="form-control w-50"
            type="text"
            v-model="project.projectRefNumber"
            @keyup="updateField('projectRefNumber', project.projectRefNumber, 'Job Reference Number')"
          />
        </h4>
        <h4 v-if="$route.name !== 'projects-details'" class="mb-1">{{ headerDataObj.titleSection.headTitle }}</h4>
        <p class="mb-1 mr-2" v-html="headerDataObj.titleSection.subTitle"></p>
      </div>

      <div class="col-md-6 slide-panel-header-buttons">
        <div class="row text-left text-sm-right h-100 align-items-center float-right mr-0 mb-3 mb-sm-0">
          <!-- Action Button -->
          <b-dropdown
            v-if="headerDataObj.isActionButtonEnabled"
            id="details-action-btn-dropdown"
            split
            ref="dropdown"
            size="md"
            class="ml-1 mr-2 split-drop-down split-drop-down-info"
            @click="onActionItemClicked(headerDataObj.defaultAction)"
          >
            <template v-slot:button-content> <span class="fa fa-plus mr-2"></span> Action </template>

            <b-dropdown-item v-for="action in headerDataObj.actions" v-bind:key="action.id" @click="onActionItemClicked(action)">{{ action.menuTitle }}</b-dropdown-item>
            
            <b-dropdown-item 
              v-if="headerDataObj.isPreviewEnabled" 
              v-bind:disabled="isDisabled" 
              class="u-hidden show-1160-below"
              v-bind:class="{'text-muted': isDisabled}"
              :id="`${pageTitle}-sidepanel-header-preview-button`"
              @click.prevent="onPreview"
              >
                Preview
            </b-dropdown-item>

            <b-dropdown-item 
                v-if="headerDataObj.isEmailEnabled"
                @click.prevent="onActionItemClicked(headerDataObj.email)"
                :id="`${pageTitle}-sidepanel-header-email-button`"
                 class="u-hidden show-1160-below">
                 Email
            </b-dropdown-item>
          
          </b-dropdown>

          <div class="group-btn-holder hide-1160-below mx-2" v-if="headerDataObj && (headerDataObj.isPreviewEnabled || headerDataObj.isEmailEnabled)">
            <div class="btn-group">
              <a
                v-if="headerDataObj.isPreviewEnabled"
                class="btn ss-primary-font-color ss-light-grey-bg"
                v-bind:disabled="isDisabled"
                @click.prevent="onPreview"
                v-bind:class="{'text-muted': isDisabled}"
                href="#"
                :id="`${pageTitle}-sidepanel-header-preview-button`"
              >
                <i v-if="!isDisabled" class="fa fa-print u-mr-xsmall"></i>
                <img v-if="isDisabled" src="@/assets/images/loading-spinner.gif" class="loading" alt="Loading..." />
                {{ printLabel }}
              </a>
              <a
                v-if="headerDataObj.isEmailEnabled"
                @click.prevent="onActionItemClicked(headerDataObj.email)"
                class="btn ss-primary-font-color ss-light-grey-bg"
                href="#"
                :id="`${pageTitle}-sidepanel-header-email-button`"
              >
                <i class="fa fa-envelope u-mr-xsmall"></i>Email
              </a>
            </div>
          </div>

          <div class="close-btn-holder ml-2">
            <a @click="onClosePanel" class="btn close-button ss-danger-bg text-white" :id="$route.name + `-sidepanel-header-close-button`">
              <i class="fa fa-close"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="saving-status">
        <transition name="fade" mode="out-in" v-on:enter="hideNotifications">
          <div v-if="isStatusSaving" class="text-muted status"><i class="fa fa-info-circle"></i>&nbsp;{{ lblStatusMessage }}</div>

          <div v-if="isStatusSaved" class="text-success status"><i class="fa fa-check-circle"></i>&nbsp;Saved.</div>
        </transition>

        <div v-if="isStatusError" class="text-danger status"><i class="fa fa-exclamation-circle"></i>&nbsp;Sorry, we failed to save your changes.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import ApplicationService from "@/services/application";
  import EarthApplicationService from "@/services/earth-application";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import APP from "@/constants/application";
  import UtilityString from "@/utilities/strings";
  import editMixin from "@/mixin/edit-mixin";
  import {intersection} from "lodash/array";
  import ProjectServices from "@/services/projects";
  const api = new ProjectServices();
  const utility = new UtilityString();
  const app = new ApplicationService();
  const earth = new EarthApplicationService();

  export default {
    mixins: [editMixin],
    props: {
      headerDataObj: {
        type: Object,
        required: true,
      },
      isIframe: {
        type: Boolean,
      },
    },
    data() {
      return {
        lblStatusMessage: "Loading...",
        EVENT_ACTION: {},
        isDisabled: false,
        printLabel: "Preview",
        pageTitle: null,
        isEditingRefNumber: false,
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          modalTitle: "",
          data: {},
        },
        project: {},
        projectOriginalState: {},
        timer: null,
      };
    },
    methods: {
      editProjectReference() {
        if (this.$route.name === "projects-details") {
          this.isEditingRefNumber = !this.isEditingRefNumber;
          this.$refs.ref_number.focus();
        }
        return;
      },
      getParentRoute() {
        let currentRoute = this.$route.name;
        let childRoutes = [
          "client-details",
          "pricing-details",
          "pages-details",
          "lead-details",
          "prospect-details",
          "contact-details",
          "quote-details",
          "purchase-order-details",
          "invoice-details",
          "supplier-details",
          "template-details",
          "inventory-details",
          "payment-details",
          "admin-details",
          "admin-details-modules",
          "search-details",
          "employees-details",
          "template-documents-details",
        ];
        let isFound = intersection([currentRoute], childRoutes);
        if (isFound.length > 0) {
          return this.$route.matched[0].path;
        } else {
          return "/dashboard";
        }
      },
      async loadData() {
        if (this.$route.name === "projects-details") {
          this.project = await api.getProjectDetails(this.$route.params.id);
          this.projectOriginalState = JSON.parse(JSON.stringify(this.project));
        }
      },
      onActionItemClicked(action) {
        //Pass action to global navigation action handler...
        app.actionNavigation(action);
      },
      onClosePanel() {
        app.closeSidePanel();
        const routeName = this.getParentRoute();
        // this.$router.go(-1);
        if (this.$route.name === "projects-details") {
          // this.$router.go(-1);
          this.$router.push("/dashboard");
        } else {
          this.$router.push(routeName);
        }
      },
      onEventSaving() {
        this.isStatusSaved = false;
        this.isStatusError = false;
        this.isStatusSaving = true;
      },
      onEventSaved() {
        this.isStatusError = false;
        this.isStatusSaving = false;
        this.isStatusSaved = true;
      },
      onEventError() {
        this.isStatusSaving = false;
        this.isStatusSaved = false;
        this.isStatusError = true;
      },
      hideNotifications() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        //Wait 500 miliseconds before pushing data.
        this.timer = setTimeout(() => {
          this.isStatusSaved = false;
          this.isStatusError = false;
          this.isStatusSaving = false;

          this.lblStatusMessage = "Saving...";
        }, 3000);
      },
      async onPreview() {
        // if (this.$isDev) {
        const document = {
          type: "preview",
          params: {
            moduleId: this.headerDataObj.print.moduleId,
            id: this.headerDataObj.print.id,
          },
          url: null,
        };
        this.showModal(this.EVENT_ACTION.PDF.View, "PDF", "", document);
        // } else {
        //   this.printLabel = "Loading...";
        //   this.isDisabled = true;

        //   //Always check for new document
        //   this.document = await app.print(this.headerDataObj.print.moduleId, this.headerDataObj.print.id);

        //   let documentStatus = !!this.document ? this.document.url : false;

        //   //Wait until PDF is ready to preview
        //   if (this.document == null) {
        //     setTimeout(() => {
        //       this.onPreview();
        //     }, 1000);
        //   } else {
        //     //Document is ready, show preview modal
        //     this.showPrintPreview(this.document);
        //   }
        // }
      },
      showPrintPreview(document) {
        if (document) {
          //Pass data to global data store
          this.$store.dispatch("setApplicationModalIframe", {
            url: document.url,
            title: document.documentName,
            showToolbar: true,
            data: document,
          });

          let action = {
            id: 1,
            eventType: this.EVENT_ACTION.IFRAME.Print,
            menuTitle: document.documentName,
            modalTitle: document.documentName,
            componentName: this.EVENT_ACTION.IFRAME.Print,
            saveButtonTitle: "",
            data: document,
            icon: "",
            UI_TYPE: APP.UI_TYPE.ModalIFrame,
          };

          app.showModal(action);

          //Reset Print label and re-enable preview button
          this.printLabel = "Preview";
          this.isDisabled = false;
        }
      },
      showModal(paramModalName, modalTitle, sendButtonText, data) {
        this.modalStatus.hideShowModal = true;
        this.modalStatus.modalName = paramModalName;
        this.modalStatus.modalTitle = modalTitle;
        this.modalStatus.modalSaveButtonTitle = sendButtonText;
        this.modalStatus.data = data;

        this.$store.dispatch("toggleModalStatus", this.modalStatus);
      },
      async updateField(propertyName, propertyValue, displayName) {
        let editObj = {};
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.id = this.project.intProjectID;

        let isValid = await this.isValidated(propertyName, propertyValue);

        if (isValid) {
          this.postAPIData(editObj);
        }
      },

      //Run validation on edit for certain fields
      async isValidated(propertyName, propertyValue) {
        this.txtValidatation = "";

        //Prevent empty value from being sent
        if (propertyValue == null || propertyValue.length == 0) return false;

        //Validate Project Reference Number
        if (propertyName === "projectRefNumber") {
          let lookupResult = await api.lookupReference(this.project.intProjectID, propertyValue);

          //If a reference with the same value exist then fail validation
          if (lookupResult) {
            this.txtValidatation = "Reference number already exist!";

            return false;
          } else return true;
        }

        return true;
      },

      async postAPIData(dataObj) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          api.updateData(dataObj);
        }, 500);
      },
    },
    async mounted() {},
    async created() {
      this.EVENT_ACTION = EVENTS;
      await this.loadData();
      this.pageTitle = this.$route.meta.gtm;

      this.$root.$on(this.savingEvent, this.onEventSaving);
      this.$root.$on(this.savedEvent, this.onEventSaved);
      this.$root.$on(this.errorEvent, this.onEventError);

      this.hideNotifications();
    },
    beforeDestroy() {
      if (this.timer) clearInterval(this.timer);

      //Unscribe from save button event...
      this.$root.$off(this.savingEvent);
      this.$root.$off(this.savedEvent);
      this.$root.$off(this.errorEvent);
    },
  };
</script>
