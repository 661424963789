import DashboardService from "@/services/dashboard";
import {DashboardFilter} from "@/interfaces/filters/DashboardFilters";
import UXTechnicianService from "@/services/ux/uxtechnicians";
import {DashboardStats} from "@/interfaces/modules/dashboard/DashboardStats";
import {DashboardState} from "@/interfaces/modules/dashboard/DashboardState";

const dashboardService = new DashboardService();
let loading = false;
//Refresh Dashoard Data
const reloadData = async ({state, commit}, filterParam) => {
  const page = state.page;
  if (!loading) {
    loading = true;
    let results = await dashboardService.getProjects({...filterParam, page});

    commit("setDashboardFilterState", filterParam);
    commit("setProjects", {projects: results.resources, page: page});

    commit("setTotal", results.total);
    if (results.resources.length > 0) commit("setPage", page + 1);
    loading = false;
  }
};

//Refresh Dashboard Stats
const reloadDashboardStatsData = async ({state, commit}, isFirstTimeLoad) => {
  let currentVersion = "";

  currentVersion = await state.currentStatsVersion;
  let results = await dashboardService.getDashboardStats(currentVersion, isFirstTimeLoad);

  if (results !== undefined && results.resources.summary.fullSizeBoxs.length > 0) {
    commit("setCurrentStatsVersion", results.version);
    commit("setDashboardStats", results.resources);
  } else {
    //Make API call do initiate dashboard configuration
    await dashboardService.initializeAndConfigureDashboard();
  }
};

const loadTechnician = ({commit}, technicianStatus) => {
  commit("setTechnicianDropdown", technicianStatus);
};

export default {
  state: {
    page: 1,
    filter: {sortOrder: ""},
    total: null,
    currentStatsVersion: "",
    dashboardStats: null,
    projects: [],
    selectedProjects: [],
    techniciansList: [],
    technicianStatusList: [],
    isSidebarVisible: false,
    isTopNavVisible: false,
    isSidebarNotifsVisible: false,
    modalStatus: {
      hideShowModal: false,
      modalName: "",
      modalTitle: "",
      isSaveButtonHidden: false,
      modalSaveButtonTitle: "Save",
      message: "",
      moduleId: null,
      isInEditMode: false,
      actionId: null,
      data: null,
      dataObj: null,
      dataListing: [],
    },
  } as DashboardState,

  getters: {
    getDashboardStats: (d) => {
      return d.dashboardStats;
    },
    getCurrentStatsVersion: (d) => {
      return d.currentStatsVersion;
    },

    getModalStatus: (d) => {
      return d.modalStatus;
    },
    getSidebarNotifsStatus: (d) => {
      return d.isSidebarNotifsVisible;
    },
    getSidebarStatus: (d) => {
      return d.isSidebarVisible;
    },
    getTopNavbarStatus: (d) => {
      return d.isTopNavVisible;
    },
    getProjects: (d) => {
      return d.projects;
    },
    getFilter: (d) => {
      return d.filter;
    },
    getSelectedProjects: (d) => {
      return d.selectedProjects;
    },
    getTotal: (d) => {
      return d.total;
    },
    getPage: (d) => {
      return d.page;
    },
    getTechnicianDropDown(d) {
      return d.techniciansList;
    },
    getTechnicianStatusDropDown(d) {
      return d.technicianStatusList;
    },
  },

  mutations: {
    reset(state) {
      //Dummy method used to trigger /component/dashboard/filter to reset dashboard paramaters...
    },
    setDashboardStats(state, newStats) {
      state.dashboardStats = newStats;
    },
    setDashboardFilterState(state, filterState) {
      state.filter = filterState;
    },
    setPage(state, page) {
      state.page = page;
    },
    setLoading(state, d) {
      state.loading = d;
    },
    setProjects(state, data) {
      state.projects = data.page === 1 ? data.projects : [...state.projects, ...data.projects];
    },
    setCurrentStatsVersion(state, versionNumber) {
      state.currentStatsVersion = versionNumber;
    },
    setModalMessage(state, messageParam) {
      state.modalStatus.message = messageParam;
    },
    setModalStatus(state, newModalStatus) {
      state.modalStatus = newModalStatus;
    },
    setSidebarNotifsStatus(state, newSidebarNotifsStatus) {
      state.isSidebarNotifsVisible = newSidebarNotifsStatus;
    },
    setSidebarStatus(state, newSidebarStatus) {
      state.isSidebarVisible = newSidebarStatus;
    },
    setTopNavbarStatus(state, param) {
      state.isTopNavVisible = param;
    },

    updateSelectedProjectsList(state, projectIds) {
      state.selectedProjects = projectIds;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setSort(state, order) {
      if (this.state.filter == null) this.state.filter = {};

      state.filter.sortOrder = order;
    },
    setTechnicianDropdown(state, apiTechs) {
      state.techniciansList = apiTechs;
    },
  },

  actions: {
    async resetDashboard({state, commit}) {
      commit("reset");
    },
    async resetSelectedProjectList({state, getters, commit, rootState, rootGetters}) {
      commit("updateSelectedProjectsList", []);
    },
    async addToSelectedProjects({state, commit}, selectedProjectId) {
      let projects: string[] = [];

      projects = state.selectedProjects;
      let selected = selectedProjectId;

      let index = projects.indexOf(selected);

      //Add/Remove item from array
      if (index === -1) {
        projects.push(selected);
      } else {
        projects.splice(index, 1);
      }

      commit("updateSelectedProjectsList", projects);
    },
    async removeSelectedProjects({state, commit}, selectedProjectId) {
      let projects: string[] = [];

      projects = state.selectedProjects;
      let selected = selectedProjectId;

      let index = projects.indexOf(selected);

      //Add/Remove item from array
      if (index >= 0) {
        projects.splice(index, 1);
      }

      commit("updateSelectedProjectsList", projects);
    },
    async initializeDashboard({state, commit}) {
      // dashboardService.initializeAndConfigureDashboard();
    },
    async checkforNewDashboardStats({state, commit}, isFirstTimeLoad) {
      reloadDashboardStatsData({state, commit}, isFirstTimeLoad);
    },
    async changeSortOrder({state, commit}, param) {
      commit("setSort", param);
      reloadData({state, commit}, this.state.filter);
    },
    async getFilterResults({state, commit}, param) {
      commit("setPage", 1);
      reloadData({state, commit}, param);
    },
    async getNextFilterResults({state, commit}, param) {
      reloadData({state, commit}, param);
    },
    async getProjectsAction({state, commit}) {
      reloadData({state, commit}, null);
    },
    async setTechniciansList({commit}, param) {
      loadTechnician({commit}, param);
    },
    async toggleSidebar({commit}, param) {
      commit("setSidebarStatus", param);
    },

    async toggleSidebarNotifs({commit}, param) {
      commit("setSidebarNotifsStatus", param);
    },
    async showfullNavigation({commit}, param) {
      commit("setTopNavbarStatus", param);
      commit("setSidebarStatus", param);
    },

    async toggleModalStatus({commit}, param) {
      commit("setModalStatus", param);
    },
    async updateModalMessage({commit}, param) {
      commit("setModalMessage", param);
    },
  },
};
