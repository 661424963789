<template>
  <div>
    <b-navbar v-if="isVisibleNavBar" toggleable="lg" type="dark" class="ss-primary-bg" fixed="top">
      <b-navbar-brand href="#" class="d-flex align-items-center mr-0">
        <div @click="toggleSidebar" class="sidebar-toggler order-md-2">
          <i class="fa fa-bars font-large"></i>
        </div>
        <span @click="navigation('0', 'dashboard')" class="w-70 mr-3 order-lg-1 logo-holder">
          <img src="@/assets/images/logo.png" class="w-80 logo" />
          <span class="u-text-small company-name">{{ companyName }}</span>
        </span>
      </b-navbar-brand>

      <b-navbar-nav>
        <b-nav-form class="search-bar" @submit.prevent="goToSearch">
          <b-form-input size="lg" class="mr-sm-2" placeholder="Search" id="top-nav-search-input" v-model="search"> </b-form-input>
          <span class="top-search-icon" id="top-nav-search-button">
            <i class="fa fa-search"></i>
          </span>
        </b-nav-form>
        <TopBarActions></TopBarActions>
      </b-navbar-nav>

      <!--Global Notification -->
      <notification></notification>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto align-items-center">
        <b-nav-item class="mt-2 hide-1160-below" v-if="!this.isUsingMobile">
          <a href="#" id="bugReport" class="u-text-white mr-3" @click="captureScreen">
            <i class="fa fa-bug u-text-larger vertical-middle mb-1"></i>
          </a>

          <a @click="showModal(EVENT_ACTION.SUPPORT.New, 'Support')" class="u-text-white support-icon">
            <img src="@/assets/images/support.png" alt />
          </a>
          <!-- <a href="#" class="u-text-white" @click="toggleSidebarNotifs">
            <i class="fa fa-bell u-text-larger vertical-middle mb-1"></i>
            <b-badge class="notification-badge" variant="warning">2</b-badge>
          </a> -->

          <a href="#" class="u-text-white px-3" @click="toggleSidebarNotifs">
            <i class="fa fa-history u-text-larger vertical-middle mb-1"></i>
            <!-- <b-badge class="notification-badge" variant="warning">2</b-badge> -->
          </a>

          <a href="#" class="u-text-white">
            <!-- <i class="fa fa-gear u-text-larger vertical-middle mb-1"></i> -->
            <!-- <b-badge class="notification-badge" variant="warning">2</b-badge> -->
          </a>
        </b-nav-item>
        <b-nav-item-dropdown right class="user-dropdown hide-1160-below" id="top-nav-avatar-icon">
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <!-- <img
              src="@/assets/images/user-profile.png"
              alt="User profile image"
              class="user-avatar"
            />-->
            <span class="avatar small" v-if="$auth.isAuthenticated" id="top-nav-avatar-nickname">
              {{ nickName }}
            </span>
          </template>
          <b-dropdown-item v-if="$auth.isAuthenticated" id="top-nav-avatar-username">
            {{ $auth.user.name }}
          </b-dropdown-item>
          <!-- <b-dropdown-item href="#">{{ $auth.app_metadata.tenantId }}</b-dropdown-item> -->
          <b-dropdown-item v-if="$auth.isAuthenticated" id="top-nav-avatar-add-edit-users" @click="showModal(EVENT_ACTION.EMPLOYEE.New, 'New Employee')">Add New Employee</b-dropdown-item>

          <b-dropdown-item v-if="$auth.isAuthenticated" id="top-nav-avatar-edit-job-type" @click="showModal(EVENT_ACTION.SETTINGS.SpecificListing, `Edit Job Type`, MODULE.PROJECTS.Type, null, false)"
            >Edit Job Type
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$auth.isAuthenticated"
            id="top-nav-avatar-edit-job-status"
            @click="showModal(EVENT_ACTION.SETTINGS.SpecificListing, `Edit Job Status`, MODULE.PROJECTS.Tech, null, false)"
            >Edit Job Status
          </b-dropdown-item>
          <b-dropdown-item v-if="$auth.isAuthenticated && $isDev" id="top-nav-avatar-manage-billing" @click="userManagement('https://billing.stripe.com/p/login/4gwdSGaFA5xwaEE8ww')"
            >Manage Your Billing
          </b-dropdown-item>

          <b-dropdown-item v-if="$auth.isAuthenticated" id="top-nav-avatar-manage-user" @click="userManagement('https://jupiter.snapsuite.io/#/employees/table')">Manage User </b-dropdown-item>

          <b-dropdown-item v-if="$auth.isAuthenticated" id="top-nav-avatar-new-manage-user" @click="navigation(1, '/employees', false)">New Manage User </b-dropdown-item>

          <b-dropdown-item v-if="!$auth.isAuthenticated" @click="login" id="top-nav-avatar-login">Login </b-dropdown-item>
          <b-dropdown-item v-if="$auth.isAuthenticated" @click="logout" id="top-nav-avatar-logout">Sign Out </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item @click="showModal(EVENT_ACTION.SUPPORT.New, 'Support')" right id="top-nav-help" class="mt-2 text-center top-help-box ss-primary-dark-bg clickable hide-1024-below">
          <span class="question-mark">?</span>
          <span class="d-block u-text-white u-text-small">Help</span>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
  import TopBarActions from "@/layouts/top-bar-actions.vue";
  import notification from "@/components/_universal/global/notification.vue";
  import {mapActions, mapState} from "vuex";
  import AuthService from "@/auth/authService";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import mobileMixin from "@/mixin/mobile-mixin";
  import globalSettings from "@/mixin/global-settings-mixin";
  import bugReport from "@/mixin/bug-report";

  const auth = AuthService.getInstance();
  export default {
    mixins: [mobileMixin, globalSettings, bugReport],
    data() {
      return {
        EVENT_ACTION: {},
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          modalTitle: "",
          data: {},
        },
        search: "",
      };
    },
    watch: {
      $route: {
        deep: true,
        handler(value) {
          if (this.search && value.name === "SearchPage") {
            this.$router.replace({
              name: "SearchPage",
              query: {
                q: this.search,
              },
            });
          }
        },
      },
      search(value) {
        if (value.length >= 2 && this.$route.name !== "SearchPage") {
          this.$router.push({
            name: "SearchPage",
            query: {
              q: value,
            },
          });
        } else if (this.$route.name === "SearchPage" && value.length >= 2) {
          this.$router.replace({
            name: "SearchPage",
            query: {
              q: value,
            },
          });
        } else if (this.$route.name === "SearchPage" && value.length === 0) {
          this.$router.push(this.origin);
          // reset
          this.setSearchOrigin(null);
        }
      },
    },
    methods: {
      ...mapActions(["setSearchOrigin"]),
      goToSearch() {
        if (this.$route.name !== "SearchPage" && this.search.length > 0) {
          this.$router.push({
            name: "SearchPage",
            query: {
              q: this.search,
            },
          });
        }
      },
      userManagement(url) {
        url = url + auth.getQueryString();

        this.navigation(1, url, true, "ManageUsers");
      },

      //Duplicate method, need to go to a centralize location
      navigation(id, url, iframe, friendlyUrl) {
        //Ensure url is not blank
        if (url) {
          //There are two types of url, iframe and the vuejs url
          if (iframe) {
            //Iframe url, set the vuex store properties
            this.$store.dispatch("setApplicationIframe", {
              showIframe: true,
              iframeUrl: url,
              friendlyUrl: friendlyUrl,
            });
          } else {
            //Reset iframe state to display false
            this.$store.dispatch("setApplicationIframe", {
              showIframe: false,
              iframeUrl: "",
            });

            //Route to Vuejs component
            this.$router.push({path: url});
          }
        }
      },
      toggleSidebar() {
        if (this.showSidebar === true) {
          this.$store.dispatch("toggleSidebar", false);
        } else {
          this.$store.dispatch("toggleSidebar", true);
        }
      },

      toggleSidebarNotifs() {
        if (this.showSidebarNotifs === true) {
          this.$store.dispatch("toggleSidebarNotifs", false);
        } else {
          this.$store.dispatch("toggleSidebarNotifs", true);
        }
      },

      // Log the user in
      login() {
        this.$auth.loginWithRedirect();
      },
      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      },
    },
    computed: {
      ...mapState({
        origin: (state) => state.Application.searchOrigin,
      }),
      isVisibleNavBar() {
        return this.$store.getters.getTopNavbarStatus;
      },
      showSidebar() {
        return this.$store.getters.getSidebarStatus;
      },
      showSidebarNotifs() {
        return this.$store.getters.getSidebarNotifsStatus;
      },
      companyName() {
        return this.$store.getters.getApplicationConfiguration.appconfig.companyName;
      },
      nickName() {
        let nick = this.$auth.user.nickname;
        let avatarNick = nick.substring(0, 2);
        return avatarNick;
      },
    },
    components: {
      TopBarActions,
      notification,
    },
    created() {
      this.EVENT_ACTION = EVENTS;
      this.MODULE = MODULE;

      if (this.$route.name === "SearchPage") {
        this.search = this.$route.query.q;
      }
    },
  };
</script>
