<template>
  <div class="content-wrapper w-100 pt-5 mt-4" id="content-wrapper">
    <!-- <router-view name="body" v-if="!iframe.showIframe && !this.isUsingMobile" /> -->
    <router-view name="body" v-if="!iframe.showIframe" />
    <slideout-panel></slideout-panel>

    <!-- data-hj-allow-iframe="true" attribute is used by hotjar.com to track iframe interaction -->
    <vue-friendly-iframe :class="[isScheduler ? 'schedulerHeight' : '', 'app-iframe']" v-if="iframe.showIframe" data-hj-allow-iframe="true" :src="iframeUrl" @load="onIframeLoad"></vue-friendly-iframe>
  </div>
</template>

<script>
  import Dashboard from "@/views/Dashboard.vue";
  import mobileMixins from "@/mixin/mobile-mixin";
  export default {
    mixins: [mobileMixins],
    data() {
      return {
        currentComponent: Dashboard,
      };
    },
    methods: {
      onIframeLoad() {
        //When the iframe loads, change the browser url
        let updateUrl = this.$store.getters.getIframeProperties.friendlyUrl;

        if (updateUrl) {
          this.$router.replace(updateUrl);
        }
      },
    },
    computed: {
      iframeUrl() {
        return this.$store.getters.getIframeProperties.iframeUrl;
      },
      iframe() {
        return this.$store.getters.getIframeProperties;
      },
      isScheduler() {
        return this.$route.name === "scheduler";
      },
    },
    components: {
      //Dashboard
    },
  };
</script>
