
































import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import {mapState} from "vuex";
import {IInfo} from "@/interfaces/IUXInfo";
import SearchService from "@/services/search";
const searchService = new SearchService();
export default {
  props: {
    dataObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      EVENT_ACTION: {},
      stats: {
        data: [
          {
            id: 1,
            title: "Total",
            numberValue: "0",
            description: null,
            class: "ss-primary-font-color",
            border: "border-left-purple",
            uiName: null,
            isCurrency: false,
            isHighlightSection: false,
            subText: null,
          },
          {
            id: 2,
            title: "-",
            numberValue: "0",
            description: null,
            class: "ss-orange-font-color",
            border: "border-left-orange",
            uiName: null,
            isCurrency: false,
            isHighlightSection: false,
            subText: null,
          },
          {
            id: 3,
            title: "-",
            numberValue: "$0.00",
            description: null,
            class: "ss-primary-blue-font-color",
            border: "border-left-blue",
            uiName: null,
            isCurrency: false,
            isHighlightSection: false,
            subText: null,
          },
          {
            id: 3,
            title: "-",
            numberValue: "$0.00",
            description: null,
            class: "ss-primary-blue-font-color",
            border: "border-left-blue",
            uiName: null,
            isCurrency: false,
            isHighlightSection: false,
            subText: null,
          },
          {
            id: 5,
            title: "-",
            numberValue: "$0.00",
            description: null,
            class: "ss-info-bg",
            border: "",
            uiName: null,
            isCurrency: false,
            isHighlightSection: true,
            subText: "Tax: $0.00",
          },
          {
            id: 6,
            title: "-",
            numberValue: "$0.00",
            description: null,
            class: "ss-info-bg",
            border: "",
            uiName: null,
            isCurrency: false,
            isHighlightSection: true,
            subText: "Tax: $0.00",
          },
        ],
      },
      statTimer: null,
    };
  },
  computed: {
    ...mapState({
      infoFilter: (state: any) => state.Application.list.filter,
    }),
    infoData() {
      if (!this.stats.data) return;
      return this.stats.data.filter((i) => !i.isHighlightSection);
    },
    infoHighlightsData() {
      if (!this.stats.data) return;
      return this.stats.data.filter((i) => i.isHighlightSection);
    },
  },
  methods: {
    onRequestToUpdateTotal(newValue) {
      if (this.$refs.divGrandTotal === undefined || this.$refs.divGrandTotal.length === 0) return;
      this.$refs.divGrandTotal[0].innerText = newValue;
    },
    title(title) {
      return title
        ? title
            .split(" ")
            .join("-")
            .toLowerCase()
        : Math.floor(Math.random());
    },
    async getStats() {
      let info = new Object() as IInfo;
      //Not implemented for now
      info.isEnabled = false;
      let id = 0;
      let apiUrl = "global/list/info";
      let infoFilter = this.infoFilter;
      if (this.dataObj.details && this.dataObj.details.id) {
        id = this.dataObj.details.id;
        apiUrl = "global/details/info";
        if (infoFilter && infoFilter.id === 0) delete infoFilter.id;
      }
      let filter = {
        actionId: this.dataObj.actionId,
        id,
        ...infoFilter,
      } as any;

      if (this.dataObj && this.dataObj.settings && (this.dataObj.settings.clientType === "Lead" || this.dataObj.settings.clientType === "Prospect") && !this.dataObj.details) {
        filter.only = this.dataObj.settings.clientType === "Lead" ? "ClientType:Lead" : "ClientType:Prospect";
      }

      let results = await searchService.getResults(apiUrl, filter);
      if (results.success) {
        info.data = results.resources;
        info.isDetailsView = true;
        this.stats = info;
      }

      if (!this.statTimer)
        this.statTimer = setInterval(async () => {
          await this.getStats();
        }, 5000);
    },
  },
  mounted() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;
    this.getStats();
    this.$root.$on(this.EVENT_ACTION.INFO.UpdateTotal, this.onRequestToUpdateTotal);
  },
  beforeDestroy() {
    if (this.statTimer) clearTimeout(this.statTimer);
    //Unscribe from save button event...
    this.$root.$off(this.EVENT_ACTION.INFO.UpdateTotal);
  },
};
