import axios from 'axios';
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;


class UXProjectsService {

	
    constructor() {
       axios.defaults.baseURL = apiUrl;

        apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }

    }


	async getProjectType() {

		let config = new API_CONFIG();
		axios.defaults.baseURL = config.API_URL;

		let results;
		let auth = AuthService.getInstance();
		let queryString = auth.getQueryString();

		results = await axios.get('ux/dropdown' + queryString + "&actionId=44", apiHeaderObj);

		return results.data.resources;
	}

	async getProjectStatus() {
		let results;
		let auth = AuthService.getInstance();
		let queryString = auth.getQueryString();

		results = await axios.get('ux/projects/status' + queryString, apiHeaderObj);

		return results.data.resources;
	}
}

export default UXProjectsService;
