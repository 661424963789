














export default {
  name: "ai-dropdown",
  methods: {
    summarize() {
      this.$emit("summarize");
    },
  },
};
