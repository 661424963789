<template>
  <div class="col-md-12 mb-2 tech-report-filters">
    <div class="c-card u-text-centerd py-2 px-4">
      <div class="row">
        <div class="col-xl-2 col-md-6 mb-2 px-0 pr-md-3">
          <v-select
            class="w-100"
            id="technician-report-filter-by-technician"
            placeholder="Filter By Technician"
            :options="uxTechniciansList"
            v-model="technicianSelected"
            :reduce="(tech) => tech.id"
            label="displayName"
            @input="reloadData"
          >
          </v-select>
        </div>
        <div class="col-xl-2 col-md-6 mb-2 px-0 pr-xl-3">
          <v-select
            class="w-100"
            id="technician-report-filter-by-client"
            placeholder="Filter By Client"
            :options="uxClientsList"
            v-model="clientSelected"
            :reduce="(client) => client.id"
            label="displayName"
            @input="reloadData"
          ></v-select>
        </div>
        <div class="col-xl-2 col-md-6 mb-2 px-0 pr-md-3">
          <v-select
            class="w-100"
            id="technician-report-filter-by-work-order"
            placeholder="Filter By Work Order"
            label="displayName"
            :options="uxWorkOrderList"
            v-model="workOrderSelected"
            :filterable="false"
            @search="onSearchWorkOrder"
            @input="reloadData"
          >
            <template slot="no-options">
              Work Order not found
            </template>
          </v-select>
          <!-- <v-select
            class="w-100"
            id="technician-report-filter-by-work-order"
            placeholder="Filter By Work Order"
            :options="uxWorkOrderList"
            v-model="workOrderSelected"
            :reduce="(job) => job.id"
            label="displayName"
            @input="reloadData"
          ></v-select> -->
        </div>
        <div class="col-xl-2 col-md-6 mb-2 px-0">
          <date-picker id="technician-report-filter-date-range" v-model="valueDate" type="date" format="YYYY-MM-DD" value-type="format" lang="en" range placeholder="Select Date Range" class="w-100" />
        </div>
        <div class="mb-2 mb-2 ml-lg-auto pr-0 pr-sm-3">
          <b-form-group class="mb-0 custom-group-radio-buttons technician-dashboard-group-buttons" v-slot="{ariaDescribedby}">
            <b-form-radio-group
              id="technician-report-filter-timeline"
              button-variant="outline-primary"
              v-model="selectedRadio"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="text-right">
          <button @click.prevent="downloadExcel" class="btn c-btn-outlined-info btn-sm" id="technician-report-download-report"><i class="fa fa-download u-text-large"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";
  import UXTechnicianService from "@/services/ux/uxtechnicians";
  import MODULE from "@/constants/modules";
  import {debounce} from "lodash/function";
  let global = new GlobalServices();
  let tech = new UXTechnicianService();
  export default {
    data() {
      return {
        selectedRadio: "thismonth",
        options: [
          {text: "This Week", value: "thisweek"},
          {text: "Last Week", value: "lastweek"},
          {text: "This Month", value: "thismonth"},
          {text: "This Year", value: "thisyear"},
        ],
        uxTechniciansList: [],
        technicianSelected: null,
        uxClientsList: [],
        clientSelected: null,
        uxWorkOrderList: [],
        workOrderSelected: null,
        valueDate: [],
        filters: {},
      };
    },
    watch: {
      valueDate(value) {
        if (value && value.length === 2 && value[0] !== "" && value[1] !== "") {
          this.selectedRadio = null;
        } else {
          this.selectedRadio = "thismonth";
        }
        this.reloadData();
      },
      selectedRadio(value) {
        if (value) {
          if (this.valueDate.length > 0) this.valueDate = [];
          this.reloadData();
        }
      },
    },
    methods: {
      reloadData() {
        this.filters = {
          DateFilter: this.selectedRadio,
        };

        if (this.technicianSelected) {
          this.filters.EmployeeId = this.technicianSelected;
        }

        if (this.clientSelected) {
          this.filters.ClientId = this.clientSelected;
        }

        if (this.workOrderSelected) {
          this.filters.ProjectId = this.workOrderSelected.id;
        }

        if (this.valueDate && this.valueDate.length === 2 && this.valueDate[0] !== "" && this.valueDate[1] !== "") {
          this.filters.StartDate = this.valueDate[0];
          this.filters.EndDate = this.valueDate[1];
        }

        this.$emit("reload", this.filters);
      },
      onSearchWorkOrder(search, loading) {
        if (search.length > 0) {
          loading(true);
          this.searchWorkOrder(loading, search, this);
        }
      },
      searchWorkOrder: debounce(async (loading, search, vm) => {
        vm.uxWorkOrderList = await global.getUXDropDown(MODULE.DASHBOARD.ActionId, null, null, null, null, null, {disabled: false, deleted: false}, search);
        loading(false);
      }, 500),
      async loadData() {
        this.reloadData();
        this.uxTechniciansList = await tech.getTechnicians();
        this.uxClientsList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
        this.uxWorkOrderList = await global.getUXDropDown(MODULE.DASHBOARD.ActionId);
      },
      async downloadExcel() {
        this.$emit("download", this.filters);
      },
    },
    mounted() {
      this.loadData();
    },
  };
</script>
