<template>
  <div>
    <!-- Detail card -->
    <div class="c-card u-p-small u-mb-small mx-2 pb-3">
      <div class="row">
        <!-- Left Section -->
        <div class="col-md-6 text-left">
          <h4 class="ss-red-font-color mb-0">{{dataObj.leftSection.title}}</h4>
          <div>
              <p v-for="label in dataObj.leftSection.labels" v-bind:key="label.id" class="mb-0">
                <span class="text-muted mb-0">{{label.title}}</span>
                <span class="mb-0 mr-3">{{ label.labelValue }}</span>
              </p>
          </div>
        </div>

        <!-- Right Section -->
        <div class="col-md-6 mt-sm-0 mt-3 text-sm-right">
          <h4 class="ss-red-font-color mb-0">{{dataObj.rightSection.title}}</h4>

          <p v-for="label in dataObj.rightSection.labels" v-bind:key="label.id" class="mb-0">
            <span class="text-muted mb-0">{{label.title}}</span> &nbsp;
            <span class="mb-0">{{ label.labelValue }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- detail card -->
    <!-- <div class="c-card u-p-small u-mb-small mx-2 pb-4 d-none">
      <div class="row">
        <div class="col-md-12">
          <h4>
            Status: Dispatched -
            <span class="ss-red-font-color">Due Date</span>
          </h4>
        </div>
        <div class="col-md-6 mb-2">
          <p class="text-muted mb-0">Start Date:</p>
          <p class="mb-0">05-Jun-2020</p>
          <p class="text-muted mb-0">End Date:</p>
          <p class="mb-0">05-Jun-2020</p>
        </div>
        <div class="col-md-6">
          <div class="c-choice c-choice--checkbox u-mb-zero">
            <input class="c-choice__input" id="emergency" name="checkboxes" type="checkbox" />
            <label class="c-choice__label" for="emergency">Job by Contractor</label>
          </div>
          <div class="c-choice c-choice--checkbox u-mb-zero">
            <input class="c-choice__input" id="emergency" name="checkboxes" type="checkbox" />
            <label class="c-choice__label" for="emergency">Emergency Job</label>
          </div>
          <div>
            <div class="c-field"></div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import UtilityString from "@/utilities/strings";

const utility = new UtilityString();
const app = new ApplicationService();


export default {
  props: {
    infoSectionDataObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataObj: {},
      project: {},
      EVENT_ACTION: {},

    };
  },
  methods: {
    async loadData() {
      this.dataObj = this.infoSectionDataObj;
    },

  },
  computed: {

  },
  components: {

  },
  mounted() {

  },
  async created() {
    this.EVENT_ACTION = EVENTS;
    await this.loadData();
  }
};
</script>
