<template>
  <div>
    <file-pond
      name="file"
      ref="pond"
      label-idle="
        <div class='filepond-custom-label text-center'>
          <i class='fa fa-picture-o'></i>
          <h3>Paste, Upload, or Drag & Drop Documents/Photos here</h3>
          <p class='text-muted mb-4'>Upload up to 20 files at once</p>
          <button id='file-browser' type='button' class='btn ss-info-bg border-0 btn-secondary btn-sm'>
            <i class='fa fa-upload u-mr-xsmall'></i>Browse 
          </button>
        </div>
      "
      v-bind:allow-multiple="true"
      accepted-file-types="image/jpeg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/*, audio/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
      maxFileSize="5000mb"
      :maxFiles="20"
      :server="getServerConfig()"
      allowFileEncode
      :allow-paste="true"
      v-on:init="handleFilePondInit"
      :credits="[]"
      :maxParallelUploads="1"
    />
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";

  // Import Vue FilePond
  import vueFilePond from "vue-filepond";

  // Import FilePond styles
  import "filepond/dist/filepond.min.css";

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
  import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

  // Create component
  const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
  const global = new GlobalServices();

  export default {
    name: "uploader",
    props: {
      isFileShare: {
        type: Boolean,
        default: false, // Type ID for Clients Documents
      },
      documentTypeId: {
        type: Number,
        default: 2, // Type ID for Clients Documents
      },
      moduleId: {
        type: Number,
        default: 0,
      },
      actionId: {
        type: Number,
        required: true,
      },
      id: {
        type: Number,
        required: true,
      },
    },
    methods: {
      handleFilePondInit() {
        // FilePond instance methods are available on `this.$refs.pond`
        document.getElementById("file-browser").addEventListener("click", () => {
          this.$refs.pond.browse();
        });
      },
      getServerConfig() {
        return {
          revert: null,
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            const reader = new FileReader();
            reader.onloadend = async () => {
              try {
                const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                const payload = {
                  id: this.id,
                  // description: "some description",
                  description: null,
                  file: base64String,
                  fileName: file.name.toLowerCase(),
                  size: file.size,
                  intModuleID: this.moduleId,
                  actionId: this.actionId,
                  intDocumentTypeID: this.documentTypeId,
                  mimeType: file.type,
                  isFileShare: this.isFileShare,
                };
                const res = await global.uploadGenericFile(payload);

                if (res && res.success) {
                  this.$emit("uploadSuccess", res.resources);
                  load(res.resources.documentId);

                  // this.$nextTick(() => {
                  //   this.$refs.pond.removeFile(file.id);
                  // });
                } else {
                  error("Invalid File");
                }
              } catch (e) {
                // console.log("[INFO] Upload error - ", e);
              }
            };

            reader.readAsDataURL(file);
          },
          // revert: (uniqueFileId) => {
          //   const index = this.form.uploadedFiles.findIndex(e => parseInt(e.documentId) === parseInt(uniqueFileId))
          //   console.log("onRevert - ", uniqueFileId, index)
          //   this.$refs.pond.removeFile(uniqueFileId)
          // }
        };
      },
    },
    components: {
      FilePond,
    },
  };
</script>
<style lang="scss">
  .filepond-custom-label {
    i.fa-picture-o {
      font-size: 80px;
      color: #dddddd;
      margin-bottom: 30px;
    }
    h3 {
      font-weight: 500 !important;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
    }
  }

  .file-uploader .filepond--root .filepond--drop-label {
    min-height: 300px;
    background: #f0f7f7;
  }

  .filepond--wrapper {
    background: #f0f7f7 !important;
  }

  .filepond--root.filepond--hopper {
    height: 55.5vh !important;
    overflow: hidden;
    border: 1px dashed #b9b8b6;
    overflow: hidden;
    border-radius: 4px;
    background: #f0f7f7 !important;
    margin-bottom: 0px !important;
  }

  .filepond--panel.filepond--panel-root,
  .filepond--panel-center.filepond--panel-root,
  .filepond--panel-bottom.filepond--panel-root,
  .filepond--list-scroller,
  .filepond--list-scroller[data-state="overflow"] {
    background: #f0f7f7 !important;
  }

  .filepond--list-scroller[data-state="overflow"] {
    mask: none !important;
  }

  .file-uploader:not(.email-attachment-uploader) {
    .filepond--list {
      // display: grid;
      // grid-template-columns: 1fr 1fr 1fr;
      // grid-gap: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-right: 10px !important;
      max-height: 245px;
      padding-bottom: 20px;
      overflow-y: auto;
      overflow-x: auto;
    }

    .filepond--item {
      // width: 100%;
      position: relative;
      transform: none !important;

      .filepond--file {
        display: flex;
        align-content: space-between;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
</style>
