import axios from "axios";
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";

let auth = AuthService.getInstance();
let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;




class NotesService {

    private static _instance: NotesService;

   
    constructor() {
       axios.defaults.baseURL = apiUrl;

        apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }

    }


    //Used for loading data from the universal notes component
    //Supporting all types of modules, eg... Permits, Deposits etc... (Except the actual notes)
    async getUniversalNotes(id, typeId) {

        axios.defaults.baseURL = apiUrl;

        let queryString = auth.getQueryString() + "&typeId=" + typeId + "&projectId=" + id;

        let dataResults = await axios.get('notes/list' + queryString, apiHeaderObj);

        return dataResults.data.resources;

    }

    //Used specifically for the project notes component
    async getNotes(id, typeId) {

        axios.defaults.baseURL = apiUrl;

        let queryString = auth.getQueryString() + "&typeId=" + typeId + "&projectId=" + id;

        let dataResults = await axios.get('notes/projects' + queryString, apiHeaderObj);

        return dataResults.data.resources;

    }



    //Update Item Record
    async updateData(item) {


        this.setAPIDefaults(item);

        let result = null;

        if (item.id === 0)
            result = await axios.post("notes/add", item, apiHeaderObj).catch(function (error) { });
        else
            result = await axios.put("notes/update", item, apiHeaderObj).catch(function (error) { });


        return result.data.resources;
    }


    //Delete notes item
    async deleteItem(item) {


        this.setAPIDefaults(item);


        let result = null;

        result = await axios.post("notes/delete", item, apiHeaderObj).catch(function (error) { });


        return result;
    }

    private setAPIDefaults(item: any) {
        axios.defaults.baseURL = apiUrl;

        item = Object.assign(item, auth.getAuthObject());


    }


    static createInstance() {
        NotesService.getInstance();
    }

    static getInstance() {
        return this._instance || (this._instance = new this());
    }

}

export default NotesService;