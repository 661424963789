<template>
  <div id="app">
    <idle v-if="isIdle"></idle>
    <div v-if="$route.name === 'extended-list' || $route.name === 'pdf-preview' || $route.name === 'image-preview' || $route.name === 'no-access'" class="pt-4 w-100">
      <router-view name="body" />
      <modal></modal>
    </div>
    <div v-else class="d-flex" :class="{hideSidebar: !hideSidebar}">
      <topBar></topBar>
      <sideBar></sideBar>
      <bodylayout></bodylayout>
      <notificationsSideBar></notificationsSideBar>
      <modal></modal>
      <what-new-modal />
    </div>
  </div>
</template>
<script>
  import sideBar from "@/layouts/side-bar.vue";
  import topBar from "@/layouts/top-bar.vue";
  import bodylayout from "@/layouts/body.vue";
  import notificationsSideBar from "@/layouts/notifications-side-bar.vue";
  import idle from "@/components/_universal/global/idle-modal.vue";
  import whatNewModal from "./components/_universal/whats-new-modal.vue";

  import modal from "@/layouts/modal.vue";
  import mobileMixins from "@/mixin/mobile-mixin";
  export default {
    mixins: [mobileMixins],
    data() {
      return {
        sidePanel: {
          isOpen: false,
          name: "MainSidePanel", //Name of the side panel, incase multiple panels are open
          data: {}, //The data to be passed into the side panel as prop value
          panelObj: {}, //Reference to the side panel object, so we can close it anywhere.
        },
      };
    },
    methods: {
      initializeSidePanel() {
        //Store Side Panel Object
        let sidePanelObj = this.$showPanel({
          component: "side-panel",
          openOn: "right",
          width: "1350px",
          cssClass: "side-panel-overlay",
          props: {
            dataObj: null,
          },
        });

        sidePanelObj.hide();

        //Set Side panel details to be sored...
        this.sidePanel = {
          panelObj: sidePanelObj,
        };

        //Send Data to Side Panel
        this.$store.dispatch("setSidePanel", this.sidePanel);
      },
    },
    computed: {
      isIdle() {
        return this.$store.state.idleVue.isIdle;
      },

      hideSidebar() {
        return this.$store.getters.getSidebarStatus;
      },
    },
    components: {
      sideBar,
      topBar,
      bodylayout,
      notificationsSideBar,
      modal,
      idle,
      whatNewModal,
    },
    mounted() {
      this.initializeSidePanel();

      // Watch for the loading property to change before we check isAuthenticated
      this.$auth.$watch("loading", (loading) => {
        //Temp. Implementation to support Iframe from ProjectEarth
        let isIframe = this.$route.query.isiframe;

        if (isIframe) {
          //Hide navigation for details page - because it's being loaded from an iframe.
          this.$store.dispatch("showfullNavigation", false);
        } else {
          this.$store.dispatch("showfullNavigation", true);
        }

        if (loading === false) {
          this.$store.dispatch("applicationConfiguration");
        } else {
        }
      });
    },
    created() {},
  };
</script>
