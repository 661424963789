<template>
  <div class="row col-12">
    <b-card class="m-1 col-md-5">
      <h5 class="mb-0">Partial Invoice</h5>
      <small class="text-muted mb-3 d-block"
        >Use this option when invoicing for a large financial project or service over an extended period, and you need to split the invoice into smaller amounts.</small
      >
      <div class="d-flex">
        <div class="c-choice c-choice--checkbox mb-0">
          <input
            class="c-choice__input"
            id="partial_invoice"
            name="checkboxes"
            type="checkbox"
            v-model="partialInvoice"
            @change="updateField('isPartialInvoice', partialInvoice, 'Partial Invoice', partialInvoice, null, null, null, false, null, MODULE.INVOICE.ActionId)"
          />
          <label class="c-choice__label" for="partial_invoice">Partial Invoice</label>
        </div>
      </div>
    </b-card>
    <b-card class="m-1 col-md-5">
      <h5 class="mb-0">Edit Invoice</h5>
      <small class="text-muted mb-3 d-block">You can lock/disable this invoice from being edited. The system will automatically disable editing if it is paid in full.</small>
      <div class="d-flex">
        <div class="c-choice c-choice--radio mb-0 mr-4">
          <input v-model="isLockedFromEditing" class="c-choice__input" id="radio1" name="radios" type="radio" :value="false" @change="reload" />
          <label class="c-choice__label" for="radio1">Allow Editing</label>
        </div>
        <div class="c-choice c-choice--radio mb-0">
          <input v-model="isLockedFromEditing" class="c-choice__input" id="radio2" name="radios" type="radio" :value="true" @change="reload" />
          <label class="c-choice__label" for="radio2">Disable Editing</label>
        </div>
      </div>
    </b-card>
    <b-card class="m-1 col-md-5">
      <h5 class="mb-0">Sort Order</h5>
      <small class="text-muted mb-3 d-block">You can change the default sort order of the invoice line items by selecting from the options below.</small>

      <v-select
        class="bg-white"
        :clearable="false"
        v-model="settingsSortOrder"
        id="slidepanel-edit-quote-tabs-details-sort-order-list"
        :options="settingsSortOrderList"
        @input="onSelectSort"
        code="id"
        label="displayName"
      ></v-select>
    </b-card>

    <TaxRateSelection :data-obj="currentDataObj.details" />

    <b-card class="m-1 col-md-5">
      <h5 class="mb-0">Payment Term</h5>
      <small class="text-muted mb-3 d-block">You can change the default payment term applied to this invoice by selecting from the options below.</small>

      <v-select
        v-model="selectedPayTerm"
        :options="uxPayTerm"
        class="bg-white"
        :clearable="false"
        @input="setPaymentTerm"
        id="slidepanel-edit-quote-tabs-details-payment-term-list"
        code="id"
        label="displayName"
      ></v-select>
    </b-card>

    <RoundingMethodSelection :data-obj="currentDataObj.details" />
  </div>
</template>
<script>
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  export default {
    name: "SettingsTab",
    mixins: [editMixin],
    data() {
      return {
        partialInvoice: false,
        isLockedFromEditing: true,
        settingsSortOrder: {displayName: "Oldest to Newest", id: 1, value: "ASC"},
        settingsSortOrderList: [
          {displayName: "Oldest to Newest", id: 1, value: "ASC"},
          {displayName: "Newest to Oldest", id: 2, value: "DESC"},
          {displayName: "Sort By Name Ascending", id: 3, value: "ASC-Name"},
          {displayName: "Sort By Name Descending", id: 4, value: "DESC-Name"},
          {displayName: "Sort By Price Ascending", id: 5, value: "ASC-Price"},
          {displayName: "Sort By Price Descending", id: 6, value: "DESC-Price"},
        ],
        selectedPayTerm: null,
        uxPayTerm: [],
      };
    },
    components: {
      TaxRateSelection: () => import("@/components/_universal/utility/invoice/tax-rate.vue"),
      RoundingMethodSelection: () => import("@/components/_universal/utility/invoice/rounding-method.vue"),
    },
    methods: {
      onSelectSort(evt) {
        this.updateField("SettingsSortOrder", evt.value, "Settings Sort Order", evt.value, "SettingsSortOrder", null, null, null, null, MODULE.INVOICE.ActionId);
        setTimeout(() => {
          location.reload();
        }, 1000);
      },
      setPaymentTerm(evt) {
        this.updateField("PayTermsID", evt.id, "Payment Terms ID", evt.displayName, "PayTermsID", null, null, null, null, MODULE.INVOICE.ActionId);
      },
      reload() {
        this.updateField("IsLockedFromEditing", this.isLockedFromEditing, "Is Locked From Editing", this.isLockedFromEditing, "IsLockedFromEditing", null, null, null, null, MODULE.INVOICE.ActionId);
        setTimeout(() => {
          location.reload();
        }, 1000);
      },
    },
    async mounted() {
      this.uxPayTerm = await global.getUXDropDown(MODULE.PAYMENT.UX.PaymentTerms);
      this.partialInvoice = this.currentDataObj.details ? this.currentDataObj.details.isPartialInvoice : false;
      this.isLockedFromEditing =
        !this.currentDataObj.details || (this.currentDataObj.details && this.currentDataObj.details.isLockedFromEditing !== undefined && this.currentDataObj.details.isLockedFromEditing !== null)
          ? this.currentDataObj.details.isLockedFromEditing
          : false; // default to true so we can edit
      if (this.currentDataObj.details.settingsSortOrder) this.settingsSortOrder = this.settingsSortOrderList.find((order) => order.value === this.currentDataObj.details.settingsSortOrder);

      if (this.currentDataObj.details.payTermsId) this.selectedPayTerm = this.uxPayTerm.find((payterm) => payterm.id === this.currentDataObj.details.payTermsId);
    },
    created() {
      this.MODULE = MODULE;
    },
  };
</script>
